import React from 'react';

const CheckmarkInTabletIcon = props => {
  const {color} = props;

  return (<svg width="55" height="73" viewBox="0 0 55 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.96 0H7.16C3.2 0 0 3.21 0 7.16V65.07C0 69.03 3.21 72.23 7.16 72.23H46.96C50.92 72.23 54.12 69.02 54.12 65.07V7.16C54.12 3.2 50.91 0 46.96 0ZM51.72 65.07C51.72 67.7 49.58 69.83 46.96 69.83H7.16C4.53 69.83 2.4 67.69 2.4 65.07V7.16C2.4 4.53 4.54 2.4 7.16 2.4H46.96C49.59 2.4 51.72 4.54 51.72 7.16V65.07Z"
      fill={color ? color : "#FFF"}/>
    <path
      d="M41.8001 6.47H12.3201C9.15007 6.47 6.57007 9.04 6.57007 12.22V50.68C6.57007 53.85 9.14007 56.43 12.3201 56.43H41.8001C44.9701 56.43 47.5501 53.86 47.5501 50.68V12.22C47.5501 9.05 44.9801 6.47 41.8001 6.47ZM45.1501 50.67C45.1501 52.51 43.6501 54.02 41.8001 54.02H12.3201C10.4801 54.02 8.97007 52.52 8.97007 50.67V12.22C8.97007 10.38 10.4701 8.87 12.3201 8.87H41.8001C43.6401 8.87 45.1501 10.37 45.1501 12.22V50.68V50.67Z"
      fill={color ? color : "#FFF"}/>
    <path
      d="M27.0601 16.08C18.7801 16.08 12.0601 22.8 12.0601 31.08C12.0601 39.36 18.7801 46.08 27.0601 46.08C35.3401 46.08 42.0601 39.36 42.0601 31.08C42.0601 22.8 35.3401 16.08 27.0601 16.08ZM27.0601 43.68C20.1101 43.68 14.4601 38.03 14.4601 31.08C14.4601 24.13 20.1101 18.48 27.0601 18.48C34.0101 18.48 39.6601 24.13 39.6601 31.08C39.6601 38.03 34.0101 43.68 27.0601 43.68Z"
      fill={color ? color : "#FFF"}/>
    <path
      d="M27.0599 59.36C25.1999 59.36 23.6799 60.87 23.6799 62.74C23.6799 64.61 25.1899 66.12 27.0599 66.12C28.9299 66.12 30.4399 64.61 30.4399 62.74C30.4399 60.87 28.9299 59.36 27.0599 59.36Z"
      fill="white"/>
    <path
      d="M33.6001 25.33C33.1501 25.33 32.7101 25.51 32.3901 25.87L25.5001 33.58L22.0301 30.48C21.7201 30.2 21.3301 30.07 20.9501 30.07C20.5001 30.07 20.0601 30.25 19.7401 30.61C19.1401 31.28 19.2001 32.31 19.8701 32.91L24.5501 37.09C24.8601 37.37 25.2501 37.5 25.6301 37.5C26.0801 37.5 26.5201 37.32 26.8401 36.96L34.8201 28.04C35.4201 27.37 35.3601 26.34 34.6901 25.74C34.3801 25.46 33.9901 25.33 33.6101 25.33H33.6001Z"
      fill={color ? color : "#FFF"}/>
  </svg>)
}

export default CheckmarkInTabletIcon;
