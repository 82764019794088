import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  tableCellName: {
    fontWeight: 500,
    width: '50%',
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  head: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'var(--primary-regular)'
  },
  table: {
    wordBreak: 'break-all'
  }
});

const rows = [
  {name: "Beneficiary bank account name", path: "beneficiary_name"},
  {name: "Number or IBAN", path: "beneficiary_iban"},
  {name: "Address", path: "beneficiary_address"},
  {name: "SWIFT", path: "beneficiary_swift"},
  {name: "Correspondent bank account name", path: "correspondent_name"},
  {name: "Number or IBAN", path: "correspondent_iban"},
  {name: "Address", path: "correspondent_address"},
  {name: "SWIFT", path: "correspondent_swift"}
];

const BankInformationPart = ({
  bank_information
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>Bank information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.path}>
              <TableCell component="th" scope="row" className={classes.tableCellName}>
                {row.name}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {bank_information[row.path]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BankInformationPart;
