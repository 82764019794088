// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = withStyles(theme => ({
  root: {
    width: 32,
    height: 32,
    padding: 8,
    margin: '5px',
    textAlign: 'center',
    backgroundColor: 'var(--primary-regular)',
    borderRadius: 4,
  },

  label: {
    marginTop: '-1px',
  },
}))(Button);

const StyledInvertedButton = withStyles(theme => ({
  root: {
    minWidth: '32px',
    width: 32,
    height: 32,
    padding: 5,
    margin: '5px',
    textAlign: 'center',
    color: 'var(--primary-regular)',
    border: '1px solid var(--black-20)',
    backgroundColor: `${theme.overrides.MuiIcon.root.color}`,
    borderRadius: 4,

    '&:disabled': {
      border: '1px solid var(--primary-disabled)',
      backgroundColor: 'var(--primary-disabled)',
    },
    
    '&:hover': {
      backgroundColor: 'var(--primary-regular)',
      color: 'inherit',
      '& span': {
        color: `${theme.overrides.MuiIcon.root.color}`,
      },
    },

    '& span': {
      color: 'var(--primary-regular)',
    },
  },

  label: {
    marginTop: '-1px',
  },
}))(Button);

const IconButton = ({ children, ...props }) => {
  const inverted = props.inverted;
  return (
    inverted ? (
      <StyledInvertedButton {...props}>{children}</StyledInvertedButton>
    ) : (
      <StyledButton {...props}>{children}</StyledButton>
    )
  );
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconButton;
