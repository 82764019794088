import React from 'react';
import PropTypes from 'prop-types';

const NotificationIcon = ({ style }) => (
  <div style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      style={{ position: 'absolute' }}>
      <path
        fill="#CB7A2A"
        fillRule="evenodd"
        d="M12 0h36v36c0 6.627-5.373 12-12 12H0V12C0 5.373 5.373 0 12 0z"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ position: 'absolute', transform: 'translate(50%, 50%)' }}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#FFF"
          d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 13.5c-.497 0-.9.398-.9.888v.024c0 .49.403.888.9.888s.9-.398.9-.888v-.024a.894.894 0 0 0-.9-.888zm0-9.9c-.497 0-.9.596-.9 1.332v4.536c0 .736.403 1.332.9 1.332s.9-.596.9-1.332V7.932c0-.736-.403-1.332-.9-1.332z"
        />
      </g>
    </svg>
  </div>
);

NotificationIcon.defaultProps = {
  style: {},
};

NotificationIcon.propTypes = {
  style: PropTypes.shape({}),
};

export default NotificationIcon;
