// Core
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// Components
import FormikInput from 'components/FormikInput';
import IconButton from 'components/IconButton';
// Context
import { CountriesContext } from 'context/CountriesContext';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      }
    }
  }
});

const EntityFormStep = ({ typeKeySubject, typeRelatedSubject, entity, index, canDeleted, handlerDelete }) => {
  const { countryMenuItems } = useContext(CountriesContext);
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, canDeleted ? "pt-0 pb-2" : "pt-4 pb-2")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      {canDeleted &&
        (<Grid item xs={12} className="pb-0 pt-0">
        <IconButton 
          inverted="true" 
          className="mr-0 float-right" 
          onClick={() => handlerDelete(index, typeRelatedSubject)}
        >
          <Icon className="fa fa-trash-alt" />
        </IconButton>
      </Grid>)}
      <Grid item xs={12} sm={6} className="pb-0 pt-4">
        <FormikInput
          mandatory
          id="name"
          name={`entities[${index}].name`}
          value={entity && entity.name}
          label="Company name"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0 pt-4">
        <FormikInput
          id="localName"
          name={`entities[${index}].name_in_local_language`}
          value={entity && entity.name_in_local_language}
          label="Name in local language"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          select
          mandatory
          id="countryCode"
          name={`entities[${index}].country_code`}
          value={entity && entity.country_code}
          label="Country">
          {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="tradingName"
          name={`entities[${index}].trading_name`}
          value={entity && entity.trading_name}
          label="Trading name"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="regAddress"
          name={`entities[${index}].registered_address`}
          value={entity && entity.registered_address}
          label="Registered address"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="businessLicenseNum"
          name={`entities[${index}].business_license_number`}
          value={entity && entity.business_license_number}
          label="Business license number"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="operAddress"
          name={`entities[${index}].operational_address`}
          value={entity && entity.operational_address}
          label="Operational address"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="taxNum"
          name={`entities[${index}].tax_number`}
          value={entity && entity.tax_number}
          label="Tax number"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-3">
        <FormikInput
          id="website"
          name={`entities[${index}].website`}
          value={entity && entity.website}
          label="Website"
          hint="Example: website.com"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {typeKeySubject && !typeRelatedSubject && (
          <FormikInput
            id="role"
            name={`entities[${index}].position`}
            value={entity && entity.position}
            label="Position"
            hint="Position in the company you need to check"
          />
        )}
      </Grid>
    </Grid>
  );
};

EntityFormStep.defaultProps = {
  typeKeySubject: false,
  typeRelatedSubject: true,
  entity: null,
  index: 0,
  canDeleted: false,
  handlerDelete: () => null
};

EntityFormStep.propTypes = {
  typeKeySubject: PropTypes.bool,
  typeRelatedSubject: PropTypes.bool,
  entity: PropTypes.object,
  index: PropTypes.number,
  canDeleted: PropTypes.bool,
  handlerDelete: PropTypes.func
};

export default EntityFormStep;
