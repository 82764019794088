import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
// Hooks
import useAsync from 'hooks/useAsync';
// Instuments
import { saveComments } from 'api/questionnaire';
import { Typography } from '@material-ui/core';

const validationSchema = Yup.object().shape({
  application_review: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
  risk_assessment: Yup.string().max(256, 'Must be 256 characters or less').nullable()
});

const useStyles = makeStyles({
  root: {
    '& label': {
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'capitalize',
      color: 'var(--primary-regular)',
      transform: 'none',
      top: '-24px',
      pointerEvents: 'auto',
      '& span.fa': {
        display: 'inline',
      },
    },
  },
  textColor: {
    fontWeight: 600,
    lineHeight: '1.2rem',
    color: 'var(--primary-regular)'
  }
});


const CommentPart = ({
  application_review,
  risk_assessment,
  partner_id,
  account_id
}) => {
  const classes = useStyles();
  const [localApplicationReview, setLocalApplicationReview] = useState(application_review);
  const [localRiskAssessment, setLocalRiskAssessment] = useState(risk_assessment);

  const initialFormikValues = {
    application_review: localApplicationReview,
    risk_assessment: localRiskAssessment,
  };

  const {
    execute: saveAdminComments,
    pending: pendingSaveComments,
    status: statusSaveComments
  } = useAsync(saveComments, false);

  const handlerSendComments = (application_review, risk_assessment) => {
    saveAdminComments({ partner_id, application_review, risk_assessment, account_id })
    setLocalApplicationReview(application_review);
    setLocalRiskAssessment(risk_assessment);
  };

  useEffect(() => {
    if (!pendingSaveComments && statusSaveComments === 200) {
      toast.success("Your comments successful saved", { autoClose: 3000 });
    }
  }, [pendingSaveComments, statusSaveComments]);

  return (
    <React.Fragment>
      <Formik
        initialValues={initialFormikValues}
        validationSchema={validationSchema}
      >
        {({
          values
        }) => {
          return (
            <Form>
              <Grid
                container
                spacing={3}
                className={"mt-3 pb-1"}
                direction="row"
                justify="space-between"
                alignItems="flex-start">
                <Grid item xs={12} className="pb-0">
                  <FormikInput
                    className={classes.root}
                    id="application_review"
                    name="application_review"
                    label="Application Review - Conclusion"
                    value={localApplicationReview}
                  />
                </Grid>
                <Grid item xs={12} className="pb-0">
                  <FormikInput
                    className={classes.root}
                    id="risk_assessment"
                    name="risk_assessment"
                    label="Risk Assessment - Conclusion"
                    value={localRiskAssessment}
                  />
                </Grid>
              </Grid>
              <Grid 
                container 
                justify="space-between"
                alignItems="center"
              >
                <Typography className={classes.textColor}>
                  Please consider the environment before printing.
                </Typography>
                <Button
                  onClick={() => handlerSendComments(values.application_review, values.risk_assessment)}
                  disabled={pendingSaveComments || (
                    values.application_review === localApplicationReview && values.risk_assessment === localRiskAssessment
                  )}
                  className="mr-0">
                    Save comments
                </Button>
              </Grid>                                      
            </Form>
          );
        }}
      </Formik>
      <ToastContainer />
    </React.Fragment>
  );
}

export default CommentPart;
