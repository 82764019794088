// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
import PermissionsCheckboxes from 'components/PermissionsCheckboxes';
// Constantts 
import { 
  ROLE_ADMIN,
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX
 } from 'utils';

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  role_id: ROLE_ADMIN,
  activeUser: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .required('Required'),
  email: Yup.string()
    .required('Required')
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    }),
  phoneNumber: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable(),
});

const StepTwoFrom = ({
  accountId,
  onCreateUser,
  isOutherSubmit,
  services,
  levels,
  isNewAccountActive
}) => {
  const onSubmit = (values, setSubmitting) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
    const dataToCreateUser = {
      account_id: accountId,
      full_name: values.name,
      email: values.email,
      phone_number: values.phoneNumber,
      active: values.activeUser
    };
    
    onCreateUser(dataToCreateUser);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
        {({
          values,
          touched,
          isSubmitting,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <Grid container className="pt-2 pb-4">
              <FormikInput
                mandatory
                id="name"
                name="name"
                label="Full name"
                value={values.name}
              />
              <FormikInput
                mandatory
                id="email"
                name="email"
                label="Email"
                value={values.email}
              />
              <FormikInput
                id="phoneNumber"
                name="phoneNumber"
                label="Phone number"
                value={values.phoneNumber}
                placeholder='+380951112233'
                className="mb-0"
              />
              {services && services.length === 0 
                ? <span style={{fontSize: 14, color: 'var(--black-40)'}}>
                    Permissions are not available as there none of them chosen on the company level
                  </span>
                : (<>
                    <InputLabel htmlFor="subscriptions">Permissions</InputLabel>
                    <PermissionsCheckboxes
                      subscriptionsValues={services.map(service => service.id)}
                      subscriptionsTouched={touched.subscriptions}
                      levelsValues={levels.map(level => level.id)}
                      levelsTouched={touched.csLevels}
                      onSetFieldValue={setFieldValue}
                      onSetFieldTouched={setFieldTouched}
                      editing={false}
                      services={services}
                      levels={levels}
                      rowVariant={false}
                    />
                  </>)}
            </Grid>
            <InputLabel htmlFor="activeUser">Status</InputLabel>
              <FormControl className="flex-centered">
                <FormControlLabel
                  disabled={!isNewAccountActive}
                  value={values.activeUser}
                  control={<Checkbox
                    id="activeUser"
                    name="activeUser"
                    color="primary"
                    onChange={(event) => {setFieldValue('activeUser', event.target.checked)}}
                  />}
                  label="Active"
                  className="mr-2" />
                <Tooltip title="Users must be activated for using the service.
                  You can activate them later from the list.
                  If the account is not active, you cannot activate the user." arrow={true} interactive={false}>
                  <Icon className="fa fa-info-circle fa-sm" />
                </Tooltip>
              </FormControl>
            <Grid container justify="flex-end" className={"pt-4 pb-4 pr-4"+(isOutherSubmit ? ' hidden' : '')}>
              <Button
                variant="outlined"
                onClick={handleReset}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="mr-0 create-new-user">
                Create
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
      
    </>
  );
};

StepTwoFrom.defaultProps = {
  accountIsActive: true,
  onCreateUser: () => null,
  isOutherSubmit: false,
  levels: null,
  isNewAccountActive: false
};

StepTwoFrom.propTypes = {
  accountId: PropTypes.string,
  accountIsActive: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape({})),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateCompany: PropTypes.func,
  isOutherSubmit: PropTypes.bool,
  isNewAccountActive: PropTypes.bool
};

export default StepTwoFrom;
