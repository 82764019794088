// Core
import React, { useEffect, useContext, useState, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import clsx from 'clsx';
// @material-ui
import Button from 'components/CustomButton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomStepper from 'components/UI/CustomStepper/CustomSteper';
import DetailsCard from 'components/DetailsCard';
import PageTitleSection from 'components/PageTitleSection';
import QuestionnaireStepper from 'components/QuestionnaireFlow/QuestionnaireStepper';
import Spinner from 'components/shared/Spinner';
import PartnerLogoutSystem from 'components/PartnerLogoutSystem';
// Context
import { UserContext } from 'context/UserContext';
// Hooks
import useDialog from 'hooks/useDialog';
import useStepper from 'hooks/useStepper';
import useAsync from 'hooks/useAsync';
import { useAuth } from 'hooks/useAuth';
// Tools
import { getPartner, changeStatusAfterFinish } from 'api/questionnaire';
import { useDispatch } from 'react-redux';
import { setInformationFromDB, clearStore } from 'redux/actions/actions';

const useStyles = makeStyles({
  projectLabel: {
    backgroundColor: 'var(--primary-disabled)',
    borderRadius: 4,
    color: 'var(--primary-regular)',
    padding: '2px 6px'
  },
  firstTab: {
    fontSize: 15,
    fontWeight: 700
  },
  text: {
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--black)'
  },
  pageTitle: {
    justifyContent: 'left',
  },
  textColor: {
    fontWeight: 600,
    lineHeight: '1.2rem',
  },
  alignRight: {
    alignSelf: 'flex-end'
  },
  justifyText: {
    textAlign: 'justify'
  }
});

const STEPS = [
  'General Information',
  'Director',
  'Shareholders (holding more than 25%)',
  'Ultimate Beneficial Owners (holding more than 10%)',
  'Bank Details',
  'Compliance Procedures',
  'Finish Questionnaire'
];

const QuestionnaireFlowPage = () => {
  const classes = useStyles();
  const { activeStep, handleNext, handleBack } = useStepper();
  const { loggedInUser } = useContext(UserContext);
  const { signout } = useAuth();
  const [politicAgree, setPoliticAgree] = useState(false);
  const dispatch = useDispatch();

  const {
    open: openConfirmFinishFlow,
    handleOpen: handleOpenConfirmFinishFlow,
    handleClose: handleCloseConfirmFinishFlow,
  } = useDialog();

  const {
    execute: getCurrentPartner,
    pending: pendingGetPartner,
    value: partnerInfo,
    status: statusGetPartner } = useAsync(getPartner, false);

  const {
    execute: changeStatus,
    pending: pendingChangeStatus,
    status: statusChabgeStatus } = useAsync(changeStatusAfterFinish, false);

  const handleConfirmDialogFinishFlow = (user_id) => {
    toast.success(
      "Thank you for the job!", { autoClose: 3000 }
    );
    setTimeout(() => {
      changeStatus(user_id);
    }, 3000);
  };

  useEffect(() => {
    if (!pendingChangeStatus && statusChabgeStatus === 200) {
      handleCloseConfirmFinishFlow();
      signout();
    }
  }, [pendingChangeStatus, statusChabgeStatus, handleCloseConfirmFinishFlow, signout]);

  useEffect(() => {
    if (loggedInUser) {
      getCurrentPartner(loggedInUser.id)
    }
  }, [loggedInUser, getCurrentPartner]);

  useEffect(() => {
    if (!pendingGetPartner && statusGetPartner === 200 && Object.keys(partnerInfo).length !== 0) {
      dispatch(setInformationFromDB(partnerInfo));
    }
    // eslint-disable-next-line
  }, [pendingGetPartner, statusGetPartner]);

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    }
  });

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        style={{ maxWidth: '760px' }}
      >
        <PageTitleSection title="Compliance Questionnaire" className={classes.pageTitle}></PageTitleSection>
        {pendingGetPartner ? <Spinner /> :
          (!politicAgree &&
            <Fragment>
              <Grid
                container
                spacing={3}
                className={clsx(classes.mbNone, "pt-2")}
                direction="column"
                justify="flex-start"
              >
                <Grid item xs={12} className={clsx(classes.textColor, "pb-0")}>
                  Consent to Personal Data Processing
                </Grid>
                <Grid item xs={12} className={clsx(classes.textColor, classes.justifyText, "pb-0")}>
                  I hereby authorize our Сounterparty {loggedInUser?.company_name} and administrator of this portal COSA LLC to
                  process and store the corporate data and documents of our Сompany, including my personal data
                  under the GDPR 679/16 – “European regulation on the protection of personal data”. The purpose of
                  collecting this data is a third-party due diligence procedure conducted by {loggedInUser?.company_name}
                </Grid>
                <Grid item xs={12} className={clsx(classes.textColor, classes.justifyText, "pb-0")}>
                  The provided information and documents for compliance purposes will be handled on a strictly
                  confidential basis and its access will be limited to the internal departments of {loggedInUser?.company_name} and
                  administrator of this portal.
                </Grid>
                <Grid item xs={12} className={clsx("mb-3", classes.alignRight)}>
                  <Button
                    disabled={false}
                    // className={classes.alignRight}
                    variant="outlined"
                    onClick={() => setPoliticAgree(prev => !prev)}>
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Fragment>)
        }
        {
          <DetailsCard bordered={true} showTitle={false}>
            <Grid container className="pt-5 pl-4 pr-4">
              <CustomStepper stepTitles={STEPS} activeStep={activeStep} />
            </Grid>
            <QuestionnaireStepper
              activeStep={activeStep}
              stepsCount={STEPS.length}
              handleNextStep={handleNext}
              handleBackStep={handleBack}
              finishQuestionnaire={handleOpenConfirmFinishFlow}
              userId={loggedInUser && loggedInUser.id}
              politicAgree={politicAgree}
              setPoliticAgree={setPoliticAgree}
              pendingGetPartner={pendingGetPartner}
            />
          </DetailsCard>
        }
      </Grid>
      <ConfirmDialog
        title="Finish questionnaire"
        confirmBtnText="Confirm"
        options={loggedInUser?.id}
        open={openConfirmFinishFlow}
        onClose={handleCloseConfirmFinishFlow}
        onConfirm={handleConfirmDialogFinishFlow}
      >
        You are about to submit your application. Please note that your temporary access
        to this Questionnaire will be unavailable once you click “Confirm”. If you would
        like to change your answers or add any documents, please contact your Counterparty
        via email.
      </ConfirmDialog>
      <PartnerLogoutSystem />
      <ToastContainer />
    </React.Fragment>
  );
};

export default QuestionnaireFlowPage;
