// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  text: {
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const Overview = props => {
  const classes = useStyles();

  const renderOverviewHeader = Object.keys(props).map(key => {
    const text =
      key === 'level' ? `Compliance screening ${props[key]} level` : props[key];

    return (
      <Typography variant="h3" className={classes.text}>
        {text}
      </Typography>
    );
  });

  return <Box>{renderOverviewHeader}</Box>;
};

Overview.propTypes = {
  id: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default Overview;
