const nav = [
  { path: '/home', text: 'Solutions', 'icon': 'home' },
  { path: '/cc-dashboard', text: 'Dashboard', 'icon': 'th-list' },
  //{ path: '/knowledge-base', text: 'Knowledge Base', 'icon': 'book-open' },
  //{ path: '/performance', text: 'Performance', 'icon': 'chart-line' },
  { path: '/accounts', text: 'Companies', 'icon': 'briefcase' },
  //{ path: '/users', text: 'Users', 'icon': 'user-friends' },
  //{ path: '/contact-us', text: 'Contact Us', 'icon': 'comments' },
];

export default nav;
