// Core 
import React from 'react';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import CommentCard from 'components/CommentCard';

const CommentCardsList = ({ commentsList }) => {
  return (
    <Grid container>
      {
        commentsList && commentsList.map(comment => {
          return (
            <CommentCard
              key={comment.id}
              comment={comment.text}
              requester={comment.uploader_name}
              requestDate={comment.created_at}
              avatar_url={comment.avatar_url}
            />
          )
        })
      }
    </Grid>
  )
}

export default CommentCardsList;
