// Core
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import DetailsCard from 'components/DetailsCard';
import EditUserForm from 'components/Account/Users/User/EditForm';
import PageTitleSection from 'components/PageTitleSection';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
import Spinner from 'components/shared/Spinner';
// Context
import { ServicesContext } from 'context/ServicesContext';
// Hooks
import useAsync from 'hooks/useAsync';
import { useAuth } from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
// Instruments
import { fetchAccountById, updateAccount } from 'api/accounts';
import { fetchUserById, updateUserById } from 'api/users';
import { sendActivationLink } from 'api/auth';
import { fetchLevels } from 'api/info';
// Constants
import { ROLE_ADMIN } from 'utils';

const UserDetailsPage = (loggedInUser) => {
  const history = useHistory();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const { signout } = useAuth();
  let { userId, id } = useParams();
  const { services } = useContext(ServicesContext);
  const [changedData, setChangedData] = useState(null);
  const {
    open: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm,
  } = useDialog();
  const handleDataChanged = dataIsChanged => {
    setHasUnsavedData(dataIsChanged);
  };

  const {
    execute: fetchUser,
    pending: fetchingUser,
    setValue: setUser,
    value: user,
  } = useAsync(fetchUserById, false);
  const {
    execute: updateUser,
    value: updatedUser
  } = useAsync(updateUserById, false);
  const {
    execute: fetchAccount,
    value: accountData,
  } = useAsync(fetchAccountById, false);
  const { execute: updateAccountValues } = useAsync(
    updateAccount,
    false,
  );
  const { value: levels } = useAsync(
    fetchLevels,
    true
  );
  const { execute: ActivateUser, value: sendActivationLinkValue } = useAsync(
    sendActivationLink,
    false,
  );

  const currentUserRoleAdmin = loggedInUser && parseInt(loggedInUser.role_id) === ROLE_ADMIN;
  if (currentUserRoleAdmin)
    id = loggedInUser.account_id;

  useEffect(() => {
    if(updatedUser && updatedUser.id){
      toast.success("The user data was successfully updated", { autoClose: 5000 });
    }
  }, [updatedUser]);

  useEffect(() => {
    if (sendActivationLinkValue) {
      toast.success(sendActivationLinkValue.message, { autoClose: 5000 });
    }
  }, [sendActivationLinkValue]);

  useEffect(() => {
    fetchUser(userId);
  }, [fetchUser, userId]);

  useEffect(() => {
    fetchAccount(id);
  }, [fetchAccount, id]);

  const servicesReduced = accountData && accountData.subscriptions && services?.filter(service => {
    const foundSubId = accountData.subscriptions.find(subscription => subscription.id === service.id);
    if (!foundSubId) return false;
    return service;
  });
  const levelsReduced = accountData && accountData.csLevels && levels?.filter(globalLevel => {
    const foundLevelId = accountData.csLevels.find(level => level.id === globalLevel.id);
    if (!foundLevelId) return false;
    return globalLevel;
  });

  const userValues = updatedUser || user;

  const handleTryToSubmitData = updatedUser => {
    if (updatedUser && parseInt(updatedUser.role_id) === ROLE_ADMIN
      && parseInt(user.role_id) !== ROLE_ADMIN) {
      handleOpenConfirm();
      setChangedData(updatedUser);
    } else {
      handleUpdateUser(updatedUser);
    }
  };

  const handleUpdateUser = updatedUser => {
    var user_role = parseInt(updatedUser.role_id);
    if (user_role === ROLE_ADMIN && user.role_id !== ROLE_ADMIN) {
      updateAccountValues({
        id: user.account_id,
        admin: updatedUser.userId
      });
    }
    let sendActivationMail = false;
    if (updatedUser && updatedUser.active && !user.active && updatedUser.email) {
      sendActivationMail = true;
      updatedUser.active = false;
    }
    const response = updateUser(updatedUser);
    if (response) {
      response.then( dataValue => {
        // no errors
        if (!dataValue){
          if (sendActivationMail) {
            ActivateUser({
              email: updatedUser.email
            });
          }
          setUser({
            ...user,
            active: updatedUser.active,
            full_name: updatedUser.full_name,
            email: updatedUser.email,
            phone_number: updatedUser.phone_number,
            role_id: updatedUser.role_id,
            subscriptions: servicesReduced.filter(service => {
              const foundSubId = updatedUser.subscriptions.find(subId => subId === service.id);
              if (!foundSubId) return false;
              return service;
            }),
            csLevels: levelsReduced.filter(globalLevel => {
              const foundLevelId = updatedUser.cs_levels.find(levelId => levelId === globalLevel.id);
              if (!foundLevelId) return false;
              return globalLevel;
            })
          });
          handleCloseConfirm();
        }
      });
    }
    if (currentUserRoleAdmin && user_role === ROLE_ADMIN && loggedInUser.id !== updatedUser.userId) {
      signout();
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
      style={{maxWidth: '760px'}}>
      <PageTitleSection title={(userValues && userValues.full_name) || ''} />
      {!fetchingUser ? (
        <DetailsCard title="User Info" bordered={true} justifyPosition="flex-start">
          <EditUserForm
            user={userValues}
            services={servicesReduced}
            levels={levelsReduced}
            currentUserRoleAdmin={currentUserRoleAdmin}
            onChangeData={handleDataChanged}
            onUpdateUser={handleTryToSubmitData}
          />
        </DetailsCard>
      ) : (
        <Spinner />
      )}
      <ToastContainer />
      <ConfirmDialog
        title="Change user role"
        open={openConfirm}
        onClose={handleCloseConfirm}
        options={changedData}
        confirmBtnText="Confirm"
        onConfirm={handleUpdateUser}>
        User role was changed to Admin, so user right and permissions will be transferred from a current Admin to this user.
        You can be logged out. Please confirm that action.
      </ConfirmDialog>
      <RouteLeavingGuard
        when={hasUnsavedData}
        navigate={path => history.push(path)}
        options={changedData}
        shouldBlockNavigation={() => {return true;}}
      />
    </Grid>
  );
};

UserDetailsPage.propTypes = {};

export default UserDetailsPage;
