// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
// Components
import CustomPartnershipTypesCheckbox from './PartnershipTypesCheckbox';
import FormInfoSection from '../FormInfoSection';
import Textarea from '../Textarea';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PartnershipTypesCheckboxes = ({
  doubleColumned,
  partnership_types,
  partnershipTypesRecords,
  onPartnershipTypeFieldChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const renderForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        className={doubleColumned ? 'pb-4' : ''}>
        {partnership_types.map(partnership_type => {
          return (
            <Grid
              item
              xs={doubleColumned ? 6 : 12}
              key={partnership_type.id}
              container
              className="pb-2">
              <Grid item xs={12}>
                <CustomPartnershipTypesCheckbox
                  id={String(partnership_type.id)}
                  name={String(partnership_type.id)}
                  value={
                    disabled === false
                      ? partnershipTypesRecords && partnershipTypesRecords.size
                        ? partnershipTypesRecords.get(
                            parseInt(partnership_type.id),
                          ).checked
                        : false
                      : true
                  }
                  disabled={disabled}
                  label={partnership_type.name}
                  onChange={event =>
                    onPartnershipTypeFieldChange(
                      event.target.name,
                      event.target.checked,
                    )
                  }
                />
              </Grid>
              {partnershipTypesRecords &&
              partnershipTypesRecords.size &&
              partnershipTypesRecords.get(parseInt(partnership_type.id))
                .checked ? (
                <Grid item xs={12} className="ml-2 mt-1">
                  <FormInfoSection text="Message" isCapitalize={false} />
                  <Textarea
                    id={`partnerSearch.message_partnership_${String(
                      partnership_type.id,
                    )}`}
                    name={`partnerSearch.message_partnership_${String(
                      partnership_type.id,
                    )}`}
                    value={
                      partnershipTypesRecords.get(parseInt(partnership_type.id))
                        .message
                    }
                    disabled={disabled}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
        <Grid container>{renderForm()}</Grid>
      </FormGroup>
    </FormControl>
  );
};

PartnershipTypesCheckboxes.defaultProps = {
  doubleColumned: false,
  partnership_types: [],
  partnershipTypesRecords: [],
  onPartnershipTypeFieldChange: () => null,
  disabled: false,
};

PartnershipTypesCheckboxes.propTypes = {
  doubleColumned: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  partnership_types: PropTypes.array,
  partnershipTypesRecords: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  onPartnershipTypeFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PartnershipTypesCheckboxes;
