// Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TooltipTextLabel from '../TooltipTextLabel';

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 40,
    margin: 0,
    width: '100%',
    border: '1px solid var(--black-20)',
    borderLeft: '5px solid var(--black-20)',
    borderRadius: 4,
    paddingLeft: 12,

    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      color: 'var(--black-100)',
      lineHeight: 1,
    },
  },

  borderGreen: {
    borderColor: 'var(--secondary-green)',
  },
  borderGrey: {
    borderColor: 'var(--black-20)',
  },
  borderBlue: {
    borderColor: 'var(--primary-regular)',
  },
  borderPurple: {
    borderColor: 'var(--secondary-purple)',
  },
  borderOrange: {
    borderColor: 'var(--secondary-orange)',
  },
  borderTurquoize: {
    borderColor: 'var(--secondary-turquoise)',
  },
  borderAzure: {
    borderColor: 'var(--secondary-azure)',
  },
});

const CustomSubjectToImproveCheckbox = ({
  id,
  name,
  value,
  label,
  tooltipLabel,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();

  const setColor = fieldId => {
    // eslint-disable-next-line radix
    switch (parseInt(fieldId)) {
      case 1:
        return classes.borderGreen;
      case 2:
        return classes.borderAzure;
      case 3:
        return classes.borderBlue;
      case 4:
        return classes.borderPurple;
      case 5:
        return classes.borderOrange;
      case 6:
        return classes.borderTurquoize;
      default:
        return classes.borderGreen;
    }
  };
  const labelTooltip = (
    <TooltipTextLabel label={label} tooltipLabel={tooltipLabel} />
  );

  return (
    <FormControlLabel
      className={clsx(classes.formControl, { [setColor(id)]: value === true })}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          color="primary"
        />
      }
      labelPlacement="start"
      label={labelTooltip}
    />
  );
};

CustomSubjectToImproveCheckbox.defaultProps = {
  id: '',
  name: '',
  value: null,
  label: '',
  tooltipLabel: '',
  onChange: () => null,
  disabled: false,
};

CustomSubjectToImproveCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  tooltipLabel: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CustomSubjectToImproveCheckbox;
