import React from 'react';

const DeleteIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>delete</title>
    <g
      id="Admin"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="Account-details-full-view"
        transform="translate(-1004.000000, -755.000000)">
        <g id="Group-9" transform="translate(388.000000, 655.000000)">
          <g id="Group-18" transform="translate(537.000000, 100.000000)">
            <g id="delete-24px" transform="translate(79.000000, 0.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <path
                d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z"
                id="Shape"
                fill="#000000"
                fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DeleteIcon;
