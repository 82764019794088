//Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
  formControlLabel: {
    "& .MuiFormControlLabel-label": {
      color: 'var(--black-100)',
      fontWeight: 600,
    }
  }
});

const CustomStakeholderCheckbox = ({
  name,
  value,
  label,
  onChange,
  disabled
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          color="primary"
        />
      }
      label={label}
      labelPlacement="end"/>
  );
}

CustomStakeholderCheckbox.defaultProps = {
  name: "",
  value: false,
  label: "",
  onChange: () => null,
  disabled: false,
}

CustomStakeholderCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default CustomStakeholderCheckbox;
