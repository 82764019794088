// Core
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// Instruments
import statusIcons from 'config/status-icons';

const StyledMenu = withStyles({
  paper: {
    '& ul': {
      width: '234px',
      padding: 9,
      marginTop: 8,
      border: '1px solid var(--input-regular-border)',
      borderRadius: 4,
    },
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '9px 12px',
    marginBottom: '4px',
    borderRadius: 4,
    '& .fa': {
      fontSize: 14,
      color: 'var(--black-60)',
      marginRight: 18,
    },
    '&.color-id-1 .fa': {
      color: 'var(--black-60)',
    },
    '&.color-id-2 .fa': {
      color: 'var(--secondary-green)',
    },
    '&.color-id-3 .fa': {
      color: 'var(--secondary-orange)',
    },
    '&.color-id-4 .fa': {
      color: 'var(--secondary-turquoise)',
    },
    '&.color-id-5 .fa': {
      color: 'var(--secondary-blue)',
    },
    '&:focus': {
      backgroundColor: 'var(--white)',
    },
    '&:hover': {
      backgroundColor: 'var(--input-active-border)',
      color: 'var(--white)',
      fontWeight: 500,
      '& .fa': {
        color: 'var(--white)',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--input-active-border)',
      color: 'var(--white)',
      '& .fa': {
        color: 'var(--white)',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'var(--input-active-border)',
      color: 'var(--white)',
      fontWeight: 500,
      '& .fa': {
        color: 'var(--white)',
      },
    },
  },
})(MenuItem);

const useStyles = makeStyles({
  selectedStatusCard: {
    fontSize: 14,
    position: 'relative',
    backgroundColor: 'var(--black-60)',
    color: 'var(--white)',
    borderRadius: '4px',
    padding: '9px 12px',
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',

    '& .fa': {
      fontSize: 14,
      marginRight: 18,
    },

    '&.color-id-1': {
      backgroundColor: 'var(--black-60)',
    },

    '&.color-id-2': {
      backgroundColor: 'var(--secondary-green)',
    },

    '&.color-id-3': {
      backgroundColor: 'var(--secondary-orange)',
    },

    '&.color-id-4': {
      backgroundColor: 'var(--secondary-turquoise)',
    },

    '&.color-id-5': {
      backgroundColor: 'var(--secondary-blue)',
    },
  },

  btnIconArrow: {
    position: 'absolute',
    top: 0,
    right: 4,
    padding: '9px 8px',

    '& .fa': {
      fontSize: 12,
      marginRight: 0,
      color: 'var(--white)',

      '&.fa-sort-down': {
        marginTop: '-1px',
      },

      '&.fa-sort-up': {
        marginTop: '3px',
      },

    },
  }
});

const DropdownStatuses = props => {
  const classes = useStyles(props);
  const { project, statuses, editable, onConfirm, onStatusChange } = props;
  const currentStatusId = project && project.status.id;
  const currentStatusName = project && project.status.name;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
      <Card
        aria-controls="statuses-menu"
        aria-haspopup="true"
        className={
          clsx(classes.selectedStatusCard, statusIcons[currentStatusId] && ("color-id-" + currentStatusId))
        }
        onClick={editable && onConfirm ? handleClick : null}>
          {statusIcons[currentStatusId].icon &&
          <Icon className={"fa fa-" + statusIcons[currentStatusId].icon} />}
          {currentStatusName}
          {editable && <IconButton className={classes.btnIconArrow}>
            <Icon className={"fa fa-sort-" + (Boolean(anchorEl) ? "up" : "down")} />
          </IconButton>}
      </Card>
      <StyledMenu
        id="statuses-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
          {
            statuses &&
            statuses.map(status => {
              return (
                <StyledMenuItem
                  key={status.id}
                  value={status.id}
                  selected={currentStatusId === status.id}
                  className={statusIcons[status.id] && ("color-id-" + status.id)}
                  onClick={event => {onStatusChange({id: project && project.id, status: event.target.value}); handleClose();}}>
                    {statusIcons[status.id].icon &&
                      <Icon color="primary" className={"fa fa-" + statusIcons[status.id].icon} />}
                    {status.name}
                </StyledMenuItem>
              )
            })
          }
      </StyledMenu>
    </React.Fragment>
  );
};

DropdownStatuses.defaultProps = {
  project: null,
  statuses: null,
  editable: false,
};

DropdownStatuses.propTypes = {
  project: PropTypes.object,
  statuses: PropTypes.array,
  editable: PropTypes.bool,
};

export default DropdownStatuses;
