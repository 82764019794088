// Core
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import ExampleLinks from 'components/ExampleLinks';
// Context
import { UserContext } from 'context/UserContext';
import { ServicesContext } from 'context/ServicesContext';
// Helpers
import { sortArrayOfObjects } from 'helpers';
import complienceScreeningLinks from 'config/examples-complience-screening';
import enhancedDueDiliganceLinks from 'config/examples-enhanced-due-diligence';
import preEmploymentScreeningLinks from 'config/examples-pre-employment-screening';
import strategicIntelligenceLinks from 'config/examples-startegic-inteligence';
// Constants
import {
  CS_SERVICE,
  EDD_SERVICE,
  SI_SERVICE,
  PES_SERVICE,
  TECH_INTELLI_SERVICE,
  LEGAL_COMP_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
} from 'utils';

const initialEntity = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  country: '',
  name_in_local_language: '',
  website: '',
  position: '',
  registered_address: '',
  tax_number: '',
  trading_name: '',
  operational_address: '',
  business_license_number: '',
  is_key: false,
  type: 'Entity',
};
const initialIndividual = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  country: '',
  name_in_local_language: '',
  website: '',
  position: '',
  alias: '',
  shares: '',
  residential_address: '',
  business_address: '',
  passport_number: '',
  national_id: '',
  is_key: false,
  type: 'Individual',
};

const links = serviceId => {
  serviceId = parseInt(serviceId);
  switch (serviceId) {
    case CS_SERVICE:
      return complienceScreeningLinks;
    case EDD_SERVICE:
      return enhancedDueDiliganceLinks;
    case PES_SERVICE:
      return preEmploymentScreeningLinks;
    case SI_SERVICE:
      return strategicIntelligenceLinks;
    default:
      return [];
  }
};

const ProjectTypeStep = props => {
  const { services } = useContext(ServicesContext);
  const { loggedInUser } = useContext(UserContext);
  const [servicesReduced, setServicesReduced] = useState(null);
  const [servicesItemsCountByFour, setServicesItemsCountByFour] = useState(
    null,
  );
  const [viewExamples, setViewExamples] = useState(false);
  const {
    serviceId,
    projectId,
    onSetFieldValue,
    onSetShowCSAdviceStep,
  } = props;

  useEffect(() => {
    if (services && loggedInUser && loggedInUser.subscriptions) {
      const availableServicesReduced = services.filter(service => {
        const foundSubId = loggedInUser.subscriptions.find(
          subscription => subscription.id === service.id,
        );
        if (!foundSubId) return false;
        return service;
      });
      availableServicesReduced.sort(sortArrayOfObjects('id'));
      setServicesReduced(availableServicesReduced);
      const portionsByFour =
        Math.floor(availableServicesReduced.length / 4) +
        (availableServicesReduced.length % 4 > 0 ? 1 : 0);
      setServicesItemsCountByFour(portionsByFour);
    }
  }, [services, loggedInUser]);

  const handleCheckExampleButtonClick = () => {
    setViewExamples(prevValue => !prevValue);
  };
  const handleSetFieldValue = value => {
    setViewExamples(false);
    onSetFieldValue('serviceId', value);
    onSetFieldValue(
      'analysisTypeId',
      parseInt(value) === SI_SERVICE
        ? 1
        : parseInt(value) === TECH_INTELLI_SERVICE
        ? 3
        : null,
    );
    onSetFieldValue('selectedConfig', {});
    onSetFieldValue('humint', false);
    onSetFieldValue('typeKeySubject', false);
    onSetFieldValue('typeRelatedSubject', true);
    onSetFieldValue('entities', [initialEntity]);
    onSetFieldValue('individuals', [initialIndividual]);
    if (parseInt(value) !== CS_SERVICE) {
      onSetFieldValue('levelId', 1);
    }
    const currentService =
      servicesReduced &&
      servicesReduced.find(service => parseInt(service.id) === parseInt(value));
    if (currentService) {
      onSetFieldValue('serviceTitle', currentService.name);
    }
  };

  useEffect(() => {
    if (servicesReduced && serviceId === 1) {
      handleSetFieldValue(String(servicesReduced[0].id));
    }
    // eslint-disable-next-line
  }, [servicesReduced]);

  const currentService =
    servicesReduced &&
    servicesReduced.find(
      service => parseInt(service.id) === parseInt(serviceId),
    );
  useEffect(() => {
    if (currentService) {
      onSetFieldValue('serviceTitle', currentService.name);
    }
  }, [currentService, onSetFieldValue]);

  return (
    <>
      <Grid container className="pt-2 pb-2">
        {(() => {
          const arr = [];
          if (servicesItemsCountByFour && servicesItemsCountByFour > 0) {
            for (let i = 0; i < servicesItemsCountByFour; i++) {
              arr.push(
                <CustomRadioGroup
                  key={i}
                  variant="middle"
                  onChange={handleSetFieldValue}
                  name="serviceId"
                  defaultValue={String(serviceId)}
                  fields={servicesReduced.slice(i * 4, i * 4 + 4)}
                />,
              );
            }
          }
          return arr;
        })()}
      </Grid>
      <Divider className="minus-m-25" />
      {currentService ? (
        <Grid container className="pt-4 pb-4">
          <Grid container justify="space-between" className="pb-3">
            <Button disabled className={`ml-0 typeService${serviceId || '1'}`}>
              {currentService.name}
            </Button>

            <Grid>
              {serviceId === CS_SERVICE &&
              (parseInt(projectId) === 0 || projectId.length < 5) ? (
                <Button variant="outlined" onClick={onSetShowCSAdviceStep}>
                  Advice Flow
                </Button>
              ) : (
                <></>
              )}
              {[
                LEGAL_COMP_SERVICE,
                TECH_INTELLI_SERVICE,
                PARTNER_SEARCH_SERVICE,
                TRENDS_INTELIGENCE_SERVICE,
              ].includes(serviceId) ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  className="mr-0"
                  onClick={handleCheckExampleButtonClick}>
                  {viewExamples === false ? 'Sample Reports' : 'Hide Reports'}
                </Button>
              )}
            </Grid>
          </Grid>
          {viewExamples === false ? (
            <Grid
              item
              className="pb-2 description-service"
              dangerouslySetInnerHTML={{ __html: currentService.description }}
            />
          ) : (
            <ExampleLinks links={links(currentService.id)} />
          )}
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};

ProjectTypeStep.defaultProps = {
  serviceId: 0,
  projectId: 0,
  onSetFieldValue: () => null,
  onSetShowCSAdviceStep: () => null,
};

ProjectTypeStep.propTypes = {
  serviceId: PropTypes.number,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSetFieldValue: PropTypes.func,
  onSetShowCSAdviceStep: PropTypes.func,
};

export default ProjectTypeStep;
