//Core
import React from 'react';
import { useHistory } from 'react-router-dom';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => {
  return {
    iconPosition: {
      position: 'absolute',
      top: '5%',
      left: 55,

      [theme.breakpoints.down('lg')]: {
        left: 40,
      },
      [theme.breakpoints.down('md')]: {
        left: 20,
      },

      "&.MuiIconButton-root": {
        padding: "12px 0px",
      },
    }
  }
});

const Logotype = () => {
  const classes = useStyles();
  const { push } = useHistory();

  const goToLoginPage = () => {
    push('/');
  };

  return (
    <IconButton
      className={classes.iconPosition} onClick={goToLoginPage}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="73" 
        height="63" 
        viewBox="0 0 73 63">
        <defs>
            <linearGradient id="0u9j3oixba" x1="100%" x2="0%" y1="27.278%" y2="75.825%">
                <stop offset="0%" stopColor="#1B1A1A"/>
                <stop offset="100%" stopOpacity="0"/>
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path d="M0 0H73V63H0z" transform="translate(-60 -60) translate(60 60)"/>
                    <path fill="#183F73" d="M35.632 38.21c.278 0 .444.019.65.046.171.022.315.063.45.077.05.004.067.027.067.054 0 
                    .036-.013.09-.022.248-.01.15-.005.398-.014.488-.004.068-.013.104-.054.104-.036 0-.045-.036-.045-.1-.004-.14-.063-.298-.175-.415-.148-.158-.498-.28-.91-.28-.391 
                    0-.647.1-.844.28-.328.303-.413.732-.413 1.17 0 1.076.812 1.609 1.413 1.609.4 0 .642-.045.822-.253.076-.086.134-.217.152-.298.014-.073.023-.09.059-.09.031 0 
                    .04.03.04.067 0 .054-.054.447-.099.601-.022.077-.04.1-.117.131-.179.072-.52.104-.807.104-.615 0-1.131-.136-1.54-.493-.44-.388-.556-.894-.556-1.333 
                    0-.307.094-.84.525-1.238.323-.298.75-.478 1.418-.478zm4.397 0c1.118 0 1.813.656 1.813 1.69 0 1.035-.718 1.853-1.858 1.853-1.288 0-1.808-.972-1.808-1.771 
                    0-.773.507-1.772 1.853-1.772zm4.491 0c.292 0 .48.046.597.077.04.01.063.023.063.055 0 .058-.018.19-.018.542 0 .1-.014.135-.05.135-.03 0-.044-.027-.044-.081 
                    0-.04-.023-.18-.117-.298-.067-.086-.198-.222-.49-.222-.331 0-.533.195-.533.466 0 .208.103.366.475.65l.126.095c.543.411.736.723.736 1.152 
                    0 .263-.099.574-.422.787-.224.144-.475.185-.713.185-.26 0-.463-.032-.656-.117-.071-.032-.085-.055-.085-.154 0-.249.018-.52.023-.592.004-.068.018-.118.053-.118.04 
                    0 .045.041.045.077 0 .059.018.154.04.23.1.335.364.457.642.457.404 0 .602-.275.602-.515 0-.221-.067-.43-.44-.723l-.206-.163c-.494-.388-.665-.705-.665-1.07 
                    0-.498.413-.854 1.037-.854zm-19.755.064c.813 0 .956.438.956.678 0 .37-.21.592-.457.79.367.127.816.448.816.976 0 .484-.372.985-1.198.985-.054 
                    0-.184-.009-.314-.013-.135-.01-.27-.014-.337-.014h-.106c-.044.001-.096.002-.15.005-.103 0-.22.009-.31.009-.057 0-.089-.014-.089-.046 0-.022.018-.036.072-.036.067 0 
                    .12-.009.161-.018.09-.018.113-.117.13-.248.02-.163.023-.448.023-.788v-1.491c0-.252-.003-.34-.009-.437-.009-.154-.045-.226-.193-.257-.036-.01-.112-.014-.175-.014-.05 
                    0-.076-.009-.076-.04 0-.032.031-.041.099-.041.237 0 .52.013.61.013.22 0 .395-.013.547-.013zm23.268-.1c.04 0 .068.041.112.145.108.248.826 2.092 1.113 
                    2.779.17.406.3.47.395.492.067.014.135.018.189.018.036 0 .058.005.058.036 0 .03-.028.042-.133.045h-.362c-.16-.001-.355-.003-.56-.008-.08-.005-.134-.005-.134-.037 
                    0-.027.018-.036.063-.04.031-.01.062-.05.04-.104l-.359-.958c-.009-.023-.022-.032-.045-.032h-1c-.023 0-.032.01-.04.037l-.243.646c-.045.113-.068.221-.068.275 0 
                    .082.04.14.18.14h.067c.054 0 .068.01.068.036 0 .037-.028.046-.077.046-.143 0-.336-.014-.476-.014-.049 0-.296.014-.529.014-.058 0-.085-.01-.085-.046 
                    0-.027.018-.036.054-.036.04 0 .103-.004.139-.009.206-.027.291-.18.381-.406l1.126-2.838c.054-.13.081-.18.126-.18zm-17.565.1c.05 0 .081.009.081.036 0 
                    .032-.036.045-.085.045-.045 0-.13.018-.198.054-.094.046-.143.1-.242.222-.148.18-.776 1.242-.857 1.436-.068.163-.068.303-.068.452v.37c0 .073 0 
                    .263.014.453.009.13.05.23.184.248.063.01.162.018.224.018.05 0 .072.014.072.036 0 .032-.036.046-.094.046-.283 0-.566-.014-.687-.014-.112 0-.395.014-.56.014-.063 
                    0-.1-.01-.1-.046 0-.022.023-.036.072-.036.063 0 
                    .117-.009.157-.018.09-.018.13-.117.14-.248.01-.152.013-.304.013-.396v-.427c0-.244-.045-.343-.103-.474-.01-.02-.065-.117-.143-.248l-.132-.22c-.19-.317-.425-.7-.515-.833-.112-.168-.224-.28-.31-.33-.058-.032-.134-.059-.188-.059-.036 
                    0-.067-.009-.067-.04 0-.028.031-.041.085-.041.08 0 .417.013.565.013.095 0 .278-.013.48-.013.045 0 .068.013.068.04 0 
                    .028-.032.032-.099.05-.04.01-.063.045-.063.081 0 .037.023.09.054.154.063.136.691 1.225.763 
                    1.351.019-.045.128-.244.253-.47l.154-.278c.127-.23.24-.436.266-.495.045-.099.063-.176.063-.234 0-.046-.018-.095-.094-.113-.04-.01-.081-.018-.081-.046 0-.031.022-.04.076-.04.148 
                    0 .292.013.422.013.099 0 .395-.013.48-.013zm9.431.153c-.516 0-1.104.29-1.104 1.415 0 .94.516 1.685 1.378 1.685.314 0 1.04-.154 1.04-1.46 
                    0-1.012-.578-1.64-1.314-1.64zM24.528 39.91c-.018 0-.027.01-.027.032v1.07c0 .299.013.376.157.435.12.05.224.054.327.054.202 0 
                    .543-.109.543-.578 0-.28-.094-.66-.435-.9-.094-.067-.197-.099-.372-.108-.081-.005-.126-.005-.193-.005zm23.393-.808c-.014 
                    0-.022.022-.027.04l-.413 1.112c-.004.018 0 .031.018.031h.826c.022 0 .027-.013.022-.027l-.403-1.116c-.005-.018-.01-.04-.023-.04zm-23.232-.642c-.063 
                    0-.103 0-.148.009-.031.01-.04.027-.04.077v1.116c0 .036.013.045.031.05.04.009.108.013.211.013.148 0 
                    .247-.018.323-.086.117-.094.184-.257.184-.479 0-.302-.175-.7-.56-.7zm28.866-14.795c1.406.285 2.108 
                    1.487 2.108 3.604h-3.336c0-.58-.127-.918-.38-1.015-.252-.097-1.127-.146-2.623-.146-1.507 0-2.377.029-2.61.085-.232.056-.348.262-.348.618 0 
                    .377.197.621.591.733.395.112 1.204.168 2.427.168 2.75 0 4.523.22 5.316.657.794.438 1.191 1.446 1.191 3.024 0 
                    1.456-.445 2.387-1.335 2.794-.768.346-2.497.52-5.187.52-2.073 0-3.418-.046-4.034-.138-.951-.143-1.598-.476-1.942-1-.344-.525-.516-1.433-.516-2.726h3.337c0 
                    .61.132.967.395 1.069.247.096 1.081.146 2.502.152h.273c1.608 0 
                    2.538-.025 2.791-.076.253-.05.38-.25.38-.595 0-.418-.178-.68-.532-.787-.354-.107-1.233-.16-2.639-.16-2.78 0-4.535-.204-5.263-.611-.728-.407-1.092-1.415-1.092-3.024 
                    0-1.486.475-2.438 1.426-2.856.728-.315 2.346-.473 4.853-.473 2.235 0 3.65.06 4.247.183zm-48.687-.046l3.003 8.064h.364l2.457-8.064h3.99l2.456 
                    8.064h.364l3.004-8.064h3.564L19.87 34.522h-5.112l-1.85-6.689c0-.02-.02-.282-.06-.786-.041-.504-.097-.756-.168-.756-.06 
                    0-.111.252-.151.756-.04.504-.066.766-.076.786l-1.836 6.69H5.505L1.304 23.617h3.564zm30.866 0l5.369 10.904h-3.898l-1.031-1.832H29.56l-1.03 
                    1.832H24.63l5.37-10.904h5.733zm31.23-.03c1.779 0 2.952.22 3.518.658.708.54 1.062 1.698 1.062 3.473 0 1.764-.349 2.917-1.047 
                    3.458-.576.448-1.754.673-3.534.673H62.14v2.672h-3.337V23.588h8.16zm-33.84 2.49h-.515l-1.805 3.786h4.11l-1.79-3.787zm33.84.335H62.14v2.611h4.823c.395 
                    0 .693-.087.895-.26.243-.205.364-.554.364-1.045 0-.482-.121-.83-.364-1.045-.202-.174-.5-.261-.895-.261z" 
                    transform="translate(-60 -60) translate(60 60)"/>
                    <path fill="url(#0u9j3oixba)" d="M36.188 0.006L13.332 13.293 13.332 19.905 23.263 14.562 36.188 7.049z" transform="translate(-60 -60) translate(60 60)"/>
                    <path fill="url(#0u9j3oixba)" d="M59.024 0L36.168 13.286 36.168 19.898 46.099 14.556 59.024 7.043z" transform="translate(-60 -60) translate(60 60) matrix(-1 0 0 1 95.191 0)"/>
                    <path fill="url(#0u9j3oixba)" d="M59.024 42.298L36.168 55.584 36.168 62.196 46.099 56.854 59.024 49.341z" transform="translate(-60 -60) translate(60 60) rotate(-180 47.596 52.247)"/>
                    <path fill="url(#0u9j3oixba)" d="M36.188 42.304L13.332 55.591 13.332 62.203 23.263 56.86 36.188 49.347z" transform="translate(-60 -60) translate(60 60) matrix(1 0 0 -1 0 104.507)"/>
                </g>
            </g>
        </g>
      </svg>
    </IconButton>
  );
}

export default Logotype;
