import { useState } from 'react';

const useStepper = () => {
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep === 1 ? prevActiveStep : prevActiveStep - 1);
  };

  const handleSkipTwoSteps = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 2);
  };

  const handleBackTwoSteps = () => {
    setActiveStep(prevActiveStep => prevActiveStep <= 2 ? prevActiveStep : prevActiveStep - 2);
  };

  const handleGoToSomeStep = (step) => {
    setActiveStep(step);
  };

  const handleResetStepper = () => {
    setActiveStep(1);
  };

  return { activeStep, handleNext, handleBack, handleSkipTwoSteps, handleBackTwoSteps, handleResetStepper, handleGoToSomeStep };
};

export default useStepper;
