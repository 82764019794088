// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
// Components
import CustomSubjectToImproveCheckbox from './CustomSubjectToImproveCheckbox';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const SubjectsToImproveCheckboxes = ({
  doubleColumned,
  subjects_to_improve,
  subjectsToImproveRecords,
  onSubjectsToImproveFieldChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const renderForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        className={doubleColumned ? 'pb-4' : ''}>
        {subjects_to_improve.map(subject_to_improve => {
          return (
            <Grid
              item
              xs={doubleColumned ? 6 : 12}
              key={subject_to_improve.id}
              container
              className="pb-2">
              <Grid item xs={12}>
                <CustomSubjectToImproveCheckbox
                  id={String(subject_to_improve.id)}
                  name={String(subject_to_improve.id)}
                  value={disabled === false ? ((subjectsToImproveRecords && subjectsToImproveRecords.size) ? subjectsToImproveRecords.get(parseInt(subject_to_improve.id)) : false) : true}
                  disabled={disabled}
                  label={subject_to_improve.name}
                  tooltipLabel={subject_to_improve.description}
                  onChange={event =>
                    onSubjectsToImproveFieldChange(event.target.name, event.target.checked)
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
        <Grid container>{renderForm()}</Grid>
      </FormGroup>
    </FormControl>
  );
};

SubjectsToImproveCheckboxes.defaultProps = {
  doubleColumned: false,
  subjects_to_improve: [],
  subjectsToImproveRecords: [],
  onSubServiceFieldChange: () => null,
  disabled: false,
};

SubjectsToImproveCheckboxes.propTypes = {
  doubleColumned: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  subjects_to_improve: PropTypes.array,
  subjectsToImproveRecords: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSubServiceFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SubjectsToImproveCheckboxes;
