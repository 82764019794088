import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  tableCellName: {
    fontWeight: 500,
    width: '50%',
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  head: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'var(--primary-regular)'
  },
  table: {
    wordBreak: 'break-all'
  }
});

const directorsRows = [
  {name: "Full name", path: "name"},
  {name: "Position", path: "director_position"},
  {name: "Date of birth", path: "date"},
  {name: "Citizenship", path: "citizenship_name"},
  {name: "Second citizenship", path: "second_citizenship_name"},
  {name: "Passport number", path: "registered_number"}
];

const shareholdersRows = [
  {name: "Legal entity or Individual", path: "entity_legal_or_individual"},
  {name: "Full name of legal person or individual", path: "name"},
  {name: "Percentage of shares", path: "shares"},
  {name: "Registered or passport number", path: "registered_number"},
  {name: "Date of incorporation or date of birth", path: "date"},
  {name: "Citizenship", path: "citizenship_name"},
  {name: "Second citizenship", path: "second_citizenship_name"},
];

const ubosRows = [
  {name: "Full name", path: "name"},
  {name: "Percentage of shares", path: "shares"},
  {name: "Passport number", path: "registered_number"},
  {name: "Date of birth", path: "date"},
  {name: "Citizenship", path: "citizenship_name"},
  {name: "Second citizenship", path: "second_citizenship_name"},
];

const EntitiesPart = ({
  directors,
  shareholders,
  ubos,
  type
}) => {
  const classes = useStyles();

  const typesObject = {directors, shareholders, ubos};
  const namesObject = {
    directors: "Director",
    shareholders: "Shareholder", 
    ubos: "Ultimate Beneficial Owner"
  };

  const rowsObject = {
    directors: directorsRows,
    shareholders: shareholdersRows,
    ubos: ubosRows
  };

  return (
    <TableContainer component={Paper}>
      {
        typesObject[type].map((entity, index) => {
          return (
            <Table key={entity.id} size="small" aria-label="a dense table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>{namesObject[type]} {index+1}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsObject[type].map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" className={classes.tableCellName}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {entity[row.path]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        })
      }
    </TableContainer>
  );
}

export default EntitiesPart;
