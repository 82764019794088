// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
// Components
import CustomSubServiceCheckbox from './CustomSubServiceCheckbox';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const SubServicesCheckboxes = ({
  doubleColumned,
  sub_services,
  subServicesRecords,
  onSubServiceFieldChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const renderForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        className={doubleColumned ? 'pb-4' : ''}>
        {sub_services.map(sub_service => {
          return (
            <Grid
              item
              xs={doubleColumned ? 6 : 12}
              key={sub_service.id}
              container
              className="pb-2">
              <Grid item xs={12}>
                <CustomSubServiceCheckbox
                  id={String(sub_service.id)}
                  name={String(sub_service.id)}
                  value={disabled === false ? ((subServicesRecords && subServicesRecords.size) ? subServicesRecords.get(parseInt(sub_service.id)) : false) : true}
                  disabled={disabled}
                  label={sub_service.name}
                  onChange={event =>
                    onSubServiceFieldChange(event.target.name, event.target.checked)
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
        <Grid container>{renderForm()}</Grid>
      </FormGroup>
    </FormControl>
  );
};

SubServicesCheckboxes.defaultProps = {
  doubleColumned: false,
  sub_services: [],
  subServicesRecords: [],
  onSubServiceFieldChange: () => null,
  disabled: false,
};

SubServicesCheckboxes.propTypes = {
  doubleColumned: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sub_services: PropTypes.array,
  subServicesRecords: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSubServiceFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SubServicesCheckboxes;
