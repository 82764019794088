// Core
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
// @material-ui
// import { makeStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
//import SvgIcon from '@material-ui/core/SvgIcon';
// Components
import ActionButtons from './ActionButtons';
//import { ArrowBackIcon } from 'components/shared/Icons';
//import SearchForm from 'components/Search/SearchForm';
//import IconButton from 'components/IconButton';


// const useStyles = makeStyles({
//   toolBar: {
//     justifyContent: 'flex-end',//'space-between',
//     padding: '0',
//     margin: '7px 24px',
//   },
// });

const Navbar = ({ nav, dropDownBtnText }) => {
  // const classes = useStyles();
  const { push/*, goBack*/ } = useHistory();
  const match = useRouteMatch({
    path: '/home',
    strict: true,
    sensitive: true,
  });

  return (
    // <Toolbar className={classes.toolBar} disableGutters>
    <Toolbar className="toolBarHeader" disableGutters>
      {/*!match && (
        <IconButton type="button" variant="outlined" onClick={() => goBack()}>
          <SvgIcon component={ArrowBackIcon} />
        </IconButton>
      )*/}
      {/* <SearchForm redirectTo="/search" /> */}
      <ActionButtons match={match} push={push} btnText={dropDownBtnText} />
    </Toolbar>
  );
};

Navbar.propTypes = {
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  dropDownBtnText: PropTypes.string.isRequired,
};

export default Navbar;
