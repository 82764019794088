// Core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import DetailsCard from 'components/DetailsCard';
import OrderFlowStepper from 'components/OrderFlow/Stepper';
import PageTitleSection from 'components/PageTitleSection';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchProjectById } from 'api/projects';
// Constants
import {
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
} from 'utils';

const useStyles = makeStyles({
  pageTitle: {
    justifyContent: 'left',
  },
});

const NewProjectPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [serviceTitle, setServiceTitle] = useState(null);
  const [showCSAdviceStep, setShowCSAdviceStep] = useState(false);
  const { execute: fetchProject, pending: fetchingProject, value: project } = useAsync(
    fetchProjectById, false
  );

  useEffect(() => {
    if (!id) return;
    fetchProject(id);
  }, [fetchProject, id]);

  const handleSetShowCSAdviceStep = () => {
    setShowCSAdviceStep(prev => !prev)
  }
  
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch" 
      style={
        !showCSAdviceStep && serviceTitle
        && !(([SI_SERVICE, TECH_INTELLI_SERVICE].includes(parseInt(serviceTitle.id)) && (serviceTitle.activeStep === 3))
            || ([CS_SERVICE].includes(parseInt(serviceTitle.id)) && ([3, 4].includes(serviceTitle.activeStep)))
              || ([EDD_SERVICE, PES_SERVICE, LEGAL_COMP_SERVICE, PARTNER_SEARCH_SERVICE].includes(parseInt(serviceTitle.id)) && (serviceTitle.activeStep === 2))) 
                ? {maxWidth: '760px'}
                : {maxWidth: '1440px'}
      }
      >
      <PageTitleSection title="New Project" className={classes.pageTitle}>
        {(serviceTitle && serviceTitle.activeStep > 1) || showCSAdviceStep ? (
          <Button disabled className={"mt-0 mb-0 ml-3 typeService" + serviceTitle.id}>
            {serviceTitle.title}
          </Button>
        ) : (<></>)}
      </PageTitleSection>
      {
        fetchingProject ? <Spinner/>
        : (
          <DetailsCard title="" bordered={!showCSAdviceStep} showTitle={false}>
            <OrderFlowStepper
              onServiceTitle={setServiceTitle}
              onSetShowCSAdviceStep={handleSetShowCSAdviceStep}
              projectForEdit={!fetchingProject && project ? project : null}
              showCSAdviceStep={showCSAdviceStep}
            />
          </DetailsCard>
        )
      }
    </Grid>
  );
};

export default NewProjectPage;
