// Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components 
import CloudWithLoop from 'components/shared/Icons/CloudWithLoop';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';

const useStyles = makeStyles({
  wideRadioDescription: {
    fontSize: 12,
    lineHeight: 1.5,
    color: 'var(--black-60)',
    margin: '0 25px 0 0',

    '& p': {
      padding: '5px 0',
      fontWeight: 'bold',
    },

    '& .fa': {
      color: 'var(--primary-regular)',
    },

    '& .fas': {
      color: 'var(--primary-regular)',
    },

    '& ul': {
      padding: '5px 0',
      listStyleType: 'none',
      lineHeight: 1.33,
      fontWeight: 500,
    },

    '& ul li': {
      padding: '0 0 10px',
      marginLeft: '20px',

      '&::before': {
        position: 'absolute',
        fontFamily: `'Font Awesome 5 Free'`,
        content: `''`,//hack!
        fontWeight: 900,
        fontSize: '0.7rem',
        color: 'var(--primary-regular)',
        padding: '0 10px 0 0',
        marginLeft: '-20px',
      },
    }
  },
  levelCardTitle: {
    fontWeight: 600,
    paddingLeft: 35
  },
  card: {
    border: '1px solid var(--primary-regular)',
    borderLeftWidth: '8px',
    background: 'var(--white)',
    borderRadius: 4,
    boxShadow: 'none',
    padding: 16,
    minHeight: 180,
    '&.emptyCard': {
      border: '1px solid var(--black-20)',
      borderLeftWidth: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      color: 'var(--black-100)'
    }
  },
  bookMark: {
    color: "var(--secondary-orange)",
    fontSize: '2.4rem',
    position: 'absolute',
    marginTop: '-20px',
  },
  font: {
    fontWeight: 600,
    color: 'black',
    fontSize: 12,
    paddingBottom: 8,
  },
});

const LevelCard = ({ 
  handleSetCurrentProjectConfig, 
  levelId, 
  hasRecommendedLevel, 
  levels, 
  avialableLevels,
  selectedNotAvailableLevel
}) => {
  const classes = useStyles();

  return (
    hasRecommendedLevel ? (
      <React.Fragment>
        {selectedNotAvailableLevel &&
          <Typography className={classes.font}>
            Recommended type of check is not allowed with your contract.
            Proceed with allowed check or contact administrator
          </Typography>}
        <CustomRadioGroup
          color="simple"
          variant="wide"
          onChange={handleSetCurrentProjectConfig}
          name="projectLevel"
          defaultValue={levelId}
          fields={levels}
          avialableLevels={avialableLevels} 
        />
      </React.Fragment>
    ) : (
      <Grid
        className={clsx(classes.card, 'mb-4 emptyCard')}>
        <CloudWithLoop width={40} height={40} color='var(--black-40)'/>
        <Typography className={classes.font}>No result yet</Typography>
      </Grid>
    )
  )
}

LevelCard.defaultProps = {
  handleSetCurrentProjectConfig: () => null,
  levelId: 1,
  hasRecommendedLevel: false,
  levels: null,
  avialableLevels: [],
  selectedNotAvailableLevel: false
};

LevelCard.propTypes = {
  handleSetCurrentProjectConfig: PropTypes.func,
  levelId: PropTypes.number,
  hasRecommendedLevel: PropTypes.bool,
  levels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  })),
  avialableLevels: PropTypes.array,
  selectedNotAvailableLevel: PropTypes.bool
};

export default LevelCard;
