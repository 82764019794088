import React from 'react';

const QuestionMarkIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <circle cx="12" cy="12" r="9" stroke="#001623" strokeWidth="1.5" />
      <path
        fill="#001623"
        fillRule="nonzero"
        d="M10.643 15.472V18h2.577v-2.528h-2.577zM8 10.055c.01-.591.112-1.133.304-1.625.191-.493.46-.92.804-1.28.345-.362.76-.644 1.248-.846A4.217 4.217 0 0111.989 6c.777 0 1.425.107 1.945.32.52.214.939.479 1.256.796.318.318.545.66.681 1.026.137.367.206.709.206 1.026 0 .526-.069.958-.206 1.297a3.198 3.198 0 01-1.182 1.486c-.246.17-.478.339-.697.509-.22.17-.413.364-.583.582-.17.22-.276.493-.32.821v.624h-2.216v-.739c.033-.47.123-.864.27-1.182.148-.317.32-.588.518-.812a4.27 4.27 0 01.461-.454l.163-.129c.218-.164.42-.328.607-.492.186-.165.336-.345.451-.542.115-.197.167-.443.156-.739 0-.503-.123-.875-.369-1.116-.246-.241-.588-.361-1.026-.361-.296 0-.55.057-.763.172a1.523 1.523 0 00-.526.46 1.994 1.994 0 00-.303.673 3.343 3.343 0 00-.099.829H8z"
      />
    </g>
  </svg>
);

export default QuestionMarkIcon;
