// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @material-ui
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Components
import ChangePasswordForm from 'components/ChangePasswordForm';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import DetailsCard from 'components/DetailsCard';
import PageTitleSection from 'components/PageTitleSection';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
import Spinner from 'components/shared/Spinner';
import { UserEditForm } from 'components/UserData';
// Context
import { UserContext } from 'context/UserContext';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import { fetchUserById, updateUserById } from 'api/users';


const useStyles = makeStyles({
  borderBottom: {
    borderBottom: 'none',
    borderRadius: '4px 4px 0 0'
  },

  borderTop: {
    borderRadius: '0 0 4px 4px'
  },
});

const CurrentUserDetailsPage = props => {
  const classes = useStyles();
  const history = useHistory();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [updateAvatar, setUpdateAvatar] = useState(false);
  const { updateUserData } = useContext(UserContext);
  const {
    open: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm,
  } = useDialog();

  const handleSetSelectedData = updatedUser => {
    handleOpenConfirm();
    setSelectedData(updatedUser);
  };

  const userId = props.id;
  const {
    execute: fetchUser,
    pending: fetchingUser,
    setValue: setUser,
    value: user,
  } = useAsync(fetchUserById, false);
  const { execute: updateUser, value: updatedUser, pending: pendingUserUpdate } = useAsync(
    updateUserById,
    false,
  );

  const handleDataChanged = dataIsChanged => {
    setHasUnsavedData(dataIsChanged);
  };

  useEffect(() => {
    if (updateAvatar) {
      updateUserData();
      fetchUser(userId);
      setUpdateAvatar(false);
    }
  }, [updateAvatar, updateUserData, fetchUser, userId]);

  const userValues = updatedUser || user;
  useEffect(() => {
    fetchUser(userId);
    setEditing(true);
  }, [fetchUser, userId]);
  
  const handleUpdateUser = updatedUser => {
    const response = updateUser(updatedUser);
    if (response) {
      response.then( dataValue => {
        // no errors
        if (!dataValue){
          toast.success('Your personal infomation was changed successfully', { autoClose: 1500 });
          setUser({
            ...user,
            full_name: updatedUser.full_name,
            email: updatedUser.email,
            phone_number: updatedUser.phone_number,
            // avatar: updatedUser.avatar,
          });
          updateUserData();
          handleCloseConfirm();
        }
      });
    }
  };

  const handleUpdatePassword = updatedUser => {
    const response = updateUser(updatedUser);
    if (response) {
      response.then( dataValue => {
        // no errors
        if (!dataValue){
          toast.success('Password changed successfully', { autoClose: 1500 });
        }
      });
    }
    return response;
  };
  // TODO change structure of page from to details cards to one big card with titles and divider between of them
  return (
    <>
      <PageTitleSection title="Account Settings" />
      <Grid 
        container
        direction="column">
        {!fetchingUser ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={7}
            xl={5}>
              <DetailsCard style={classes.borderBottom} bordered={true} title="User Details">
                <UserEditForm
                  user={user}
                  editing={editing}
                  onChangeData={handleDataChanged}
                  onUpdateUser={handleSetSelectedData}
                  pendingUserUpdate={pendingUserUpdate}
                  setUpdateAvatar={setUpdateAvatar}
                />
              </DetailsCard>
          </Grid>
        ) : (
          <Spinner />
        )}
        <Grid 
          item
          xs={12}
          sm={12}
          md={10}
          lg={7}
          xl={5}>
            <DetailsCard style={classes.borderTop} bordered={true} title="Change password">
              <ToastContainer />
              <ChangePasswordForm
                oldvalues={userValues}
                onUpdatePassword={handleUpdatePassword}
                />
            </DetailsCard>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Change personal data"
        open={openConfirm}
        onClose={handleCloseConfirm}
        options={selectedData}
        confirmBtnText="Confirm"
        onConfirm={handleUpdateUser}>
        You are about to change your personal infomation. Please press Confirm to continue the action or Cancel.
      </ConfirmDialog>
      <RouteLeavingGuard
        when={hasUnsavedData}
        navigate={path => history.push(path)}
        options={selectedData}
        shouldBlockNavigation={() => {return true;}}
      />
    </>
  );
};

CurrentUserDetailsPage.propTypes = {};

export default CurrentUserDetailsPage;
