// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  list: {
    listStyle: 'disc',
    paddingLeft: 30
  },
  liName: {
    fontWeight: 600,
    fontStyle: 'italic',
  }
});

const PrivacyPolicyPage = ({hideTitle}) => {
  const classes = useStyles();

  return (
    <Grid>
      {
        !hideTitle && (
          <Typography variant="h2" paragraph={true} color="textPrimary">
            Privacy policy
          </Typography>
        )
      }
      <Typography variant="h2" color="textPrimary" align="center">
        Introduction
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        COSA LLC (further “COSA”) complies with internationally recognized standards of privacy protection,
        and with various privacy laws globally including, but not limited to, the General Data Protection Regulation 2016/679 (“GDPR”)
        and any EU national laws implementing or supplementing the same (the “Data Privacy Laws”).
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        COSA is a professional supplier of consulting and corporate intelligence services, including integrity due diligence and compliance checks,
        country risk assessment and corporate investigations (collectively, “services”). In this respect,
        COSA LLC providing these services may act as data processor or as a separate data controller depending on the service being provided
        and the amount of control COSA has over the purpose(s) and means of the data processing.
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        To the extent that COSA is deemed to be a data controller under Data Privacy Laws, this notice fulfils our obligation to provide
        certain information to third parties whose personal data we process in this capacity as required by Article 14 of the GDPR.
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        Information we collect
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        The following types of personal information may be collected, stored, and used:
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Contact data:</span> Some of the services on this website allow you to send us an email. We will use the information you provide,
            such as email address or phone number, only to respond to your inquiry. Keep in mind that email transmissions are not encrypted by default,
            so we suggest you do not send sensitive information such as Social Security numbers, credit card numbers, or bank account information via such contact forms.
            If such information is required, it will be via a web page that clearly states the page and its transmission of information is secure and encrypted.
            All electronic messages received from visitors are deleted when no longer needed.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" paragraph={true} color="textPrimary">
          <span className={classes.liName}>Website visitor information:</span> We use cookies to collect and temporarily store certain information about your visit.
            The information these cookies collect includes: the domain you access our website from, your computer’s IP address, the date and time you accessed the site.
          </Typography>
        </li>
      </ul>
      <Typography variant="h2" color="textPrimary" align="center">
        Processing of Personal Information
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        We collect personal data to offer and administer our services.<br />
        The data you provide to us will be processed in accordance with the purposes specified in this notice, namely:
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant="h4" color="textPrimary">
            To provide the products or perform the services requested by clients and individuals pursuant to a letter of engagement,
            statement of work, or similar (where the processing is necessary for our legitimate business interests in conducting and managing our business).
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            To provide the products or perform the services requested by clients and individuals using our website or web applications
            (where the processing is necessary for our legitimate business interests in conducting and managing our business).
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            For complying with obligations provided by laws, current regulations and European legislation (e.g. tax regulations)
            (where processing is based on a legal obligation).
          </Typography>
        </li>
        <li>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            For operating and improving COSA’s website and your customer experience. For example, we may collect and analyse data on your use of our website
            and process it for the purpose of improving our online experience.
          </Typography>
        </li>
      </ul>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        Whenever we process your personal data for our legitimate interests, we make sure to consider and balance any potential impact on you
        and your rights under data protection laws. Our legitimate business interests do not automatically override your interests – we will not use
        your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required
        or permitted to by law). You have the right to object to this processing if you wish.
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        Personal data and the performance of client services
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        Our clients engage us on a wide range of matters to help them mitigate risk. In many cases, the client’s engagement of COSA is
        to fulfil a requirement of EU and USA or member state law or regulation, e.g. the EU Anti-Money Laundering Regulations, the UK Bribery
        Act 2010 and FCPA. The personal data we process in the performance of services for and on behalf of our clients includes but is not limited to
        any information relating to a living individual in which the individual is identified or identifiable, for example, the individual’s name, contact
        information, education information, work history, directorships, financial information, as well as, where necessary, data concerning criminal
        convictions and offences and some special categories of information as defined by article 9 of the GDPR. In connection with our services we collect
        various types of personal data from a variety of different sources, including from: our clients; public sources such as public records and
        registers, social media sites and the Internet; other third party sources such as industry or country experts.
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        How data is processed
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        Personal data is processed both manually and electronically in accordance with the above-mentioned purposes and in compliance with
        current regulations. We permit only authorized COSA employees and third-party providers to have access to your information. 
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        Storage of Personal Data
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        COSA will keep personal data for a reasonable period, taking into account legitimate business needs to capture and retain such information.
        Information will also be stored for a period necessary to comply with state, local, federal regulations, or country specific regulations and requirements.
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        Disclosure/Sharing of Personal Data
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        We only share your personal data with your consent or in accordance with this notice. We will not otherwise share, sell or distribute any
        of the information you provide to us except as described in this notice.<br /><br />

        COSA may share your information with external third parties, such as vendors, consultants, legal advisors, auditors and other service providers
        who are performing, advising or assisting with certain services on behalf of COSA. Such third parties have access to personal data solely for the
        purposes of performing the services specified in the applicable contract, and not for any other purpose. COSA requires these third parties to
        undertake security measures consistent with the protections specified in this notice.<br /><br />

        COSA may be required to disclose personal data in response to lawful requests by public authorities, including meeting national security or
        law enforcement requirements.
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        International data transfers
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        Personal information may be transferred, accessed and stored globally as necessary for the uses stated above in accordance with
        this notice, and in compliance with local law and regulations.
      </Typography>
      <Typography variant="h2" color="textPrimary" align="center">
        Third Party Websites or Other Services
      </Typography>
      <Typography variant="h4" paragraph={true} color="textPrimary">
        We are not responsible for the privacy practices of any non-COSA operated websites, mobile apps or other digital services,
        including those that may be linked through COSA websites or services, and we encourage you to review the privacy policies or notices published thereon.
      </Typography>
    </Grid>
  )
}

PrivacyPolicyPage.defaultProps = {
  hideTitle: false,
}

PrivacyPolicyPage.propTypes = {
  hideTitle: PropTypes.bool,
}

export default PrivacyPolicyPage;
