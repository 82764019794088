// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import MomentUtils from '@date-io/moment';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// Components
import DatePicker from 'components/DatePicker';
import FormInfoSection from 'components/FormInfoSection';
// import CustomButton from 'components/CustomButton';

const useStyles = makeStyles({
  datePicker: {
    marginBottom: 13,
  },
});

const DatesForm = ({ values, disabled }) => {
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}>
      {({ values, handleChange, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <FormInfoSection text="Due date" />
              <Field
                component={DatePicker}
                disabled={disabled}
                className={classes.datePicker}
                name="dueDate"
                value={values && values.dueDate}
                onChange={handleChange}
              />
              <FormInfoSection text="Preferred date" />
              <Field
                component={DatePicker}
                disabled={disabled}
                name="preferredDate"
                minDate={new Date()}
                value={values && values.preferredDate}
                onChange={handleChange}
              />
              {/* <CustomButton
                type="submit"
                disabled={isSubmitting}
                style={{ width: 125, alignSelf: 'flex-end' }}>
                Save
              </CustomButton> */}
            </MuiPickersUtilsProvider>
          </form>
        );
      }}
    </Formik>
  );
};

DatesForm.defaultProps = {
  disabled: false,
};

DatesForm.propTypes = {
  values: PropTypes.shape({
    dueDate: PropTypes.string.isRequired,
    preferredDate: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default DatesForm;
