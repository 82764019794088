const statusIcons = {
  '1': {
    id: 1,
    name: 'Draft',
    icon: 'pen-alt',
  },
  '2': {
    id: 1,
    name: 'New',
    icon: 'file',
  },
  '3': {
    id: 3,
    name: 'On hold',
    icon: 'pause-circle',
  },
  '4': {
    id: 4,
    name: 'In progress',
    icon: 'play-circle',
  },
  '5': {
    id: 5,
    name: 'Completed',
    icon: 'check-circle',
  },
};

export default statusIcons;
