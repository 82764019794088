// Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types'; 
// @material-ui
import { withStyles, makeStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';

const OSINTSwitch = withStyles({
  root: {
    width: 30,
    height: 15,
    padding: 0,
    marginRight: 8
  },
  switchBase: {
    padding: 0,
    top: 0,
    width: 15,
    height: 15,
    backgroundColor: 'var(--black-100)',
    '&:hover': {
      backgroundColor: 'var(--black-100)',
    },
    '&$checked': {
      transform: 'translateX(15px)',
      backgroundColor: 'var(--primary-regular) !important',
      '&$disabled': {
        backgroundColor: 'var(--black-20) !important',
      },
      '& + $track': {
        opacity: 1,
        backgroundColor: 'var(--white) !important',
        border: '1px solid var(--black-20)',
      },
    },
    '&$disabled': {
      backgroundColor: 'var(--black-60)',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'var(--black-05) !important',
        border: '1px solid var(--black-20)',
      },
    }
  },
  track: {
    border: '1px solid var(--black-20)',
    backgroundColor: 'var(--white)',
    opacity: 1,
  },
  checked: {},
  disabled: {},
})(Switch);

const useStyles = makeStyles({
  label: {
    margin: '0px !important',
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      color: 'var(--black-100)',
    },
  },
  icon: {
    fontSize: 7, 
  }
})

const CustomSwitch = ({
  id,
  name,
  value,
  label,
  onChange,
  checked,
  disabled
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.label}
      control={
        <OSINTSwitch
          id={id}
          name={name}
          checked={checked}
          value={value}
          disabled={disabled}
          onChange={onChange}
          icon={<Icon className={clsx("fas fa-times", classes.icon)}/>}
          checkedIcon={<Icon className={clsx("fas fa-check", classes.icon)}/>}
        />
      }
      label={label}
    />
  )
}

CustomSwitch.defaultProps = {
  id: null,
  name: null,
  value: false,
  label: null,
  onChange: () => null,
  checked: false,
  disabled: false,
}

CustomSwitch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CustomSwitch;
