// Core 
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
// @aterial-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// Components
import CustomIconButton from 'components/CustomIconButton';
import DatePicker from 'components/DatePicker';
import IconButton from 'components/IconButton';

const useStyles = makeStyles({
  summaryFieldsWrapper: {
    '& button': {
      marginLeft: 0,
    }
  },
  dueDate: {
    cursor: 'pointer',
    marginBottom: '1rem',
    '&:hover': {
      '& :last-child': {
        visibility: 'visible',
      },
      '& button:first-child': {
        border: '1px solid var(--primary-regular)',
        backgroundColor: 'var(--primary-regular)',
        color: 'var(--primary-disabled)',
        '& span': {
          color: 'var(--primary-disabled)',
        },
      },
    },
  },
  innerSpan: {
    display: 'flex',
    flexDirection: 'row',
  },
  dueDateChange: {
    marginBottom: '1rem',
    '& .MuiIconButton-root': {
      width: '2rem',
      height: '2rem',
    },
  },
  editIcon:{
    color: 'var(--primary-regular)',
    visibility: 'hidden',
  },
  dueDateTitle: {
    color: 'var(--black-60)',
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '18px',
    paddingRight: '0.5rem',
  },
  dateArea: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const DueDateCard = props => {
  const classes = useStyles();
  const {
    dueDate,
    editable, //TODO: can be deleted, because has the same meaning as datePickerOpened
    hideDate,
    onDueDateChange,
    newDueDate,
    handleUnsetSelectedDueDate,
    handleConfirmUpdateDueDate,
    datePickerOpened,
    handleDatePickerOpen
  } = props;

  return (
    editable ? 
      datePickerOpened ? (
      <Grid container item className={classes.dueDateChange} justify="space-between" alignItems="center" direction="row">
        <DatePicker
          dueDate={dueDate}
          onDueDateChange={onDueDateChange}
          newDueDate={newDueDate}/>
        <span>
          <CustomIconButton tooltipText="Save" icon="fas fa-check" onIconButtonClick={() => handleConfirmUpdateDueDate()}/>
          <CustomIconButton tooltipText="Cancel" icon="fas fa-times" onIconButtonClick={() => { handleDatePickerOpen(); handleUnsetSelectedDueDate(); }}/>
        </span>
      </Grid>) : (
      <Grid container item className={classes.dueDate} justify="space-between" alignItems="center" direction="row" onClick={handleDatePickerOpen}>
        <span className={classes.innerSpan}>
          <IconButton inverted="true" disabled><Icon className="fa fa-calendar-check" /></IconButton>
          <Typography className={classes.dateArea} variant="body2">
            <span>
              <span className={classes.dueDateTitle}>
                Due Date
              </span>
            </span>
            <span className="text-truncate">
              {hideDate ? 'TBD' : moment(dueDate).format('DD MMM YYYY')}
            </span> 
          </Typography>
        </span>
        <Icon className={clsx("fas fa-pen", classes.editIcon)}/>
      </Grid>
      ) : (
      <Grid container item className="mb-3">
        <IconButton inverted="true" disabled><Icon className="fa fa-calendar-check" /></IconButton>
        <Typography variant="body2" className={classes.dateArea}>
          <span>
            <span className={classes.dueDateTitle}>
              Due Date
            </span>
            <Tooltip 
              title="Due date will be set by Administrator after the project review." 
              arrow={true} 
              interactive={false}
              style={{color: 'var(--primary-regular)', fontSize: 8}}>
                <Icon className="fa fa-info-circle fa-sm" />
            </Tooltip>
          </span>
          <span className="text-truncate">
            {hideDate ? 'TBD' : moment(dueDate).format('DD MMM YYYY')}
          </span>
        </Typography>
      </Grid>)
  );
}

DueDateCard.propTypes = {
  editable: PropTypes.bool.isRequired,
  hideDate: PropTypes.bool.isRequired,
  onDueDateChange: PropTypes.func,
  newDueDate: PropTypes.instanceOf(Date),
  handleUnsetSelectedDueDate: PropTypes.func,
  handleConfirmUpdateDueDate: PropTypes.func,
  datePickerOpened: PropTypes.bool,
  handleDatePickerOpen: PropTypes.func
}

export default DueDateCard;
