//Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types'; 
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 40,
    margin: 0,
    width: '100%',
    border: '1px solid var(--black-20)',
    borderLeft: '5px solid var(--black-20)',
    borderRadius: 4,
    paddingLeft: 12,

    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      color: 'var(--black-100)',
      lineHeight: 1,
    },
  },

  borderGreen: {
    borderColor: 'var(--secondary-green)',
  },
  borderBlue: {
    borderColor: 'var(--primary-regular)',
  },
  borderOrange: {
    borderColor: 'var(--secondary-orange)',
  },
  borderPurple: {
    borderColor: 'var(--secondary-purple)',
  },
  borderTurquoize: {
    borderColor: 'var(--secondary-turquoise)',
  },
  borderAzure: {
    borderColor: 'var(--secondary-azure)',
  },
});

const CustomCheckbox = ({
  id,
  name,
  value,
  label,
  onChange,
  disabled=false
}) => {
  const classes = useStyles();

  const setColor = (fieldId) => {
    switch(parseInt(fieldId)) {
      case 1:
        return classes.borderGreen;
      case 2:
        return classes.borderBlue;
      case 3:
        return classes.borderOrange;
      case 4:
        return classes.borderPurple;
      case 5:
        return classes.borderTurquoize;
      case 6:
        return classes.borderAzure;
      case 7:
        return classes.borderGreen;
      case 8:
        return classes.borderBlue;
      case 9:
        return classes.borderOrange;
      case 10:
        return classes.borderPurple;
      case 11:
        return classes.borderTurquoize;
      case 12:
        return classes.borderAzure;
      default:
        return classes.borderGreen;
    };
  };

  return (
    <FormControlLabel 
      className={clsx(classes.formControl, {[setColor(id)]: value === true})}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          color="primary"
        />
      }
      labelPlacement="start"
      label={label}
    />
  );
}

CustomCheckbox.defaultProps = {
  id: "",
  name: "",
  value: null,
  label: "",
  onChange: () => null,
}

CustomCheckbox.propTypes= {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default CustomCheckbox;
