// Core
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import FormikInput from 'components/FormikInput';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important'
      }
    }
  }
});

const BankStep = ({
  bank_entity,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, "pt-3")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="beneficiaryBank"
          name="bank_entity.beneficiary_name"
          label="Beneficiary bank account name"
          value={bank_entity.beneficiary_name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          id="correspondentBank"
          name="bank_entity.correspondent_name"
          label="Correspondent bank account name"
          value={bank_entity.correspondent_name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="beneficiaryAddress"
          name="bank_entity.beneficiary_address"
          label="Beneficiary bank address"
          value={bank_entity.beneficiary_address}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          id="correspondentAddress"
          name="bank_entity.correspondent_address"
          label="Correspondent bank address"
          value={bank_entity.correspondent_address}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="beneficiaryIBAN"
          name="bank_entity.beneficiary_iban"
          label="IBAN number"
          value={bank_entity.beneficiary_iban}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          id="correspondentIBAN"
          name="bank_entity.correspondent_iban"
          label="IBAN number"
          value={bank_entity.correspondent_iban}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="beneficiarySWIFT"
          name="bank_entity.beneficiary_swift"
          label="SWIFT"
          value={bank_entity.beneficiary_swift}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          id="correspondentSWIFT"
          name="bank_entity.correspondent_swift"
          label="SWIFT"
          value={bank_entity.correspondent_swift}
        />
      </Grid>
      <Grid item xs={12} className="mb-1" />
    </Grid>
  );
};

export default BankStep;
