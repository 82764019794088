// Core
import React, { createContext, useEffect } from 'react';
// Hooks
import { useAuth } from 'hooks/useAuth';
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchServices } from 'api/info';

export const ServicesContext = createContext({});

const ServicesProvider = ({ children }) => {
  let { accessToken } = useAuth();
  if (!accessToken) {
    accessToken = localStorage.getItem('access_token');
  }

  const {
    execute: getServices,
    pending: isLoadingServices,
    value: services,
  } = useAsync(fetchServices, false);

  useEffect(() => {
    if (accessToken) {
      getServices();
    }
  }, [getServices, accessToken]);

  return (
    <ServicesContext.Provider value={{ services, isLoadingServices }}>
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesProvider;
