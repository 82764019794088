// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
// Components
import BreadcrumbsCustom from 'components/BreadcrumbsCustom';
import DrawerMenu from 'components/DrawerMenu';
import Footer from 'components/Footer';
import Header from 'components/Header';
import NotFoundPage from 'pages/NotFoundPage';
// Hooks
import { useAuth } from 'hooks/useAuth';
// Helpers
// Auto logout after 15min inactive user
import UserAutoLogout from '../../helpers/userAutoLogout';


const useStyles = makeStyles({
  content: {
    minHeight: '100vh',
    margin: 'auto',
    padding: '0 24px 24px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  mainWrapper: {
    display: 'flex',
  },
});

const DefaultLayout = ({ routes, loggedInUser, ...props }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { signout } = useAuth();
  const notFoundPath = '/404';
  const { nav } = props;
  const [widthMain, setWidthMain] = useState('calc(100% - 64px)');

  const widthWrapper = (isOpen) => {
    if (isOpen) {
      return setWidthMain('calc(100% - 250px)');
    }
    return setWidthMain('calc(100% - 64px)');
  };

  useEffect(() => {
    const timer = new UserAutoLogout({
      timeout: 1230,
      onTimeout: () => {
        signout();
      }
    });
    return () => {
      timer.cleanInterval();
    };
  }, [signout]);
    
  return (
  <div className={classes.mainWrapper}>
    <DrawerMenu variant="permanent" links={nav} onOpenMenu={widthWrapper} />
    <main className={classes.content} style={{width: widthMain}}>
      <span>
        {pathname !== notFoundPath 
          && (loggedInUser.role_id !== 6 
            ? <Header {...props}/> : null)
        }
        {pathname !== notFoundPath && <BreadcrumbsCustom addToBreadcrumbs={routes} currentPath={pathname} />}
        <Switch>
          {routes.map(({path, component: Component, exact}, idx) => (
            <Route key={idx} {...{path, exact}}>
              <Component {...loggedInUser} />
            </Route>
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </span>
      <Footer/>
    </main>
  </div>
  );
};

DefaultLayout.defaultProps = {
  loggedInUser: null,
};

DefaultLayout.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      exact: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  loggedInUser: PropTypes.shape({}),
};

export default DefaultLayout;
