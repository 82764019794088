// Core
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 0',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  },
}));

const PageTitleSection = ({ title, children, className }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Typography variant="h1">{title}</Typography>
      {children}
    </Box>
  );
};

PageTitleSection.defaultProps = {
  title: '',
  children: null,
  className: '',
};

PageTitleSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default PageTitleSection;
