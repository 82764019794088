// Core
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import DetailsPartnerCard from 'components/Partners/DetailsPartnerCard';
import Spinner from 'components/shared/Spinner';
import WatchlistGuideLinksList from 'components/WatchlistGuide'
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Context
import { UserContext } from 'context/UserContext';
// Instruments
import { getDetailsPartner } from 'api/questionnaire';
import { createProject } from 'api/projects';
import { fetchLevels } from 'api/info';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const useStyles = makeStyles({
  isawlWrapper: {
    marginLeft: 15,
  },
});

const PartnerDetailsPage = () => {
  const styles = useStyles();

  const { push } = useHistory();
  const { id } = useParams();
  const { loggedInUser } = useContext(UserContext);
  const [pdfInProcess, setPdfInProcess] = useState(false);
  const [levelCS, setLevelsCS] = useState("1");
  const [levelerror, setLevelerror] = useState(false);

  const { value: levels } = useAsync(fetchLevels, true);
  const { execute: createNewProject } = useAsync(createProject, false);
  const { execute: fetchGetPartner, pending: pendingGetPartner, value: partner } = useAsync(
    getDetailsPartner,
    false
  );

  const {
    open: openCreateCSProject,
    handleOpen: handleOpenCreateCSProject,
    handleClose: handleCloseCreateCSProject
  } = useDialog();
  const {
    open: openCreateEDDProject,
    handleOpen: handleOpenCreateEDDProject,
    handleClose: handleCloseCreateEDDProject
  } = useDialog();

  let avialableLevels;
  let isAvailableCSModule;
  let isAvailableEDDModule;
  if (loggedInUser) {
    avialableLevels = loggedInUser.cs_levels.map(item => item.id).sort();
    isAvailableCSModule = loggedInUser.subscriptions.some(sub => sub.id === 1);
    isAvailableEDDModule = loggedInUser.subscriptions.some(sub => sub.id === 2);
  }

  useEffect(() => {
    if (isAvailableCSModule && avialableLevels && !avialableLevels.includes(parseInt(levelCS))) {
      if (parseInt(levelCS) < 3) {
        setLevelsCS(String(parseInt(levelCS) + 1));
      } else if (parseInt(levelCS) === 3 && avialableLevels.includes(2)) {
        setLevelsCS("2");
      } else {
        setLevelsCS("1");
      }
      setLevelerror(true);
    }
  }, [levelCS, avialableLevels, isAvailableCSModule]);

  useEffect(() => {
    if (partner) {
      switch (partner.general_information.risk) {
        case "Basic":
          setLevelsCS("1");
          break;
        case "Moderate":
          setLevelsCS("2");
          break;
        case "High":
          setLevelsCS("3");
          break;
        default:
          setLevelsCS("1");
      }
    }
  }, [partner]);

  useEffect(() => {
    fetchGetPartner(id);
  }, [fetchGetPartner, id]);

  const ids_array = [
    "cosa_politics_id",
    "general_information_id",
    "directors_id",
    "shareholders_id",
    "ubos_id",
    "bank_id",
    "compliance_procedures_id",
    "files_links_id_1",
    "files_links_id_2",
    "files_links_id_3",
    "files_links_id_4",
    "files_links_id_5",
    "respondent_id"
  ];

  const generatePage = async (doc, div_id, index, resolve) => {
    const input = document.getElementById(div_id);
    if (input.tagName !== 'SPAN') {
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 193;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      if (index !== 0) {
        doc.addPage();
        doc.setPage(index + 2);
      }
      doc.addImage(imgData, 'JPEG', 8, 8, imgWidth, imgHeight);
    }

    if (index !== ids_array.length - 1) {
      generatePage(doc, ids_array[index + 1], index + 1, resolve);
    } else {
      resolve();
    }
  };

  const downloadPdf = () => {
    setPdfInProcess(true);
    let doc = new jsPDF('p', 'mm', 'a4', true);

    const promise = new Promise(resolve => {
      generatePage(doc, ids_array[0], 0, resolve);
    });

    const result_name = `Compliance_Questionnaire_${partner.general_information.legal_name}_${partner.general_information.registered_number}.pdf`;

    promise.then(
      () => {
        doc.save(result_name, { returnPromise: true }).then(() => {
          setPdfInProcess(false);
        });
      }
    );
  };

  const createProjectCS = () => {
    let individualsArray = null;
    if ([2, 3].includes(parseInt(levelCS))) {
      individualsArray = partner.directors.map((director) => {
        return {
          name: director.name,
          country_code: director.citizenship,
          name_in_local_language: "",
          alias: "",
          shares: "",
          residential_address: "",
          business_address: "",
          passport_number: director.registered_number,
          national_id: "",
          website: "",
          is_key: false,
          position: director.director_position
        }
      });
    }
    const valuesForCreatingProject = {
      service_id: 1,
      config_id: parseInt(levelCS),
      entities: [{
        name: partner.general_information.legal_name,
        country_code: partner.general_information.country_code_registration,
        name_in_local_language: "",
        trading_name: partner.general_information.trading_name,
        registered_address: partner.general_information.registered_address,
        operational_address: partner.general_information.office_address,
        business_license_number: partner.general_information.registered_number,
        tax_number: "",
        website: partner.general_information.corporate_website,
        is_key: true,
        position: ""
      }],
      individuals: individualsArray
    };
    createNewProject(valuesForCreatingProject);
  };

  const createProjectEDD = () => {
    const valuesForCreatingProject = {
      service_id: 2,
      config_id: 4,
      entities: [{
        name: partner.general_information.legal_name,
        country_code: partner.general_information.country_code_registration,
        name_in_local_language: "",
        trading_name: partner.general_information.trading_name,
        registered_address: partner.general_information.registered_address,
        operational_address: partner.general_information.office_address,
        business_license_number: partner.general_information.registered_number,
        tax_number: "",
        website: partner.general_information.corporate_website,
        is_key: true,
        position: ""
      }],
      individuals: null
    };
    createNewProject(valuesForCreatingProject);
  };

  const handlerCreateCSProject = () => {
    createProjectCS();
    handleCloseCreateCSProject();
    push('/projects');
  };
  const handlerCreateEddProject = () => {
    createProjectEDD();
    handleCloseCreateEDDProject();
    push('/projects');
  };

  const handlerChangeLevelCS = (level) => {
    setLevelsCS(level);
  };

  return (
    <React.Fragment>
      {
        !pendingGetPartner && partner ?
          (
            <Grid
              container
              direction="row">
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ maxWidth: '760px' }}>
                <Grid item>
                  <Button onClick={downloadPdf} variant="outlined" color="primary" disabled={pdfInProcess}>
                    {pdfInProcess ? "Wait please" : "Convert to PDF file"}
                  </Button>
                  <Button color="primary" disabled={true}>
                    Local Watchlist Guide
                  </Button>
                  {/* Code below waiting for Local WatchLists */}
                  {/* {
                    partner.finish_form.respondent_date
                      ? (<Link to={{ pathname: `/partners/guide/${id}` }}>
                        <Button color="primary">
                          Local watch-list guide
                        </Button>
                      </Link>)
                      : (<Button color="primary" disabled={true}>
                        Local watch-list guide
                      </Button>)
                  } */}
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleOpenCreateCSProject}
                    disabled={!isAvailableCSModule || !partner.finish_form.respondent_date}
                    className={!isAvailableCSModule || !partner.finish_form.respondent_date ? "ml-3" : "ml-3 typeService1"}
                  >
                    Request Screening
                  </Button>
                  <Button
                    onClick={handleOpenCreateEDDProject}
                    disabled={!isAvailableEDDModule}
                    className={!isAvailableEDDModule ? "ml-3" : "ml-3 typeService2"}
                  >
                    Request EDD
                  </Button>
                </Grid>
                <DetailsPartnerCard companyName={loggedInUser?.company_name} partner={partner} />
              </Grid>
              <span className={styles.isawlWrapper}>
                <WatchlistGuideLinksList />
              </span>
            </Grid>
          )
          : <Spinner />
      }
      <ToastContainer />
      <ConfirmDialog
        fullWidth={true}
        title="Create CS project"
        open={openCreateCSProject}
        onClose={handleCloseCreateCSProject}
        confirmBtnText="Confirm"
        onConfirm={handlerCreateCSProject}>
        You are about to create Compliance screening project.
        Recomended level - {partner?.general_information?.risk}.
        {levelerror && " But you haven't permission to this level, we chose another one. "}
        Change it if you want.
        Please, check and finish project on dashboard page.
        <Grid style={{ paddingTop: 16 }}>
          <CustomRadioGroup
            color="simple"
            variant="wide"
            onChange={handlerChangeLevelCS}
            name="projectLevel"
            defaultValue={levelCS}
            avialableLevels={avialableLevels}
            fields={levels}
          />
        </Grid>
      </ConfirmDialog>
      <ConfirmDialog
        title="Create EDD project"
        open={openCreateEDDProject}
        onClose={handleCloseCreateEDDProject}
        confirmBtnText="Confirm"
        onConfirm={handlerCreateEddProject}>
        You are about to create Enhanced Due Diligence project.
        Please, check and finish project on dashboard page.
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default PartnerDetailsPage;
