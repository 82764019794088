// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomStepper from 'components/UI/CustomStepper/CustomSteper';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
import useStepper from 'hooks/useStepper';
// Instrument
import { createComment } from 'api/comments';
import { createProject, updateProject } from 'api/projects';
// Helpers
import {
  checkIfAllValsAreFalsy,
  getLocationQueryVariable,
  transformProjectId,
} from 'helpers';
// Constants
import {
  ADMIN_EMAIL,
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
  LEGAL_COMP_CONFIG,
  TECH_SEARCH_ANALYSIS_CONFIG,
  INTELL_TECH_LAB_ANALYSIS_CONFIG,
  PARTNER_SEARCH_ANALYSIS_CONFIG,
  TRENDS_INTELLIGENCE_CONFIG,
} from 'utils';
import { ENHANCED_ANALYSIS, PESTEL_ANALYSIS } from 'utils';
import StepCard from './StepCard';

const useStyles = makeStyles({
  projectLabel: {
    backgroundColor: 'var(--primary-disabled)',
    borderRadius: 4,
    color: 'var(--primary-regular)',
    padding: '2px 6px',
  },

  firstTab: {
    fontSize: 15,
    fontWeight: 700,
  },

  text: {
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--black)',
  },
});

const initialEntity = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  country: '',
  name_in_local_language: '',
  website: '',
  position: '',
  registered_address: '',
  tax_number: '',
  trading_name: '',
  operational_address: '',
  business_license_number: '',
  is_key: false,
  type: 'Entity',
};
const initialIndividual = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  country: '',
  name_in_local_language: '',
  website: '',
  position: '',
  alias: '',
  shares: '',
  residential_address: '',
  business_address: '',
  passport_number: '',
  national_id: '',
  is_key: false,
  type: 'Individual',
};

const initialGeneralValues = {
  newProjectId: 0,
  serviceId: 1,
  levelId: null,
  humint: false,
  // Type of the key subject, default false = "Entity"
  typeKeySubject: false,
  // Type of the related subject, default true = "Individual"
  typeRelatedSubject: true,
  serviceTitle: '',
  analysisTypeId: 1,
  selectedConfig: {},
  entities: [initialEntity],
  individuals: [initialIndividual],
  comment: '',
  adviceFlow: {
    country: '',
    industry: '',
    duration: '',
    relationship: '',
  },
  strategicIntelligence: {
    settings_id: '',
    industry_id: 1,
    industry_name: 'None',
    country_code: '',
    risks: [],
    stakeholders: [],
  },
  legalCompliance: {
    settings_id: '',
    industry_id: 1,
    industry_name: 'None',
    country_code: '',
    sub_services: [],
  },
  techIntelligence: {
    settings_id: '',
    industry_id: 1,
    industry_name: 'None',
    country_code: '',
    challenge_problem: '',
    expectations: '',
  },
  partnerSearch: {
    settings_id: '',
    industry_id: 1,
    industry_name: 'None',
    country_code: '',
    partnership_types: [],
  },
  trendsIntelligence: {
    settings_id: '',
    subjects_to_improve: [],
    key_markets: '',
    key_products: '',
    key_competitors: '',
  },
};

const STEPS_CS = [
  'Project Type',
  'Key Subject',
  'Key Info',
  'Related Info',
  'Summary',
];

const STEPS_EDD = ['Project Type', 'General Subject', 'Summary'];

const STEPS_PES = ['Project Type', 'Candidate\'s Profile', 'Summary'];

const STEPS_SI = [
  'Project Type',
  'Analysis Type',
  'Analysis Details',
  'Summary',
];

const STEPS_LEGAL_COMP_SERVICE = ['Project Type', 'General Subject', 'Summary'];
const STEPS_TECH_INTELLI_SERVICE = [
  'Project Type',
  'General Subject',
  'General Information',
  'Summary',
];
const STEPS_PARTNER_SEARCH_SERVICE = [
  'Project Type',
  'General Subject',
  'Summary',
];
const STEPS_TRENDS_INTELIGENCE_SERVICE = [
  'Project Type',
  'General Subject',
  'Summary',
];

const Stepper = ({
  onServiceTitle,
  projectForEdit,
  showCSAdviceStep,
  onSetShowCSAdviceStep,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    activeStep,
    handleNext,
    handleBack,
    handleSkipTwoSteps,
    handleBackTwoSteps,
    handleGoToSomeStep,
  } = useStepper();
  const location = useLocation();
  const serviceType = parseInt(getLocationQueryVariable(location, 'type'));
  if (serviceType > 0) {
    initialGeneralValues.serviceId = serviceType;
    initialGeneralValues.analysisTypeId = parseInt(serviceType) === SI_SERVICE ? 1 : parseInt(serviceType) === TECH_INTELLI_SERVICE ? 3 : null;
    
  }
  const [projectData, setProjectData] = useState(null);
  const [values, setValues] = useState(initialGeneralValues);

  const { open, handleOpen } = useDialog();

  const {
    execute: createNewProject,
    pending: pendingCreateProject,
    value: newProject,
  } = useAsync(createProject, false);
  const {
    execute: updateProjectData,
    pending: pendingUpdateProject,
    value: updatedProject,
  } = useAsync(updateProject, false);
  const { execute: sendComment } = useAsync(createComment, false, false);

  const orderFlowSteps = serviceId => {
    switch (parseInt(serviceId)) {
      case CS_SERVICE:
        return STEPS_CS;
      case EDD_SERVICE:
        return STEPS_EDD;
      case PES_SERVICE:
        return STEPS_PES;
      case SI_SERVICE:
        return STEPS_SI;
      case LEGAL_COMP_SERVICE:
        return STEPS_LEGAL_COMP_SERVICE;
      case TECH_INTELLI_SERVICE:
        return STEPS_TECH_INTELLI_SERVICE;
      case PARTNER_SEARCH_SERVICE:
        return STEPS_PARTNER_SEARCH_SERVICE;
      case TRENDS_INTELIGENCE_SERVICE:
        return STEPS_TRENDS_INTELIGENCE_SERVICE;
      default:
        return STEPS_CS;
    }
  };

  useEffect(() => {
    if (projectForEdit) {
      let projectSetData = {};
      if (projectForEdit.subjects) {
        const entityArray = projectForEdit.subjects.filter(
          subject => subject.type === 'Entity',
        );
        const individualArray = projectForEdit.subjects.filter(
          subject => subject.type === 'Individual',
        );
        if (entityArray) {
          Object.keys(entityArray).map(key => {
            if (entityArray[key] === null) {
              entityArray[key] = '';
            }
            return entityArray[key];
          });
        }
        if (individualArray) {
          Object.keys(individualArray).map(key => {
            if (individualArray[key] === null) {
              individualArray[key] = '';
            }
            return individualArray[key];
          });
        }

        projectSetData = {
          newProjectId: projectForEdit.project_id,
          serviceId: projectForEdit.service_id || 1,
          levelId: projectForEdit.level_id,
          typeKeySubject: projectForEdit.key_subject !== 'Entity',
          typeRelatedSubject: projectForEdit.related_subject !== 'Entity',
          configId: projectForEdit.config_id || 1,
        };
        projectSetData.entities = entityArray || [initialEntity];
        projectSetData.individuals = individualArray || [initialIndividual];
      } else if (parseInt(projectForEdit.service_id) === SI_SERVICE) {
        projectSetData = {
          newProjectId: projectForEdit.project_id,
          serviceId: projectForEdit.service_id || 1,
          configId: projectForEdit.config_id || 1,
          strategicIntelligence: projectForEdit.analysis_details,
          analysisTypeId: projectForEdit.analysis_type,
        };
      } else if (parseInt(projectForEdit.service_id) === LEGAL_COMP_SERVICE) {
        projectSetData = {
          newProjectId: projectForEdit.project_id,
          serviceId: projectForEdit.service_id || LEGAL_COMP_SERVICE,
          configId: projectForEdit.config_id || LEGAL_COMP_CONFIG,
          legalCompliance: projectForEdit.legal_comp_details,
        };
      } else if (parseInt(projectForEdit.service_id) === PARTNER_SEARCH_SERVICE) {
        projectSetData = {
          newProjectId: projectForEdit.project_id,
          serviceId: projectForEdit.service_id || PARTNER_SEARCH_SERVICE,
          configId: projectForEdit.config_id || PARTNER_SEARCH_ANALYSIS_CONFIG,
          partnerSearch: projectForEdit.partner_search_details,
        };
      } else if (parseInt(projectForEdit.service_id) === TECH_INTELLI_SERVICE) {
        projectSetData = {
          analysisTypeId: projectForEdit.analysis_type,
          newProjectId: projectForEdit.project_id,
          serviceId: projectForEdit.service_id || TECH_INTELLI_SERVICE,
          configId: projectForEdit.config_id || TECH_SEARCH_ANALYSIS_CONFIG,
          techIntelligence: projectForEdit.tech_intelli_details,
        };
      } else if (parseInt(projectForEdit.service_id) === TRENDS_INTELIGENCE_SERVICE) {
        projectSetData = {
          newProjectId: projectForEdit.project_id,
          serviceId: projectForEdit.service_id || TRENDS_INTELIGENCE_SERVICE,
          configId: projectForEdit.config_id || TRENDS_INTELLIGENCE_CONFIG,
          trendsIntelligence: projectForEdit.trends_intelligence_details,
        };
      }
      setProjectData(projectSetData);
    }
  }, [projectForEdit, setProjectData]);

  useEffect(() => {
    if (!updatedProject) return;
    if (updatedProject) {
      const typeKeySubject = updatedProject.key_subject !== 'Entity';
      const typeRelatedSubject = updatedProject.related_subject !== 'Entity';
      const projectSetData = {
        newProjectId: updatedProject.id,
        serviceId: updatedProject.service_id || 1,
        levelId: updatedProject.level_id,
        configId: updatedProject.config_id || 1,
        typeKeySubject,
        typeRelatedSubject,
      };
      if ([2, 3].includes(updatedProject.config_id)) {
        if (!typeKeySubject) {
          projectSetData.entities = updatedProject.entities;
          projectSetData.individuals = typeRelatedSubject
            ? updatedProject.individuals
            : null;
        } else if (typeKeySubject) {
          projectSetData.individuals = updatedProject.individuals;
          projectSetData.entities = !typeRelatedSubject
            ? updatedProject.entities
            : null;
        }
      } else if (
        [ENHANCED_ANALYSIS, PESTEL_ANALYSIS].includes(updatedProject.config_id)
      ) {
        projectSetData.strategicIntelligence =
          updatedProject.si_project_settings;
      } else if ([LEGAL_COMP_CONFIG].includes(updatedProject.config_id)) {
        projectSetData.legalCompliance = updatedProject.legal_comp_details;
      } else if (
        [PARTNER_SEARCH_ANALYSIS_CONFIG].includes(updatedProject.config_id)
      ) {
        projectSetData.partnerSearch = updatedProject.partner_search_details;
      } else if (
        [TRENDS_INTELLIGENCE_CONFIG].includes(updatedProject.config_id)
      ) {
        projectSetData.trendsIntelligence =
          updatedProject.trends_intelligence_details;
      } else if (
        [TECH_SEARCH_ANALYSIS_CONFIG, INTELL_TECH_LAB_ANALYSIS_CONFIG].includes(
          updatedProject.config_id,
        )
      ) {
        projectSetData.techIntelligence = updatedProject.tech_intelli_details;
      } else {
        projectSetData.entities = !typeKeySubject
          ? updatedProject.entities
          : null;
        projectSetData.individuals = typeKeySubject
          ? updatedProject.individuals
          : null;
      }

      setProjectData(projectSetData);
      if (
        (activeStep === 5 && parseInt(values.serviceId) === CS_SERVICE) ||
        (activeStep === 3 &&
          [EDD_SERVICE, PES_SERVICE, LEGAL_COMP_SERVICE, PARTNER_SEARCH_SERVICE, TRENDS_INTELIGENCE_SERVICE].includes(parseInt(values.serviceId))) ||
        (activeStep === 4 && [SI_SERVICE, TECH_INTELLI_SERVICE].includes(parseInt(values.serviceId)))
      ) {
        handleOpen();
      } else if (
        values.levelId === 1 &&
        parseInt(values.serviceId) === CS_SERVICE
      ) {
        handleSkipTwoSteps();
      } else {
        handleNext();
      }
    }
    // eslint-disable-next-line
  }, [updatedProject, setProjectData]);

  useEffect(() => {
    if (!newProject) return;
    if (values.newProjectId === newProject.id) return;
    values.newProjectId = newProject.id;
    setValues(values);
    if (values.levelId === 1 && parseInt(values.serviceId) === CS_SERVICE) {
      handleSkipTwoSteps();
    } else {
      handleNext();
    }
  }, [values, newProject, handleNext, handleSkipTwoSteps, setValues]);

  const handleUpdateValues = updatedValues => {
    setValues(updatedValues);
  };

  const handleCreateNewProject = (params = {}) => {
    if (pendingCreateProject) {
      return;
    }
    const serviceId = parseInt(values.serviceId);
    const levelId = parseInt(values.levelId);
    const { typeKeySubject } = values;
    const { typeRelatedSubject } = values;
    const configId = values.selectedConfig.config_id || values.config_id;
    const entitiesArray = checkIfAllValsAreFalsy(values.entities);
    const individualsArray = checkIfAllValsAreFalsy(values.individuals);
    let valuesForCreatingProject = {};
    if (serviceId === CS_SERVICE) {
      let entities = [];
      let individuals = [];
      // For the CS if level = 1 transform one of the subject to null
      if (levelId === 1) {
        entities = typeKeySubject && levelId === 1 ? null : entitiesArray;
        individuals =
          !typeKeySubject && levelId === 1 ? null : individualsArray;
      } else {
        entities = typeKeySubject && typeRelatedSubject ? null : entitiesArray;
        individuals =
          !typeKeySubject && !typeRelatedSubject ? null : individualsArray;
      }
      valuesForCreatingProject = {
        service_id: serviceId,
        config_id: configId,
        entities,
        individuals,
      };
    } else if (serviceId === SI_SERVICE || serviceId === LEGAL_COMP_SERVICE || serviceId === TECH_INTELLI_SERVICE 
        || serviceId === PARTNER_SEARCH_SERVICE || serviceId === TRENDS_INTELIGENCE_SERVICE) {
      valuesForCreatingProject = mapToDefaultType(values, params);
    } else {
      const entities = typeKeySubject ? null : entitiesArray;
      const individuals = !typeKeySubject ? null : individualsArray;
      valuesForCreatingProject = {
        service_id: serviceId,
        config_id: configId,
        entities,
        individuals,
      };
    }
    if (Object.entries(valuesForCreatingProject).length > 0) {
      createNewProject(valuesForCreatingProject);
    }
  };

  const mapToDefaultType = (initial, params) => {
    const aggregateValues = {
      service_id: parseInt(initial.serviceId),
      config_id: initial.selectedConfig.config_id,
    };

    switch (aggregateValues.service_id) {
      case SI_SERVICE:
        return {
          ...aggregateValues,
          country_code: initial.strategicIntelligence.country_code,
          industry_id: initial.strategicIntelligence.industry_id,
          risks: Object.fromEntries(params.chosenRisks.entries()),
          stakeholders: params.chosenStakeholders,
        };
      case LEGAL_COMP_SERVICE:
        return {
          ...aggregateValues,
          country_code: initial.legalCompliance.country_code,
          industry_id: initial.legalCompliance.industry_id,
          sub_services: Object.fromEntries(params.chosenSubServises.entries()),
        };
      case TECH_INTELLI_SERVICE:
        return {
          ...aggregateValues,
          country_code: initial.techIntelligence.country_code,
          industry_id: initial.techIntelligence.industry_id,
          challenge_problem: initial.techIntelligence.challenge_problem,
          expectations: initial.techIntelligence.expectations,
        };
      case PARTNER_SEARCH_SERVICE:
        return {
          ...aggregateValues,
          country_code: initial.partnerSearch.country_code,
          industry_id: initial.partnerSearch.industry_id,
          partnership_types: Object.fromEntries(params.chosenPartnershipTypes.entries()),
        };
      case TRENDS_INTELIGENCE_SERVICE:
        return {
          ...aggregateValues,
          subjects_to_improve: Object.fromEntries(params.chosenSubjectsToImprove.entries()),
          key_markets: initial.trendsIntelligence.key_markets,
          key_products: initial.trendsIntelligence.key_products,
          key_competitors: initial.trendsIntelligence.key_competitors,
        };
      default:
        return null;
    }
  };

  const handleUpdateProject = props => {
    const { step, analysisDetailsValues } = props;
    const { formLegalComp } = props;
    const { formPartnerSearch } = props;
    const { formTrendsIntelligence } = props;
    if (pendingUpdateProject) {
      return;
    }

    const project_id = values.newProjectId;
    if (project_id === 0) return;
    if (!values.selectedConfig) return;
    const levelId = parseInt(values.levelId);
    const serviceId = parseInt(values.serviceId);
    let entities = [];
    let individuals = [];
    const { typeKeySubject } = values;
    const { typeRelatedSubject } = values;
    const entitiesArray = checkIfAllValsAreFalsy(values.entities);
    const individualsArray = checkIfAllValsAreFalsy(values.individuals);

    // For the all projects if level = 1 transform one of the subject to null
    if (levelId === 1) {
      entities = typeKeySubject ? null : entitiesArray;
      individuals = !typeKeySubject ? null : individualsArray;
    } else {
      // If entity-entity individuals array = null, otherwise if individual-individual entities array = null
      entities = typeKeySubject && typeRelatedSubject ? null : entitiesArray;
      individuals =
        !typeKeySubject && !typeRelatedSubject ? null : individualsArray;
    }
    let valuesForUpdatingProject = {};
    if (
      (step === 5 && serviceId === CS_SERVICE) ||
      (step === 3 &&
        [
          EDD_SERVICE,
          PES_SERVICE,
          LEGAL_COMP_SERVICE,
          PARTNER_SEARCH_SERVICE,
          TRENDS_INTELIGENCE_SERVICE,
        ].includes(serviceId)) ||
      (step === 4 && [SI_SERVICE, TECH_INTELLI_SERVICE].includes(serviceId))
    ) {
      const comment = {
        text: values.comment,
        without_notification: true,
      };

      valuesForUpdatingProject = {
        config_id: values.selectedConfig.config_id || values.config_id,
        project_id,
        service_id: serviceId,
        status: 'New',
      };
      const response = updateProjectData(valuesForUpdatingProject);
      response.then(value => {
        if (!value && comment && comment.text !== '') {
          sendComment({ id: project_id, comment });
        }
      });
    } else if (step === 3 && (serviceId === SI_SERVICE)) {
      valuesForUpdatingProject = {
        config_id: values.selectedConfig.config_id,
        project_id,
        service_id: serviceId,
        analysisDetails: {
          id: values.strategicIntelligence.settings_id,
          country_code: values.strategicIntelligence.country_code,
          industry_id: values.strategicIntelligence.industry_id,
          risks: Object.fromEntries(analysisDetailsValues.chosenRisks),
          stakeholders: analysisDetailsValues.chosenStakeholders,
        },
      };
      updateProjectData(valuesForUpdatingProject);
    } else if (step === 3 && (serviceId === TECH_INTELLI_SERVICE)) {
      valuesForUpdatingProject = {
        config_id: values.selectedConfig.config_id,
        project_id,
        service_id: serviceId,
        analysisDetails: {
          id: values.techIntelligence.settings_id,
          country_code: values.techIntelligence.country_code,
          industry_id: values.techIntelligence.industry_id,
          challenge_problem: values.techIntelligence.challenge_problem,
          expectations: values.techIntelligence.expectations,
        },
      };
      updateProjectData(valuesForUpdatingProject);
    } else if (step === 2 && serviceId === LEGAL_COMP_SERVICE) {
      valuesForUpdatingProject = {
        config_id: values.selectedConfig.config_id || values.config_id,
        project_id,
        service_id: serviceId,
        formLegalComp: {
          id: values.legalCompliance.settings_id,
          country_code: values.legalCompliance.country_code,
          industry_id: values.legalCompliance.industry_id,
          sub_services: Object.fromEntries(formLegalComp.chosenSubServises),
        },
      };
      updateProjectData(valuesForUpdatingProject);
    } else if (step === 2 && serviceId === PARTNER_SEARCH_SERVICE) {
      valuesForUpdatingProject = {
        config_id: values.selectedConfig.config_id || values.config_id,
        project_id,
        service_id: serviceId,
        formPartnerSearch: {
          id: values.partnerSearch.settings_id,
          country_code: values.partnerSearch.country_code,
          industry_id: values.partnerSearch.industry_id,
          partnership_types: Object.fromEntries(
            formPartnerSearch.chosenPartnershipTypes,
          ),
        },
      };
      updateProjectData(valuesForUpdatingProject);
    } else if (step === 2 && serviceId === TRENDS_INTELIGENCE_SERVICE) {
      valuesForUpdatingProject = {
        config_id: values.selectedConfig.config_id || values.config_id,
        project_id,
        service_id: serviceId,
        formTrendsIntelligence: {
          id: values.trendsIntelligence.settings_id,
          subjects_to_improve: Object.fromEntries(
            formTrendsIntelligence.chosenSubjectsToImprove,
          ),
          key_markets: values.trendsIntelligence.key_markets,
          key_products: values.trendsIntelligence.key_products,
          key_competitors: values.trendsIntelligence.key_competitors,
        },
      };
      updateProjectData(valuesForUpdatingProject);
    } else {
      valuesForUpdatingProject = {
        entities,
        individuals,
        config_id: values.selectedConfig.config_id,
        project_id,
        service_id: serviceId,
        status: 'Draft',
      };
      updateProjectData(valuesForUpdatingProject);
    }
  };

  const handlePushToDashboard = () => {
    history.push('/projects');
  };

  useEffect(() => {
    if (activeStep <= 5 && values.serviceTitle && values.serviceId) {
      onServiceTitle({
        activeStep,
        id: values.serviceId,
        title: values.serviceTitle,
      });
    }
  }, [onServiceTitle, activeStep, values.serviceTitle, values.serviceId]);

  /* Method for delte related subjects by it index from summary page */
  const onDeleteRelatedSubject = (typeDeleteSubject, index, typeKeySubject) => {
    const typeRelated = typeDeleteSubject !== 'Entity';
    if (typeKeySubject !== typeRelated) {
      index -= 1;
    }
    if (projectData) {
      if (!typeKeySubject) {
        if (typeDeleteSubject === 'Entity') {
          projectData.entities.splice(index, 1);
        } else if (typeDeleteSubject === 'Individual') {
          projectData.individuals.splice(index, 1);
        }
      } else if (typeKeySubject) {
        if (typeDeleteSubject === 'Individual') {
          projectData.individuals.splice(index, 1);
        } else if (typeDeleteSubject === 'Entity') {
          projectData.entities.splice(index, 1);
        }
      }
      setProjectData(projectData);
    }
    if (values) {
      if (!typeKeySubject) {
        if (typeDeleteSubject === 'Entity') {
          values.entities.splice(index, 1);
        } else if (typeDeleteSubject === 'Individual') {
          values.individuals.splice(index, 1);
        }
      } else if (typeKeySubject) {
        if (typeDeleteSubject === 'Individual') {
          values.individuals.splice(index, 1);
        } else if (typeDeleteSubject === 'Entity') {
          values.entities.splice(index, 1);
        }
      }
      setValues(values);
    }
  };

  return (
    <>
      {showCSAdviceStep ? (
        <></>
      ) : (
        <Grid container className="pt-5 pl-4 pr-4">
          <CustomStepper
            stepTitles={orderFlowSteps(values.serviceId)}
            activeStep={activeStep}
          />
        </Grid>
      )}
      <StepCard
        values={values}
        projectData={projectData}
        activeStep={activeStep}
        onServiceTitle={onServiceTitle}
        steps={orderFlowSteps(values.serviceId)}
        onClickNext={handleNext}
        onClickBack={handleBack}
        onClickNextSkipOneStep={handleSkipTwoSteps}
        onClickBackTwoSteps={handleBackTwoSteps}
        onSetUpdatedValues={handleUpdateValues}
        onCreateProject={handleCreateNewProject}
        onUpdateProject={handleUpdateProject}
        onDeactivateOrderFlow={handlePushToDashboard}
        onSetShowCSAdviceStep={onSetShowCSAdviceStep}
        showCSAdviceStep={showCSAdviceStep}
        showSpinner={!(!pendingCreateProject && !pendingUpdateProject)}
        onDeleteRelatedSubject={onDeleteRelatedSubject}
        pendingCreateProject={pendingCreateProject}
        pendingUpdateProject={pendingUpdateProject}
        handleGoToSomeStep={handleGoToSomeStep}
      />
      <ConfirmDialog
        title="Thank you for your request"
        open={open}
        onClose={handlePushToDashboard}
        onConfirm={handlePushToDashboard}
        hideCancelBtn
        confirmBtnText="Go to Dashboard">
        <Grid container item direction="column">
          <Grid className="pb-3">
            <span className={classes.firstTab}>
              Project
              <span className={classes.projectLabel}>
                {updatedProject &&
                  updatedProject.account_id +
                    transformProjectId(updatedProject.id)}
              </span>
              was created successfully!
            </span>
          </Grid>
          <Typography className={classes.text}>
            You can access your New Project at any time <br /> through the link
            below and above or contact us at{' '}
            <Link href="#" className="exampleLink">
              {ADMIN_EMAIL}
            </Link>{' '}
            for support.
          </Typography>
        </Grid>
      </ConfirmDialog>
    </>
  );
};

Stepper.defaultProps = {
  onServiceTitle: () => null,
  projectForEdit: null,
  showCSAdviceStep: false,
  onSetShowCSAdviceStep: () => null,
};

Stepper.propTypes = {
  onServiceTitle: PropTypes.func,
  projectForEdit: PropTypes.object,
  showCSAdviceStep: PropTypes.bool,
  onSetShowCSAdviceStep: PropTypes.func,
};

export default Stepper;
