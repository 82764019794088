// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import EntityFormStep from './OrderStepForms/EntityFormStep';
import IndividualFormStep from './OrderStepForms/IndividualFormStep';
import MapChart from 'components/MapChart/MapChart';
// Hooks
import useDialog from 'hooks/useDialog';

const initialEntity = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  name_in_local_language: '',
  website: '',
  position: '',
  registered_address: '',
  tax_number: '',
  trading_name: '',
  operational_address: '',
  business_license_number: '',
  is_key: false,
  type: 'Entity'
}
const initialIndividual = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  name_in_local_language: '',
  website: '',
  position: '',
  alias: '',
  shares: '',
  residential_address: '',
  business_address: '',
  passport_number: '',
  national_id: '',
  is_key: false,
  type: 'Individual'
}

const CSRelatedSubjectStep = ({
  typeKeySubject,
  typeRelatedSubject,
  entities,
  individuals,
  isNewProject,
  onSetFieldValue,
  setRenderForUpdateNextButton
}) => {
  const [localRelatedSubject, setLocalRelatedSubject] = useState(typeRelatedSubject);
  const subjects = [{'id': false, 'name': 'Entity'}, {'id': true, 'name': 'Individual'}];

  const {
    open: openConfirmToChangeRelatedSubject,
    handleOpen: handleOpenConfirmToChangeRelatedSubject,
    handleClose: handleCloseConfirmToChangeRelatedSubject,
  } = useDialog();

  /* In case when draft project was created with 'Moderate' or 'High' level and with only key subject
  If user open this project, on fourth step will be empty form
  For testing without 'useEffect'!!!!! */
  if ((!typeKeySubject && !typeRelatedSubject && entities.length === 1) || entities.length === 0) {
    entities.push(initialEntity);
    onSetFieldValue('entities', entities);
  }
  if ((typeKeySubject && typeRelatedSubject && individuals.length === 1) || individuals.length === 0) {
    individuals.push(initialIndividual);
    onSetFieldValue('individuals', individuals);
  }

  /* If user choosed entity-entity or individual-individual, we need add second initial object in array
  First initial entity or individual object for third step, with index '0', second for fourth step, with index '1' */
  const handleSetRelatedSubject = value => {
    onSetFieldValue('typeRelatedSubject', value === 'true');
    if (!typeKeySubject) {
      if (value === 'false') {
        entities.push({...initialEntity, is_key: false});
        individuals = [initialIndividual];
      } else if (value === 'true') {
        entities.splice(1);
        if (!isNewProject && individuals.length === 0) {
          individuals.push({...initialIndividual, is_key: false});
        }
      }
    } else if (typeKeySubject) {
      if (value === 'true') {
        individuals.push({...initialIndividual, is_key: false});
        entities = [initialEntity];
      } else if (value === 'false') {
        individuals.splice(1);
        if (!isNewProject && entities.length === 0) {
          entities.push({...initialEntity, is_key: false});
        }
      }
    };
    onSetFieldValue('entities', entities);
    onSetFieldValue('individuals', individuals);
    setLocalRelatedSubject(value);
    handleCloseConfirmToChangeRelatedSubject();
  };

  const isNecessaryPopup = typeKeySubject 
    ? (typeRelatedSubject ? individuals[1].name : entities[0].name) 
    : (typeRelatedSubject ? individuals[0].name : entities[1].name);

  const handlerChangeRelatedSubject = (value) => {
    // if (isNewProject) {
    if (!isNecessaryPopup) {
      handleSetRelatedSubject(value);
    } else {
      setLocalRelatedSubject(value);
      handleOpenConfirmToChangeRelatedSubject();
    }
  }

  // Handler for button 'Add related subject'
  const handlerAddRelatedSubject = () => {
    if (typeRelatedSubject) {
      individuals.push(initialIndividual);
      setRenderForUpdateNextButton(prevValue => !prevValue);
      onSetFieldValue('individuals', individuals);
    } else if (!typeRelatedSubject) {
      entities.push(initialEntity);
      setRenderForUpdateNextButton(prevValue => !prevValue);
      onSetFieldValue('entities', entities);
    }
  };

  const handlerDeleteRelatedForm = (indexForDelete, typeRelatedSubject) => {
    if (typeRelatedSubject) {
      individuals.splice(indexForDelete, 1);
      setRenderForUpdateNextButton(prevValue => !prevValue);
      onSetFieldValue('individuals', individuals);
    } else if (!typeRelatedSubject) {
      entities.splice(indexForDelete, 1);
      setRenderForUpdateNextButton(prevValue => !prevValue);
      onSetFieldValue('entities', entities);
    }
  }

  const renderCurrentSubjects = (typeRelatedSubject) => {
    let objectForRendering = typeRelatedSubject ? individuals : entities;
      return (
        <React.Fragment>
          {objectForRendering.map((item, index) => {
            let canDeleted = false;
            if (typeRelatedSubject === typeKeySubject) {
              if (index === 0) return null;
              if (index !== 1) canDeleted = true;
            } else {
              if (index !== 0) canDeleted = true;
            }
            return (
              <React.Fragment key={index}>
                <Divider className="minus-m-25" />
                {typeRelatedSubject 
                  ? (<IndividualFormStep 
                        typeKeySubject={typeKeySubject}
                        typeRelatedSubject={typeRelatedSubject}
                        individual={item} 
                        index={index}
                        canDeleted={canDeleted}
                        handlerDelete={handlerDeleteRelatedForm}
                    />) 
                  : (<EntityFormStep 
                        typeKeySubject={typeKeySubject}
                        typeRelatedSubject={typeRelatedSubject} 
                        entity={item} 
                        index={index}
                        canDeleted={canDeleted}
                        handlerDelete={handlerDeleteRelatedForm}
                    />)
                }
              </React.Fragment>
            )
          })}
        </React.Fragment>
      )
  };


  return (
    <Grid
        container
        alignItems="stretch"
        direction="row">
      <Grid item xs={12} md={5} className="pr-4">
        <Grid container className="pb-2">
          <CustomRadioGroup
            images={{
              false: 'fa-city',
              true: 'fa-user-tie',
            }}
            color="simple"
            variant="small"
            onChange={handlerChangeRelatedSubject}
            name="typeRelatedSubject"
            defaultValue={String(typeRelatedSubject)}
            fields={subjects} />
        </Grid>

        {renderCurrentSubjects(typeRelatedSubject)}
        
        <Grid 
          container
          justify="flex-end"
          className="pb-4">
          <Button
            className="mr-0 mt-0"
            variant="outlined"
            onClick={handlerAddRelatedSubject}>
            Add related info
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} className='leftBorderMap'>
        <Grid container className='generalMapWrapper'>
          <Grid item className='generalMapWrapper'>
            <MapChart width="100%" selectedCountry={!typeKeySubject ? entities[0].country_code : individuals[0].country_code}/>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Related Subject"
        open={openConfirmToChangeRelatedSubject}
        onClose={handleCloseConfirmToChangeRelatedSubject}
        confirmBtnText="Confirm"
        options={localRelatedSubject}
        onConfirm={handleSetRelatedSubject}>
          You are about to change related subject. Previous data will be lost. Please confirm.
      </ConfirmDialog>
    </Grid>
  );
};

CSRelatedSubjectStep.defaultProps = {
  typeKeySubject: false,
  typeRelatedSubject: true,
  onSetFieldValue: () => null,
  setRenderForUpdateNextButton: () => null,
  isNewProject: true,
  entities: [],
  individuals: []
};

CSRelatedSubjectStep.propTypes = {
  typeKeySubject: PropTypes.bool,
  typeRelatedSubject: PropTypes.bool,
  onSetFieldValue: PropTypes.func,
  setRenderForUpdateNextButton: PropTypes.func,
  isNewProject: PropTypes.bool,
  entities: PropTypes.array,
  individuals: PropTypes.array
};

export default CSRelatedSubjectStep;
