// Core
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Components
import CustomIconButton from 'components/CustomIconButton';
import IconButton from 'components/IconButton';

const useStyles = makeStyles({
  fileItemWrapper: {
    position: 'relative',

    '&:hover .Mui-disabled': {
      border: '1px solid var(--primary-regular)',
      backgroundColor: 'var(--primary-regular)',
      '& .fa': {
        color: 'var(--white)',
      },
    },

    '& .file-delete-btn': {
      visibility: 'hidden',
      position: 'absolute',
      right: 0,
      '& .fas': {
        fontSize: 'small',
      },
    },

    '&:hover .file-delete-btn': {
      visibility: 'visible',
    },
  },

  fileNameWrapper: {
    maxWidth: '180px',
    paddingTop: '2px',
    cursor: 'pointer',
    '& label': {
      cursor: 'pointer',
    }
  },
});

const FileCard = ({ id, name, upload_timestamp, editable, onDownloadFile, onDeleteFile }) => {
  const classes = useStyles();

  const fileType = (filename) => {
    var re = /(?:\.([^.]+))?$/;
    let fileExt = re.exec(filename)[1];
    switch (fileExt) {
      case "jpg":
      case "png":
        return "fa-file-image";
      case "pdf":
        return "fa-file-pdf";
      case "doc":
      case "docx":
        return "fa-file-word";
      case "rar":
      case "zip":
        return "fa-file-archive";
      default:
        return "fa-file";
    }
  }

  return (
    <Grid container item className={"mb-3 " + classes.fileItemWrapper}>
      <IconButton color="primary" inverted="true" disabled className="ml-0">
        <Icon className={`fa ${fileType(name)}`} />
      </IconButton>
      <Typography variant="body2" className={"text-truncate " + classes.fileNameWrapper} onClick={() => onDownloadFile(id)}>
        <span className="text-truncate">{name}</span><br />
        <Icon color="primary" className="small-msg fa fa-arrow-down" />
        <label className={classes.timestamp}>
          {moment(upload_timestamp).format('DD MMM YYYY, HH:mm')}
        </label>
      </Typography>
      {editable && <span className="file-delete-btn">
        <CustomIconButton
          tooltipText="Delete"
          icon="fas fa-times"
          onIconButtonClick={() => onDeleteFile(id)}
        />
      </span>
      }
    </Grid>
  );
};

FileCard.defaultProps = {
  editable: false,
  onDownloadFile: () => null,
  onDeleteFile: () => null,
};

FileCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  upload_timestamp: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  onDownloadFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
};

export default FileCard;
