import React from 'react';
import DownloadFilesForm from 'components/QuestionnaireFlow/Forms/DownloadFilesForm';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  wrapper: {
    padding: '1rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    border: '1px solid var(--black-20)',
    borderRadius: '5px'
  }
});

const filesLinksFields = [
  { name: "Corporate registry extract detailing shareholders, UBOs and authorized persons", path: "registry_extract" },
  { name: "Certificate of Incorporation", path: "certificate_incorporation" },
  { name: "Ownership Chart", path: "ownership_chart" },
  { name: "VAT Certificate", path: "vat_certificate" },
  { name: "Professional License Certificate", path: "prof_license_certificate" },
  { name: "Counterparty Reference Letter", path: "counterparty_reference_letter" },
  { name: "Bank Reference Letter", path: "bank_reference_letter" },
  { name: "ID Document of Director(s)", path: "document_directors" },
  { name: "ID Document of Shareholder(s)", path: "document_shareholders" },
  { name: "ID Document of UBO(s)", path: "document_ubos" },
  { name: "Introduction Presentation", path: "introduction_presentation" },
  { name: "Code of Conduct and Compliance Policies", path: "code_conduct_compliance_policies" },
  { name: "Audited Annual Report or Financial Statement", path: "latest_audited_report" },
  { name: "Invoice Sample", path: "invoice_sample" },
  { name: "Other", path: "other" }
]

const linksSectionsIds = [
  "files_links_id_1",
  "files_links_id_2",
  "files_links_id_3",
  "files_links_id_4",
  "files_links_id_5"
]

const FilesLinksPart = ({
  finish_form,
  partner_files,
  partnerId
}) => {
  const classes = useStyles();

  return ( 
    <React.Fragment>
      {
        linksSectionsIds.map((section, index) => {
          const startIndex = index * 3;
          const linksForSection = [...filesLinksFields].splice(startIndex, 3);
          return (
            <Grid
              key={section}
              id={section}
              container
              className={classes.wrapper}>
                {
                  linksForSection.map((item, index) => {
                    const filesByType = partner_files.filter(file => file.document_type === item.path);

                    return (finish_form[item.path] || filesByType.length > 0)
                    ? (
                      <DownloadFilesForm
                        key={item.path}
                        editable={false}
                        stepNamePath={item} 
                        finish_form={finish_form}
                        currentLink={finish_form[item.path]}
                        partnerId={partnerId}
                        currentFiles={filesByType}
                      />
                    ) : null
                  })
                }                
            </Grid>
          )
        })
      }
    </React.Fragment>
  );
}

export default FilesLinksPart;
