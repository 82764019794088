// Core
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip'
//Components
import { LogotypeIcon } from 'components/shared/Icons';
import CookiesDisclaimer from 'components/CookiesDisclaimer';
import VerticalNavigation from 'components/Navbar/VerticalNavigation';
// Hooks
import { useAuth } from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerPaper: {
    minHeight: '430px',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'var(--secondary-blue)',
  },
  
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
    backgroundColor: 'var(--secondary-blue)',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },

  toolbarOpen: {
    padding: '0 5px 0 16px',
  },

  toolbarClose: {
    padding: '0 4px',
  },

  logotype: {
    padding: '16px 0',
  },

  menuButton: {
    '& div': {
      '& span.fa': {
        width: '1.8rem',
        textAlign: 'center',
      },
    },

    '&:hover': {
      backgroundColor: 'var(--primary-regular)',
    },
  },

  menuButtonClose: {
    marginTop: '16px',
    paddingTop: '16px',
    paddingBottom: '15px',
  },

  menuButtonOpen:  {
    marginTop: '16px',
  },

  hide: {
    display: 'none',
  },

  LogoutButtonBlock: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },

  LogoutButton: {
    marginTop: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

const DrawerMenu = props => {
  const { variant, onOpenMenu } = props;
  const classes = useStyles();
  const { push } = useHistory();
  const { signout } = useAuth();
  const { links } = props;
  const match = useRouteMatch({
    path: '/home',
    strict: true,
    sensitive: true,
  });

  const {
    open: openCookiesDisclaimer,
    handleOpen: handleOpenCookiesDisclaimer,
    handleClose: handleCloseCookiesDisclaimer,
  } = useDialog();

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
    onOpenMenu(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    onOpenMenu(false);
  };

  const goToMainPage = () => {
    push('/home');
  };

  const closeCookieDisclaimerHandler = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false;
    }
    handleCloseCookiesDisclaimer()
    
  }
  
  useEffect(() => {
    onOpenMenu(open);
  }, [onOpenMenu, open]);

  useEffect(() => {
    const cookies = document.cookie.split('; ');
    let isCookieExists = null;
    cookies.forEach(cookie => {
      if (cookie.startsWith('cosa_accept_cookies')) {
        isCookieExists = cookie;
      }
    });
    if (!isCookieExists) {
      handleOpenCookiesDisclaimer();
    }
  // eslint-disable-next-line
  }, []);

  const handleAcceptCookiesDisclaimer = () => {
    const dateNow = Date.now();
    const acceptDate = (new Date(dateNow)).toUTCString();
    const expiresDate = (new Date(dateNow + 31536e6)).toUTCString();
    document.cookie = `cosa_accept_cookies=${acceptDate}; expires=${expiresDate}`;
    handleCloseCookiesDisclaimer();
  };

  return (
    <React.Fragment>
    <Drawer
        variant={variant}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [ classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={clsx(classes.toolbar, {
          [classes.toolbarOpen]: open,
          [classes.toolbarClose]: !open,
        })}>
          <IconButton className={classes.logotype} onClick={goToMainPage}>
            <SvgIcon component={LogotypeIcon} />
          </IconButton>
          <Tooltip title="Close menu" arrow={true}>
            <IconButton onClick={handleDrawerClose}
              className={clsx(classes.menuButton, {
                [classes.hide]: !open,
              })}>
              <Icon className="fa fa-bars" />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <List>
          {!open ? (
            <Tooltip title="Open menu" arrow={true}>
              <ListItem button key="Open Menu" onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, classes.menuButtonClose)}>
                <ListItemIcon><Icon className="fa fa-bars" /></ListItemIcon>
              </ListItem>
            </Tooltip>
          ) : (
            <ListItem button disabled className={clsx(classes.menuButton, classes.menuButtonOpen)}>
              <ListItemText>&nbsp;</ListItemText>
            </ListItem>
          )}
          <VerticalNavigation match={match} links={links} />
        </List>
        <div className={classes.LogoutButtonBlock}>
          <Divider />
          <List>
            <ListItem button onClick={() => signout()}
              className={clsx(classes.menuButton, classes.LogoutButton)}>
              <ListItemIcon><Icon className="fa fa-sign-out-alt" /></ListItemIcon>
              <ListItemText secondary="Log out" />
            </ListItem>
          </List>
        </div>
    </Drawer>
    <CookiesDisclaimer
      open={openCookiesDisclaimer}
      onClose={closeCookieDisclaimerHandler}
      onAccept={handleAcceptCookiesDisclaimer}
    >
    </CookiesDisclaimer>
    </React.Fragment>
  );
};

DrawerMenu.defaultProps = {
  variant: 'permanent',
};

DrawerMenu.propTypes = {
  variant: PropTypes.string,
};

export default DrawerMenu;
