// Core
import React from 'react';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Icon  from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  customHoverFocus: {
    "&:hover": {
      color: 'var(--primary-regular)',
    },
  },
  icnBtn: {
    maxWidth: '2.6rem',
  },
  admIcon:{
    maxWidth: '2.5rem',
  },
});

const CustomIconButton = ({
  icon,
  tooltipText='',
  onIconButtonClick
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipText} arrow={true} interactive={false}>
      <IconButton color="primary" className={classes.icnBtn} onClick={onIconButtonClick}>
        <Icon color="secondary" className={clsx(icon, classes.customHoverFocus, {[classes.admIcon]: tooltipText === "Admin"})}/>
      </IconButton>
    </Tooltip>
  );
}; 

export default CustomIconButton;
