// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
import PermissionsCheckboxes from 'components/PermissionsCheckboxes';
// Constants
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
  ROLE_USER } from 'utils';


const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  activeUser: false,
  readOnlyRole: false,
  role_id: ROLE_USER,
  subscriptions: [],
  csLevels: []
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .required('Required'),
  email: Yup.string()
    .required('Required')
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    }),
  phoneNumber: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable(),
});

const CreateUserForm = ({
  accountId,
  accountIsActive=true,
  services,
  levels,
  onCreateUser,
  isOutherSubmit,
  firstUser,
  responseErrors = {}
}) => {

  const onSubmit = (values, setSubmitting) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
    const dataToCreateUser = {
      account_id: accountId,
      full_name: values.name,
      email: values.email,
      phone_number: values.phoneNumber,
      active: values.activeUser ? values.activeUser : false,
      read_only: values.readOnlyRole ? values.readOnlyRole: false,
      subscriptions: values.subscriptions,
      cs_levels: values.csLevels
    };
    
    onCreateUser(dataToCreateUser);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({
        values,
        isSubmitting,
        errors,
        touched,
        handleReset,
        handleSubmit,
        setFieldValue,
        setFieldTouched
      }) => (
        <form onSubmit={handleSubmit} style={{width: '100%'}}>
          <Grid container className="pt-2 pb-4">
            {firstUser ? (
              <FormControl className="mb-4 flex-centered">
                <FormControlLabel
                  disabled
                  control={<Checkbox checked name="role_id" color="primary" />}
                  label="Create as Admin"
                  className="mr-2" />
                <Tooltip title="Only 1 Admin is available per company" arrow={true} interactive={false}>
                  <Icon className="fa fa-info-circle fa-sm" />
                </Tooltip>
              </FormControl>
            ) : null}
            <FormikInput
              mandatory
              id="name"
              name="name"
              label="Full name"
              value={values.name}
              apiError={responseErrors['full name']}
            />
            <FormikInput
              mandatory
              id="email"
              name="email"
              label="Email"
              value={values.email}
              apiError={responseErrors['user email']}
            />
            <FormikInput
              id="phoneNumber"
              name="phoneNumber"
              label="Phone number"
              value={values.phoneNumber}
              placeholder='+380951112233'
              className="mb-0"
              apiError={responseErrors['phone number']}
            />
            <InputLabel htmlFor="subscriptions">Permissions</InputLabel>
            <Grid container className="pt-1 pb-3">
              <PermissionsCheckboxes
                subscriptionsValues={values.subscriptions}
                subscriptionsError={errors.subscriptions}
                subscriptionsTouched={touched.subscriptions}
                levelsValues={values.csLevels}
                levelsError={errors.csLevels}
                levelsTouched={touched.csLevels}
                onSetFieldValue={setFieldValue}
                onSetFieldTouched={setFieldTouched}
                editing={true}
                services={services}
                levels={levels}
                rowVariant={false}
                firstUser={firstUser}
              />
            </Grid>
            <Grid
              container>
              <Grid item className="mb-4">
                <InputLabel htmlFor="activeUser">Status</InputLabel>
                <FormControl className="flex-centered">
                  <FormControlLabel
                    disabled={!accountIsActive}
                    value={values.activeUser}
                    control={<Checkbox
                      id="activeUser"
                      name="activeUser"
                      color="primary"
                      onChange={(event) => {setFieldValue('activeUser', event.target.checked)}}
                    />}
                    label="Active"
                    className="mr-2" />
                  <Tooltip title="Users must be activated for using the service.
                    You can activate them later from the list.
                    If the account is not active, you cannot activate the user." arrow={true} interactive={false}>
                    <Icon className="fa fa-info-circle fa-sm" />
                  </Tooltip>
                </FormControl>
              </Grid>
              <Grid item>
                <InputLabel htmlFor="readOnlyRole">Create company admin with read only role</InputLabel>
                <FormControl className="flex-centered">
                  <FormControlLabel
                    disabled={!accountIsActive}
                    value={values.readOnlyRole}
                    control={<Checkbox
                      id="readOnlyRole"
                      name="readOnlyRole"
                      color="primary"
                      onChange={(event) => {setFieldValue('readOnlyRole', event.target.checked)}}
                    />}
                    label="Read only role"
                    className="mr-2" />
                  <Tooltip title="example tooltip" arrow={true} interactive={false}>
                    <Icon className="fa fa-info-circle fa-sm" />
                  </Tooltip>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="flex-end" className={"pt-4 pb-4 pr-4"+(isOutherSubmit ? ' hidden' : '')}>
            <Button
              variant="outlined"
              onClick={handleReset}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="mr-0 create-new-user">
              Create
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateUserForm.defaultProps = {
  accountIsActive: true,
  services: null,
  levels: null,
  onCreateUser: () => null,
  isOutherSubmit: false,
  firstUser: false,
};

CreateUserForm.propTypes = {
  accountId: PropTypes.string,
  accountIsActive: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape({})),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  onCreateUser: PropTypes.func,
  isOutherSubmit: PropTypes.bool,
  firstUser: PropTypes.bool,
};

export default CreateUserForm;
