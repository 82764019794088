// Core 
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Button from 'components/CustomButton';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import FileCard from 'components/FileCard';
import FormikInput from 'components/FormikInput';
import FileUpload from 'components/FileUpload';
import IconButton from 'components/IconButton';
// Hooks
import useDialog from 'hooks/useDialog';
// Instrument
import { deletePartnerFile, downloadPartnerFile } from 'api/questionnaireFiles';
import { ViewDetails } from 'components/shared';
import FormInfoSection from 'components/FormInfoSection';
import clsx from 'clsx';

const useStyles = makeStyles({
  titleText: {
    fontSize: 20,
    color: 'var(--primary-regular)'
  },
  trashBtn: {
    width: 30,
    height: 30,
    minWidth: 'unset',
    margin: '0px 0px 0px 5px'
  }
});

const DownloadFilesForm = ({
  stepNamePath,
  finish_form,
  currentLink,
  partnerId,
  currentFiles,
  setPartnersFiles,
  partnerFiles,
  editable,
  onSetFieldValue
}) => {
  const classes = useStyles();

  const [isDisabledDialog, setIsDisabledDialog] = useState(false);
  const [dataForDeleteFile, setDataForDeleteFile] = useState(null);

  const {
    open: openUploading,
    handleOpen: handleOpenUploading,
    handleClose: handleCloseUploading
  } = useDialog();

  const {
    open: openDeleteFileConfirm,
    handleOpen: handleOpenDeleteFileConfirm,
    handleClose: handleCloseDeleteFileConfirm
  } = useDialog();

  const handleUpdateFiles = (file_obj, toRemove = false) => {
    if (file_obj && file_obj.id) {
      let current_file = partnerFiles.filter(file => file.id === file_obj.id);
      if (current_file.length === 0 && !toRemove) {
        partnerFiles.push(file_obj);
        setPartnersFiles(partnerFiles);
        currentFiles.push(file_obj);
      } else if (toRemove) {
        setPartnersFiles(partnerFiles.filter(file => file.id !== file_obj.id));
        currentFiles = partnerFiles.filter(file => file.id !== file_obj.id);
      }
    }
  };

  const handleDeleteFile = (fileId, isRemoved = false) => {
    if (partnerId) {
      if (isRemoved) {
        deletePartnerFile(partnerId, fileId);
        handleUpdateFiles({ id: fileId }, true);
      } else {
        setDataForDeleteFile(fileId);
        handleOpenDeleteFileConfirm();
      }
    }
  };

  const confirmedDeleteFile = (fileId) => {
    if (partnerId) {
      deletePartnerFile(partnerId, fileId);
      handleUpdateFiles({ id: fileId }, true);
      setDataForDeleteFile(null);
      handleCloseDeleteFileConfirm();
    }
  };

  const handleDownloadFile = (fileId) => {
    if (partnerId) {
      downloadPartnerFile(partnerId, fileId);
    }
  };

  const handleUploadFile = (disableDialog = false) => {
    setIsDisabledDialog(disableDialog)
  };

  const addLink = () => {
    if (finish_form[stepNamePath.path]) {
      let updatedLinks = finish_form[stepNamePath.path].map(s => s);
      updatedLinks.push('');
      onSetFieldValue(`finish_form.${stepNamePath.path}`, updatedLinks);
    } else {
      onSetFieldValue(`finish_form.${stepNamePath.path}`, ['']);
    }
  }

  const deleteLink = (index) => {
    let updatedLinks = finish_form[stepNamePath.path].map(s => s);
    updatedLinks.splice(index, 1);
    onSetFieldValue(`finish_form.${stepNamePath.path}`, updatedLinks);
  }

  const fileCards = currentFiles && currentFiles.length > 0 &&
    currentFiles.map(file => (
      <Grid item xs={6} key={"wrapper_" + file.id}>
        <FileCard
          id={file.id}
          name={file.file_name}
          upload_timestamp={file.upload_timestamp}
          editable={editable}
          onDownloadFile={() => handleDownloadFile(file.id)}
          onDeleteFile={() => handleDeleteFile(file.id)}
        />
      </Grid>
    ));
      
  const editableLinks = (
    <>
      {
        finish_form[stepNamePath.path]?.map((link, idx) => {
          return (
            <Grid key={idx} container>
              <Grid item xs={11}>
                <FormikInput
                  className={idx ? "mt-0 mb-0" : "mb-0"}
                  id={stepNamePath.path[idx]}
                  name={`finish_form.${stepNamePath.path}[${idx}]`}
                  label=""
                />
              </Grid>
              {
                idx !== 0 && (
                  <Grid item xs={1}>
                    <IconButton
                      inverted="true"
                      onClick={() => deleteLink(idx)}
                      className={classes.trashBtn}
                    >
                      <Icon className="fa fa-trash-alt" />
                    </IconButton>
                  </Grid>
                )
              }
            </Grid>
          )
        })
      }
      <Button 
        variant="outlined"
        className={
          finish_form[stepNamePath.path] && finish_form[stepNamePath.path].length ? 
          "mt-0 ml-0 mb-3" : 
          "mt-2 ml-0 mb-3"
        }
        onClick={addLink}>
        Add Link
      </Button>
    </>
  );

  const nonEditableLinks = (
    currentLink && currentLink.map((link, idx) => (
      <ViewDetails
        key={idx}
        label={<FormInfoSection text="" />}
        name={
          <Link
            to={{ pathname: link }}
            target="_blank"
          >
            {link}
          </Link>}
        fullWidth={true}
      />
    ))
  );

  return (
    <Fragment>
      <Grid
        container
        className="pl-0 pr-0">
        <Grid
          container
          direction="column">
          <Typography className={clsx(classes.titleText, "pl-0")} variant="h3">
            {stepNamePath.name}
          </Typography>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={editable ? 9 : 12}>
              <Grid container direction="row" justify="flex-start">
                <Grid item xs={12} className="pt-3">
                  {(editable || currentLink) &&
                    <Typography variant="body2" className="pl-0">
                      {editable ? "Public Links:" : "Links:"}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={12}>
                  {
                    editable ? 
                    editableLinks :
                    nonEditableLinks
                  }
                </Grid>
              </Grid>
            </Grid>
            {editable &&
              <Grid item xs={3} className="pt-1">
                <Grid container direction="row" justify="flex-end">
                  <Typography variant="body2" className="pt-2 mt-1 mr-2">
                    Add Files
                  </Typography>
                  <IconButton inverted="true" className="mr-0" onClick={handleOpenUploading}>
                    <Icon className="fas fa-plus" />
                  </IconButton>
                </Grid>
              </Grid>}
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="stretch">
            {fileCards}
          </Grid>
        </Grid>
      </Grid>
      <Divider className="mb-2" />
      <ConfirmDialog
        title={"Upload files"}
        open={openUploading}
        onClose={handleCloseUploading}
        confirmBtnText="Finish"
        onConfirm={handleCloseUploading}
        disableBackdrop={true}
        disableEscapeKey={true}
        hideCancelBtn={true}
        isDisabledDialog={isDisabledDialog}
        messageText="Uploading in progress... Please, wait for selected files to finish loading">
        <Grid>
          <FileUpload
            typeUpload="partner"
            partnerId={partnerId}
            typePartnerFile={stepNamePath.path}
            onRemovedFile={handleDeleteFile}
            onUpdateFiles={handleUpdateFiles}
            onUploadFile={handleUploadFile}
          />
        </Grid>
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete file"
        open={openDeleteFileConfirm}
        onClose={handleCloseDeleteFileConfirm}
        options={dataForDeleteFile}
        confirmBtnText="Confirm"
        onConfirm={confirmedDeleteFile}>
        You are about to delete file. Please confirm.
      </ConfirmDialog>
    </Fragment>
  );
}


export default DownloadFilesForm;
