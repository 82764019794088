// Core
import React, { useEffect, useState } from 'react';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
// Hooks
import useDialog from 'hooks/useDialog';
import { useAuth } from 'hooks/useAuth';

const PartnerLogoutSystem = () => {
  const { signout } = useAuth();

  const [logoutTimeout, setLogoutTimeout] = useState(null);
  const [firstModalTimeout, setFirstModalTimeout] = useState(null);
  const [secondModalTimeout, setSecondModalTimeout] = useState(null);
  const [thirdModalTimeout, setThirdModalTimeout] = useState(null);

  const {
    open: openFirstLogoutNotification,
    handleOpen: handleOpenFirstLogoutNotification,
    handleClose: handleCloseFirstLogoutNotification,
  } = useDialog();

  const {
    open: openSecondLogoutNotification,
    handleOpen: handleOpenSecondLogoutNotification,
    handleClose: handleCloseSecondLogoutNotification,
  } = useDialog();

  const {
    open: openThirdLogoutNotification,
    handleOpen: handleOpenThirdLogoutNotification,
    handleClose: handleCloseThirdLogoutNotification,
  } = useDialog();


  useEffect(() => {
    // Creates and sets timeout on half of hour to show the first logout notification.
    const timeout1 = setTimeout(() => handleOpenFirstLogoutNotification(), 1800000);
    setFirstModalTimeout(timeout1);
    // Creates and sets timeout on 45 minutes to show the second logout notification.
    const timeout2 = setTimeout(() => handleOpenSecondLogoutNotification(), 2700000);
    setSecondModalTimeout(timeout2);
    // Creates and sets a timeout on 55 minutes to show the third logout notification.
    const timeout3 = setTimeout(() => handleOpenThirdLogoutNotification(), 3300000);
    setThirdModalTimeout(timeout3);
    // Creates and sets a timeout on 1 hour to logout partner from the system.
    var timeout = setTimeout(() => signout(), 3600000);
    setLogoutTimeout(timeout);

    return () => {
      [firstModalTimeout, secondModalTimeout, thirdModalTimeout, logoutTimeout].forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ConfirmDialog
        hideCancelBtn={true}
        title="System Notification"
        confirmBtnText="Confirm"
        open={openFirstLogoutNotification}
        onClose={handleCloseFirstLogoutNotification}
        onConfirm={handleCloseFirstLogoutNotification}
      >
        You will be logged out in 30 minutes. 
      </ConfirmDialog>
      <ConfirmDialog
        hideCancelBtn={true}
        title="System Notification"
        confirmBtnText="Confirm"
        open={openSecondLogoutNotification}
        onClose={handleCloseSecondLogoutNotification}
        onConfirm={handleCloseSecondLogoutNotification}
      >
        You will be logged out in 15 minutes.
      </ConfirmDialog>
      <ConfirmDialog
        hideCancelBtn={true}
        title="System Notification"
        confirmBtnText="Confirm"
        open={openThirdLogoutNotification}
        onClose={handleCloseThirdLogoutNotification}
        onConfirm={handleCloseSecondLogoutNotification}
      >
        You will be logged out in 5 minutes.
      </ConfirmDialog>
    </React.Fragment>
  )
}

export default PartnerLogoutSystem;
