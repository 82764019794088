// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import List from '@material-ui/core/List';
// Components
import ListItemLinkWithIcon from './ListItemLinkWithIcon';


const VerticalNavigation = ({ links, ...props }) => {

  const renderNavLinks = links.map((link, idx) => (
    <ListItemLinkWithIcon key={idx} {...link} {...props} />
  ));

  return (
    <List component="nav">
      {renderNavLinks}
    </List>
  );
};

VerticalNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

export default VerticalNavigation;
