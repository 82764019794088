// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  inputStyle: {
    minHeight: 32,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 'normal',
    borderRadius: 4,
    border: `1px solid var(--black-20)`,
    backgroundColor: 'var(--input-disabled-border)',
    padding: '6px 10px',
    marginBottom: 13,
    wordWrap: 'break-word'
  },
});

const ViewDetails = props => {
  const { label, name, countryCode, fullWidth } = props;
  const classes = useStyles(props);

  return (
    <Grid
      item
      xs={12}
      md={fullWidth ? 12 : 6}
      className="pb-0">
        <span>
          {label}
        </span>
        <Grid className={classes.inputStyle}>
          {label.props.text.toLowerCase() === 'country' && <div className={`flag flag-${countryCode} mr-2`}></div>}
          {name}
        </Grid>
    </Grid>
  );
};

ViewDetails.defaultProps = {
  label: null,
  name: '',
  countryCode: '',
  fullWidth: false
};

ViewDetails.propTypes = {
  label: PropTypes.element,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  countryCode: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default ViewDetails;
