// Core
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Button from 'components/CustomButton';
import Spinner from 'components/shared/Spinner';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import {
  fetchProjectTrendsIntelligenceProjectSettings,
  fetchTrendsIntelligenceDicts,
} from 'api/projects';
// Constants
import { TRENDS_INTELLIGENCE_CONFIG } from 'utils';
import TrendsIntelligenceGeneralForm from './TrendsIntelligenceGeneralForm';

const TrendsIntelligenceGeneralStep = ({
  config_id,
  newProjectId,
  onUpdateProject,
  values,
  onCreateProject,
  onClickBack,
  onSetFieldValue,
}) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [dynamicValuesForRequest, setDynamicValuesForRequest] = useState();
  const [dynamicValues, setDynamicValues] = useState({
    subjects_to_improve: {},
  });

  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeCurrentPage,
    handleClose: handleCloseConfirmToChangeCurrentPage,
  } = useDialog();

  const { execute: getDictionaries, value: dictionaries } = useAsync(
    fetchTrendsIntelligenceDicts,
    false,
  );
  const { execute: getTrendsIntelligenceProjectSettings, value: settings } = useAsync(
    fetchProjectTrendsIntelligenceProjectSettings,
    false,
  );

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (config_id) {
      getDictionaries(config_id);
      onSetFieldValue('config_id', config_id);
    }
    // eslint-disable-next-line camelcase
  }, [getDictionaries, onSetFieldValue, config_id]);

  useEffect(() => {
    if (newProjectId) {
      getTrendsIntelligenceProjectSettings(newProjectId);
    }
  }, [getTrendsIntelligenceProjectSettings, newProjectId]);

  useEffect(() => {
    if (values && settings && dynamicValuesForRequest) {
      handleChangeData(values, settings, dynamicValuesForRequest);
    }
    // eslint-disable-next-line
  }, [values, dynamicValuesForRequest]);

  useEffect(() => {
    if (dictionaries) {
      const chosenSubjectsToImprove = new Map();
      if (dictionaries.subjects_to_improve) {
        if (
          settings &&
          settings.subjects_to_improve &&
          settings.subjects_to_improve.length &&
          settings.config_id === config_id
        ) {
          onSetFieldValue('config_id', config_id);
          dictionaries.subjects_to_improve.map(subject_to_improve => {
            const foundSubjectsToImprove = settings.subjects_to_improve.find(
              foundSubjectsToImprove => foundSubjectsToImprove.id === subject_to_improve.id,
            );
            if (foundSubjectsToImprove) {
              chosenSubjectsToImprove.set(subject_to_improve.id, true);
            } else {
              chosenSubjectsToImprove.set(subject_to_improve.id, false);
            }
            return true;
          });
        } else if (
          !newProjectId ||
          (settings && settings.config_id !== config_id)
        ) {
          dictionaries.subjects_to_improve.map(subject_to_improve => {
            chosenSubjectsToImprove.set(subject_to_improve.id, true);
            return true;
          });
        }
        setDynamicValues({ ...dynamicValues, subjects_to_improve: chosenSubjectsToImprove });
        if (
          !dynamicValuesForRequest ||
          !dynamicValuesForRequest.subjects_to_improve ||
          !dynamicValuesForRequest.subjects_to_improve.size
        ) {
          setDynamicValuesForRequest({
            ...dynamicValuesForRequest,
            subjects_to_improve: chosenSubjectsToImprove,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [dictionaries, settings]);

  const subjectToImproveFieldOnChange = (subjectToImproveId, value) => {
    let changedSubjectsToImprove = dynamicValuesForRequest.subjects_to_improve;

    if (config_id === TRENDS_INTELLIGENCE_CONFIG) {
      changedSubjectsToImprove = changedSubjectsToImprove.set(
        parseInt(subjectToImproveId),
        value,
      );
      setDynamicValuesForRequest({
        ...dynamicValuesForRequest,
        subjects_to_improve: changedSubjectsToImprove,
      });
    }
  };

  const formTrendsIntelligenceOptions = subjects_to_improve => {
    const chosenSubjectsToImprove = new Map();
    subjects_to_improve.forEach((value, key) => {
      if (value) {
        chosenSubjectsToImprove.set(key, value);
      }
    });
    return {
      chosenSubjectsToImprove,
    };
  };

  const onClickNextButton = () => {
    const formTrendsIntelligence = formTrendsIntelligenceOptions(
      dynamicValuesForRequest.subjects_to_improve,
    );
    const step = 2;

    if (newProjectId) {
      onUpdateProject({ step, formTrendsIntelligence });
    } else {
      onCreateProject(formTrendsIntelligence);
    }
  };

  const shouldDisableNextButton = values => {
    let noneOfSubjectsToImproveChecked = true;
    if (
      dynamicValuesForRequest &&
      dynamicValuesForRequest.subjects_to_improve &&
      dynamicValuesForRequest.subjects_to_improve.size
    ) {
      for (const value of dynamicValuesForRequest.subjects_to_improve.values()) {
        if (value === true) {
          noneOfSubjectsToImproveChecked = false;
        }
      }
    }
    // return values.country_code === '' || noneOfSubjectsToImproveChecked;
    return noneOfSubjectsToImproveChecked;
  };

  const handleChangeData = (values, oldValues, newValues) => {
    let isDifferent = false;
    if (
      oldValues.subjects_to_improve &&
      newValues.subjects_to_improve &&
      newValues.subjects_to_improve.size
    ) {
      if (isDifferent === false) {
        let newSubjectsToImproveCount = 0;
        newValues.subjects_to_improve.forEach(val => {
          if (val === true) {
            newSubjectsToImproveCount += 1;
          }
        });
        isDifferent = oldValues.subjects_to_improve.length !== newSubjectsToImproveCount;
      }
      if (isDifferent === false) {
        newValues.subjects_to_improve.forEach((value, key) => {
          if (value && isDifferent === false) {
            const foundObj = oldValues.subjects_to_improve.find(
              subject_to_improve => subject_to_improve.id === key,
            );
            if (!foundObj) {
              isDifferent = true;
            }
          }
        });
      }
    }
    setHasUnsavedData(isDifferent);
  };

  const handleChangeCurrentPage = () => {
    setHasUnsavedData(false);
    handleCloseConfirmToChangeCurrentPage();
    onClickBack();
  };

  return dictionaries && (!newProjectId || (newProjectId && settings)) ? (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12} className="pr-1">
        {dictionaries && (
          <TrendsIntelligenceGeneralForm
            config_id={config_id}
            subjects_to_improve={dictionaries.subjects_to_improve}
            values={values}
            dynamicValues={dynamicValuesForRequest || dynamicValues}
            subjectToImproveFieldOnChange={subjectToImproveFieldOnChange}
          />
        )}
      </Grid>
      <Grid container justify="flex-end" className="mt-4">
        <Grid>
          <Button
            className="mb-0"
            variant="outlined"
            onClick={() => {
              if (hasUnsavedData) {
                handleOpenConfirmToChangeCurrentPage();
              } else {
                onClickBack();
              }
            }}>
            Back
          </Button>
          <Button
            className="mb-0 mr-0"
            disabled={shouldDisableNextButton(values && values)}
            onClick={() => onClickNextButton()}>
            Next
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Unsaved data"
        open={openConfirmToChangeCurrentPage}
        onClose={handleCloseConfirmToChangeCurrentPage}
        options={hasUnsavedData}
        confirmBtnText="Confirm"
        onConfirm={handleChangeCurrentPage}>
        You are about to leave a page. All unsaved data will be lost. Please
        confirm.
      </ConfirmDialog>
      <RouteLeavingGuard
        when={hasUnsavedData}
        navigate={path => {
          if (path !== url) {
            push(path);
          }
        }}
        options={dynamicValuesForRequest}
        shouldBlockNavigation={path => {
          return path.pathname !== url;
        }}
      />
    </Grid>
  ) : (
    <Spinner />
  );
};

TrendsIntelligenceGeneralStep.defaultProps = {
  newProjectId: '',
  values: {},
  onClickBack: () => null,
  onSetFieldValue: () => null,
  onCreateProject: () => null,
  onUpdateProject: () => null,
};

TrendsIntelligenceGeneralStep.propTypes = {
  config_id: PropTypes.number.isRequired,
  newProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdateProject: PropTypes.func,
  values: PropTypes.shape({
    settings_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subjects_to_improve: PropTypes.array,
  }),
  onCreateProject: PropTypes.func,
  onClickBack: PropTypes.func,
  onSetFieldValue: PropTypes.func,
};

export default TrendsIntelligenceGeneralStep;
