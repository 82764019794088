// Core
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import AdviceForm from 'components/AdviceForm';
import Button from 'components/CustomButton';
import LevelCard from 'components/LevelCard';
import MapChart from 'components/MapChart/MapChart';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
// Context
import { CountriesContext } from 'context/CountriesContext';
import { UserContext } from 'context/UserContext';
// Instruments
import { fetchRecommendedLevel, fetchAdviceFlowDataDictionaries } from 'api/projects';

const useStyles = makeStyles({
  border: {
    border: '1px solid var(--black-20)',
    borderRadius: 4,
    padding: '32px 24px 19px 24px',
    position: 'relative',
    width: 'inherit',
    "&.card": {
      padding: '0px !important'
    }
  },
  buttonsArea: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  mapArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardSpinWrapper: {
    height: 190
  },
});

const CSAdviceForm = ({
  typeKeySubject,
  onClickNext,
  onSetShowCSAdviceStep,
  values,
  onSetFieldValue
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { countries } = useContext(CountriesContext);
  const { loggedInUser } = useContext(UserContext);
  const [isRecommendedLevelFetched, setIsRecommendedLevelFetched] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [error, setError] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState();
  const [adviceFlow, setAdviceFlow] = useState({ industry: '' });

  const {
    execute: getRecommendedLevel,
    pending: getRecLevelPeding,
    value: recommendedLevel,
    status: getRecommendedLevelStatus,
  } = useAsync(fetchRecommendedLevel, false);
  const { value: dictionaries } = useAsync(fetchAdviceFlowDataDictionaries);

  const avialableLevels = loggedInUser && loggedInUser.cs_levels.map(item => item.id);

  const setConfigParam = useCallback((levelValue) => {
    let configurations = dictionaries && dictionaries.configurations;
    const currentProjectConfig = configurations.find(config => {
      return parseInt(config.level) === parseInt(levelValue);
    });
    if (currentProjectConfig !== undefined) {
      setSelectedLevel(currentProjectConfig.level);
      setSelectedConfig(currentProjectConfig);
    }
  }, [dictionaries]);

  const handleSetCurrentProjectConfig = useCallback((levelValue) => {
    setConfigParam(parseInt(levelValue));
  }, [setConfigParam]);

  useEffect(() => {
    if (recommendedLevel && getRecommendedLevelStatus === 200) {
      setIsRecommendedLevelFetched(true);
      if (avialableLevels.includes(recommendedLevel.id)) {
        setSelectedLevel(recommendedLevel.id);
        handleSetCurrentProjectConfig(recommendedLevel.id);
      } else {
        setError(true);
        setSelectedLevel(avialableLevels[0]);
        handleSetCurrentProjectConfig(avialableLevels[0]);
      }
    }
  // eslint-disable-next-line
  }, [getRecommendedLevelStatus, recommendedLevel, handleSetCurrentProjectConfig]);

  const subjects = [{'id': false, 'name': 'Entity'}, {'id': true, 'name': 'Individual'}];

  const onUpdateResult = (values) => {
    const { relationship, duration, industry } = values;
    let countryScore = countries.find(country => country.code === values.country).score;
    const data = {
      country: countryScore,
      relationship: relationship[0],
      duration: duration,
      industry: industry[0]
    };
    getRecommendedLevel(data);
  };
  
  const onSelect = () => {
    onClickNext();
    onSetShowCSAdviceStep();
    onSetFieldValue('adviceFlow', {country: "", duration: "", relationship: "", industry: ""});
    onSetFieldValue('selectedConfig', selectedConfig);
    onSetFieldValue('levelId', selectedLevel);
  };

  const onBack = () => {
    onSetShowCSAdviceStep();
    onSetFieldValue('adviceFlow', {country: "", duration: "", relationship: "", industry: ""});
  };

  const handlePushToDashboard = () => {
    history.push('/projects');
  };

  const handleSetFieldSubject = value => {
    onSetFieldValue('typeKeySubject', value === 'true');
    onSetFieldValue('typeRelatedSubject', value === 'false');
  };

  return (
    <Grid
      container
      direction="row">
        <Grid
          container
          item
          xs={12}
          md={4}
          xl={3}
          className={classes.border}>
            <Typography variant="h3" className="pb-4">Advice</Typography>
            <AdviceForm
              durations={dictionaries && dictionaries.durations}
              industries={dictionaries && dictionaries.industries}
              typeKeySubject={typeKeySubject}
              onChange={handleSetFieldSubject}
              onSubmit={onUpdateResult}
              relationships={dictionaries && dictionaries.relationships}
              subjects={subjects}
              values={values}
              setAdviceFlow={setAdviceFlow}
              adviceFlow={adviceFlow}
              onClickNext={onClickNext}/>
        </Grid>
        <Grid 
          container
          item
          direction="row"
          alignItems="stretch"
          xs={12}
          md={8}
          xl={9}
          >
            <Grid
              container
              item
              alignItems="center"
              justify="center"
              className={clsx(classes.border, "ml-4 mb-4 card")}>
                <MapChart selectedCountry={values.country}/>
            </Grid>
            <Grid
              container
              direction="column"
              justify="space-between"
              className={clsx(classes.border, "ml-4 mb-0")}>
                <Typography variant="h3" className="pb-4">Recommended Level</Typography>
                {
                  (
                    !getRecLevelPeding && 
                      <LevelCard 
                        levels={dictionaries && dictionaries.levels} 
                        handleSetCurrentProjectConfig={handleSetCurrentProjectConfig} 
                        levelId={selectedLevel}
                        hasRecommendedLevel={isRecommendedLevelFetched}
                        avialableLevels={avialableLevels}
                        selectedNotAvailableLevel={error}/>
                  ) || (
                    <Grid className={classes.cardSpinWrapper}>
                      <Spinner />
                    </Grid>
                  )
                }
                <Grid className={classes.buttonsArea}>
                  {error && 
                    <Button 
                      variant="outlined"
                      onClick={handlePushToDashboard}>
                      Go to dashboard
                    </Button>
                  }
                  <Button 
                    variant="outlined"
                    onClick={onBack}>
                      Back
                  </Button>
                  <Button 
                    className="mr-0"
                    onClick={onSelect} 
                    disabled={(!recommendedLevel && true) || false}>
                      {error ? 'Proceed' : 'Select'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  )
}

CSAdviceForm.defaultProps = {
  typeKeySubject: false,
  onClickNext: () => null,
  onSetShowCSAdviceStep: () => null,
  onSetFieldValue: () => null,
  values: null
};

CSAdviceForm.propTypes = {
  typeKeySubject: PropTypes.bool,
  onClickNext: PropTypes.func,
  onSetShowCSAdviceStep: PropTypes.func,
  onSetFieldValue: PropTypes.func,
  values: PropTypes.shape({
    country: PropTypes.string,
    industry: PropTypes.string,
    duration: PropTypes.string,
    relationship: PropTypes.string,
  }),
};

export default CSAdviceForm;
