import {
  ADD_EMPTY_OBJECT_TO_PART_OF_STORE,
  ADD_OBJECT_TO_STORE,
  CHANGE_CHECKBOX,
  DELETE_OBJECT_FROM_PART_OF_STORE,
  SET_INFO_FROM_DB,
  CHANGE_UBOS,
  CLEAR_STORE
} from "redux/actions/types";

const compliance_policies_procedures = 'compliance_policies_procedures';
const third_parties_subcontractors_purpose = "third_parties_subcontractors_purpose";

const cpp_sub_procedures = {
  CODE_OF_CONDUCT: "code_of_conduct",
  ANTI_BRIBERY_CORRUPTION: "anti_bribery_corruption",
  ANTI_MONEY_LAUNDERING: "anti_money_laundering",
  SANCTIONS_POLICY: "sanctions_policy",
  THIRD_PARTY_RISK_MANAGEMENT: "third_party_risk_management",
  CONFLICTS_OF_INTERESTS: "conflicts_of_interests",
  ENVIRONMENTAL_SOCIAL_AND_GOVERNANCE: "environmental_social_and_governance",
  OTHER_COMPLIANCE_POLICIES: "other_compliance_policies"
};
const tpsp_sub_procedures = {
  CONDUCT_DUE_DILIGENCE: "conduct_due_diligence"
};

const emptyDirectorObject = {
  name: '',
  director_position: '',
  date: null,
  citizenship: '',
  second_citizenship: '',
  registered_number: ''
};
const emptyShareholderObject = {
  entity_legal_or_individual: '',
  name: '',
  shares: '',
  registered_number: '',
  date: null,
  citizenship: '',
  second_citizenship: ''
};
const emptyUboObject = {
  name: '',
  shares: '',
  registered_number: '',
  date: null,
  citizenship: '',
  second_citizenship: '',
};
const emptyBankObject = {
  beneficiary_name: '',
  beneficiary_iban: '',
  beneficiary_address: '',
  beneficiary_swift: '',
  correspondent_name: '',
  correspondent_iban: '',
  correspondent_address: '',
  correspondent_swift: ''
};
const emptyComplianceProcedures = {
  politically_exposed_persons: false,
  politically_exposed_persons_text: '',
  environmental_social_and_governance: false,
  exist_compliance_officer: false,
  exist_compliance_officer_text: '',
  compliance_policies_procedures: false,
  compliance_policies_procedures_text: '',
  conflicts_of_interests: false,
  anti_bribery_corruption: false,
  anti_money_laundering: false,
  code_of_conduct: false,
  other_compliance_policies: false,
  other_compliance_policies_text: '',
  third_parties_subcontractors_purpose: false,
  conduct_due_diligence: false,
  third_party_risk_management: false,
  bankruptcy_liquidation_proceedings: false,
  bankruptcy_liquidation_proceedings_text: '',
  company_sanctions_restrictions: false,
  company_sanctions_restrictions_text: '',
  country_sanctions_restrictions: false,
  country_sanctions_restrictions_text: '',
  individual_international_operation_restrictions: false,
  individual_international_operation_restrictions_text: '',
  individual_sanctions_restrictions: false,
  individual_sanctions_restrictions_text: '',
  sanctions_policy: false,
};
const emptyFinishForm = {
  respondent_name: '',
  respondent_position: '',
  registry_extract: [''],
  certificate_incorporation: [''],
  ownership_chart: [''],
  vat_certificate: [''],
  prof_license_certificate: [''],
  latest_audited_report: [''],
  bank_reference_letter: [''],
  document_directors: [''],
  document_shareholders: [''],
  document_ubos: [''],
  introduction_presentation: [''],
  code_conduct_compliance_policies: [''],
  counterparty_reference_letter: [''],
  invoice_sample: [''],
  other: ['']
};

const initialState = {
  general_information: {
    legal_name: '',
    trading_name: '',
    registered_number: '',
    vat_number: '',
    duns_number: '',
    country_code_registration: '',
    industry_id: '',
    registered_address: '',
    office_address: '',
    corporate_website: '',
    contact_telephone: '',
    contact_email: '',
    business_relation: '',
    duration_cooperation: '',
    incorporation_date: null
  },
  directors: [emptyDirectorObject],
  shareholders: [emptyShareholderObject],
  ubos: [emptyUboObject],
  bank_entity: emptyBankObject,
  compliance_procedures: emptyComplianceProcedures,
  finish_form: emptyFinishForm
}

const storeParts = {
  'directors': emptyDirectorObject,
  'shareholders': emptyShareholderObject,
  'ubos': emptyUboObject
}

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_STORE:
      return initialState;
    case ADD_OBJECT_TO_STORE:
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          [action.partOfStore]: [...action.payload]
        };
      } else {
        return {
          ...state,
          [action.partOfStore]: action.payload
        };
      }
    case ADD_EMPTY_OBJECT_TO_PART_OF_STORE:
      return {
        ...state,
        [action.partOfStore]: [...state[action.partOfStore], storeParts[action.partOfStore]]
      };
    case DELETE_OBJECT_FROM_PART_OF_STORE:
      state[action.partOfStore].splice(action.index, 1);
      return { ...state };
    case CHANGE_UBOS:
      return {
        ...state,
        ubos: action.payload
      };
    case CHANGE_CHECKBOX:
      switch (action.payload) {
        case compliance_policies_procedures:
          if (!state.compliance_procedures[compliance_policies_procedures]) {
            return {
              ...state,
              compliance_procedures: { ...state.compliance_procedures, [action.payload]: !state.compliance_procedures[action.payload] }
            };
          } else {
            return {
              ...state,
              compliance_procedures: {
                ...state.compliance_procedures,
                other_compliance_policies_text: '',
                [compliance_policies_procedures]: false,
                [cpp_sub_procedures.ANTI_BRIBERY_CORRUPTION]: false,
                [cpp_sub_procedures.ANTI_MONEY_LAUNDERING]: false,
                [cpp_sub_procedures.CODE_OF_CONDUCT]: false,
                [cpp_sub_procedures.CONFLICTS_OF_INTERESTS]: false,
                [cpp_sub_procedures.ENVIRONMENTAL_SOCIAL_AND_GOVERNANCE]: false,
                [cpp_sub_procedures.SANCTIONS_POLICY]: false,
                [cpp_sub_procedures.THIRD_PARTY_RISK_MANAGEMENT]: false,
                [cpp_sub_procedures.OTHER_COMPLIANCE_POLICIES]: false
              }
            }
          }
        case third_parties_subcontractors_purpose:
          if (!state.compliance_procedures[third_parties_subcontractors_purpose]) {
            return {
              ...state,
              compliance_procedures: { ...state.compliance_procedures, [action.payload]: !state.compliance_procedures[action.payload] }
            };
          } else {
            return {
              ...state,
              compliance_procedures: {
                ...state.compliance_procedures,
                [third_parties_subcontractors_purpose]: false,
                [tpsp_sub_procedures.CONDUCT_DUE_DILIGENCE]: false
              }
            }
          }
        default:
          return {
            ...state,
            compliance_procedures: { ...state.compliance_procedures, [action.payload]: !state.compliance_procedures[action.payload] }
          };
      }
    case SET_INFO_FROM_DB:
      if (action.payload.directors.length === 0) action.payload.directors = [emptyDirectorObject];
      if (action.payload.shareholders.length === 0) action.payload.shareholders = [emptyShareholderObject];
      if (action.payload.ubos.length === 0) action.payload.ubos = [emptyUboObject];
      if (!action.payload.bank_entity) action.payload.bank_entity = emptyBankObject;
      if (!action.payload.compliance_procedures) action.payload.compliance_procedures = emptyComplianceProcedures;
      if (!action.payload.finish_form) action.payload.finish_form = emptyFinishForm;
      return {
        ...action.payload
      };
    default:
      return state;
  }
}

export default questionnaireReducer;
