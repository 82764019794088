import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    marginBottom: '1rem',
    wordBreak: 'break-all'
  },
  tableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  tableCellName: {
    fontWeight: 500,
    width: '50%',
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  head: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'var(--primary-regular)'
  },
  textColor: {
    fontWeight: 600,
    lineHeight: '1.2rem',
    color: 'var(--primary-regular)',
    marginBottom: 12
  }
});

const rows = [
  { name: "Respondent name", path: "respondent_name" },
  { name: "Respondent position", path: "respondent_position" },
  { name: "Application date", path: "respondent_date" }
];

const FinishPart = ({
  finish_form
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography color='primary' className={classes.textColor}>
        I hereby authorize our Сounterparty {finish_form.company_name} and administrator of this portal COSA LLC to
        process and store the corporate data and documents of our Сompany, including my personal data
        under the GDPR 679/16 – “European regulation on the protection of personal data”. The purpose of
        collecting this data is a third-party due diligence procedure conducted by {finish_form.company_name}.
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>Respondent Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" className={classes.tableCellName}>
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {finish_form[row.path]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default FinishPart;
