// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
// Components
import Checkbox from 'components/CustomCheckbox';
import Spinner from 'components/shared/Spinner';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  checkboxGroupLine: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  checkboxGroupColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    width: '48%'
  },
  rowVariant: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
  },
  columnVariant: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  gridItemRow: {
    width: '48%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  gridItemColumn: {
    width: 'inherit'
  },
  gridItemLevels: {
    width: '90%'
  },
  gridItemLevelsWrapRow: {
    width: '48%',
    justifyContent: 'flex-end'
  },
  gridItemLevelsWrapColumn: {
    width: 'inherit',
    justifyContent: 'flex-end'
  }
}));

const PermissionsCheckboxes = ({
  subscriptionsValues,
  levelsValues,
  onSetFieldValue,
  onSetFieldTouched,
  editing,
  services,
  levels,
  rowVariant,
  firstUser
}) => {
  const classes = useStyles();
  const [toggleLevels, setToggleLevels] = useState(false);
  const availableLevelsToChoose = levels && levels.map(item => item.id);

  const handleChange = (event, arrayForChange, nameArray) => {
    const target = event.currentTarget;
    let valueArray = [...arrayForChange] || [];

    if (target.checked) {
      valueArray.push(Number(target.id));
      if (Number(target.id) === 1) {
        onSetFieldValue("csLevels", availableLevelsToChoose || []);
      }
    } else {
      valueArray.splice(valueArray.indexOf(Number(target.id)), 1);
      if (nameArray === "subscriptions" && Number(target.id) === 1) {
        levelsValues = [];
        onSetFieldValue("csLevels", levelsValues);
        setToggleLevels(false);
      }
      if (nameArray === "csLevels" && valueArray.length === 0) {
        const newSubscriptionsValues = [...subscriptionsValues];
        newSubscriptionsValues.splice(newSubscriptionsValues.indexOf(1), 1);
        onSetFieldValue("subscriptions", newSubscriptionsValues);
        setToggleLevels(false);
      }
    }
    onSetFieldValue(nameArray, valueArray);
  };

  const handleBlur = (nameArray) => {
    onSetFieldTouched(nameArray, true);
  };

  const blockWithLevels = levels && (
    <Grid
      container
      className={rowVariant ? classes.gridItemLevelsWrapRow : classes.gridItemLevelsWrapColumn}
    >
      {React.Children.toArray(
        levels.map(level => (
          <Grid
            item
            className={classes.gridItemLevels}
          >
            <Field
              id={String(level.id)}
              disabled={!editing || firstUser}
              component={Checkbox}
              value={levelsValues.includes(Number(level.id)) || firstUser}
              onChange={(event) => handleChange(event, levelsValues, "csLevels")}
              onBlur={() => handleBlur("csLevels")}
              label={level.name}
              name={level.name}
              isCsLevels={true}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
  // TODO Refactor this shit
  if (!services) return <Spinner />;
  if (services) {
    if (services.length === 0) {
      return 'Your have no permissions available';
    } else {
      return (
        <Grid
          container
          className={rowVariant ? classes.rowVariant : classes.columnVariant}
        >
          {services[0] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[0].id}
                id={String(services[0].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[0].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[0].name}
                name={services[0].name}
                setToggleLevels={setToggleLevels}
                toggleLevels={toggleLevels}
              />
            </Grid>}
          {toggleLevels && (subscriptionsValues.includes(Number(services[0].id)) || firstUser) && !rowVariant && blockWithLevels}
          {services[1] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[1].id}
                id={String(services[1].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[1].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[1].name}
                name={services[1].name}
              />
            </Grid>}
          {toggleLevels && (subscriptionsValues.includes(Number(services[0].id)) || firstUser) && rowVariant && blockWithLevels}
          {services[2] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[2].id}
                id={String(services[2].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[2].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[2].name}
                name={services[2].name}
              />
            </Grid>}
          {services[3] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[3].id}
                id={String(services[3].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[3].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[3].name}
                name={services[3].name}
              />
            </Grid>}
          {services[4] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[4].id}
                id={String(services[4].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[4].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[4].name}
                name={services[4].name}
              />
            </Grid>}
          {services[5] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[5].id}
                id={String(services[5].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[5].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[5].name}
                name={services[5].name}
              />
            </Grid>}
          {services[6] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[6].id}
                id={String(services[6].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[6].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[6].name}
                name={services[6].name}
              />
            </Grid>}
          {services[7] &&
            <Grid
              item
              className={rowVariant ? classes.gridItemRow : classes.gridItemColumn}
            >
              <Field
                key={services[7].id}
                id={String(services[7].id)}
                disabled={!editing || firstUser}
                value={subscriptionsValues.includes(Number(services[7].id)) || firstUser}
                onChange={(event) => handleChange(event, subscriptionsValues, "subscriptions")}
                onBlur={() => handleBlur("subscriptions")}
                component={Checkbox}
                label={services[7].name}
                name={services[7].name}
              />
            </Grid>}
        </Grid>
      );
    }
  }
};

PermissionsCheckboxes.defaultProps = {
  subscriptionsValues: [],
  levelsValues: [],
  onSetFieldValue: () => null,
  onSetFieldTouched: () => null,
  editing: true,
  services: [],
  levels: [],
  rowVariant: true,
  firstUser: false
};

PermissionsCheckboxes.propTypes = {
  subscriptionsValues: PropTypes.arrayOf(PropTypes.number),
  levelsValues: PropTypes.arrayOf(PropTypes.number),
  onSetFieldValue: PropTypes.func,
  onSetFieldTouched: PropTypes.func,
  editing: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  rowVariant: PropTypes.bool,
  firstUser: PropTypes.bool
};

export default PermissionsCheckboxes;
