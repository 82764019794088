// Core
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import FormikInput from 'components/FormikInput';
// Context
import { CountriesContext } from 'context/CountriesContext';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      }
    }
  }
});

const CandidateProfileForm = ({ individual, index }) => {
  const { countryMenuItems } = useContext(CountriesContext);
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, "pt-4 pb-2")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
        <Grid item xs={12} sm={6} className="pb-0 pt-4">
          <FormikInput
            mandatory
            id="name"
            name={`individuals[${index}].name`}
            value={individual && individual.name}
            label="Full name"
          />
        </Grid>
        <Grid item xs={12} sm={6} className="pb-0 pt-4">
          <FormikInput
            id="passportNumber"
            name={`individuals[${index}].passport_number`}
            value={individual && individual.passport_number}
            label="Passport number"
          />
        </Grid>
        <Grid item xs={12} sm={6} className="pb-0">
          <FormikInput
            select
            mandatory
            id="countryCode"
            name={`individuals[${index}].country_code`}
            value={individual && individual.country_code}
            label="Country">
            {countryMenuItems}
          </FormikInput>
        </Grid>
        <Grid item xs={12} sm={6} className="pb-0">
          <FormikInput
            id="nationalId"
            name={`individuals[${index}].national_id`}
            value={individual && individual.national_id}
            label="National ID"
          />
        </Grid>
        <Grid item xs={12} sm={6} className="pb-0">
          <FormikInput
            id="role"
            name={`individuals[${index}].position`}
            value={individual && individual.position}
            label="Planned position"
            hint="Individual's planned position in a company"
          />
        </Grid>
        <Grid item xs={12} sm={6} className="pb-0">
          <FormikInput
            id="resAddress"
            name={`individuals[${index}].residential_address`}
            value={individual && individual.residential_address}
            label="Residential address"
          />
        </Grid>
        <Grid item xs={12} sm={6} className="pb-3">
          <FormikInput
            id="localName"
            name={`individuals[${index}].name_in_local_language`}
            value={individual && individual.name_in_local_language}
            label="Name in local language"
          />
        </Grid>
    </Grid>
  );
};

CandidateProfileForm.defaultProps = {
  individual: null,
  index: 0
};

CandidateProfileForm.propTypes = {
  individual: PropTypes.object,
  index: PropTypes.number
};

export default CandidateProfileForm;
