// Core
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
// Components
import Button from 'components/CustomButton';
import CogIcon from 'components/shared/Icons/CogIcon';
import EyeInMonitorIcon from 'components/shared/Icons/EyeInMonitorIcon';
import IntelligenceIcon from 'components/shared/Icons/IntelligenceIcon';
import TabletIcon from 'components/shared/Icons/TabletIcon';
import BulbIcon from 'components/shared/Icons/BulbIcon';
import CheckmarkInTabletIcon from 'components/shared/Icons/CheckmarkInTabletIcon';
import LoupIcon from 'components/shared/Icons/LoupIcon';
import SaturnBikeIcon from 'components/shared/Icons/SaturnBikeIcon';
// Constants
import {
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
} from 'utils';

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid',
    borderRadius: '4px',
    height: '100%',
    "&.orangeCard": {
      borderColor: 'var(--secondary-orange)',
    },
    "&.turquoiseCard": {
      borderColor: 'var(--secondary-turquoise)',
    },
    "&.darkblueCard": {
      borderColor: 'var(--input-active-border)',
    },
    "&.greenCard": {
      borderColor: 'var(--secondary-green)',
    },
    "&.yellowCard": {
      borderColor: 'var(--dark-yellow)',
    },
    "&.redCard": {
      borderColor: 'var(--dark-red)',
    },
    "&.blueCard": {
      borderColor: 'var(--dark-blue)',
    },
    "&.purpleCard": {
      borderColor: 'var(--dark-purple)',
    },
    "&:hover": {
      boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
      transition: '.3s'
    }
  },

  disabledCard: {
    "&:hover": {
      boxShadow: 'unset',
      transition: 'unset'
    }
  },

  cardIcon: {
    width: '15%',
    backgroundColor: 'var(--black-20)',
    display: 'flex',
    margin: '-1px 0px 0px -1px',
    borderRadius: '4px 0px 0px 4px',
    justifyContent: 'center',
    alignItems: 'center',
    "&.orangeCard": {
      borderColor: 'var(--secondary-orange)',
      backgroundColor: 'var(--secondary-orange)',
    },
    "&.turquoiseCard": {
      borderColor: 'var(--secondary-turquoise)',
      backgroundColor: 'var(--secondary-turquoise)',
    },
    "&.darkblueCard": {
      borderColor: 'var(--input-active-border)',
      backgroundColor: 'var(--input-active-border)',
    },
    "&.greenCard": {
      borderColor: 'var(--secondary-green)',
      backgroundColor: 'var(--secondary-green)',
    },
    "&.yellowCard": {
      borderColor: 'var(--dark-yellow)',
      backgroundColor: 'var(--dark-yellow)',
    },
    "&.redCard": {
      borderColor: 'var(--dark-red)',
      backgroundColor: 'var(--dark-red)',
    },
    "&.blueCard": {
      borderColor: 'var(--dark-blue)',
      backgroundColor: 'var(--dark-blue)',
    },
    "&.purpleCard": {
      borderColor: 'var(--dark-purple)',
      backgroundColor: 'var(--dark-purple)',
    },
  },

  cardContent: {
    width: '85%',
    padding: '5px 5px 0px 10px', 
    "& h3": {
      padding: '0px',
    },
  },

  description: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 'normal',
    color: 'var(--black-60)'
  },

  titleWithButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: '3px'
  },

  blockTitleWithCounter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));

const color = (id) => {
  id = parseInt(id);
  switch (id) {
    case CS_SERVICE:
      return 'orangeCard';
    case EDD_SERVICE:
      return 'turquoiseCard';
    case PES_SERVICE:
      return 'darkblueCard';
    case SI_SERVICE:
      return 'greenCard';
    case LEGAL_COMP_SERVICE:
      return 'yellowCard';
    case TECH_INTELLI_SERVICE:
      return 'redCard';
    case PARTNER_SEARCH_SERVICE:
      return 'blueCard';
    case TRENDS_INTELIGENCE_SERVICE:
      return 'purpleCard';
    default:
      return 'darkblueCard';
  }
};

const iconId = (id) => {
  id = parseInt(id);
  switch (id) {
    case CS_SERVICE:
      return <EyeInMonitorIcon/>;
    case EDD_SERVICE:
      return <CogIcon/>;
    case PES_SERVICE:
      return <TabletIcon/>;
    case SI_SERVICE:
      return <IntelligenceIcon/>;
    case LEGAL_COMP_SERVICE:
      return <CheckmarkInTabletIcon/>;
    case TECH_INTELLI_SERVICE:
      return <BulbIcon/>;
    case PARTNER_SEARCH_SERVICE:
      return <LoupIcon/>;
    case TRENDS_INTELIGENCE_SERVICE:
      return <SaturnBikeIcon/>;
    default:
      return <EyeInMonitorIcon/>;
  }
};

const LinkCard = props => {
  const classes = useStyles(props);
  const { id, name, description, disabled, path, counter } = props;

  const projectCard = (
    <Grid
      container
      direction="row"
      className={clsx(classes.card, color(id), disabled && classes.disabledCard)}>
        <Grid
          item 
          xs={12}
          sm={2}
          className={clsx(classes.cardIcon, color(id))}>
            {iconId(id)}
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          className={classes.cardContent}>
            <Grid className={classes.titleWithButton}>
              <Grid className={classes.blockTitleWithCounter}>
                <Typography variant="h3" className="pt-2">{name}</Typography>
                {!disabled && 
                <Button disabled={true} color="primary">
                  <Typography color="textPrimary" variant="h3">{counter}</Typography>
                </Button>}
              </Grid>
            </Grid>
            <Typography className={clsx(classes.description, "description-service")} dangerouslySetInnerHTML={{__html: description}}/>
        </Grid>
    </Grid>
  )
   
  return (
    <>
    {!disabled 
      ? (<Link to={`${path}/?type=${id}`}>
          {projectCard}
        </Link>)
      : (<>
          {projectCard}
        </>)
    }
    </>
  );
};

LinkCard.defaultProps = {
  waspAdmin: false,
};

LinkCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  waspAdmin: PropTypes.bool,
};

export default LinkCard;
