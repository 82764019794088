import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import FormikInput from 'components/FormikInput';
import Button from 'components/CustomButton';
// Constants
import { CODE_REGEX, PASSWORD_REGEX } from 'utils';


const initialValues = { code: '', password: '', 'confirm Password': '' };

const validationSchema = Yup.object().shape({
  code: Yup.string()
  .required('Required')
  .matches(CODE_REGEX, {
    message: 'Code is incorrect',
    excludeEmptyString: true,
  }),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(100, 'Must be 100 characters or less')
    .matches(PASSWORD_REGEX, {
      message: 'Must contain upper and lowercase letters, digits, and at least one symbol like @#$%^&+=',
      excludeEmptyString: true,
    })
    .required('Password is required'),

  'confirm Password': Yup.string()
    .required('Confirm Password is required')
    .matches(PASSWORD_REGEX, {
      message: 'Password is too easy',
      excludeEmptyString: true,
    })
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const ResetPasswordForm = ({resetPasswordError, formResetPassword, className}) => {
  const { push } = useHistory();

  const goToLoginPage = () => {
    push('/');
  };

  const onSubmit = (values, setSubmitting) => {
    formResetPassword({id, values});
    setTimeout(() => {
      setSubmitting(false);
    }, 1500);    
  };

  let { id } = useParams();

  const codeError = resetPasswordError && resetPasswordError.message;
  const passwordError = resetPasswordError && resetPasswordError.password;

  const renderForm = values => {
    const form = Object.keys(values).map(key => {      
      const type = key ===  'code' ? 'text' : 'password'; 
      const error = key === 'code' ? codeError : passwordError;

      return (
        <FormikInput
          key={key}
          mandatory
          type={type}
          offMultiline={key === 'code'}
          id={key}
          name={key}
          label={key}
          value={values[key]}
          apiError={error}
        />
      );
    });

    return form;
  };

  return(
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({ values, handleSubmit, isSubmitting, isValid }) => (
        <form className={className} onSubmit={handleSubmit}>
          {renderForm(values)}
          <Button
            type="submit"
            disabled={!isValid || isSubmitting} 
            className="wideButton">
              Set Password
          </Button>
          <Button
            variant="outlined"
            className="wideButton"
            onClick={goToLoginPage}>
              Log in
          </Button>
        </form>
      )}    
    </Formik>
  );
}

export default ResetPasswordForm;
