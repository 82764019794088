import React from 'react';
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import QuestionnaireSubmitForm from './Forms/QuestionnaireSubmitForm';
import DownloadFilesForm from './Forms/DownloadFilesForm';
import useAsync from 'hooks/useAsync';
// Instruments
import { getPartnerId } from 'api/questionnaire';
import { getPartnerFilesList } from 'api/questionnaireFiles';
import { useEffect } from 'react';
import Spinner from 'components/shared/Spinner';

const finalStepFields = [
  { name: "Corporate registry extract detailing shareholders, UBOs and authorized persons", path: "registry_extract" },
  { name: "Certificate of Incorporation", path: "certificate_incorporation" },
  { name: "Ownership Chart", path: "ownership_chart" },
  { name: "VAT Certificate", path: "vat_certificate" },
  { name: "Professional License Certificate", path: "prof_license_certificate" },
  { name: "Counterparty Reference Letter", path: "counterparty_reference_letter" },
  { name: "Bank Reference Letter", path: "bank_reference_letter" },
  { name: "ID Document of Director(s)", path: "document_directors" },
  { name: "ID Document of Shareholder(s)", path: "document_shareholders" },
  { name: "ID Document of UBO(s)", path: "document_ubos" },
  { name: "Introduction Presentation", path: "introduction_presentation" },
  { name: "Code of Conduct and Compliance Policies", path: "code_conduct_compliance_policies" },
  { name: "Audited Annual Report or Financial Statement", path: "latest_audited_report" },
  { name: "Invoice Sample", path: "invoice_sample" },
  { name: "Other", path: "other" }
]

const DownloadFilesStep = ({
  linksObject,
  userId,
  onSetFieldValue
}) => {
  const finish_form = useSelector(state => state.questionnaire.finish_form);

  const {
    execute: getPartnerIdByUserId,
    pending: pendingGetPartnerId,
    status: statusGetPartnerId,
    value: partnerId
  } = useAsync(getPartnerId, false);

  const {
    execute: handlerGetPartnerFiles,
    setValue: setPartnersFiles,
    pending: pendingGetPartnerFiles,
    value: partnerFiles
  } = useAsync(getPartnerFilesList, false);

  useEffect(() => {
    if (userId) {
      getPartnerIdByUserId(userId);
    }
  }, [getPartnerIdByUserId, userId]);

  useEffect(() => {
    if (!pendingGetPartnerId && statusGetPartnerId === 200) {
      handlerGetPartnerFiles(partnerId.partner_id);
    }
  }, [pendingGetPartnerId, statusGetPartnerId, partnerId, handlerGetPartnerFiles]);

  return (
    partnerId && !pendingGetPartnerFiles && partnerFiles ? (
      <React.Fragment>
        {
          finalStepFields.map((item) => {
            const filesByType = partnerFiles.filter(file => file.document_type === item.path);
            return (
              <DownloadFilesForm
                key={item.path}
                editable={true}
                stepNamePath={item}
                finish_form={linksObject}
                partnerId={partnerId.partner_id}
                currentFiles={filesByType}
                setPartnersFiles={setPartnersFiles}
                partnerFiles={partnerFiles}
                onSetFieldValue={onSetFieldValue}
              />
            );
          })
        }
        <QuestionnaireSubmitForm finish_form={finish_form} />
        <Divider className="mt-3 mb-3 minus-m-25" />
      </React.Fragment>
    ) : (
      <Spinner />
    )
  );
}


export default DownloadFilesStep;
