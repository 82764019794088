// Core
import clsx from 'clsx';
import React, {useState, useEffect} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import TechIntelligenceDetailsForm from 'components/OrderFlow/TechIntelligenceStepForms/TechIntelligenceDetailsForm';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Button from 'components/CustomButton';
import MapChart from 'components/MapChart/MapChart';
import Spinner from 'components/shared/Spinner';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import {
  fetchAnalysisDetails,
  fetchProjectTechIntelliProjectSettings
} from 'api/projects';


const AnalysisDetailsStep = ({
  config_id,
  newProjectId,
  onUpdateProject,
  values, 
  onCreateProject, 
  onClickBack,
  onSetFieldValue,
}) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeCurrentPage,
    handleClose: handleCloseConfirmToChangeCurrentPage,
  } = useDialog();

  const { execute: getDictionaries, value: dictionaries } = useAsync(
    fetchAnalysisDetails,
    false,
  );
  const { execute: getTechIntelliProjectSettings, value: settings } = useAsync(
    fetchProjectTechIntelliProjectSettings,
    false,
  );

  useEffect(() => {
    if (config_id) {
      getDictionaries(config_id);
    }
  }, [getDictionaries, config_id]);

  useEffect(() => {
    if(newProjectId) {
      getTechIntelliProjectSettings(newProjectId);
    }
  }, [getTechIntelliProjectSettings, newProjectId]);

  useEffect(() => {
    if (values && settings) {
      handleChangeData(values, settings);
    }
  // eslint-disable-next-line
  }, [values]);
  
  const onClickNextButton = () => {
    const step = 3;
    if (newProjectId) {
      onUpdateProject({ step });
    } else {
      onCreateProject({});
    }
  };

  const shouldDisableNextButton = (values) => {
    return values.country_code === '';
  };

  const handleChangeData = (values, oldValues) => {
    let isDifferent = false;
    isDifferent = (values.country_code !== oldValues.country_code);
    if (isDifferent === false) {
      isDifferent = (values.industry_id !== oldValues.industry_id);
    }
    setHasUnsavedData(isDifferent);
  };

  const handleChangeCurrentPage = () => {
    setHasUnsavedData(false);
    handleCloseConfirmToChangeCurrentPage();
    onSetFieldValue('techIntelligence.industry_id', settings.industry_id);
    onSetFieldValue('techIntelligence.country_code', settings.country_code);
    onClickBack();
  }

  return (
    dictionaries && (!newProjectId || (newProjectId && settings)) ? (
      <Grid
        container
        alignItems="stretch"
        direction="row">
          <Grid item xs={12} md={5} className="pr-1">
            {
              dictionaries && (
                <TechIntelligenceDetailsForm 
                  industries={dictionaries.industries} 
                  values={values}
                  analysisType={config_id}
                />
              )
            }
          </Grid>
          <Grid item xs={12} md={7} className='leftBorderMap'>
            <Grid container className={clsx("pl-3", 'generalMapWrapper')}>
              <Grid container>
                <Typography variant="h3" className="pb-0 pl-0">Corruption Perception Index</Typography>
              </Grid>
              <Grid item className='generalMapWrapper'>
                <MapChart selectedCountry={values.country_code} width="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Grid 
            container
            justify="flex-end">
              <Grid>
                <Button
                  className="mb-0"
                  variant="outlined"
                  onClick={() => {
                    if (hasUnsavedData) {
                      handleOpenConfirmToChangeCurrentPage()
                    } else {
                      onClickBack()
                    }
                  }}>
                    Back
                </Button>
                <Button
                  className="mb-0 mr-0"
                  disabled={shouldDisableNextButton(values && values)}
                  onClick={() => onClickNextButton()}>
                    Next
                </Button>
              </Grid>
            </Grid>
        <ConfirmDialog
          title="Unsaved data"
          open={openConfirmToChangeCurrentPage}
          onClose={handleCloseConfirmToChangeCurrentPage}
          options={hasUnsavedData}
          confirmBtnText="Confirm"
          onConfirm={handleChangeCurrentPage}>
          You are about to leave a page. All unsaved data will be lost. Please confirm.
        </ConfirmDialog>
        <RouteLeavingGuard
          when={hasUnsavedData}
          navigate={path => {
            if(path !== url){
              push(path);
            }
          }}
          shouldBlockNavigation={(path) => { return path.pathname === url ? false : true; }}
        />
      </Grid>
    ) : <Spinner />
  )
}

AnalysisDetailsStep.defaultProps = {
  newProjectId: "",
  values: {},
  onClickBack: () => null,
  onSetFieldValue: () => null,
  onCreateProject: () => null,
  onUpdateProject: () => null,
}

AnalysisDetailsStep.propTypes = {
  config_id: PropTypes.number.isRequired,
  newProjectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onUpdateProject: PropTypes.func,
  values: PropTypes.shape({
    settings_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    industry_name: PropTypes.string,
    risks: PropTypes.array,
    stakeholders: PropTypes.array,
  }), 
  onCreateProject: PropTypes.func, 
  onClickBack: PropTypes.func,
  onSetFieldValue: PropTypes.func,
}

export default AnalysisDetailsStep;
