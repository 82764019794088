// Core
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// Components
import FormikInput from 'components/FormikInput';
import IconButton from 'components/IconButton';
// Context
import { CountriesContext } from 'context/CountriesContext';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      }
    }
  }
});

const IndividualFormStep = ({ typeKeySubject, typeRelatedSubject, individual, index, canDeleted, handlerDelete }) => {
  const { countryMenuItems } = useContext(CountriesContext);
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, canDeleted ? "pt-0 pb-2" : "pt-4 pb-2")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      {canDeleted &&
        (<Grid item xs={12} className="pb-0 pt-0">
        <IconButton 
          inverted="true" 
          className="mr-0 float-right" 
          onClick={() => handlerDelete(index, typeRelatedSubject)}
        >
          <Icon className="fa fa-trash-alt" />
        </IconButton>
      </Grid>)}
      <Grid item xs={12} sm={6} className="pb-0 pt-4">
        <FormikInput
          mandatory
          id="name"
          name={`individuals[${index}].name`}
          value={individual && individual.name}
          label="Full name"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0 pt-4">
        <FormikInput
          id="shares"
          name={`individuals[${index}].shares`}
          value={individual && individual.shares}
          label="Shares"
          customAdornment={"percent"}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          select
          mandatory
          id="countryCode"
          name={`individuals[${index}].country_code`}
          value={individual && individual.country_code}
          label="Country">
          {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="website"
          name={`individuals[${index}].website`}
          value={individual && individual.website}
          label="Website"
          hint="Example: website.com"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="localName"
          name={`individuals[${index}].name_in_local_language`}
          value={individual && individual.name_in_local_language}
          label="Name in local language"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="alias"
          name={`individuals[${index}].alias`}
          value={individual && individual.alias}
          label="Alias"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="passportNumber"
          name={`individuals[${index}].passport_number`}
          value={individual && individual.passport_number}
          label="Passport number"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="nationalId"
          name={`individuals[${index}].national_id`}
          value={individual && individual.national_id}
          label="National ID"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="resAddress"
          name={`individuals[${index}].residential_address`}
          value={individual && individual.residential_address}
          label="Residential address"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={!typeKeySubject ? "pb-0" : "pb-1"}>
        <FormikInput
          id="businessAddress"
          name={`individuals[${index}].business_address`}
          value={individual && individual.business_address}
          label="Business address"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="pb-0">
        <FormikInput
          id="role"
          name={`individuals[${index}].position`}
          value={individual && individual.position}
          label="Position"
          hint="Individual's position in the company"
        />
      </Grid>
    </Grid>
  );
};

IndividualFormStep.defaultProps = {
  typeKeySubject: false,
  typeRelatedSubject: true,
  individual: null,
  index: 0,
  canDeleted: false,
  handlerDelete: () => null
};

IndividualFormStep.propTypes = {
  typeKeySubject: PropTypes.bool,
  typeRelatedSubject: PropTypes.bool,
  individual: PropTypes.object,
  index: PropTypes.number,
  canDeleted: PropTypes.bool,
  handlerDelete: PropTypes.func
};

export default IndividualFormStep;
