import client from './client';

/**
 * Get all user files for the project
 *
 * @param {string} project_id the project id
 * @param {boolean} is_result default: true - get a group of files for the project: results or users' data
 * @param {boolean} only default: false - get only selected group of files
 * @returns {Promise} Promise object represents operation result
 */
export const getUserFilesList = async (project_id, is_result=true, only=false) => {
  let isResult = is_result ? "Y" : "N";
  let isOnly = only ? "Y" : "N";

  try {
    const response = await client.get(`/api/project_files/${project_id}/?is_result=${isResult}&only=${isOnly}`);

    if (response.status === 200 || response.status === 204) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

/**
 * Downloads file from the server
 *
 * @param {String} projectId project id
 * @param {String} fileId file id
 * @returns {Promise} Promise object represents operation result
 */
export const downloadFile = async (projectId, fileId) => {

  try {
    const response = await client.get(
      `/api/project_files/${projectId}/${fileId}/`,
      {
        responseType: 'blob',
      },
    );

    if (response) {
      function getFileNameFromContentDisposition(contentDisposition) {
        if (!contentDisposition) return null;
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        return match ? match[1] : null;
      }
      function getFileLocatonFromContentLocation(contentLocation) {
        if (!contentLocation) return null;
        const match = contentLocation.match(/.blob.core.windows.net?/);
        return match ? match["input"] : null;
      }
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const actualFileName = getFileNameFromContentDisposition(response.headers['content-disposition']);
      const azureLocation = getFileLocatonFromContentLocation(response.headers['content-location']);

      const link = document.createElement('a');
      link.download = actualFileName;
      link.href = azureLocation ? azureLocation : downloadUrl;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    return error;
  }
};

/**
 * Delete a current file from the server
 * 
 * @param {String} projectId project id
 * @param {String} fileId file id
 * @returns {Promise} Promise object represents operation result
 */
export const deleteFile = async (projectId, fileId) => {

  try {
    const response = await client.delete(`/api/project_files/${projectId}/${fileId}/`);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
