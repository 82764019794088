import React from 'react';
import { Tooltip } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const TooltipTextLabel = ({ label, tooltipLabel }) => {
  return (
    <div>
      <span>{label}</span>
      <Tooltip
        title={tooltipLabel}
        placement="top"
        arrow
        interactive={false}
        style={{ color: 'var(--primary-regular)', fontSize: 12, marginLeft:'10px' }}>
        <Icon className="fa fa-info-circle fa-sm" />
      </Tooltip>
    </div>
  );
};

export default TooltipTextLabel;
