// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
// Components
import ListItemLink from './ListItemLink';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    textTransform: 'uppercase',
    borderBottom: '4px solid rgba(185, 185, 185, 0.15)',
  },
});

const Navigation = ({ links, ...props }) => {
  const classes = useStyles();

  const renderNavLinks = links.map((link, idx) => (
    <ListItemLink key={idx} {...link} {...props} />
  ));

  return (
    <List component="nav" className={classes.root}>
      {renderNavLinks}
    </List>
  );
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default Navigation;
