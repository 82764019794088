import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  tableCellName: {
    fontWeight: 500,
    width: '50%',
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  head: {
    fontWeight: 600,
    fontSize: '16px'
  }
});

const PartnerGuideLinksPart = ({
  infoForTable
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      {
        infoForTable.map((item) => (
          <Table 
            size="small" 
            aria-label="a dense table" 
            className={classes.table}
            key={item.name}
          >
            <TableHead>
              <TableRow>
                <TableCell 
                  className={classes.head}
                >
                  {`${item.name} - ${item.citizenship_name} - ${item.registered_number}`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.links.length > 0 
                ? (item.links.map((link, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" className={classes.tableCellName}>
                      <Link 
                        to={{ pathname: link }} 
                        target="_blank"
                      >
                        {link}
                      </Link>
                    </TableCell>
                  </TableRow>)))
                : (<TableRow>
                    <TableCell component="th" scope="row" className={classes.tableCellName}>
                      Don't found any links
                    </TableCell>
                  </TableRow>)
                }
            </TableBody>
            {item.second_citizenship_name && (
              <React.Fragment>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      className={classes.head}
                    >
                      {`Second citizenship: ${item.name} - ${item.second_citizenship_name} - ${item.registered_number}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.second_citizenship_links.length > 0 
                    ? (item.second_citizenship_links.map((link, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" className={classes.tableCellName}>
                          <Link 
                            to={{ pathname: link }} 
                            target="_blank"
                          >
                            {link}
                          </Link>
                        </TableCell>
                      </TableRow>)))
                    : (<TableRow>
                        <TableCell component="th" scope="row" className={classes.tableCellName}>
                          Don't found any links
                        </TableCell>
                      </TableRow>)
                    }
                </TableBody>
              </React.Fragment>)
            }
          </Table>
        ))
      }
    </TableContainer>
  );
}

export default PartnerGuideLinksPart;
