import client from './client';

/**
 * Fetches Compliance Checks project configuration
 * @param {Number} id service id
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectConfig = async serviceId => {
  const response = await client.get(`/api/projects/config_list/${serviceId}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches projects from api
 *
 * @param {Object} parameters Additional request parameters for filtration
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjects = async (parameters = {}) => {
  let parametersUrl = '';
  if (Object.keys(parameters).length > 0) {
    parametersUrl = new URLSearchParams(parameters).toString();
    parametersUrl = `?${parametersUrl}`;
  }

  const response = await client.get(`/api/projects/${parametersUrl}`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Creates new project - Compliance Screening up
 *
 * @param {Object} values contains data for creating project
 * @returns {Promise} Promise object represents operation result
 */
async function createProject(values) {
  const data = values;

  const response = await client.post('/api/projects/', data);

  if (response.status === 201) {
    return response;
  }
}

/**
 * Fetches project by id
 *
 * @param {string} id project id
 * @returns {Promise} Promise object represents operation result
 */
async function fetchProjectById(id) {
  const response = await client.get(`/api/projects/details/${id}/`);

  if (response.status === 200) {
    const project = {
      data: {
        project_id: response.data.id,
        account_id: response.data.account_id,
        key_subject: response.data.key_subject,
        related_subject: response.data.related_subject,
        level: response.data.level,
        level_id: response.data.level_id,
        service_id: response.data.service_id,
        config_id: response.data.config_id,
        priority: response.data.priority,
        files: response.data.files,
        comments: response.data.comments,
        due_date: response.data.due_date,
        pref_date: response.data.pref_date,
        req_date: response.data.req_date,
        requester: response.data.requester,
        requester_acc_id: response.data.requester_acc_id,
        status: response.data.status,
        humint: response.data.humint,
        subjects: response.data.subjects,
        analysis_details: response.data.analysis_details,
        analysis_type: response.data.analysis_type_id,
        analysis_type_name: response.data.analysis_type_name,
        legal_comp_details: response.data.legal_comp_details,
        partner_search_details: response.data.partner_search_details,
        tech_intelli_details: response.data.tech_intelli_details,
        trends_intelligence_details: response.data.trends_intelligence_details,
      },
    };

    return project;
  }
}

/**
 * Updates project by id
 *
 * @param {String} id project id
 * @param values data that should be updated
 * @returns {Promise} Promise object represents operation result
 */
async function updateProject({ project_id, ...values }) {
  const data = { ...values };

  const response = await client.put(`/api/projects/${project_id}/`, data);

  if (response.status === 200) {
    return response;
  }
}

/**
 * Fetches project statuses
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectStatuses = async () => {
  const response = await client.get('/api/info/projects/statuses/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Delete related subject
 *
 * @param {String} project_id project id
 * @param {String} subject_type subject type: entity or individual
 * @returns {Promise} Promise object represents operation result
 */
const deleteRelatedSubject = async ({
  project_id,
  subject_type,
  subject_id,
}) => {

  const data = {
    project_id,
    subject_type,
    subject_id,
  };
  const response = await client.delete('/api/projects/cs/delete_related_subject/', {data});

  if (response.status === 200) {
    return response;
  }
};

/**
 * Delete project
 *
 * @param {String} project_id project id
 * @returns {Promise} Promise object represents operation result
 */
const deleteProject = async project_id => {

  const response = await client.delete(`/api/projects/delete_project/${project_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Get's recommended level by score points
 *
 * @param data includes score points
 * @returns {Promise} Promise object represents operation result
 */
const fetchRecommendedLevel = async data => {

  const response = await client.post('/api/projects/advice_flow/', data);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches config settings for analysis details
 * @param {Number} config_id config id
 * @returns {Promise} Promise object represents operation result
 */
const fetchAnalysisDetails = async config_id => {

  const response = await client.get(`/api/projects/si/analysis_details/${config_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches dictionaries for Legal Compliance form
 * @param {Number} config_id config id
 * @returns {Promise} Promise object represents operation result
 */
const fetchLegalComplianceDicts = async config_id => {

  const response = await client.get(`/api/projects/legal-compliance/dictionaries/${config_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches dictionaries for Partner Search form
 * @param {Number} config_id config id
 * @returns {Promise} Promise object represents operation result
 */
const fetchPartnerSearchDicts = async config_id => {

  const response = await client.get(`/api/projects/partner-search/dictionaries/${config_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches dictionaries for Legal Compliance form
 * @param {Number} config_id config id
 * @returns {Promise} Promise object represents operation result
 */
const fetchTrendsIntelligenceDicts = async config_id => {

  const response = await client.get(`/api/projects/trends-intelligence/dictionaries/${config_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches Advice Flow data dictionaries
 * @returns {Promise} Promise object represents operation result
 */
const fetchAdviceFlowDataDictionaries = async () => {
  const response = await client.get('/api/projects/advice_flow/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Downloads CSV file from the server
 *
 * @param {Object} parameters Additional request parameters for filtration
 * @returns {Promise} Promise object represents operation result
 */
const downloadCsv = async (parameters = {}) => {
  let parametersUrl = '';
  if (Object.keys(parameters).length > 0) {
    parametersUrl = new URLSearchParams(parameters).toString();
    parametersUrl = `?${parametersUrl}`;
  }

  try {
    const response = await client.get(`/api/projects/download_csv/${parametersUrl}`);

    if (response) {
      function getFileNameFromContentDisposition(contentDisposition) {
        if (!contentDisposition) return null;

        const match = contentDisposition.match(/filename="?([^"]+)"?/);

        return match ? match[1] : null;
      }

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const actualFileName = getFileNameFromContentDisposition(
        response.headers['content-disposition'],
      );

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', actualFileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    return error;
  }
};

/**
 * Fetches data such as risks and stakeholders fo SI projects
 * @param {String} project_id project id
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectSiProjectSettings = async project_id => {

  const response = await client.get(`/api/projects/si/project_settings/${project_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches data such as challenge/problem and expectations for Tech Intelligence projects
 * @param {String} project_id project id
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectTechIntelliProjectSettings = async project_id => {

  const response = await client.get(`/api/projects/tech-intelligence/project_settings/${project_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches data such as sub_services Legal Compliance projects
 * @param {String} project_id project id
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectPartnerSearchProjectSettings = async project_id => {

  const response = await client.get(`/api/projects/partner-search/project_settings/${project_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches data such as sub_services Legal Compliance projects
 * @param {String} project_id project id
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectLegalCompProjectSettings = async project_id => {

  const response = await client.get(`/api/projects/legal-compliance/project_settings/${project_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches data such as sub_services Trends intelligence projects
 * @param {String} project_id project id
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectTrendsIntelligenceProjectSettings = async project_id => {

  const response = await client.get(`/api/projects/trends-intelligence/project_settings/${project_id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches projects counters
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectsCounters = async () => {

  const response = await client.get('/api/projects/get_projects_counters/');

  if (response.status === 200) {
    return response;
  }
};

export {
  createProject,
  deleteProject,
  deleteRelatedSubject,
  downloadCsv,
  fetchAdviceFlowDataDictionaries,
  fetchAnalysisDetails,
  fetchProjects,
  fetchProjectById,
  fetchProjectConfig,
  fetchProjectStatuses,
  fetchRecommendedLevel,
  fetchProjectSiProjectSettings,
  updateProject,
  fetchProjectsCounters,
  fetchLegalComplianceDicts,
  fetchPartnerSearchDicts,
  fetchProjectLegalCompProjectSettings,
  fetchProjectTechIntelliProjectSettings,
  fetchProjectPartnerSearchProjectSettings,
  fetchTrendsIntelligenceDicts,
  fetchProjectTrendsIntelligenceProjectSettings,
};
