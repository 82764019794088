// Core
import clsx from 'clsx';
import React, { useContext } from 'react';
import PropTypes, { shape } from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
// Components
import FormikInput from 'components/FormikInput';
// Context
import { CountriesContext } from 'context/CountriesContext';
import FormInfoSection from '../../FormInfoSection';
import Textarea from '../../Textarea';

const useStyles = makeStyles({
  form: {
    paddingRight: 10,
    listStyle: 'none',
    scrollbarWidth: 'thin',
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    },
  },
  risksLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--black-100)',
  },
  risksDescription: {
    fontSize: 10,
    fontWeight: 500,
    color: 'var(--black-40)',
  },
  industriesSelect: {
    '& .MuiOutlinedInput-root': {
      overflow: 'unset !important',
      height: 30,
    },
    '& .MuiInputBase-input': {
      position: 'absolute',
      maxWidth: '90%',
    },
    marginBottom: '0px !important',
  },
});

const TechIntelligenceDetailsForm = ({ values, industries, analysisType }) => {
  const classes = useStyles();
  const { countryMenuItems } = useContext(CountriesContext);
  const industryMenuItems =
    industries &&
    industries.map(industry => {
      const { id, name } = industry;
      return (
        <MenuItem key={id} value={parseInt(id)}>
          {name}
        </MenuItem>
      );
    });
  return (
    <Grid className={clsx(classes.form, 'pt-2')}>
      <Grid item>
        <FormikInput
          select
          mandatory
          id="country_code"
          name="techIntelligence.country_code"
          value={values && values.country_code}
          label="Country">
          {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item className="pb-2">
        <FormikInput
          select
          mandatory
          id="industry_id"
          name="techIntelligence.industry_id"
          // offMultiline={true}
          value={values && values.industry_id}
          label="industry"
          className={classes.industriesSelect}>
          {industryMenuItems}
        </FormikInput>
      </Grid>
      <Grid container direction="column" className="pb-3">
        <FormInfoSection
          text="Describe your challenge/problem"
          isCapitalize={false}
        />
        <Textarea
          id="challenge_problem"
          name="techIntelligence.challenge_problem"
          value={values && values.challenge_problem}
        />
        <FormInfoSection text="Your expectations" isCapitalize={false} />
        <Textarea
          id="expectations"
          name="techIntelligence.expectations"
          value={values && values.expectations}
        />
      </Grid>
    </Grid>
  );
};

TechIntelligenceDetailsForm.defaultProps = {
  values: null,
  industries: [],
  analysisType: null,
};

TechIntelligenceDetailsForm.propTypes = {
  values: PropTypes.shape({
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    industry_name: PropTypes.string,
    expectations: PropTypes.string,
    challenge_problem: PropTypes.string,
    settings_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  industries: PropTypes.arrayOf(
    shape({
      id: PropTypes.number,
      name: PropTypes.string,
      score: PropTypes.string,
    }),
  ),
  analysisType: PropTypes.number,
};

export default TechIntelligenceDetailsForm;
