// Core
import React from 'react';
import MomentUtils from '@date-io/moment';
// Material-UI
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  datePicker: {
    '& .MuiToolbar-root': {
      alignItems: 'center'
    }
  },
  hov: {
    fontWeight: 600,
    height: 30,
    border: '1px solid var(--black-20)',
    borderRadius: '4px',
    paddingLeft: '10px',
    "& .MuiGrid-root": {
      "& .MuiBox-root": {
        marginBottom: '0px !important'
      }
    },
    '&:hover': {
      borderColor: 'var(--input-active-border)',
      border: '1px solid #183f73'
    },
    "& .MuiInput-underline": {
      "&::after": {
        border: '1px solid var(--black-20)'
      },
      "&::before": {
        border: '1px solid #183f73'
      }
    }
  }
});

const SimpleDatePicker = ({ 
  dateValue, 
  onChangeHandler, 
  format, 
  label, 
  name,
  id,
  disabled
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
       <KeyboardDatePicker
          disabled={disabled}
          disableFuture
          maxDate={Date.now()}
          id={id}
          fullWidth={true}
          onChange={onChangeHandler}
          name={name}
          margin="normal"
          label={label}
          format={format ? format : "DD/MM/YYYY"}
          value={dateValue}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{
            className: classes.hov,
          }}
          DialogProps={{ 
            className: classes.datePicker 
          }}
        />
  </MuiPickersUtilsProvider>
  )
}

export default SimpleDatePicker;
