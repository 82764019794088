import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Navbar from 'components/Navbar';

const Header = props => (
  <AppBar position="relative">
    <Navbar {...props} />
    <Divider />
  </AppBar>
);

export default Header;
