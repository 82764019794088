// Core
import clsx from 'clsx';
import React, { useContext } from 'react';
import PropTypes, { shape } from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// Context
import { CountriesContext } from 'context/CountriesContext';
// Components
import FormikInput from 'components/FormikInput';
import { PARTNER_SEARCH_ANALYSIS_CONFIG } from '../../../utils';
import PartnershipTypesCheckboxes from '../../PartnershipTypesCheckboxes/PartnershipTypesCheckboxes';

const useStyles = makeStyles({
  form: {
    maxHeight: 626,
    paddingRight: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
    listStyle: 'none',
    scrollbarWidth: 'thin',
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    },
  },
  servicesLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--black-60)',
  },
  industriesSelect: {
    '& .MuiOutlinedInput-root': {
      overflow: 'unset !important',
      height: 30,
    },
    '& .MuiInputBase-input': {
      position: 'absolute',
      maxWidth: '90%',
    },
    marginBottom: '0px !important',
  },
});

const PartnerSearchGeneralForm = ({
  values,
  industries,
  partnership_types,
  config_id,
  dynamicValues,
  partnershipTypeFieldOnChange,
}) => {
  const classes = useStyles();
  const { countryMenuItems } = useContext(CountriesContext);
  const industryMenuItems =
    industries &&
    industries.map(industry => {
      const { id, name } = industry;
      return (
        <MenuItem key={id} value={parseInt(id)}>
          {name}
        </MenuItem>
      );
    });
  return (
    <Grid className={clsx(classes.form, 'pt-2')}>
      <Grid item>
        <FormikInput
          select
          mandatory
          id="country_code"
          name="partnerSearch.country_code"
          value={values && values.country_code}
          label="Country">
          {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item className="pb-2">
        <FormikInput
          select
          mandatory
          id="industry_id"
          name="partnerSearch.industry_id"
          value={values && values.industry_id}
          label="industry"
          className={classes.industriesSelect}>
          {industryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item className="pb-3">
        <Typography className={classes.servicesLabel}>
          Type of partnership
        </Typography>
      </Grid>
      {partnership_types && (
        <PartnershipTypesCheckboxes
          partnership_types={partnership_types}
          partnershipTypesRecords={dynamicValues.partnership_types}
          onPartnershipTypeFieldChange={partnershipTypeFieldOnChange}
        />
      )}
      <Grid className="pb-3" />
    </Grid>
  );
};

PartnerSearchGeneralForm.defaultProps = {
  configId: PARTNER_SEARCH_ANALYSIS_CONFIG,
  values: null,
  industries: [],
  partnership_types: [],
  dynamicValues: null,
  partnershipTypeFieldOnChange: () => null,
};

PartnerSearchGeneralForm.propTypes = {
  values: PropTypes.shape({
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    industry_name: PropTypes.string,
    settings_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    partnership_types: PropTypes.array,
  }),
  industries: PropTypes.arrayOf(
    shape({
      id: PropTypes.number,
      name: PropTypes.string,
      score: PropTypes.string,
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  partnership_types: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  config_id: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  dynamicValues: PropTypes.object,
  partnershipTypeFieldOnChange: PropTypes.func,
};

export default PartnerSearchGeneralForm;
