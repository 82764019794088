// Core
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles( theme => ({
  stickyBlock: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      width: '350px',
      left: '102%',
      top: 0,
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    }
  },
}));

const StickyBlock = props => {
  const classes = useStyles(props);
  const { className, children } = props;
  
  return (
    <Container maxWidth="xs" className={clsx(className, classes.stickyBlock)}>
      {children}
    </Container>
  );
};

StickyBlock.defaultProps = {
  children: null,
  className: '',
};

StickyBlock.propTypes = {
  children: PropTypes.shape({}),
  className: PropTypes.string,
};

export default StickyBlock;
