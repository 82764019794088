import React, {useState, useEffect} from "react";
import clsx from "clsx";
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 9,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "var(--secondary-turquoise)",
      position: "relative",
    }
  },
  completed: {
    "& $line": {
      borderColor: "var(--secondary-turquoise)",
      position: "relative",
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 10,
    borderRadius: 9,
    marginLeft: "-10px",
    marginRight: "-10px",
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    zIndex: 3,
  },
  active: {
    color: "var(--white)",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    position: "relative",    
  },
  completed: {
    color: "var(--white)",
    zIndex: 1,
    fontSize: 18
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      <div className={classes.circle} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiPaper-root":{
      padding: '0 0 15px 0'
    },
    "& .MuiStepper-root": {
      "& .MuiStep-root:first-child": {
        visibility: 'collapse'
      },
      "& .MuiStep-root:last-child": {
        visibility: 'collapse'
      }
    }
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepperTytle:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  stepName: {
    fontSize: 14,
    fontWeight: 600,
  },
  currentStep:{
    fontSize: 10,
    color: "var(--black-60)"
  }
}));

function getSteps() {
  return ["Start"];
}

const CustomStepper = ({stepTitles, activeStep}) => {
  const classes = useStyles();
  const [steps, setSteps] = useState(getSteps());

  useEffect(() => {
    if (stepTitles) {
      setSteps(
        getSteps().concat(stepTitles)
      );
    };
  }, [stepTitles]);

  return (
    <Grid
      className={classes.root}>
        <Grid className={classes.stepperTytle}>
          <span className={classes.stepName}>
            {steps[activeStep]}
          </span>
          <span className={classes.currentStep}>
            Step {activeStep}
          </span>
        </Grid>
        <Stepper
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}/>
            </Step>
          ))}
        </Stepper>      
    </Grid>
  );
}

CustomStepper.defaultProps = {
  stepTitles: [],
  activeStep: 1,
};

CustomStepper.propTypes = {
  stepTitles: PropTypes.array,
  activeStep: PropTypes.number.isRequired,
};

export default CustomStepper;
