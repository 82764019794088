// Core
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
// Components
import FormikInput from 'components/FormikInput';
import Button from 'components/CustomButton';
import { fileExists } from 'helpers';
// Instruments

const initialValues = { email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    })
    .required('Required'),
  password: Yup.string().required('Required'),
});

const useStyles = makeStyles(theme => {
  return {
    forgotPassword: {
      position: 'absolute',
      top: 73,
      right: 0,
      [theme.breakpoints.down('md')]: {
        left: 0,
        top: 240
      },
    },

    forgotPasswordLink: {
      fontWeight: 600,
      fontSize: 15,
      color: 'var(--primary-regular)',
      fontFamily: 'Montserrat',
      textDecoration: 'underline',
      "&:hover":{
        color: 'var(--primary-hover)',
        textDecoration: 'underline'
      }
    },
  };
});

const SignInForm = ({ onSignIn, authError, className, pending, isHuman }) => {

  const classes = useStyles();
  const onSubmit = (values) => {
    if (fileExists('./site-key.txt')) {

      fetch('./site-key.txt').then(res => res.text()).then(text => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(text, { action: "submit" }).then(google_recaptcha_token => {
            onSignIn({...values, google_recaptcha_token});
          });
        });
      })
    } else {
      onSignIn({...values});
    }
  };

  const emailAuthError = authError && authError.message;
  const passwordAuthError = authError && authError.password;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}> 
      {
        ({ values, handleSubmit }) => (
          <form className={className} onSubmit={handleSubmit}>
            <FormikInput 
              mandatory
              key="email"
              id="email"
              name="email"
              label="email"
              type="text"
              offMultiline={true}
              value={values["email"]}
              apiError={emailAuthError}
            />
            <FormikInput
              mandatory
              key="pasword"
              type="password"
              offMultiline={true}
              id="password"
              name="password"
              label="password"
              value={values["password"]}
              apiError={passwordAuthError}
            />
            <Button
              type="submit"
              className="wideButton"
              disabled={pending === true || isHuman === false}>
              Log in
            </Button>
            <span className={classes.forgotPassword}>
              <Link to="/forgot_password" className={classes.forgotPasswordLink}>
                Forgot your password?
              </Link>
            </span>
          </form>
        )
      }
    </Formik>
  );
};

SignInForm.defaultProps = {
  authError: null,
  className: '',
  onSignIn: () => null,
};

SignInForm.propTypes = {
  authError: PropTypes.shape({}),
  className: PropTypes.string,
  onSignIn: PropTypes.func,
};

export default SignInForm;
