// Core
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import MUIDataTable, {TableFilterList} from "mui-datatables";
import {ToastContainer, toast} from 'react-toastify';
// @material-ui
import Chip from '@material-ui/core/Chip';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MomentUtils from '@date-io/moment';
import {makeStyles} from '@material-ui/core/styles';
// Components
import Button from 'components/CustomButton';
import CustomTableFilter from 'components/CustomTableFilter/CustomTableFilter';
import EmptyPage from 'components/EmptyPage';
import IconButton from 'components/IconButton';
import Pagination from 'components/Pagination';
import ProjectCard from 'components/Project/Card';
import PageTitleSection from 'components/PageTitleSection';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
// Context
import {ServicesContext} from 'context/ServicesContext';
import {UserContext} from 'context/UserContext';
// Instruments
import {fetchProjects, downloadCsv} from 'api/projects';
// Helpers
import {getLocationQueryVariable} from 'helpers';
// Constants
import {
  ROLE_WASP_ADMIN,
  ROLE_ADMIN,
  ROLE_USER,
  CS_SERVICE,
  SI_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
  PROJECT_STATUSES,
  PROJECT_LEVELS,
} from 'utils';

const useStyles = makeStyles( theme => ({
  rowPaging: {
    '& td': {
      paddingBottom: 10,
      margin: 0,
    },
    '&:nth-of-type(4n) td': {
      paddingBottom: 0,
    },
  },

  customTableToolbarOrdering: {
    order: '-1',
  },

  customFilterFooter: {
    /* position: 'fixed',
    bottom: 0,
    padding: '12px 24px 24px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '408px', */
  },

  typeServiceFiltered: {
    color: 'var(--white)',
    backgroundColor: 'var(--primary-regular)',
    height: 32,
    boxShadow: 'none',
    fontSize: 14,
    lineHeight: 'normal',
    margin: '5px 10px 20px 0',
    fontWeight: 500,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'var(--primary-hover)',
    },
    '&.MuiChip-deletable.MuiChip-outlinedPrimary:focus': {
      backgroundColor: 'var(--primary-hover)',
    },
    '& .MuiChip-deleteIcon': {
      width: '1em',
      height: '1em',
      color: 'var(--white)',
      fill: 'var(--white)',
      fontSize: 10,
      margin: '0 10px 0 1px',
    },
    '&.MuiChip-deletable.MuiButton-outlinedPrimary': {
      color: 'var(--primary-regular)',
      backgroundColor: 'var(--white)',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 278
    }
  },

  inputDatePicker: {
    marginTop: '10px',
    marginBottom: '10px',
    border: '1px solid var(--black-20)',
    padding: '0px 10px',
    minHeight: '24px',
    fontSize: '14px',
    color: 'var(--black-100)',
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: '4px',
    whiteSpace: 'normal',
    '&:focus': {
      borderRadius: '4px',
    },
    '&.MuiInput-underline:before': {
      border: 'none !important',
    },
    '&.MuiInput-underline:after': {
      border: 'none !important',
    },
    '&.Mui-disabled': {
      borderColor: 'var(--black-20)',
    },
    '& .MuiInputBase-input': {
      height: 'auto',
      fontWeight: 600,
    },
  },

  placeholderDatePicker: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
  },

  filterCustomReset: {
    margin: 0,
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
  },

  iconCalendar: {
    marginRight: '7px',
    marginTop: '-2px',
    color: 'var(--primary-regular)',
  },
  filterApplied: {
    color: '#ed7916 !important',
    position: 'absolute',
    fontSize: '8px',
    margin: '0 0 13px 13px'
  }
}));

const ProjectsDashboardPage = () => {
  const [dataForTable, setDataForTable] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [wasSetService, setWasSetService] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  const [countriesNames, setCountriesNames] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const classes = useStyles();
  const {push} = useHistory();
  const {loggedInUser} = useContext(UserContext);
  const {services} = useContext(ServicesContext);
  const {execute: getProjectsByFilter, pending: filterPending, value: projectsByFilter} = useAsync(
    fetchProjects,
    true,
  );
  const location = useLocation();
  const serviceType = parseInt(getLocationQueryVariable(location, "type"));

  if (serviceType) {
    const userSubscriptions = loggedInUser && loggedInUser.role_id !== ROLE_WASP_ADMIN && loggedInUser.subscriptions.map(
      (subItem) => {
        return subItem.id;
      });
    if (userSubscriptions && !userSubscriptions.includes(serviceType)) {
      toast.error("You have no permission to the project type", {autoClose: 5000});
      push("/projects");
    }
  }

  const addTypeToLink = serviceType ? "?type=" + serviceType : "";
  const isEmptyUserPermissions = loggedInUser && (loggedInUser.subscriptions === null && loggedInUser.role_id !== ROLE_WASP_ADMIN);
  const letUserToCreateProjects = loggedInUser && [ROLE_ADMIN, ROLE_USER].includes(loggedInUser.role_id);
  const allProjectsURL = (location.pathname).endsWith("/") ? (location.pathname).slice(0, -1) : location.pathname;

  let startedFilterParameters = {page: currentPage};
  if ([CS_SERVICE, EDD_SERVICE, PES_SERVICE, SI_SERVICE, LEGAL_COMP_SERVICE, TECH_INTELLI_SERVICE, PARTNER_SEARCH_SERVICE, TRENDS_INTELIGENCE_SERVICE].includes(serviceType)) {
    startedFilterParameters = {
      "type": serviceType,
      page: currentPage
    };
  }
  ;

  useEffect(() => {
    if (isEmptyUserPermissions || !services) return;
    if (!filterPending) {
      if (!isFiltered) {
        getProjectsByFilter(startedFilterParameters);
        setIsFiltered(true);
      }
      if (!filterPending && projectsByFilter) {
        setNumberOfProjects(projectsByFilter.number_of_all_projects);
        setCountriesNames(projectsByFilter.countries);
        setDataForTable(projectsByFilter.projects_per_page);
      }
    }
    // eslint-disable-next-line
  }, [
    isEmptyUserPermissions,
    startedFilterParameters,
    filterPending,
    projectsByFilter,
    currentPage,
    setDataForTable,
    getProjectsByFilter,
    setIsFiltered
  ]);

  let servicesIds = [];
  let servicesItems = {};
  if (services) {
    Object.keys(services).map(key => {
      const service_id = services[key].id;
      servicesIds.push(service_id);
      const name = services[key].name;
      return servicesItems[service_id] = name;
    });
  }

  const filterData = [
    [],
    [...servicesIds],
    [...PROJECT_STATUSES],
    [], [], [],
    [...PROJECT_LEVELS],
    [],
    [...countriesNames],
    [], [], []
  ];

  const CustomFilterListComponent = (props) => {
    const filterNotApplied = props.filterList.every(item => item.length === 0);
    setIsFilterApplied(!filterNotApplied);
    let countFilledList = 0;
    Object.keys(props.filterList).map(key => {
      if (projectsTableColumns[key].options.filter) {
        const value = props.filterList[key];
        if (value.length > 0)
          countFilledList += 1;
      }
      return true;
    });
    return (<>
      <TableFilterList {...props} />
      {countFilledList > 0 &&
      <div className={classes.filterCustomReset}>
        <Chip
          color="primary"
          variant="outlined"
          deleteIcon={<Icon className="fa fa-times hidden"/>}
          className={clsx("MuiButton-outlinedPrimary", classes.typeServiceFiltered)}
          label="Clear All"
          onClick={() => {
            props.options.onFilterChange("clear_all", props.filterList, 'chip', props.options.onFilterChange);
          }}
          onDelete={() => {
            props.options.onFilterChange("clear_all", props.filterList, 'chip', props.options.onFilterChange);
          }}
        />
      </div>
      }
      <Chip
        className="set-type-btn hidden"
        label="set type"
        onClick={() => {
          props.options.onFilterChange("set_type", props.filterList, 'chip', props.options.onFilterChange);
        }}
      />
    </>);
  };

  const getFormatDate = (date) => {
    date = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    return date;
  }

  // Some code for pagination
  const CustomTablePagination = (props) => {
    return (
      <Pagination {...props} />
    );
  };

  const handlerChangePage = (page, columns, filterList) => {
    let parameters = {page: page};
    if (filterList) {
      filterList.map((item, index) => {
        if (item.length !== 0) {
          let columName = columns[index].name;
          if (columName !== "req_date") {
            parameters[columName] = item;
          } else {
            if (item[0]) {
              let dateObject = new Date(item[0]);
              parameters[columName + '_from'] = getFormatDate(dateObject);
            }
            if (item[1]) {
              let dateObject = new Date(item[1]);
              parameters[columName + '_to'] = getFormatDate(dateObject);
            }
          }
        }
        return true;
      });
      setCurrentPage(page);
      getProjectsByFilter(parameters);
    }
  };

  const CustomTableFilterComponent = (props) => {
    let newProps = Object.assign({}, props);
    return (
      <CustomTableFilter {...newProps} filterData={filterData}/>);
  };

  const CustomTooltipComponent = (props) => {
    return (<Tooltip {...props} arrow={true}/>);
  };

  const createNewRequest = () => {
    push(`/projects/new-project${addTypeToLink}`);
  };

  const customToolbarFunction = () => {
    return (
      <React.Fragment>
        {letUserToCreateProjects &&
        <Button
          className={classes.customTableToolbarOrdering}
          onClick={createNewRequest}
          disabled={isEmptyUserPermissions}>
          Create a project
        </Button>
        }
      </React.Fragment>
    );
  };

  const customFilterDialogFooter = (currentFilterList, applyFilters, resetFilters, handleClose) => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.customFilterFooter}>
        <Grid container item xs={3}>
          <Button
            variant="outlined"
            onClick={() => {
              if (!!resetFilters) resetFilters();
              getProjectsByFilter(startedFilterParameters);
              setFilterParameters({});
              setIsFiltered(false);
              if (!!handleClose) handleClose();
              if (serviceType > 0) push(allProjectsURL);
            }}>
            Clear All
          </Button>
        </Grid>
        <Grid container item xs={9} justify="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              if (!!handleClose) handleClose();
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleFilterSubmit(applyFilters);
              //TODO loadItemsPage(0);
            }}>
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  };

  const downloadBtnClick = () => {
    downloadCsv(filterParameters || startedFilterParameters);
  }

  const DownloadIcon = (props) => {
    return (
      <IconButton inverted="true" component="span">
        <Icon {...props} className="fas fa-file-download" color="primary"/>
      </IconButton>
    );
  };

  const FilterIcon = (props) => {
    return (
      <IconButton inverted="true" component="span">
        <Icon {...props} className="fas fa-filter" color="primary"/>
        {isFilterApplied &&
        <Icon {...props} className={clsx("fas fa-circle", classes.filterApplied)}/>
        }
      </IconButton>
    );
  };

  let iconCalendar = (<Icon className={clsx("fas fa-calendar-alt ", classes.iconCalendar)}/>);

  const projectsTableColumns = [
    {
      name: "id",
      label: "Project ID",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "service_id",
      label: "Project Type",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "checkbox",
        filterOptions: {
          fullWidth: true,
          renderValue: v => {
            return servicesItems[v] ? servicesItems[v] : v;
          },
        },
        customFilterListOptions: {
          render: v => {
            if (parseInt(v) > 0) {
              return servicesItems[v] ? `Project Type: ${servicesItems[v]}` : `${v}`;
            }
            return false;
          },
        },
      }
    },
    {
      name: "status",
      label: "Project Status",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "multiselect",
        customFilterListOptions: {
          render: v => {
            if (v) {
              return `Status: ${v}`;
            }
            return false;
          },
        },
        filterOptions: {
          fullWidth: true,
          renderChipedSelectedValue: true,
          noneValueText: "All statuses"
        },
      }
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        download: false,
        display: false,
        filter: false,
      }
    },
    {
      name: "subject_name",
      label: "Key Subject",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "account_name",
      label: "Account Name",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "level",
      label: "Level",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "multiselect",
        customFilterListOptions: {
          render: v => {
            if (v) {
              return `Level: ${v}`;
            }
            return false;
          },
        },
        filterOptions: {
          fullWidth: true,
          renderChipedSelectedValue: true,
          noneValueText: "All levels"
        },
      }
    },
    {
      name: "humint",
      label: "HUMINT",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "country",
      label: "Country",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "multiselect",
        customFilterListOptions: {
          render: v => {
            if (v) {
              return `Country: ${v}`;
            }
            return false;
          },
        },
        filterOptions: {
          fullWidth: true,
          renderChipedSelectedValue: true,
          noneValueText: "All countries"
        },
      }
    },
    {
      name: "due_date",
      label: "Due Date",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "req_date",
      label: "Launch Date",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              return `Launch Date: ${moment(v[0]).format('DD MMM YYYY')} - ${moment(v[1]).format('DD MMM YYYY')}`;
            } else if (v[0]) {
              return `Launch Date: from ${moment(v[0]).format('DD MMM YYYY')}`;
            } else if (v[1]) {
              return `Launch Date: to ${moment(v[1]).format('DD MMM YYYY')}`;
            }
            return false;
          },
        },
        filterOptions: {
          fullWidth: true,
          showLabel: true,
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            check.setDate(check.getDate() + 1);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            check = new Date(check).setHours(0, 0, 0, 0);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);
            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant="inline"
                  disableFuture={true}
                  disableToolbar={true}
                  emptyLabel=""
                  label=""
                  defaultValue={null}
                  name="launchDateFrom"
                  format="DD MMM YYYY"
                  value={filterList[index][0] ? moment(filterList[index][0]).format('DD MMM YYYY') : null}
                  placeholder="Select date from"
                  InputProps={{
                    className: clsx({
                      [classes.inputDatePicker]: true,
                      [classes.placeholderDatePicker]: !filterList[index][0]
                    }),
                    startAdornment: iconCalendar,
                  }}
                  onChange={date => {
                    filterList[index][0] = date._d;
                    onChange(filterList[index], index, column);
                  }}
                  style={{width: '49%', marginRight: '1%'}}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant="inline"
                  disableFuture={true}
                  disableToolbar={true}
                  emptyLabel=""
                  label=""
                  name="launchDateTo"
                  format="DD MMM YYYY"
                  value={filterList[index][1] ? moment(filterList[index][1]).format('DD MMM YYYY') : null}
                  placeholder="Select date to"
                  InputProps={{
                    className: clsx({
                      [classes.inputDatePicker]: true,
                      [classes.placeholderDatePicker]: !filterList[index][1]
                    }),
                    startAdornment: iconCalendar,
                  }}
                  onChange={date => {
                    filterList[index][1] = date._d;
                    onChange(filterList[index], index, column);
                  }}
                  style={{width: '49%', marginLeft: '1%'}}
                />
              </MuiPickersUtilsProvider>
            </div>
          ),
        },
      } // options
    },
    {
      name: "account_id",
      label: "Account id",
      options: {
        download: false,
        display: false,
        filter: false,
      }
    },
    {
      name: "country_code",
      label: "Country code",
      options: {
        download: false,
        display: false,
        filter: false,
      }
    },
  ];

  const tableTextLabels = {
    body: {
      noMatch: "Sorry, no matching records found"
    },
    toolbar: {
      downloadCsv: "Export projects",
      filterTable: "Filter projects",
    },
    filter: {
      all: "All",
      title: "Filters",
    },
  };

  const customRowRenderFunction = (colData, rowIndex) => {
    if (filterPending) {
      return (
      <tr key={rowIndex}>
        <td><Spinner/></td>
      </tr>
      );
    }

    if (isFiltered && !projectsByFilter) {
      if (rowIndex === 0) {
        return (letUserToCreateProjects
            ? <EmptyPage
              key={rowIndex}
              height={65}
              hasButton={true}
              handleClick={createNewRequest}
              title="project"
              disabled={isEmptyUserPermissions}/>
            : <EmptyPage height={65} title="project" key={rowIndex}/>
        );
      }
      return ('');
    }

    const [id, service_id, status, priority, subject_name, account_name,
      level, humint, country, due_date, req_date, account_id, country_code] = colData;
    const obProject = {
      'id': id,
      'service_id': service_id,
      'service_name': servicesItems && servicesItems[service_id] ? servicesItems[service_id] : "",
      'status': status,
      'priority': priority,
      'subject_name': subject_name,
      'account_name': account_name,
      'level': level,
      'country': country || "",
      'humint': humint,
      'due_date': due_date,
      'req_date': req_date,
      'account_id': account_id,
      'country_code': country_code || ""
    };
    var linkToCard = `/projects/${id}`;
    if (loggedInUser && loggedInUser.role_id === ROLE_WASP_ADMIN) {
      linkToCard = `/cc-dashboard/${id}`;
    }
    if (status === 'Draft') {
      linkToCard = `/projects/new-project/${id}`;
    }
    return (
      <tr key={rowIndex} className={classes.rowPaging}>
        <td colSpan={0}>
          <ProjectCard key={id} to={linkToCard} variantTiny={true} {...obProject} />
        </td>
      </tr>
    );
  };

  const handleFilterChange = (changedColumn, filterList, type, onFilterChange = () => {
  }) => {
    if (type === 'chip' && changedColumn === 'clear_all') {
      Object.keys(filterList).map(key => {
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter && value.length > 0) {
          const name = projectsTableColumns[key].name;
          filterList[key] = []; // clear all
          onFilterChange(name, filterList, 'chip');
        }
        return true;
      });
      if (serviceType > 0) push(allProjectsURL);
    } else if (type === 'chip' && changedColumn === 'set_type') {
      Object.keys(filterList).map(key => {
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter && value.length === 0) {
          const name = projectsTableColumns[key].name;
          if (name === 'service_id' && [CS_SERVICE, EDD_SERVICE, PES_SERVICE, SI_SERVICE, LEGAL_COMP_SERVICE, 
            TECH_INTELLI_SERVICE, PARTNER_SEARCH_SERVICE, TRENDS_INTELIGENCE_SERVICE].includes(serviceType)) {
            filterList[key] = [serviceType];
            onFilterChange(name, filterList, 'chip');
          }
        }
        return true;
      });
    }

    if (typeof changedColumn === 'string') {
      let parameters = {};
      Object.keys(filterList).map(key => {
        const name = projectsTableColumns[key].name;
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter && value.length > 0) {
          if (name !== "req_date") {
            parameters[name] = value;
          } else {
            if (value[0]) {
              let dateObject = new Date(value[0]);
              parameters[name + '_from'] = getFormatDate(dateObject);
            }
            if (value[1]) {
              let dateObject = new Date(value[1]);
              parameters[name + '_to'] = getFormatDate(dateObject);
            }
          }
        }
        return true;
      });
      setFilterParameters(parameters);
      setCurrentPage(1);
      getProjectsByFilter({...parameters, page: 1});
      setIsFiltered(true);
    }
  };

  const handleFilterSubmit = (applyFilters) => {
    if (!!applyFilters) {
      let filterList = applyFilters();
      let parameters = {};
      Object.keys(filterList).map(key => {
        const name = projectsTableColumns[key].name;
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter) {
          if (value.length > 0) {
            if (name === "req_date") {
              if (value[0]) {
                let dateObject = new Date(value[0]);
                parameters[name + '_from'] = getFormatDate(dateObject);
              }
              if (value[1]) {
                let dateObject = new Date(value[1]);
                parameters[name + '_to'] = getFormatDate(dateObject);
              }
            } else {
              parameters[name] = value;
            }
          }
        }
        return true;
      });
      setCurrentPage(1);
      getProjectsByFilter({...parameters, page: 1});
      if (!filterPending) {
        setFilterParameters(parameters);
        setIsFiltered(true);
      }
    }
  };

  const handleBackdrop = () => {
    let filter = document.querySelector('.customized-filter');
    let filterParent = filter && filter.parentElement;
    let rolePresentation = filterParent && filterParent.parentElement;
    let filterBackdrop = rolePresentation && rolePresentation.querySelector('div[aria-hidden="true"]');
    if (filterBackdrop) {
      filterBackdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.34)';
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleBackdrop);
    return () => {
      document.removeEventListener('click', handleBackdrop)
    }
  }, [])

  const renderContent = dataForTable ? (
    <MUIDataTable
      title={<Typography variant="h1">Dashboard</Typography>}
      data={dataForTable}
      columns={projectsTableColumns}
      className={classes.projectsTableStyle}
      options={{
        responsive: "vertical",
        selectableRows: "none",
        download: true,
        pagination: true,
        count: numberOfProjects,
        page: currentPage,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        tableBodyHeight: "100%",
        filter: true,
        filterType: "dropdown",
        confirmFilters: true,
        showStandardResetButton: false,
        onDownload: () => {
          downloadBtnClick();
          return false;
        },
        onTableChange: (action, tableState) => {
          if (action === 'changePage') {
            handlerChangePage(tableState.page, tableState.columns, tableState.filterList);
          }
        },
        customFilterDialogFooter: customFilterDialogFooter,
        setFilterChipProps: () => {
          return {
            color: "primary",
            variant: "contained",
            deleteIcon: <Icon className="fa fa-times"/>,
            className: clsx("MuiButton-containedPrimary", classes.typeServiceFiltered),
          };
        },
        textLabels: tableTextLabels,
        onFilterChange: handleFilterChange,
        serverSide: true,
        customToolbar: customToolbarFunction,
        rowsPerPage: 20,
        rowsPerPageOptions: [20],
        onChangePage: handlerChangePage,
        customRowRender: customRowRenderFunction,
      }}
      components={{
        TableFooter: CustomTablePagination,
        TableFilter: CustomTableFilterComponent,
        TableFilterList: CustomFilterListComponent,
        Tooltip: CustomTooltipComponent,
        icons: {
          DownloadIcon,
          FilterIcon,
        },
      }}
    />
  ) : (
    <React.Fragment>
      <PageTitleSection title="Dashboard">
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center">
          {letUserToCreateProjects &&
          <Button
            className="mr-0"
            onClick={createNewRequest}
            disabled={isEmptyUserPermissions}>
            Create a project
          </Button>
          }
        </Grid>
      </PageTitleSection>
      {filterPending ? <Spinner/> : (
        letUserToCreateProjects ? <EmptyPage
            height={65}
            hasButton={true}
            handleClick={createNewRequest}
            title="project"
            disabled={isEmptyUserPermissions}/>
          : <EmptyPage height={65} title="project"/>
      )}
    </React.Fragment>
  );

  let setTypeButton = document.querySelector('.set-type-btn');
  if (setTypeButton && !wasSetService) {
    setWasSetService(true);
    setTypeButton.click();
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems={(projectsByFilter ? "stretch" : "center")}
        style={{minHeight: '60vh', display: "block"}}>
        {renderContent}
      </Grid>
      <ToastContainer/>
    </Grid>
  );
};

export default ProjectsDashboardPage;
