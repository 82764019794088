// Core
import React, { Fragment } from 'react';
// @material-ui
import Button from 'components/CustomButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// Components
import UbosForm from './Forms/UbosForm';

const emptyUboObject = {
  name: '',
  shares: '',
  registered_number: '',
  date: null,
  citizenship: '',
  second_citizenship: '',
};

const UbossStep = ({
  ubos,
  onSetFieldValue,
  countryMenuItems,
}) => {
  const deleteOneUbo = (index) => {
    let updatedUbos = ubos.map(s => {return s});
    updatedUbos.splice(index, 1);
    onSetFieldValue('ubos', updatedUbos);
  };

  const addOneUbo = () => {
    let updatedUbos = ubos.map(s => {return s});
    updatedUbos.push(emptyUboObject);
    onSetFieldValue('ubos', updatedUbos);
  };

  return (
    <Grid
      container
      alignItems="stretch"
      direction="row">
      <Grid item>
        {ubos?.map((item, index) => {
          return (
            <Fragment key={index}>
              {index !== 0 && <Divider className="minus-m-25" />}
              <UbosForm
                index={index}
                ubo={item}
                onSetFieldValue={onSetFieldValue}
                deleteOneUbo={deleteOneUbo}
                countryMenuItems={countryMenuItems}
              />
            </Fragment>
          )
        })}
      </Grid>
      <Grid 
        item
        xs={12}
        style={{display: "flex", justifyContent: "flex-end"}}>
        <Button 
          className="mr-0 mt-0"
          onClick={addOneUbo}
          variant="outlined">
          Add Ultimate Beneficial Owner
        </Button>
      </Grid>
    </Grid>
  )
};

export default UbossStep;
