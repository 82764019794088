// Core
import React from 'react';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
// Components
import Button from 'components/CustomButton';

const useStyles = makeStyles({
  card: {
    width: '100%',
    borderRadius: 4,
  },

  root: {
    padding: 20,
  },

  withoutPadding: {
    padding: '0 0 10px 0',
  },

  bordered: {
    border: `1px solid var(--black-20)`,
  },
  
  unbordered: {
    border: 'none'
  }
});

const DetailsCard = ({ showTitle=true, title, hasButton, hasPadding=true, addUserToggle, children, bordered, justifyPosition="center", style="" }) => {
  const classes = useStyles();

  return (
    <Grid 
      className={clsx(classes.card, style, {
        [classes.unbordered]: !bordered,
        [classes.bordered]: bordered
      })}
      container
      direction="column"
      justify="center"
      alignItems="stretch">
        {showTitle ? (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={clsx({
              [classes.withoutPadding]: !hasPadding,
              [classes.root]: hasPadding})}>
              <Typography variant="h3">
                {title}
              </Typography>
              {hasButton ? (
                <Button
                    style={{ alignSelf: 'flex-end', width: 125,}}
                    onClick={addUserToggle}>
                    Add New User
                </Button>) : "" }
          </Grid>
        ) : "" }
        <Grid
          container
          justify={justifyPosition}
          alignItems="center">
            {children}
        </Grid>
    </Grid>
  );
};

export default DetailsCard;
