// Core
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  buttonsArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
  },
  scrollStyle: {
    scrollbarWidth: "thin",
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    }
  }
});

const DrawerDialog = props => {
  const classes = useStyles();
  const { openPage, closePage, title, onSubmit, children } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    closePage();
    setOpen(false);
  }, [closePage]);

  useEffect(() => {
    if (openPage) {
      handleClickOpen();
    } else {
      handleClose();
    }
  }, [openPage, handleClickOpen, handleClose]);

  const DialogTitle = ((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h2" style={{position: 'relative'}}>
          {children}
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose} style={{position: 'absolute', right: 0, top: -8}}>
              <Icon className="fa fa-times" />
            </IconButton>
          ) : null}
        </Typography>
      </MuiDialogTitle>
    );
  });

  return (
    <Dialog className="drawer-page" open={open}>
      <DialogTitle disableTypography className="drawer-page-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.scrollStyle}>
        {children}
      </DialogContent>
      <DialogActions className={classes.buttonsArea}>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DrawerDialog.defaultProps = {
  openPage: false,
  title: '',
  onSubmit: () => null,
  children: '',
};

DrawerDialog.propTypes = {
  openPage: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default DrawerDialog;
