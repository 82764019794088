import React, { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { signIn, logout } from 'api/auth';

const AuthContext = createContext();

const AuthProvider = AuthContext.Provider;

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(AuthContext);

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const { push } = useHistory();
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [pending, setPending] = useState(false);
  const [isHuman, setIsHuman] = useState(true);

  const signin = credentials => {
    setPending(true);
    return signIn(credentials)
      .then(({ data }) => {
        const { access_token, refresh_token } = data;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        setAccessToken(access_token);
        setRefreshToken(refresh_token);
        setError(null);
        setPending(false);
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response);
          setPending(false);
          if (err.response.status >= 500) {
            const errorMsg = `${err.response.status} ${err.response.statusText}`;
            setError(errorMsg);
            enqueueSnackbar(errorMsg, {
              variant: 'error',
            });
            return errorMsg;
          } else if (err.response.status === 423) {
            setIsHuman(false);
          }

          const error = err.response.data.errors;
          setError(error);
          return error;
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
        }
        console.log(err.config);
      }
    );
  };

  const signout = () => {
    return logout().then((resp) => {
      setAccessToken(null);
      setRefreshToken(null);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      localStorage.removeItem('expiredTime');
      if (resp.status === 200) {
        push('/sign_in');
      }
    }).catch(reason => {
      setAccessToken(null);
      setRefreshToken(null);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      localStorage.removeItem('expiredTime');
      push('/sign_in');
    });
  };

  // Returns token and auth methods
  return {
    accessToken,
    refreshToken,
    error,
    pending,
    isHuman,
    signin,
    signout,
  };
};

// Provider component that wraps app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();

  return <AuthProvider value={auth}>{children}</AuthProvider>;
};

ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
