// Core
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components 
import Button from 'components/CustomButton';
import FormInfoSection from 'components/FormInfoSection';
import Textarea from 'components/Textarea';

const initialValues = {
  text: '',
};

const validationSchema = Yup.object().shape({
  text: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .transform((value) => value.trim())
    .required('Required'),
});

const LeaveCommentForm = ({sendingComment, onSendComment, onDeleteComment, commentValue}) => {
  const commentInitialValue = commentValue
    ? {
        text: commentValue.text
      }
    : initialValues;
  
    const onSubmit = (values, setSubmitting) => {
      setSubmitting(sendingComment);
      onSendComment(values);
    };

  return (
    <Formik
      initialValues={commentInitialValue || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({
        values,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormInfoSection 
              text="Enter text here"
              isCapitalize={false}
            />
            <Textarea
              id="text"
              name="text"
              value={values.text}
            />    
            <Grid container justify="flex-end">
              <Button
                variant="outlined"
                onClick={handleReset}>
                  Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || sendingComment}
                className="mr-0">
                  Send
              </Button>
            </Grid>                                      
          </form>
        );
      }}
    </Formik>
  )
}

export default LeaveCommentForm;
