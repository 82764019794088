// Core
import clsx from 'clsx';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useField } from 'formik';
import InputMask from "react-input-mask";
// @material-ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Input = withStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
    '&.withDescription': {
      marginTop: 25,
    },
    '& label': {
      fontSize: 14,
      textTransform: 'capitalize',
      color: 'var(--black-60)',
      transform: 'none',
      top: '-20px',
      pointerEvents: 'auto',
      '& span.fa': {
        display: 'inline',
      },
    },
    '&.withDescription label': {
      top: '-43px',
    },
    '& .MuiInputBase-input': {
      padding: '6px 12px',
      color: 'var(--black-100)',
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      overflow: 'hidden',
      padding: 0,
      fontFamily: 'inherit',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      '& fieldset': {
        borderColor: 'var(--black-20)',
      },
      '&:focus': {
        borderColor: 'var(--input-active-border)',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: 'var(--input-active-border)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--input-active-border)',
        border: '1px solid',
        borderWidth: 1,
      },
      '&.Mui-disabled': {
        background: 'var(--input-disabled-border)',
        '& fieldset': {
          borderColor: 'var(--black-20)',
        },
        '&:hover fieldset': {
          borderColor: 'var(--black-20)',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: '2px 12px 3px',
    },
    '& .MuiOutlinedInput-multiline': {
      '& .MuiInputBase-inputMultiline': {
        minHeight: '18px !important',
        lineHeight: '18px !important'
      }
    }
  },
})(TextField);

const useStyles = makeStyles({
  startAdornment: {
    marginRight: 36,
  },
  uncapitallyze: {
    textTransform: 'none !important'
  },
  description: {
    fontSize: '10px',
    color: 'var(--black-60)',
    paddingTop: 2,
    textTransform: 'none',

    '& .MuiInputBase-root': {
      marginTop: '26px !important',
    },
  },

  openedEye: {
    color: 'var(--primary-regular)',
  },

  endAdornmentCustom: {
    backgroundColor: 'var(--input-regular-border)',

    '& .fa': {
      color: 'var(--black-40)',

      '&.fa-pwd': {
        width: '25px',
        height: 'auto',
        fontSize: '10px',
      },
    },
  },

  endAdornmentCustomPwd: {
    borderLeft: 'none',

    '& .fa': {
      color: 'var(--black-40)',

      '&.fa-pwd': {
        width: '25px',
        height: 'auto',
        fontSize: '10px',

        '& .MuiSvgIcon-root': {
          fontSize: '1.1rem',
        },
      },
    },
  },

  label: {
    fontWeight: 600,
    color: 'var(--black-100)',
    fontSize: 14,
  },
});

const iconStyles = {
  selectIcon: {
    position: 'absolute',
    right: 15,
    top: 'calc(50% - 10px)',
    width: '10px',
    fontSize: 14,
    '&.MuiSelect-iconOpen': {
      top: 'calc(50% - 5px)',
    },
  },
};

const customizedSelectArrowIcon = withStyles(
  iconStyles,
)(({ className, classes, ...rest }) => (
  <Icon
    {...rest}
    className={classnames("fa fa-sort-down", className, classes.selectIcon)}
  />
));

const FormikInput = ({
  id,
  type,
  name,
  select,
  label,
  description,
  disabled,
  mandatory,
  mask = '',
  uncapitalized,
  offMultiline,
  placeholder,
  customAdornment,
  style,
  apiError,
  children,
  className = "",
  onChange,
  hint = ""
}) => {
  const classes = useStyles();
  const [field, meta] = useField(name);
  const [showValue, setShowValue] = useState(type !== 'password');
  function handleShowPassword() {
    setShowValue(!showValue);
  }

  const inputType = (type === 'search') ? 'search' : (showValue ? 'text' : 'password');
  const inputDisabled =
    disabled || (select && children && children.length === 0);
  const error = meta.error && meta.touched;

  const helperText = error ? meta.error : <></>;

  const startAdornment = (type === 'search') && (
    <InputAdornment position="start">
      <Icon className="fa fa-search" />
    </InputAdornment>
  );

  const endAdornment =
    type === 'password' ? (
      <InputAdornment position="end" classes={{ positionEnd: classes.endAdornmentCustomPwd }}>
        <Icon className="fa fa-pwd" onClick={handleShowPassword}>
          {showValue ? <Visibility className={classes.openedEye} /> : <VisibilityOff />}
        </Icon>
      </InputAdornment>
    ) : customAdornment ? (
      <InputAdornment position="end" classes={{ positionEnd: classes.endAdornmentCustom }}>
        <Icon className={"fa fa-" + customAdornment} />
      </InputAdornment>
    ) : null;

  const drawHint = hint && (
    <Tooltip color="primary" title={hint}
      arrow={true} interactive={false}>
      <Icon className="fa fa-info-circle fa-sm" />
    </Tooltip>
  );

  if (mask && mask !== '') {
    return (
      <InputMask
        mask={mask}
        maskChar="_"
        {...field}
      >{() =>
        <Input
          fullWidth
          id={id}
          type={inputType}
          select={select}
          disabled={inputDisabled}
          variant="outlined"
          label={
            <div>
              {label + (mandatory ? ' * ' : ' ')}
              {drawHint}
            </div>
          }
          placeholder={placeholder}
          style={style}
          onChange={onChange}
          error={!!apiError || error}
          {...field}
          className={className}
          SelectProps={{
            IconComponent: customizedSelectArrowIcon,
          }}
          InputProps={{
            startAdornment,
            endAdornment,
          }}
          InputLabelProps={{
            style: { pointerEvents: "auto" },
            disableAnimation: true,
            shrink: false,
          }}
          helperText={apiError || helperText}>
          {children}
        </Input>
        }
      </InputMask>
    );
  }

  return (
    <Input
      fullWidth
      id={id}
      type={inputType}
      select={select}
      disabled={inputDisabled}
      variant="outlined"
      multiline={(inputType === 'password' || offMultiline === true) ? false : true}
      label={
        description ? (
          <div>
            <Typography className={classes.label}>{label}</Typography>
            <Typography className={classes.description}>{description}</Typography>
          </div>
        ) : (
          <div className={uncapitalized ? classes.uncapitallyze : ""}>
            {label + (mandatory ? ' * ' : ' ')}
            {drawHint}
          </div>
        )
      }
      placeholder={placeholder}
      style={style}
      onChange={onChange}
      error={!!apiError || error}
      {...field}
      className={clsx(className, description ? "withDescription" : "")}
      SelectProps={{
        IconComponent: customizedSelectArrowIcon,
      }}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
      InputLabelProps={{
        style: { pointerEvents: "auto" },
        disableAnimation: true,
        shrink: false,
      }}
      helperText={apiError || helperText}>
      {children}
    </Input>
  );
};

FormikInput.defaultProps = {
  type: 'text',
  disabled: false,
  mandatory: false,
  offMultiline: false,
  placeholder: '',
  customAdornment: '',
  children: null,
  onChange: () => null,
  hint: '',
};

FormikInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  offMultiline: PropTypes.bool,
  placeholder: PropTypes.string,
  customAdornment: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  onChange: PropTypes.func,
  hint: PropTypes.string,
};

export default FormikInput;
