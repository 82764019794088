// Core
import React from 'react';
// @material-ui
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// Components
import DropdownMenu from 'components/DropdownMenu';


const ActionButtons = ({ match, ...props }) => {

  const handleBellClick = () => {
    console.log('bell clicked');
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{
        minWidth: 210,
      }}>
      <IconButton match={match} onClick={handleBellClick}>
        <Icon color="primary" className="fa fa-bell" />
      </IconButton>
      <DropdownMenu match={match} {...props} />
    </Box>
  );
};

export default ActionButtons;
