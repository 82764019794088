import React from 'react';

const LoupIcon = props => {
  const { color } = props;

  return (
    <svg
      width="67"
      height="70"
      viewBox="0 0 67 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.65 7.20001C16.8 7.20001 7.19995 16.8 7.19995 28.65C7.19995 40.5 16.8 50.1 28.65 50.1C40.5 50.1 50.1 40.5 50.1 28.65C50.1 16.8 40.5 7.20001 28.65 7.20001ZM28.65 47.71C18.14 47.71 9.59995 39.16 9.59995 28.66C9.59995 18.16 18.15 9.61 28.65 9.61C39.15 9.61 47.7 18.16 47.7 28.66C47.7 39.16 39.15 47.71 28.65 47.71Z"
        fill={color || '#FFF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.75 60.96L50.72 46.92C54.83 41.96 57.3 35.59 57.3 28.65C57.3 12.82 44.47 0 28.65 0C12.83 0 0 12.83 0 28.65C0 44.47 12.83 57.3 28.65 57.3C33.71 57.3 38.46 55.98 42.59 53.68L57.31 68.4C58.34 69.43 59.69 69.94 61.04 69.94C62.39 69.94 63.74 69.43 64.76 68.4C65.76 67.41 66.3 66.08 66.3 64.67C66.3 63.26 65.75 61.94 64.76 60.95L64.75 60.96ZM2.39 28.66C2.39 14.18 14.17 2.41 28.64 2.41C43.11 2.41 54.89 14.19 54.89 28.66C54.89 43.13 43.11 54.91 28.64 54.91C14.17 54.91 2.39 43.13 2.39 28.66ZM63.05 66.72C61.93 67.84 60.11 67.84 58.99 66.72L44.68 52.41C46.28 51.33 47.75 50.09 49.1 48.72L63.05 62.67C63.59 63.21 63.89 63.93 63.89 64.7C63.89 65.47 63.59 66.19 63.05 66.73V66.72Z"
        fill={color || '#FFF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3899 18.7C37.9199 18.61 37.4499 18.67 37.0199 18.83C36.9499 18.86 36.8799 18.8 36.8999 18.72L37.2099 17.05C37.3399 16.34 36.9199 15.66 36.2799 15.54L34.8099 15.27C34.6599 15.24 34.5099 15.33 34.4299 15.48C33.8999 16.5 32.9999 17.12 32.1099 16.95C31.2199 16.79 30.5999 15.89 30.4699 14.75C30.4499 14.58 30.3399 14.44 30.1899 14.41L29.1899 14.23C28.5399 14.11 27.9099 14.59 27.7799 15.31L27.4699 16.98C27.4599 17.06 27.3699 17.09 27.3199 17.04C26.7899 16.57 26.0799 16.3 25.2599 16.41C24.2599 16.54 23.3499 17.29 22.9599 18.31C22.2299 20.2 23.2599 22.18 24.9699 22.5C25.4399 22.59 25.9099 22.53 26.3399 22.37C26.4099 22.34 26.4799 22.4 26.4599 22.48L26.2099 23.86C26.0799 24.57 26.4999 25.25 27.1399 25.37L28.1399 25.55C28.2899 25.58 28.4399 25.49 28.5199 25.34C29.0499 24.32 29.9499 23.7 30.8399 23.87C31.7299 24.03 32.3499 24.93 32.4799 26.07C32.4999 26.24 32.6099 26.38 32.7599 26.41L34.2299 26.68C34.8799 26.8 35.5099 26.32 35.6399 25.6L35.8899 24.21C35.8999 24.13 35.9899 24.1 36.0399 24.15C36.5699 24.62 37.2799 24.89 38.0999 24.78C39.0999 24.65 40.0099 23.9 40.3999 22.88C41.1299 20.99 40.0999 19.01 38.3899 18.69V18.7Z"
        fill={color || '#FFF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2499 36.46C30.1699 36.45 30.1399 36.36 30.1999 36.3L31.3199 34.95C31.7999 34.37 31.7699 33.55 31.2399 33.12L30.0499 32.13C29.9299 32.03 29.7399 32.03 29.5999 32.13C28.6099 32.78 27.4799 32.88 26.7599 32.28C26.0399 31.68 25.9399 30.56 26.3899 29.46C26.4599 29.3 26.4299 29.12 26.3099 29.01L25.4999 28.34C24.9799 27.91 24.1599 28.02 23.6799 28.6L22.5599 29.95C22.5099 30.01 22.4199 30 22.3899 29.93C22.1499 29.23 21.6499 28.64 20.8499 28.32C19.8799 27.93 18.6899 28.15 17.8099 28.87C16.1899 30.21 16.1199 32.51 17.4999 33.66C17.8799 33.98 18.3299 34.16 18.7999 34.23C18.8799 34.24 18.9099 34.33 18.8499 34.39L17.9199 35.51C17.4399 36.09 17.4699 36.91 17.9999 37.34L18.8099 38.01C18.9299 38.11 19.1199 38.11 19.2599 38.01C20.2499 37.36 21.3799 37.26 22.0999 37.86C22.8199 38.46 22.9199 39.58 22.4699 40.68C22.3999 40.84 22.4299 41.02 22.5499 41.13L23.7399 42.12C24.2599 42.55 25.0799 42.44 25.5599 41.86L26.4899 40.74C26.5399 40.68 26.6299 40.69 26.6599 40.76C26.8999 41.46 27.3999 42.05 28.1999 42.37C29.1699 42.76 30.3599 42.54 31.2399 41.82C32.8599 40.48 32.9299 38.18 31.5499 37.03C31.1699 36.71 30.7199 36.53 30.2499 36.46Z"
        fill={color || '#FFF'}
      />
    </svg>
  );
};

export default LoupIcon;
