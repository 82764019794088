// Core
import React, {useContext} from 'react';
import PropTypes, { shape } from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// Components
import Button from 'components/CustomButton';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import FormikInput from 'components/FormikInput';
import Spinner from 'components/shared/Spinner';
// Context 
import { CountriesContext } from 'context/CountriesContext';

const useStyles = makeStyles({
  description: {
    fontSize: '10px',
    color: 'var(--black-60)'
  },
  label: {
    fontWeight: 600,
    color: 'var(--black-100)',
    fontSize: 14
  },
  innerMargin: {
    "& .MuiInputBase-root": {
      marginTop: "26px !important"
    },
  },
  industriesSelect: {
    '& .MuiOutlinedInput-root': {
      overflow: 'unset !important',
      height: 30,
    },
    '& .MuiInputBase-input': {
      position: 'absolute',
      maxWidth: '90%',
    }
  }
});

const AdviceForm = ({
  durations,
  industries,
  typeKeySubject,
  onChange,
  onSubmit,
  relationships,
  subjects,
  values,
}) => {
  const classes = useStyles();
  const { countryMenuItems } = useContext(CountriesContext);

  const durationMenuItems = durations &&
    durations.map(duration => {
      const {id, name, score} = duration;
      return (
        <MenuItem key={id} name={name} value={score}>
          {name}
        </MenuItem>
      );
    });
  
  const industryMenuItems = industries &&
    industries.map(industry => {
      const {id, name, score} = industry;
      return (
        <MenuItem key={id} value={`${score}_${id}`}>
          {name}
        </MenuItem>
      );
    });

  const relationshipMenuItems = relationships &&
    relationships.map(relationship => {
      const {id, name, score} = relationship;
      return (
        <MenuItem key={id} name={name} value={`${score}_${id}`}>
          {name}
        </MenuItem>
      );
    });

  return (
    (countryMenuItems && durations && industries && relationships && (
      <Grid 
        container
        direction="column"
        spacing={2}
        justify="space-between">
        <Grid 
          container
          direction="column"
          spacing={3}
          justify="space-between"
          className='p-2'>
          <Grid item>
            <Grid
              container
              direction="column"
              className="pb-3">
                <Typography className={classes.label}>Main Subject</Typography>
                <Typography className={classes.description}>Select main subject of your compliance checks.</Typography>
            </Grid>
            <Grid>
              <CustomRadioGroup
                className="pb-4"
                id="typeKeySubject"
                name="typeKeySubject"
                images={{
                  false: 'fa-city',
                  true: 'fa-user-tie',
                }}
                color="simple"
                variant="small"
                onChange={onChange}
                defaultValue={String(typeKeySubject)}
                fields={subjects}/>
            </Grid>
          </Grid>
          <Grid item>
            <FormikInput
              select
              mandatory
              id="countryCode"
              name="adviceFlow.country"
              value={values && values.country_code}
              label="Country"
              description="Country of operations we should focus on.">
                {countryMenuItems}
            </FormikInput>
          </Grid>
          <Grid item>
            <FormikInput
              select
              mandatory
              id="industry"
              name="adviceFlow.industry"
              offMultiline={true}
              value={values && values.industry}
              label="industry"
              className={classes.industriesSelect}
              description="Type of industries we should focus on.">
                {industryMenuItems}
            </FormikInput>
          </Grid>
          <Grid item>
            <FormikInput
              select
              mandatory
              id="Relationship"
              name="adviceFlow.relationship"
              value={values && values.relationship}
              label="Relationship"
              description="Type of the activities the subject conducts on your behalf.">
                {relationshipMenuItems}
            </FormikInput>
          </Grid>
          <Grid item>
            <FormikInput
              select
              mandatory
              id="Duration"
              name="adviceFlow.duration"
              value={values && values.duration}
              label="Duration"
              description="Duration of the partnership with the subject.">
                {durationMenuItems}
            </FormikInput>
          </Grid>
        </Grid>
        <Grid 
          container 
          item
          justify="flex-end"
          className="pt-0 pl-0">
            <Button
              className="mr-0"
              disabled={((!values.country || !values.duration || !values.industry || !values.relationship) && true) || false}
              onClick={() => {onSubmit(values)}}>
                Advice
            </Button>
        </Grid>
      </Grid>
    )) || <Spinner />
  )
}

AdviceForm.defaultProps = {
  values: null,
}

AdviceForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  typeKeySubject: PropTypes.bool,
  durations: PropTypes.arrayOf(shape({
    id: PropTypes.number,
    name: PropTypes.string,
    score: PropTypes.string,
  })),
  industries: PropTypes.arrayOf(shape({
    id: PropTypes.number,
    name: PropTypes.string,
    score: PropTypes.string,
  })),
  relationships: PropTypes.arrayOf(shape({
    id: PropTypes.number,
    name: PropTypes.string,
    score: PropTypes.string,
  })),
  subjects: PropTypes.arrayOf(shape({
    id: PropTypes.bool,
    name: PropTypes.string,
  })).isRequired,
  values: PropTypes.shape({
    country: PropTypes.string,
    industry: PropTypes.string,
    duration: PropTypes.string,
    relationship: PropTypes.string,
  }),
}

export default AdviceForm;
