// Core
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Components
import CustomSwitch from 'components/UI/CustomSwitch';
import DropdownStatuses from 'components/Statuses/DropdownStatuses';
import DueDateCard from 'components/DueDateCard';
import IconButton from 'components/IconButton';
// Context
import { ProjectStatusesContext } from 'context/ProjectStatusesContext';
import { UserContext } from 'context/UserContext';
// Constants
import {
  LEGAL_COMP_SERVICE,
  PARTNER_SEARCH_SERVICE,
  ROLE_WASP_ADMIN,
  TECH_INTELLI_SERVICE,
  TRENDS_INTELIGENCE_SERVICE
} from 'utils';
import { CS_SERVICE, SI_SERVICE } from 'utils';
import { PESTEL_ANALYSIS } from 'utils';

const useStyles = makeStyles({
  summaryFieldsWrapper: {
    '& button': {
      marginLeft: 0,
    },
  },

  dueDate: {
    marginBottom: '1rem',

    '&:hover': {
      '& button:last-child': {
        visibility: 'visible',
      },

      '& button:first-child': {
        border: '1px solid var(--primary-regular)',
        backgroundColor: 'var(--primary-regular)',
        color: 'var(--primary-disabled)',

        '& span': {
          color: 'var(--primary-disabled)',
        },
      },
    },

    '& button:last-child': {
      visibility: 'hidden',
    },
  },

  icon: {
    '&.MuiIcon-root': {
      width: 'unset',
      height: 'unset',
    },
  },

  innerSpan: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const ProjectSummaryBlock = props => {
  const classes = useStyles(props);
  const { projectStatuses } = useContext(ProjectStatusesContext);
  const { loggedInUser } = useContext(UserContext);
  const {
    project,
    onConfirm,
    osintValue,
    handleSetDueDate,
    newDueDate,
    handleUnsetSelectedDueDate,
    handleConfirmUpdateDueDate,
    datePickerOpened,
    handleSetSelectedStatus,
    handleDatePickerOpen,
  } = props;

  useEffect(() => {
    projectStatuses &&
      projectStatuses.map((item, index) => {
        if (item.name === 'Draft') {
          projectStatuses.splice(index, 1);
        }
        return true;
      });
  }, [projectStatuses]);

  const secondCard = project => {
    switch (parseInt(project.service_id)) {
      case CS_SERVICE:
        return (
          <Grid container item className="mb-3">
            <IconButton inverted="true" disabled>
              <Icon className="fa fa-angle-double-up" />
            </IconButton>
            <Typography variant="body2" className="text-truncate">
              <label>Level</label>
              <br />
              <span className="text-truncate">{project.level}</span>
            </Typography>
          </Grid>
        );
      case SI_SERVICE:
        return (
          <>
            <Grid container item className="mb-3">
              <IconButton inverted="true" disabled>
                <Icon
                  className={clsx(
                    classes.icon,
                    parseInt(project.config_id) === PESTEL_ANALYSIS
                      ? 'fas fa-binoculars'
                      : 'fas fa-glasses',
                  )}
                />
              </IconButton>
              <Typography variant="body2" className="text-truncate">
                <label>Analysis type</label>
                <br />
                <span className="text-truncate">
                  {project.analysis_type_name}
                </span>
              </Typography>
            </Grid>
            {osintValue !== null && (
              <Grid container item className="mb-3">
                <IconButton inverted="true" disabled>
                  <Icon className="fas fa-search-location" />
                </IconButton>
                <Typography variant="body2" className="text-truncate">
                  <label>OSINT</label>
                  <br />
                  <span className="text-truncate">
                    <CustomSwitch
                      checked={osintValue}
                      label={osintValue === true ? 'Activated' : 'Deactivated'}
                    />
                  </span>
                </Typography>
              </Grid>
            )}
          </>
        );
      case LEGAL_COMP_SERVICE:
        return (
          <></>
        );
      case TECH_INTELLI_SERVICE:
        return (
          <></>
        );
      case PARTNER_SEARCH_SERVICE:
        return (
          <></>
        );
      case TRENDS_INTELIGENCE_SERVICE:
        return (
          <></>
        );
      default:
        return (
          <Grid container item className="mb-3">
            <IconButton inverted="true" disabled>
              <Icon className="fas fa-phone-alt" />
            </IconButton>
            <Typography variant="body2" className="text-truncate">
              <label>HUMINT</label>
              <br />
              <span className="text-truncate">
                {project.humint === false ? 'Deactivated' : 'Activated'}
              </span>
            </Typography>
          </Grid>
        );
    }
  };

  return (
    <>
      {loggedInUser && loggedInUser.role_id === ROLE_WASP_ADMIN ? (
        <Typography variant="h3" className="pb-3 pl-0 pr-0">
          Change Project Status
        </Typography>
      ) : (
        <Typography variant="h3" className="pb-3 pl-0 pr-0">
          Project Status
        </Typography>
      )}
      <DropdownStatuses
        statuses={projectStatuses}
        project={project}
        editable={loggedInUser && loggedInUser.role_id === ROLE_WASP_ADMIN}
        onConfirm={onConfirm}
        onStatusChange={handleSetSelectedStatus}
      />
      <Typography variant="h3" className="pt-4 pb-3 pl-0 pr-0">
        General Summary
      </Typography>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.summaryFieldsWrapper}>
        <Grid container item className="mb-3">
          <IconButton color="primary" inverted="true" disabled>
            <Icon className="fa fa-user" />
          </IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>User Name</label>
            <br />
            <span className="text-truncate">{project.requester}</span>
          </Typography>
        </Grid>
        {secondCard(project && project)}
        {project && project.analysis_details && (
          <>
            <Grid container item className="mb-3">
              <IconButton inverted="true" disabled>
                <Icon className="fas fa-globe-europe" />
              </IconButton>
              <Typography variant="body2" className="text-truncate">
                <label>Country</label>
                <br />
                <span className="text-truncate">
                  {project.analysis_details.country_name}
                </span>
              </Typography>
            </Grid>
            <Grid container item className="mb-3">
              <IconButton inverted="true" disabled>
                <Icon className="fas fa-hand-holding-usd" />
              </IconButton>
              <Typography variant="body2" className="text-truncate">
                <label>CPI</label>
                <br />
                <span className="text-truncate">
                  {project.analysis_details.cpi_score}/100
                </span>
              </Typography>
            </Grid>
          </>
        )}
        {project && project.legal_comp_details && (
          <>
            <Grid container item className="mb-3">
              <IconButton inverted="true" disabled>
                <Icon className="fas fa-globe-europe" />
              </IconButton>
              <Typography variant="body2" className="text-truncate">
                <label>Country</label>
                <br />
                <span className="text-truncate">
                  {project.legal_comp_details.country_name}
                </span>
              </Typography>
            </Grid>
            <Grid container item className="mb-3">
              <IconButton inverted="true" disabled>
                <Icon className="fas fa-hand-holding-usd" />
              </IconButton>
              <Typography variant="body2" className="text-truncate">
                <label>CPI</label>
                <br />
                <span className="text-truncate">
                  {project.legal_comp_details.cpi_score}/100
                </span>
              </Typography>
            </Grid>
          </>
        )}
        <Grid container item className="mb-3">
          <IconButton inverted="true" disabled>
            <Icon className="fas fa-stopwatch" />
          </IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Launch Date</label>
            <br />
            <span className="text-truncate">
              {project.req_date
                ? moment(project.req_date).format('DD MMM YYYY, HH:mm')
                : ''}
            </span>
          </Typography>
        </Grid>
        <DueDateCard
          dueDate={project.due_date}
          hideDate={
            moment(project.req_date).format('DD MMM YYYY') ===
            moment(project.due_date).format('DD MMM YYYY')
          }
          editable={loggedInUser && loggedInUser.role_id === ROLE_WASP_ADMIN}
          onDueDateChange={handleSetDueDate}
          newDueDate={newDueDate}
          datePickerOpened={datePickerOpened}
          handleDatePickerOpen={handleDatePickerOpen}
          handleUnsetSelectedDueDate={handleUnsetSelectedDueDate}
          handleConfirmUpdateDueDate={handleConfirmUpdateDueDate}
        />
      </Grid>
    </>
  );
};

ProjectSummaryBlock.defaultProps = {
  project: null,
  newDueDate: null,
  datePickerOpened: false,
  onConfirm: true,
  handleSetDueDate: () => null,
  handleUnsetSelectedDueDate: () => null,
  handleConfirmUpdateDueDate: () => null,
  handleSetSelectedStatus: () => null,
  handleDatePickerOpen: () => null,
};

ProjectSummaryBlock.propTypes = {
  project: PropTypes.object.isRequired,
  onConfirm: PropTypes.bool,
  handleSetDueDate: PropTypes.func,
  newDueDate: PropTypes.instanceOf(Date),
  handleUnsetSelectedDueDate: PropTypes.func,
  handleConfirmUpdateDueDate: PropTypes.func,
  datePickerOpened: PropTypes.bool,
  handleSetSelectedStatus: PropTypes.func,
  handleDatePickerOpen: PropTypes.func,
};

export default ProjectSummaryBlock;
