// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import CustomExpansionPanel from 'components/UI/CustomExpansionPanel';
import EntityFormStep from 'components/OrderFlow/OrderStepForms/EntityFormStep';
import IndividualFormStep from 'components/OrderFlow/OrderStepForms/IndividualFormStep';
import MapChart from 'components/MapChart/MapChart';


const CSKeySubjectStep = ({
  typeKeySubject,
  typeRelatedSubject,
  entityValues,
  individualValues
}) => {

  return (
    <Grid
        container
        alignItems="stretch"
        direction="row">
      <Grid item xs={12} md={5} className="pr-4">
        <CustomExpansionPanel title={!typeKeySubject ? "Company" : "Individual"} disabled={true}>
          {!typeKeySubject
            ? (<EntityFormStep 
                typeKeySubject={typeKeySubject}
                typeRelatedSubject={typeRelatedSubject} 
                entity={entityValues} 
                index={0}
                canDeleted={false}
              />)
            : (<IndividualFormStep 
                typeKeySubject={typeKeySubject}
                typeRelatedSubject={typeRelatedSubject}
                individual={individualValues}
                index={0}
                canDeleted={false}
              />)
          }
        </CustomExpansionPanel>
      </Grid>
      <Grid item xs={12} md={7} className='leftBorderMap'>
        <Grid container>
          <Grid item className='generalMapWrapper'>
            <MapChart width="100%" selectedCountry={entityValues.country_code || individualValues.country_code}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

CSKeySubjectStep.defaultProps = {
  typeKeySubject: false,
  typeRelatedSubject: true,
  entityValues: {},
  individualValues: {}
};

CSKeySubjectStep.propTypes = {
  typeKeySubject: PropTypes.bool,
  typeRelatedSubject: PropTypes.bool,
  entityValues: PropTypes.shape({}),
  individualValues: PropTypes.shape({})
};

export default CSKeySubjectStep;
