import client from './client';

/**
 * Save questionnaire information
 *
 * @param {Object} info object contains all questionnaire store
 * @param {String} stepName string name of step
 * @returns {Promise} Promise object represents operation result
 */
const saveQuestionnaire = async ({ info, stepName }) => {
  const data = info;

  const response = await client.post(`/api/questionnaire/${stepName}/`, data);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Get information about partner for user, based on user id
 *
 * @param {String} userId current user id
 * @returns {Promise} Promise object represents operation result
 */
 const getPartner = async (userId) => {

  const response = await client.get(`/api/questionnaire/${userId}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Get information about partner for company admin, based on partner id
 *
 * @param {String} partner_id partner_id
 * @returns {Promise} Promise object represents operation result
 */
 const getDetailsPartner = async (partner_id) => {

  const response = await client.get(`/api/questionnaire/get_details_partner/${partner_id}/`);

  if (response.status === 200) {
    return response;
  }
};


/**
 * Get list of third-party partners
 *
 * @param {String} account_id current company admin account id
 * @returns {Promise} Promise object represents operation result
 */
 const getAllPartners = async (parameters={}) => {
  let parametersUrl = "";
  if ((Object.keys(parameters)).length > 0) {
    parametersUrl = new URLSearchParams(parameters).toString();
    parametersUrl = "?" + parametersUrl;
  }

  const response = await client.get(`/api/questionnaire/get_partners/${parametersUrl}`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Change status to 'Inactive' for third-party partner after finishinf questionnaire
 *
 * @param {String} userId current user id
 * @returns {Promise} Promise object represents operation result
 */
 const changeStatusAfterFinish = async (userId) => {

  const response = await client.put(`/api/questionnaire/${userId}/`, null);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Change status for third-party partner on dashboard page
 *
 * @param {String} statusObject contains new status, partner account id, partner id
 * @returns {Promise} Promise object represents operation result
 */
 const changeStatusOnDashboard = async (statusObject) => {
  const data = statusObject;

  const response = await client.put(`/api/questionnaire/change_status/`, data);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Change revision date for third-party partner
 *
 * @param {String} revisionDateObject contains new revision date, partner account id, partner id
 * @returns {Promise} Promise object represents operation result
 */
 const changeRevisionDate = async (revisionDateObject) => {
  const data = revisionDateObject;

  const response = await client.put(`/api/questionnaire/change_revision_date/`, data);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Get partner id by user id
 *
 * @param {String} userId current user id
 * @returns {Promise} Promise object represents operation result
 */
 const getPartnerId = async (userId) => {

  const response = await client.get(`/api/questionnaire/get_partner_id/${userId}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Save admin comment
 *
 * @param {Object} data contains partner_id(int) and comment(str)
 * @returns {Promise} Promise object represents operation result
 */
 const saveComments = async (data) => {

  const response = await client.put(`/api/questionnaire/comments/`, data);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Get info for Partners links guide by partner id
 *
 * @param {String} partnerId current user id
 * @returns {Promise} Promise object represents operation result
 */
 const getPartnerLinkGuide = async (partnerId) => {

  const response = await client.get(`/api/questionnaire/guide/${partnerId}/`);

  if (response.status === 200) {
    return response;
  }
};

export {
  saveQuestionnaire,
  getPartner,
  getDetailsPartner,
  getAllPartners,
  changeStatusAfterFinish,
  changeStatusOnDashboard,
  changeRevisionDate,
  getPartnerId,
  saveComments,
  getPartnerLinkGuide
};
