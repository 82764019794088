// Core
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchProjectConfig } from 'api/projects';
import { fetchAnalysisTypes } from 'api/info';
// Constants
import { SI_SERVICE } from 'utils';

const useStyles = makeStyles({
  textBody: {
    minHeight: 400
  },
  description: {
    color: 'var(--black-100)',
    fontWeight: 600,
    lineHeight: '23px',
  }
});


const AnalysisStep = ({
  onSetFieldValue,
  serviceId,
  analysisTypeId,
  selectedConfig,
}) => {
  const classes = useStyles();

  const { execute: getAnalysisTypes, pending: pendinggetAnalysisTypes, value: analysisTypes } = useAsync(fetchAnalysisTypes, false);
  const { execute: getProjectConfig, value: projectConfig, status: getConfigStatus } = useAsync(
    fetchProjectConfig,
    false,
  );

  useEffect(() => {
    if (serviceId && parseInt(serviceId) === SI_SERVICE) {
      getProjectConfig(serviceId);
      getAnalysisTypes(serviceId);
    }
  }, [getProjectConfig, getAnalysisTypes, serviceId]);

  const currentAnalysisType = analysisTypes && analysisTypes.find(type => type.id === parseInt(analysisTypeId));
  const setConfigParam = useCallback((analysisTypeValue) => {
    const currentProjectConfig =
      projectConfig && Object.values(projectConfig).filter(
        configuration => parseInt(analysisTypeValue) === parseInt(configuration.analysis_type_id)
      );
    if (currentProjectConfig !== null && currentProjectConfig[0]) {
      onSetFieldValue('selectedConfig', currentProjectConfig[0]);
      onSetFieldValue('analysisTypeId', currentProjectConfig[0].analysis_type_id);
    }
  }, [onSetFieldValue, projectConfig]);

  useEffect(() => {
    if (getConfigStatus === 200 && Object.keys(selectedConfig).length === 0) {
      setConfigParam(analysisTypeId);
    }
  }, [setConfigParam, selectedConfig, getConfigStatus, analysisTypeId]);

  const handleSetCurrentProjectConfig = (analysisTypeValue) => {
    setConfigParam(parseInt(analysisTypeValue));
  }

  return (
    !pendinggetAnalysisTypes && analysisTypes ? (
      <>
        <Grid container className="pb-2">
          <CustomRadioGroup
            color="simple"
            variant="inline"
            onChange={handleSetCurrentProjectConfig}
            name="projectAnalysisType"
            defaultValue={String(analysisTypeId)}
            fields={analysisTypes} />
        </Grid>
        <Divider className="minus-m-25" />
        <Grid className={classes.textBody}>
          <Typography variant="h2" className="pt-3 pb-3">{currentAnalysisType.name}</Typography>
          <Typography variant="body1" className={classes.description}>
            {currentAnalysisType.description}
          </Typography>
        </Grid>
      </>
      ) : (
        <Spinner />
      )
  );
}

AnalysisStep.defaultProps = {
  selectedConfig: {},
  startedKeySubject: false,
  onSetFieldValue: () => null,
};

AnalysisStep.propTypes = {
  analysisTypeId: PropTypes.number.isRequired,
  selectedConfig: PropTypes.shape({
    analysis_type_id: PropTypes.number,
    config_id: PropTypes.number,
    days_to_complete: PropTypes.number,
    level: PropTypes.number,
    sources: PropTypes.shape({}),
  }),
  serviceId: PropTypes.number.isRequired,
  onSetFieldValue: PropTypes.func,
};

export default AnalysisStep;
