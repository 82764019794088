// Core
import React from 'react';
// @material-ui
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0
  }
});

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress color="secondary" />
    </div>
  )
};

export default Spinner;
