import AccountsPage from 'pages/AccountsPage';
import AccountDetailsPage from 'pages/AccountDetailsPage';
import ProjectsDashboardPage from 'pages/ProjectsDashboardPage';
import CookiesReadMorePage from 'pages/CookiesReadMorePage';
import CurrentUserDetailsPage from 'pages/CurrentUserDetailsPage';
import MyServicesPage from 'pages/MyServicesPage';
import KnowledgeBasePage from 'pages/KnowledgeBasePage';
import PerformancePage from 'pages/PerformancePage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import ProjectDetailsPage from 'pages/ProjectDetailsPage';
import TermsConditionsPage from 'pages/TermsConditionsPage';
import UserDetailsPage from 'pages/UserDetailsPage';

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: MyServicesPage,
    exact: true,
    level: 0,
  },
  {
    path: '/cc-dashboard',
    name: 'Dashboard',
    component: ProjectsDashboardPage,
    exact: true,
    level: 1,
  },
  {
    path: '/cc-dashboard/:id',
    name: 'Project Details',
    component: ProjectDetailsPage,
    exact: true,
    level: 2,
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    component: KnowledgeBasePage,
    exact: true,
    level: 1,
  },
  {
    path: '/performance',
    name: 'Performance',
    component: PerformancePage,
    exact: true,
    level: 1,
  },
  {
    path: '/accounts',
    name: 'Companies',
    component: AccountsPage,
    exact: true,
    level: 1,
  },
  {
    path: '/accounts/:id',
    name: 'Company details',
    component: AccountDetailsPage,
    exact: true,
    level: 2,
  },
  {
    path: '/accounts/:id/:userId',
    name: 'User details',
    component: UserDetailsPage,
    exact: true,
    level: 3,
  },
  {
    path: '/current-user-details',
    name: 'Current user details',
    component: CurrentUserDetailsPage,
    exact: true,
    level: 1,
  },
  {
    path: '/cookies-read-more',
    name: 'Read more about cookies',
    component: CookiesReadMorePage,
    exact: true,
    level: 1
  },
  {
    path: '/cookies-read-more/privacy-policy',
    name: 'Privacy policy',
    component: PrivacyPolicyPage,
    exact: true,
    level: 2
  },
  {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    component: TermsConditionsPage,
    exact: true,
    level: 1
  },
];

export default routes;
