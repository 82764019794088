// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
// Components
import CustomExpansionPanel from 'components/UI/CustomExpansionPanel';
import CustomStakeholderCheckbox from 'components/StakeholdersCheckboxes/CustomStakeholderCheckbox';
// import CustomStakeholderCheckbox from './CustomStakeholderCheckbox';

const StakeholdersCheckboxes = ({
  stakeholders,
  stakeholderRecords,
  onStakeholderFieldChange,
  disabled = false,
}) => {
  
  return(
    <CustomExpansionPanel title="Activate Stakeholder Mapping">
      <FormControl 
        component="fieldset">
          <FormGroup>
            <Grid container>
              {
                stakeholders && stakeholders.map(stakeholder => {
                  return (
                    <Grid key={stakeholder.id} item xs={12}>
                      <CustomStakeholderCheckbox
                        name={String(stakeholder.id)}
                        disabled={disabled}
                        value={disabled === false ? ((stakeholderRecords && stakeholderRecords.size) ? stakeholderRecords.get(parseInt(stakeholder.id)) : false) : true}
                        label={stakeholder.name}
                        onChange={(event) => onStakeholderFieldChange(event.target.name, event.target.checked)}
                      />
                    </Grid>
                  );
                })
              }
            </Grid>
          </FormGroup>
      </FormControl>
    </CustomExpansionPanel>
  );
}

StakeholdersCheckboxes.defaultProps = {
  stakeholders: null,
  stakeholderRecords: null,
  onStakeholderFieldChange: () => null,
  disabled: false,
}

StakeholdersCheckboxes.propTypes = {
  stakeholders: PropTypes.array,
  stakeholderRecords: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  onStakeholderFieldChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default StakeholdersCheckboxes;
