// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';

const CustomizedSwitch = withStyles(theme => ({
  root: {
    width: 244,
    height: 32,
    padding: 0,
    border: `1px solid var(--black-20)`,
    borderRadius: 4,
  },
  switchBase: {
    padding: 1,
    top: -1,
    left: -1,
    '&$checked': {
      transform: 'translateX(129px)',
      '& + $track': {
        backgroundColor: theme.palette.common.white,
        opacity: 0,
      },
    },
  },
  thumb: {
    width: 115,
    height: 32,
  },
  track: {
    backgroundColor: theme.palette.common.white,
  },
  checked: {
    width: 109,
    height: 26,
    position: 'static',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    'font-size': 14,
    borderRadius: 4,
    margin: 2
   },
  label: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      icon={
        <IconButton classes={{ root: classes.checked, label: classes.label }}>
          {props.opt1}
        </IconButton>
      }
      checkedIcon={
        <IconButton classes={{ root: classes.checked, label: classes.label }}>
          {props.opt2}
        </IconButton>
      }
      {...props}
    />
  );
});

const useStyles = makeStyles(theme => ({
  formControlLabel: {
    margin: 0,
  },

  container: {
    position: 'relative',
  },

  item: {
    position: 'absolute',
    top: '20%',
    left: '15%',
  },

  item1: {
    position: 'absolute',
    top: '20%',
    right: '15%',
  },

  text: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.custom.navy,
    cursor: 'pointer',
  },

  hiddenText: {
    opacity: 0,
  },
}));

const CustomSwitch = props => {
  const classes = useStyles(props);
  const {
    id,
    opt1,
    opt2,
    field: { name, value, onChange },
  } = props;

  return (
    <FormGroup>
      <Grid
        component="label"
        container
        classes={{ container: classes.container }}>
        <Grid item classes={{ item: classes.item }}>
          <Typography classes={{ root: classes.text }}>{opt1}</Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            classes={{ root: classes.formControlLabel }}
            control={
              <CustomizedSwitch
                id={id}
                name={name}
                value={value}
                checked={value}
                onChange={onChange}
                {...props}
              />
            }
          />
        </Grid>
        <Grid item classes={{ item: classes.item1 }}>
          <Typography
            classes={
              value ? { root: classes.hiddenText } : { root: classes.text }
            }>
            {opt2}
          </Typography>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

CustomSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  opt1: PropTypes.string.isRequired,
  opt2: PropTypes.string.isRequired,
};

export default CustomSwitch;
