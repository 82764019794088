// Core
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// Components
import Logotype from 'components/Logotype/Logotype';

const useStyles = makeStyles(theme => {
  return {
    earthGlobe: {
      minHeight: '100vh',
      background: `${theme.palette.background.images.earthGlobe} no-repeat, linear-gradient(298deg, #2c4173, #001623)`,
      [theme.breakpoints.down('xs')]: {
        backgroundSize: '653px, 100%',
        backgroundPosition: '50%, 100%',
      },
      [theme.breakpoints.up('sm')]: {
        backgroundSize: '768px, 100%',
        backgroundPosition: '50%, 100%',
      },
      [theme.breakpoints.up('md')]: {
        backgroundSize: '768px, 100%',
        backgroundPosition: '50%, 100%',
      },
      [theme.breakpoints.up('lg')]: {
        backgroundSize: '768px, 100%',
        backgroundPosition: '50%, 100%',
      },
      [theme.breakpoints.up('xl')]: {
        backgroundSize: '900px, 100%',
      },
    },
    honeycombs: {
      minHeight: '100vh',
      backgroundColor: theme.palette.background.colors.honeycombs,
    },
    secondLayer:{
      // minHeight: '100vh',
      // background: `${theme.palette.background.images.honeyCombs} no-repeat`,
      [theme.breakpoints.down('xs')]: {
        backgroundSize: '653px, 100% !important',
        backgroundPosition: '50%, 100% !important',
      },
      [theme.breakpoints.up('sm')]: {
        backgroundSize: '850px, 100% !important',
        backgroundPosition: '50%, 100% !important',
      },
      [theme.breakpoints.up('md')]: {
        backgroundSize: '850px, 100% !important',
        backgroundPosition: '50%, 100% !important',
      },
      [theme.breakpoints.up('lg')]: {
        backgroundSize: '110%, 100% !important',
        backgroundPosition: '50%, 100% !important',
      },
      [theme.breakpoints.up('xl')]: {
        backgroundSize: '1330px, 100% !important',
      },
    },
    paddingFromTop: {
      [theme.breakpoints.up('xl')]: {
        paddingTop: '26vh',
      },
      [theme.breakpoints.down('lg')]: {
        paddingTop: '26vh',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '20vh',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '12vh',
      }
    }
  };
});

const BackgroundTheming = props => {
  const { image, children } = props;
  const classes = useStyles(props);

  const customClassName =
    image === 'earthGlobe' ? classes.earthGlobe : classes.honeycombs;

  return image === 'earthGlobe' ? (<Grid
    container>
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        className="heightVariant"
      >
        <Logotype/>
        <Grid className={classes.paddingFromTop}>
          {children}
        </Grid>
      </Grid>
      <Grid 
        item  
        md={7}
        lg={8}
        className={customClassName}>
          <Grid 
            item
            xs={12}
            // className={classes.secondLayer}>              
            className={clsx('secondLayerGlobal', classes.secondLayer)}>              
          </Grid>
      </Grid>
    </Grid>) : <div className={customClassName}>{children}</div>
};

BackgroundTheming.defaultProps = {
  image: 'honeycombs',
};

BackgroundTheming.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BackgroundTheming;
