// Core
import React, { useEffect, useRef } from 'react';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import Button from 'components/CustomButton';
import CustomIconButton from 'components/CustomIconButton';

const useStyles = makeStyles({
  dialog: {
    borderRadius: '4px',
  },
  confirmDialog: {
    '& .MuiDialog-paperWidthSm': {
      lineHeight: 'normal',
      borderRadius: '4px',
      boxShadow: 'none',
      maxWidth: 1400,
    },
  },
  dialogTitle: {
    padding: '20px',
  },
  dialogContent: {
    fontSize: 15,
    fontWeight: 600
  },
  testClass: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  divider: {
    height: 'unset',
    '& .MuiDivider-root': {
    },
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--black-60)',
      borderRadius: 10,
    }
  },
  dialogActions: {
    padding: 10,
  },
});

const ScrollPaperDialog = ({
  title,
  open,
  onClose,
  children,
  messageText
}) => {
  const classes = useStyles();

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      className={classes.confirmDialog}
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Grid 
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.dialogTitle}>
          <Typography variant="h2">
            {title}
          </Typography>
          <CustomIconButton icon="fas fa-times" tooltipText="Close" onIconButtonClick={onClose}/>
      </Grid>
      <Divider className={classes.divider}/>
      <DialogContent dividers={true} className={classes.customScrollbar}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {children ? children : messageText}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScrollPaperDialog;
