// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
// @material-ui
import { Box, FormHelperText, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Assets
import defaultAvatar from 'assets/profile_photo.jpg';
// Constants
import { AVATAR_FILE_SIZE, AVATAR_FILE_TYPES_VALIDATION } from 'utils';


const useStyles = makeStyles({
  inputAvatar: {
    width: 10,
    height: 10,
    opacity: 0,
    overflow: 'hidden',
  },

  imageAvatar: {
    marginBottom: 10,
    width: 110
  },
});

function validateAvatar(value) {
  let error = '';
  if (value !== null && typeof(value) !== 'undefined') {
    if (!(AVATAR_FILE_TYPES_VALIDATION.includes(value.type))) {
      error = 'Wrong file format, please try .jpeg or .png';
    }
    if (((value.size)/1024/1024) > AVATAR_FILE_SIZE) {
      error = error + ' File size should not be bigger than ' + AVATAR_FILE_SIZE + 'Mb';
    }
  }
  return error;
}

function handleChangeAvatar(avatarUploadedFile, setFieldValue, onUpdateAvatar) {
  var reader = new FileReader();
  let uploadedImage = '';
  reader.onload = function(upload) {
    var image = new Image();
    image.onload=function(){
      var canvas=document.createElement("canvas");
      var context=canvas.getContext("2d");
      canvas.width=200;
      canvas.height=200;
      context.drawImage(image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      uploadedImage = canvas.toDataURL();
    }
    image.src = upload.target.result;
  };
  reader.readAsDataURL(avatarUploadedFile);
  setTimeout(function() {
    setFieldValue('avatar_img', uploadedImage);
    onUpdateAvatar(true, uploadedImage);
  }, 1000);
}

function handleDeleteAvatar(setFieldValue, onUpdateAvatar) {
  setFieldValue('avatar_img', defaultAvatar);
  setFieldValue('avatar', '');
  setFieldValue('avatar_error', '');
  onUpdateAvatar(true, '');
}

const Avatar = ({ avatar_url, editing, onUpdateAvatar }) => {
    let user_avatar = (!avatar_url || avatar_url === "") ? defaultAvatar : avatar_url;
    const classes = useStyles();

    if (editing) {
      return (
        <Formik initialValues={{ avatar: user_avatar, avatar_img: '', avatar_error: '' }}>
          {({ values, setFieldValue }) => {
            return (
              <>
              <Box display="block" textAlign="center" alignItems="center">
                  <img
                    className="avatar"
                    id="avatar_img" 
                    src={values.avatar_img ? values.avatar_img : values.avatar}
                    alt="User avatar"
                  />
                  <div className={classes.inputAvatar}>
                    <input 
                      id="avatar"
                      type="file"
                      name="avatar"
                      accept="image/*"
                      onChange={event => {
                        setFieldValue('avatar_error', '');
                        let errors_avatar = validateAvatar(event.currentTarget.files[0]);
                        if (typeof(event.currentTarget.files[0]) !== 'undefined') {
                          if (errors_avatar){
                            setFieldValue('avatar_error', errors_avatar);
                            onUpdateAvatar(false, '');
                          } else {
                            handleChangeAvatar(event.currentTarget.files[0], setFieldValue, onUpdateAvatar);
                          }
                        }
                      }}
                    />
                  </div>
                </Box>
                <FormHelperText error id="avatar_error">{values.avatar_error}</FormHelperText>
                <Box display="block" textAlign="center" alignItems="center">
                  <InputLabel htmlFor="avatar" style={{ width: 125, marginRight: 8, display: 'inline-block' }}>
                    {(avatar_url || values.avatar_img) ? 'Change' : 'Upload'} Photo
                  </InputLabel>
                  <InputLabel style={{ width: 125, marginBottom: 15, display: 'inline-block' }}
                    onClick={() => {handleDeleteAvatar(setFieldValue, onUpdateAvatar)}}>
                    Delete Photo
                  </InputLabel>
                </Box>
              </>
            );
          }}
        </Formik>
      );
    }
    return (
      <Box className={classes.image} display="block" textAlign="center" alignItems="center">
        <img className={classes.imageAvatar} src={user_avatar} alt="User avatar" />
      </Box>
    );
}

Avatar.defaultProps = {
  avatar_url: defaultAvatar,
  editing: true,
  onUpdateAvatar: PropTypes.func,
};

export default Avatar;
