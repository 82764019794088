// Core
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import FormikInput from 'components/FormikInput';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important'
      }
    }
  }
});

const QuestionnaireSubmitForm = ({
  finish_form
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, "mt-3")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="requesterName"
          name="finish_form.respondent_name"
          label="Respondent name"
          value={finish_form.respondent_name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="requesterPosition"
          name="finish_form.respondent_position"
          label="Respondent position"
          value={finish_form.respondent_position}
        />
      </Grid>
    </Grid>
  );
}


export default QuestionnaireSubmitForm;
