import React from 'react';


const CloudWithLoop = ({height=null, width=null, color=null}) => {
    return (
        <svg 
            width={width !== null ? width : 248} 
            height={height !== null ? height : 248} 
            viewBox="0 0 248 248" 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg">
            <title>D70F82D4-381F-4312-B3D5-D20C8E479013</title>
            <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="usr-Dashboard-Empty-02" transform="translate(-628.000000, -365.000000)">
                    <g id="img-empty-cloud" transform="translate(628.000000, 365.000000)">
                        <rect id="Rectangle" x="0" y="0" width="248" height="248"></rect>
                        <path d="M124.201201,113.409623 C145.813216,113.409623 163.4004,130.998442 163.4004,152.612632 
                        C163.4004,162.452085 159.743159,171.733091 153.26378,178.891986 L152.894014,179.294929 L151.711125,180.566313 
                        L166.60748,195.457634 L170.778478,191.284901 L170.904293,191.170173 C171.686421,190.518345 172.980959,190.484486 
                        173.806859,191.092946 L173.947541,191.206994 L174.066219,191.320274 L197.225397,214.482604 L197.440592,214.704296 C201.3141,218.812592 
                        201.242357,225.308645 197.22476,229.330644 C195.235548,231.322036 192.572471,232.4 189.801121,232.4 C187.254439,232.4 184.794227,231.488352 
                        182.867646,229.790611 L182.608279,229.55424 L182.358739,229.311568 L159.184439,206.137247 L159.060755,206.003548 C158.356066,205.179482 
                        158.325082,203.975201 158.981186,203.117551 L159.0969,202.977944 L159.218918,202.849984 L163.356345,198.710239 L148.237476,183.597566 
                        L146.996159,184.488135 L146.511585,184.830313 C140.348904,189.113108 133.091595,191.552713 125.467758,191.796064 L124.84882,191.810975 
                        L124.186565,191.816301 L123.539842,191.811036 C102.238601,191.464062 85.0014014,174.011275 85.0014014,152.612632 C85.0014014,130.997507 
                        102.587118,113.409623 124.201201,113.409623 Z M172.405064,198.710309 L166.605778,204.512536 L186.901478,224.805317 C188.501447,226.407837 
                        191.1056,226.407837 192.698962,224.805317 C194.245599,223.258536 194.297153,220.777805 192.853626,219.170931 L192.698962,219.007894 
                        L172.405064,198.710309 Z M124,118 C104.670034,118 89,133.670034 89,153 C89,172.329966 104.670034,188 124,188 C143.329966,188 159,172.329966 
                        159,153 C159,133.670034 143.329966,118 124,118 Z M161.09958,15 C187.236073,15 208.501021,36.2662072 208.501021,62.406013 C208.501021,64.5450719 
                        208.339947,66.7350601 208.020177,68.953601 L207.892829,69.7868486 L207.656772,71.2484318 L209.045347,71.7620538 L209.693007,72.0062814 
                        C230.996334,80.1937474 245.4,101.013382 245.4,123.910826 C245.4,153.926697 221.475935,178.521145 191.625919,179.487928 L190.719578,179.509974 
                        L189.786487,179.517982 L177.501061,179.517982 L177.343515,179.512134 C176.146153,179.431201 175.20024,178.434601 175.20024,177.215639 
                        C175.20024,176.040644 176.082731,175.065471 177.227158,174.93043 L177.384781,174.917283 L177.545172,174.913896 L189.801121,174.914437 
                        L190.643247,174.907591 C218.380286,174.456367 240.80016,151.755959 240.80016,123.910826 C240.80016,101.486255 225.792052,81.3908856 
                        204.28774,75.0252419 C203.117888,74.6802828 202.420936,73.4778694 202.703101,72.2953556 C203.496856,68.9455213 203.899379,65.6199344 
                        203.899379,62.406013 C203.899379,38.8002444 184.702473,19.6000011 161.09958,19.6000011 C141.185468,19.6000011 123.524299,30.7011875 
                        118.183676,46.7295972 C117.965553,47.3870509 117.45898,47.9123539 116.808272,48.1561392 C116.217872,48.3731765 115.561932,48.3405509 
                        114.99634,48.0689814 L114.829528,47.9803529 L114.255515,47.6488923 L113.692508,47.3367325 C111.24055,46.0072633 109.04156,45.2204651 
                        106.23219,44.7677525 L105.698069,44.686733 L105.02844,44.5984389 L104.333588,44.5206926 L103.611249,44.4529471 L103.426104,44.4375092 
                        L103.239064,44.4226536 L102.471248,44.3688836 L101.670285,44.3237466 L100.833909,44.2866958 L99.9598565,44.2571839 L99.0458631,44.234664 
                        L98.0896643,44.2185889 L97.088996,44.2084117 L95.4996597,44.2030076 C73.6678312,44.2030076 55.3815395,60.7068566 52.9684849,82.196275 
                        L52.9002334,82.8489837 L52.7193746,84.716186 L54.5924532,84.8197747 L55.3018684,84.8628023 C61.8795748,85.2974336 67.2457457,86.6585317 
                        71.9480243,89.0580187 C73.0789264,89.6339578 73.5266135,91.0202811 72.946827,92.1536514 C72.5504954,92.9322126 71.7578361,93.406618 
                        70.8965366,93.406618 C70.5347357,93.406618 70.1760405,93.322272 69.8482384,93.1558796 C65.0009646,90.6749429 59.2242948,89.4545704 
                        51.8044676,89.3203121 L51.104023,89.310902 L50.4093393,89.307836 L49.7098112,89.3133824 L49.0115532,89.3303597 C26.0148435,90.0747799 
                        7.59923925,109.004979 7.59923925,132.111428 C7.59923925,155.217382 26.0149363,174.147479 48.9973415,174.891722 L49.6951616,174.908694 
                        L50.3868072,174.914377 L66.8005205,174.914437 L66.9580206,174.919744 C68.1550573,175.000692 69.1013413,175.997439 69.1013413,177.215639 
                        C69.1013413,178.390921 68.2185873,179.366378 67.0743259,179.501446 L66.9167277,179.514595 L66.7563985,179.517982 L50.4014414,179.517982 
                        L49.6188034,179.511077 C23.8410732,179.091615 3,157.989126 3,132.111428 C3,107.63853 21.719953,87.2236506 45.8653986,84.9212923 L46.5987257,84.8570825
                         L48.1279184,84.73508 L48.2498753,83.2058837 L48.3133036,82.4805573 C50.5632747,58.8363872 70.1916526,40.3555528 94.0312268,39.6254438 
                         L94.7549136,39.6087656 L95.513579,39.6029527 L96.7346601,39.6036911 L97.8766155,39.6092482 L98.9914716,39.6229462 L100.052918,39.6455407
                          L101.064669,39.6778352 L101.55307,39.697871 L102.03044,39.7206331 L102.953946,39.774738 L103.838901,39.8409532 L104.054552,39.8594934 
                          L104.268084,39.8788532 L105.102179,39.9647405 C107.90445,40.2823669 110.192647,40.8480285 112.636793,41.8066385 L113.16317,42.0181223 
                          L114.750889,42.6709186 L115.478144,41.1158967 L115.703519,40.6423187 C123.243182,25.0696735 140.884554,15 161.09958,15 Z" 
                          id="Combined-Shape" 
                          fill={color !== null ? color : "#F2F3F3"}></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CloudWithLoop;