import React from 'react';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// Components
import IconButton from 'components/IconButton';
import Button from 'components/CustomButton';


const Pagination = ({ page, rowCount, rowsPerPage, changePage }) => {
  let totalPages = Math.ceil(rowCount / rowsPerPage);
  
  const renderButtons = (numberOfPages) => {
    let buttonsArray = [];
    for (let i = 1; i <= numberOfPages; i++) {
      if (page === i) {
        buttonsArray.push(<Button key={i} variant="contained" className="mr-0">{i}</Button>);
      } else {
        buttonsArray.push(<Button key={i} variant="text" className="mr-0" onClick={() => changePage(i)}>{i}</Button>);
      }
    }
    return (
      <div>
        {buttonsArray}
      </div>
    )
  };

  return (
    <Grid
      container
      justify="flex-end"
      className="pb-1"
    >
    {page > 1 &&
      (<IconButton inverted="true" className="mr-0" onClick={() => changePage(page - 1)}>
        <Icon className="fa fa-caret-left" />
      </IconButton>)
    }
    {renderButtons(totalPages)}
    {page !== totalPages &&
      (<IconButton inverted="true" className="mr-0" onClick={() => changePage(page + 1)}>
        <Icon className="fa fa-caret-right" />
      </IconButton>)
    }
    </Grid>
  );
};

export default Pagination;
