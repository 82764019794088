// Core
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import EntityFormStep from '../OrderStepForms/EntityFormStep';
import IndividualFormStep from 'components/OrderFlow/OrderStepForms/IndividualFormStep';
import MapChart from 'components/MapChart/MapChart';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import { fetchProjectConfig } from 'api/projects';

const GeneralSubjectStep = ({
  typeKeySubject,
  typeRelatedSubject,
  humint,
  entityValues,
  individualValues,
  selectedConfig,
  startedKeySubject,
  serviceId,
  onSetFieldValue,
  isNewProject
}) => {
  const [ localKeySubject, setLocalKeySubject ] = useState(typeKeySubject);
  const subjects = [{'id': false, 'name': 'Entity'}, {'id': true, 'name': 'Individual'}];
  const clearEntity = {
    project_id: '',
    subject_id: '',
    name: '',
    country_code: '',
    country: '',
    name_in_local_language: '',
    website: '',
    position: '',
    registered_address: '',
    tax_number: '',
    trading_name: '',
    operational_address: '',
    business_license_number: '',
    is_key: false,
    type: 'Entity'
  };
  const clearIndividual = {
    project_id: '',
    subject_id: '',
    name: '',
    country_code: '',
    country: '',
    name_in_local_language: '',
    website: '',
    position: '',
    alias: '',
    shares: '',
    residential_address: '',
    business_address: '',
    passport_number: '',
    national_id: '',
    is_key: false,
    type: 'Individual'
  };

  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeKeySubject,
    handleClose: handleCloseConfirmToChangeKeySubject,
  } = useDialog();

  const { execute: getProjectConfig, value: projectConfig, status: getEDDConfigStatus } = useAsync(
    fetchProjectConfig,
    false,
  );

  const setConfigParam = useCallback((humintValue) => {
    const currentProjectConfig = projectConfig && Object.values(projectConfig).filter(
      configuration => humintValue === configuration.humint
    );
    if (currentProjectConfig !== null) {
      onSetFieldValue('selectedConfig', currentProjectConfig[0]);
      onSetFieldValue('humint', humintValue);
      if (startedKeySubject) {
        onSetFieldValue('typeKeySubject', startedKeySubject);
      }
    }
  }, [onSetFieldValue, projectConfig, startedKeySubject]);
  
  useEffect(() => {
    if (serviceId && parseInt(serviceId) === 2) {
      getProjectConfig(serviceId);
    }
  },[getProjectConfig, serviceId]);

  useEffect(() => {
    if (getEDDConfigStatus === 200 && Object.keys(selectedConfig).length === 0) {
      setConfigParam(humint);
    }
  }, [setConfigParam, selectedConfig, getEDDConfigStatus, humint]);

  const handleSetFieldSubject = value => {
    if (isNewProject) {
      if (value === 'false') {
        onSetFieldValue('individuals', [clearIndividual]);
      } else if (value === 'true') {
        onSetFieldValue('entities', [clearEntity]);
      }
      onSetFieldValue('typeKeySubject', value === 'true');
    } else {
      handleOpenConfirmToChangeKeySubject();
      setLocalKeySubject(value);
    }
  };

  let entities = [];
  let individuals = [];
  const handleChangeKeySubject = typeKeySubject => {
    if (typeKeySubject === 'false') {
      entities.push(clearEntity);
      individuals = [clearIndividual];
    } else if (typeKeySubject === 'true') {
      individuals.push(clearIndividual);
      entities = [clearEntity];
    }
    onSetFieldValue('typeKeySubject', typeKeySubject === 'true');
    onSetFieldValue('entities', entities);
    onSetFieldValue('individuals', individuals);
    handleCloseConfirmToChangeKeySubject();
  }

  const handleSetCurrentProjectConfig = (humintValue) => {
    setConfigParam(humintValue);
  }

  return (
    <Grid
        container
        alignItems="stretch"
        direction="row">
      <Grid item xs={12} md={5} className="pr-4">
        <Grid container className="pt-1 pb-3">
          <Typography variant="h3">Type</Typography>
        </Grid>
        <Grid container className="pb-2">
          <CustomRadioGroup
            images={{
              false: 'fa-city',
              true: 'fa-user-tie',
            }}
            color="simple"
            variant="small"
            onChange={handleSetFieldSubject}
            name="keySubject"
            defaultValue={String(typeKeySubject)}
            fields={subjects} />
        </Grid>
        <Divider className="minus-m-25" />
        {typeKeySubject
          ? <IndividualFormStep typeKeySubject={typeKeySubject} individual={individualValues} index={0}/> 
          : <EntityFormStep typeKeySubject={typeKeySubject} typeRelatedSubject={typeRelatedSubject} entity={entityValues} index={0}/>}
        <Divider className="minus-m-25" />
        <Grid 
          container
          direction='column'>
          <Grid className="pt-3 pb-1">
            <Typography className="pl-0" variant="h3">HUMINT</Typography>
          </Grid>
          <FormControl className="mb-4 flex-centered">
            <FormControlLabel
              value={humint}
              checked={humint}
              control={<Checkbox
                id="humint"
                name="humint"
                color="primary"
                onChange={(event) => {handleSetCurrentProjectConfig(event.target.checked)}}
              />}
              label="Activate"
              className="mr-2" />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} className='leftBorderMap'>
        <Grid container className='generalMapWrapper'>
          <Grid item className='generalMapWrapper'>
            <MapChart width="100%" selectedCountry={entityValues.country_code || individualValues.country_code}/>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Key Subject"
        open={openConfirmToChangeCurrentPage}
        onClose={handleCloseConfirmToChangeKeySubject}
        confirmBtnText="Confirm"
        options={localKeySubject}
        onConfirm={handleChangeKeySubject}>
          You are about to change key subject. Previous data will be lost. Please confirm.
      </ConfirmDialog>
    </Grid>
  )
}

GeneralSubjectStep.defaultProps = {
  typeKeySubject: false,
  typeRelatedSubject: true,
  humint: false,
  selectedConfig: {},
  startedKeySubject: false,
  entityValues: {},
  individualValues: {},
  isNewProject: true,
  serviceId: 2,
  onSetFieldValue: () => null,
};

GeneralSubjectStep.propTypes = {
  selectedConfig: PropTypes.shape({
    config_id: PropTypes.number,
    calculated_due_date: PropTypes.string,
    days_to_complete: PropTypes.number,
    sources: PropTypes.shape({}),
  }),
  startedKeySubject: PropTypes.bool,
  typeKeySubject: PropTypes.bool,
  typeRelatedSubject: PropTypes.bool,
  humint: PropTypes.bool,
  isNewProject: PropTypes.bool,
  entityValues: PropTypes.shape({}),
  individualValues: PropTypes.shape({}),
  serviceId: PropTypes.number.isRequired,
  onSetFieldValue: PropTypes.func,
};

export default GeneralSubjectStep;
