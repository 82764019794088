// Core
import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
// @material-ui
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Root Component
import App from './App';
// Context
import { ProvideAuth } from 'hooks/useAuth';
// Instruments
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
// Styles
import ThemeProvider from './theme/theming';
import 'typeface-roboto';
import './theme/init.scss';

// adds action to all snackbars
const notistackRef = createRef();
const onClose = key => () => {
  notistackRef.current.closeSnackbar(key);
};

const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ThemeProvider>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          action={key => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClose(key)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}>
          <ProvideAuth>
            <App />
          </ProvideAuth>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  </Provider>,
  root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
