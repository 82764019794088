import client from './client';

/**
 * Fetches services from api
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchServices = async () => {
  const response = await client.get('/api/info/services/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches countries from api for Select component
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchCountries = async () => {

  const response = await client.get('/api/info/countries/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches levels from api
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchLevels = async () => {

  const response = await client.get('/api/info/levels/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches analysis types from api
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchAnalysisTypes = async serviceId => {

  const response = await client.get(`/api/info/analysis_types/${serviceId}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches sources from api
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchSources = async () => {

  const response = await client.get('/api/info/sources/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches scopes of work from api
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchScopes = async () => {

  const response = await client.get('/api/info/scopes/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches roles for Select component
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchProjectsRoles = async () => {

  const response = await client.get('/api/info/projects/roles/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches user roles for users table 
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchUserRoles = async () => {

  const response = await client.get('/api/info/users/roles/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches industries for advice flow 
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchIndustries = async () => {

  const response = await client.get('/api/info/advice_flow/industries/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches relationships for advice flow 
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchRelationships = async () => {

  const response = await client.get('/api/info/advice_flow/relationships/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Fetches durations for advice flow 
 *
 * @returns {Promise} Promise object represents operation result
 */
const fetchDurations = async () => {

  const response = await client.get('/api/info/advice_flow/durations/');

  if (response.status === 200) {
    return response;
  }
};

export {
  fetchServices,
  fetchCountries,
  fetchDurations,
  fetchIndustries,
  fetchLevels,
  fetchAnalysisTypes,
  fetchRelationships,
  fetchSources,
  fetchScopes,
  fetchProjectsRoles,
  fetchUserRoles
};
