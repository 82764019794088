// Core 
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import FileCard from 'components/FileCard';
import FileUpload from 'components/FileUpload';
import IconButton from 'components/IconButton';
// Hooks
import useDialog from 'hooks/useDialog';
// Instruments
import { deleteFile, downloadFile } from 'api/files';

const ResultFiles = props => {
  const { columns, editable, files, handleUpdateFiles, isResult, projectId, serviceId } = props;
  const { open: openUploading, handleOpen, handleClose } = useDialog();
  const [isDisabledDialog, setIsDisabledDialog] = useState(false);
  const [dataForDeleteFile, setDataForDeleteFile] = useState(null);

  const {
    open: openDeleteFileConfirm,
    handleOpen: handleOpenDeleteFileConfirm,
    handleClose: handleCloseDeleteFileConfirm,
  } = useDialog();

  const handleUploadFile = (disableDialog=false) => {
    setIsDisabledDialog(disableDialog);
  };

  const handleDownloadFile = (fileId, isResult) => {
    if (projectId) {
      downloadFile(projectId, fileId, isResult);
    }
  };

  const handleDeleteFile = (fileId, isRemoved=false) => {
    if (projectId) {
      if (isRemoved) {
        deleteFile(projectId, fileId);
        handleUpdateFiles({id: fileId}, true);
      } else {
        setDataForDeleteFile(fileId);
        handleOpenDeleteFileConfirm();
      }
    }
  };

  const confirmedDeleteFile = (fileId) => {
    if (projectId) {
      deleteFile(projectId, fileId);
      handleUpdateFiles({id: fileId}, true);
      setDataForDeleteFile(null);
      handleCloseDeleteFileConfirm();
    }
  };

  let xsItemWidth = 12;
  if (columns === 2) {
    xsItemWidth = 6;
  }
  let fileCards =
    files &&
    files.length > 0 &&
    files.map(file => (
      <Grid item xs={xsItemWidth} key={"wrapper_" + file.id}>
        <FileCard
          editable={editable}
          onDownloadFile={() => handleDownloadFile(file.id, isResult)}
          onDeleteFile={() => editable ? handleDeleteFile(file.id) : null}
          {...file}
        />
      </Grid>
    ));

  return (
    <>
      <Grid
        container
        className="pb-3 pl-0 pr-0">
          <Grid
            container
            direction="row"
            justify="space-between">
              {
                parseInt(serviceId) !== 3 ?
                  <Typography variant="h3" className="pl-0 pt-2 pb-3">
                    {isResult ? "Result Files" : "Files"}
                  </Typography> :
                  <Typography variant="subtitle1" className="pl-0 pt-2 pb-3" color="primary">
                    Please, don't forget to upload candidate's CV, Diploma and ID 
                  </Typography>
              }  
              {
                projectId && editable ?
                <IconButton inverted="true" className="mr-0" onClick={handleOpen}>
                  <Icon className="fas fa-plus"/>
                </IconButton> :
                <></>
              }
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="stretch">
              {fileCards}
          </Grid>
      </Grid>
      {projectId && <ConfirmDialog
        title={isResult ? "Upload result file" : "Upload files"}
        open={openUploading}
        onClose={handleClose}
        confirmBtnText="Finish"
        onConfirm={handleClose}
        disableBackdrop={true}
        disableEscapeKey={true}
        hideCancelBtn={true}
        isDisabledDialog={isDisabledDialog}
        messageText="Uploading in progress... Please, wait for selected files to finish loading">
        <Grid>
          <FileUpload
            projectId={projectId}
            onRemovedFile={handleDeleteFile}
            onUploadFile={handleUploadFile}
            onUpdateFiles={handleUpdateFiles}
            isResult={isResult}
          />
        </Grid>
      </ConfirmDialog>}
      <ConfirmDialog
        title="Delete file"
        open={openDeleteFileConfirm}
        onClose={handleCloseDeleteFileConfirm}
        options={dataForDeleteFile}
        confirmBtnText="Confirm"
        onConfirm={confirmedDeleteFile}>
          You are about to delete file. Please confirm.
      </ConfirmDialog>
    </>
  );
}


ResultFiles.defaultProps = {
  columns: 1,
  editable: false,
  files: null,
  handleUpdateFiles: () => null,
  isResult: true,
};

ResultFiles.propTypes = {
  columns: PropTypes.number,
  editable: PropTypes.bool,
  files: PropTypes.array,
  handleUpdateFiles: PropTypes.func,
  isResult: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
};

export default ResultFiles;
