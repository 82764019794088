// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// Assets
import defaultAvatar from 'assets/profile_photo.jpg';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { updateUserAvatar, deleteUserAvatar } from 'api/users';
// Constants
import { 
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
  ROLE_WASP_ADMIN,
  ROLE_ADMIN,
  ROLE_WASP_USER,
  ROLE_APPLICANT,
  ROLE_NOVATOR,
  ROLE_ADMIN_READ_ONLY,
  ROLE_USER,
  AVATAR_FILE_SIZE,
  AVATAR_FILE_TYPES_VALIDATION
} from 'utils';


const initialValues = {
  avatar: '',
  fullName: '',
  country: '',
  phoneNumber: '',
  roleId: '',
  email: '',
};

const userRole = (roleId) => {
  switch(roleId){
    case ROLE_WASP_ADMIN:
      return "WASP admin"      
    case ROLE_ADMIN:
      return "Company Admin"
    case ROLE_WASP_USER:
    case ROLE_APPLICANT:
    case ROLE_NOVATOR:
      return "WASP user"
    case ROLE_USER:
      return "Company User";
    case ROLE_ADMIN_READ_ONLY:
      return "Company admin with read only role"
    default:
      return "User"
  };
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .required('Required')
    .nullable(),
  email: Yup.string()
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    })
    .required('Required')
    .nullable(),
  phoneNumber: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable(),
});

const useStyles = makeStyles({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 0 12px 24px'
  },
  avatar: {
    width: 110,
    height: 110
  },
  wrapAvatar: {
    // paddingBottom: 6
  },
  avatarButtons: {
    marginLeft: '12px',
    width: 125
  }
});

const UserEditForm = ({
  user,
  editing,
  onChangeData,
  onUpdateUser,
  pendingUserUpdate,
  setUpdateAvatar
}) => {
  const [errorAvatar, setErrorAvatar] = useState(false);
  const {
    execute: changeUserAvatar,
    pending: pendingChangeAvatar,
    status: statusChangeAvatar
  } = useAsync(updateUserAvatar, false);
  const {
    execute: deleteAvatar,
    pending: pendingDeleteAvatar,
    status: statusDeleteAvatar
  } = useAsync(deleteUserAvatar, false);

  const classes = useStyles();
  const userValues = user && {
    fullName: user.full_name,
    email: user.email,
    phoneNumber: user.phone_number !== null ? user.phone_number : '',
    roleId: userRole(user.role_id)
  };

  const changeFunc = (values, userValues) => {
    let formIsChanged = false;
    if (userValues && values) {
      Object.keys(userValues).map(key => {
        if (userValues[key] !== values[key]) {
          formIsChanged = true;
        }
        return formIsChanged;
      });
    }
    return onChangeData(formIsChanged);
  };

  const onSubmit = (values) => {
    const dataToUpdateUser = {
      userId: user && user.id,
      full_name: values.fullName,
      email: values.email,
      phone_number: values.phoneNumber
    };
    const updateUser = () => {
      onUpdateUser(dataToUpdateUser);
    };
    const submit = editing ? updateUser() : false;
    submit();
  };
  
  useEffect(() => {
    if (statusChangeAvatar === 200 && !pendingChangeAvatar) {
      setUpdateAvatar(true);
    }
  }, [statusChangeAvatar, pendingChangeAvatar, setUpdateAvatar]);
  useEffect(() => {
    if (statusDeleteAvatar === 200 && !pendingDeleteAvatar) {
      setUpdateAvatar(true);
    }
  }, [statusDeleteAvatar, pendingDeleteAvatar, setUpdateAvatar]);

  const handleUploadAvatar = (file) => {
    const formData = new FormData();
    formData.append('avatar', file);
    changeUserAvatar(formData);
  };

  const validateAvatar = (value) => {
    let error = false;
    if (value !== null && typeof(value) !== 'undefined') {
      if (!(AVATAR_FILE_TYPES_VALIDATION.includes(value.type))) {
        error = true;
      }
      if (((value.size)/1024/1024) > AVATAR_FILE_SIZE) {
        error = true;
      }
    }
    return error;
  };

  const handlerDeleteAvatar = () => {
    deleteAvatar();
  };

  return (
    <React.Fragment>
      <Grid container className={classes.avatarContainer}>
        <Grid item className={classes.wrapAvatar}>
          <Avatar 
            variant="rounded" 
            className={classes.avatar} 
            src={user && user.avatar !== null ? `${user.avatar}/?${Date.now()}` : defaultAvatar}
          />
        </Grid>
        <Grid item>
          <Grid item>
            <Typography className="ml-3" color={errorAvatar ? "error" : "primary"}>
              {`Max size ${AVATAR_FILE_SIZE}Mb. Use 'jpeg' or 'png' formats.`}
            </Typography>
          </Grid>
          <Grid item>
            <input
              accept="image/jpeg,image/png"
              style={{display: 'none'}}
              id="contained-button-file"
              multiple={false}
              type="file"
              onChange={event => {
                const file = event.target.files[0];
                const error = validateAvatar(file);
                setErrorAvatar(error);
                if (typeof file !== 'undefined' && !error) {
                  handleUploadAvatar(file);
                }
              }}
            />
            <label htmlFor="contained-button-file">
              <Button 
                component="span"
                variant="outlined" 
                className={classes.avatarButtons} 
                disabled={pendingChangeAvatar}
              >
                Upload New 
              </Button>
            </label>
          </Grid>
          <Grid item>
            <Button 
              variant="outlined" 
              className={classes.avatarButtons} 
              onClick={handlerDeleteAvatar}
              disabled={pendingDeleteAvatar || (user && user.avatar === null)}
            >
              Delete avatar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        initialValues={userValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}>
        {({
          values,
          handleSubmit,
          handleReset
        }) => {
          changeFunc(values, userValues);
          return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className="pt-2 pl-4 pr-4"> 
              <Grid item xs={12} sm={6} className="pb-0">
                <FormikInput
                  mandatory
                  id="fullName"
                  name="fullName"
                  label="full name"
                  value={values.fullName}
                />
                <FormikInput
                  id="phoneNumber"
                  name="phoneNumber"
                  label="phone"
                  value={values.phoneNumber}
                  placeholder="+380951112233"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pb-0">
                <FormikInput
                  mandatory
                  disabled
                  id="roleId"
                  name="roleId"
                  label="role"
                  value={values.roleId}
                />
                <FormikInput
                  mandatory
                  disabled
                  id="email"
                  name="email"
                  label="email"
                  value={values.email}
                />
              </Grid>
              <Grid container justify="flex-end" className="pb-4 pr-2">
                <Button
                  variant="outlined"
                  style={{ width: 125, marginRight: 8 }}
                  onClick={handleReset}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={pendingUserUpdate}
                  style={{ width: 125 }}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

UserEditForm.defaultProps = {
  user: null,
  editing: true,
  pendingUserUpdate: false,
  onChangeData: () => null,
  onUpdateUser: () => null,
  setUpdateAvatar: () => null
};

UserEditForm.propTypes = {
  user: PropTypes.shape({}),
  editing: PropTypes.bool,
  pendingUserUpdate: PropTypes.bool,
  onChangeData: PropTypes.func,
  onUpdateUser: PropTypes.func,
  setUpdateAvatar: PropTypes.func
};

export default UserEditForm;
