// Core
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// Components
import { AccountCard, CreateCompanyForm, StepTwoFrom } from 'components/Account';
import Button from 'components/CustomButton';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomStepper from 'components/UI/CustomStepper/CustomSteper';
import DrawerDialog from 'components/UI/DrawerDialog/DrawerDialog';
import EmptyPage from 'components/EmptyPage';
import PageTitleSection from 'components/PageTitleSection';
import Spinner from 'components/shared/Spinner';
// Context
import { ServicesContext } from 'context/ServicesContext';
//Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
import useStepper from 'hooks/useStepper';
// Instruments
import { fetchAccounts, createAccount, updateAccount, deleteAccount } from 'api/accounts';
import { createUser } from 'api/users';
import { sendActivationLink } from 'api/auth';
import { fetchLevels } from 'api/info';
import 'simplebar/dist/simplebar.min.css';
//Helpers
import { sortArrayOfObjects } from 'helpers';

const useStyles = makeStyles(theme => ({
  title: {
    padding: '25px 25px 0px',
    borderBottom: '4px solid rgba(185, 185, 185, 0.15)',

    '& h2': {
      width: 'fit-content',
      marginBottom: -4,
      paddingBottom: 25,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      lineHeight: 'normal',
      borderBottom: `4px solid ${theme.palette.secondary.main}`,
    },
  },
  
  tableBody: {
    "& .MuiTable-root":{
      "& .MuiTableHead-root": {
        "& .MuiTableRow-head": {
          border: 'none !important',
          marginBottom: '8px !important',
          
          "&:hover": {
            border: "none",
          },

          "& div":{
            fontFamily: 'Montserrat',
            fontSize: 10,
            color: 'var(--black-60)',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          },

          "& .MuiTableCell-head": {
            borderBottom: 'none !important',
            width: "21.9%",
          },
        },

        "& .MuiTableRow-root": {
          border: '1px solid var(--black-20)',
          borderRadius: 4,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
          
          "& td:last-child":{
            justifyContent: "flex-end",          
            visibility: "hidden",          
          },
          
          "&:hover": {
            border: "1px solid  var(--primary-regular)",

            "& td:last-child":{
              justifyContent: "flex-end",
              visibility: "visible"          
            },
          },
        },
      },

      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          border: '1px solid var(--black-20)',
          borderRadius: 4,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
          
          "& td:last-child":{
            justifyContent: "flex-end",          
            visibility: "hidden",          
          },
          
          "&:hover": {
            border: "1px solid  var(--primary-regular)",

            "& td:last-child":{
              justifyContent: "flex-end",
              visibility: "visible"          
            },
          },

          "& .MuiTableCell-root": {
            width: "16.5%",
            borderBottom: 'none',
            padding: '5px 16px 5px 16px',
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
  },
}));

const steps = ["General info", "Admin"];

const AccountsPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { services } = useContext(ServicesContext);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [companyIdForDelete, setCompanyIdForDelete] = useState(null);
  const [toggleCreateCompany, setToggleCreateCompany] = useState(false);
  const { activeStep, handleNext, handleResetStepper } = useStepper();

  const { value: levels } = useAsync(
    fetchLevels,
    true
  );

  const getFormSubmit = step => {
    switch (step) {
      case 1:
        return document.getElementsByClassName("create-new-company")[0].click();
      case 2:
        return document.getElementsByClassName("create-new-user")[0].click();
      default:
        return null;
    }
  }

  const {
    open: openConfirmToActivate,
    handleOpen: handleOpenConfirmToActivate,
    handleClose: handleCloseConfirmToActivate,
  } = useDialog();

  const {
    open: openConfirmToDeactivate,
    handleOpen: handleOpenConfirmToDeactivate,
    handleClose: handleCloseConfirmToDeactivate,
  } = useDialog();

  const {
    open: openConfirmDeleteCompany,
    handleOpen: handleOpenConfirmDeleteCompany,
    handleClose: handleCloseConfirmDeleteCompany,
  } = useDialog();


  const openCreateCompany = () => {
    setToggleCreateCompany(true);
  };

  const closeCreateCompany = () => {
    setToggleCreateCompany(false);
    handleResetStepper();
  };

  const { execute: fetchAccountsList, setValue: setAccounts, pending, value: accounts } = useAsync(
    fetchAccounts,
    false,
  );

  const { 
    execute: createNewAccount, 
    value: newAccount, 
    status: addAccountStatus, 
    pending: pendingCreateCompany } = useAsync(
    createAccount,
    false,
  );
  const { execute: updateAccountValues, value: updatedAccount } = useAsync(
    updateAccount,
    false,
  );
  const { execute: deleteAccountFromDatabase, pending: pendingDeleteAccount, status: statusDeleteCompany } = useAsync(
    deleteAccount,
    false,
  );
  const { execute: createNewUser, value: newUser, status: createUserStatus } = useAsync(
    createUser,
    false,
  );
  const { execute: ActivateUser, value: sendActivationLinkValue } = useAsync(
    sendActivationLink,
    false,
  );

  useEffect(() => {
    if (statusDeleteCompany === 200 && !pendingDeleteAccount) {
      const deleteAccount = accounts.find(acc => acc.id === companyIdForDelete);
      toast.success(`Company '${deleteAccount.name}' was successfully deleted`, { autoClose: 5000 });
      const newAccounts = accounts.filter(acc => acc.id !== companyIdForDelete);
      setAccounts(newAccounts);
    }
  // eslint-disable-next-line
  }, [statusDeleteCompany, pendingDeleteAccount]);

  useEffect(() => {
    if (newAccount && addAccountStatus === 201 && pendingCreateCompany) {
      toast.success("New Company was successfully added.", { autoClose: 5000 });
      const newAccounts = accounts;
      newAccounts.push({...newAccount});
      newAccounts.sort(sortArrayOfObjects('name'));
      setAccounts(newAccounts);
    };
  }, [newAccount, addAccountStatus, accounts, setAccounts, pendingCreateCompany]);

  useEffect(() => {
    if (updatedAccount !== null) {
      toast.success("The company data was successfully updated.", { autoClose: 5000 });
    }
  }, [updatedAccount]);

  useEffect(() => {
    if (newUser && createUserStatus === 201) {
      toast.success("User was successfully created.", { autoClose: 5000 });
    }
  }, [updatedAccount, newUser, createUserStatus]);

  useEffect(() => {
    if (sendActivationLinkValue) {
      toast.success(sendActivationLinkValue.message, { autoClose: 5000 });
    }
  }, [sendActivationLinkValue]);

  useEffect(() => {
    fetchAccountsList();
  }, [fetchAccountsList]);

  const servicesReduced = newAccount && newAccount.subscriptions && services.filter(service => {
    const foundSubId = newAccount.subscriptions.find(subscription => subscription.id === service.id);
    if (!foundSubId) return false;
    return service;
  });

  const levelsReduced = newAccount && newAccount.cs_levels && levels.filter(globalLevel => {
    const foundLevelId = newAccount.cs_levels.find(level => level.id === globalLevel.id);
    if (!foundLevelId) return false;
    return globalLevel;
  });
  const isNewAccountActive = newAccount && newAccount.active;

  const getStepContent = step => {
    switch (step) {
      case 1:
        return (
          <CreateCompanyForm
            onCreateCompany={handleCreateCompany}
            isOutherSubmit={true}
            levels={levels}
          />);
      case 2:
        return (
          <StepTwoFrom
            onCreateUser={handleCreateUser}
            isOutherSubmit={true}
            services={servicesReduced} 
            levels={levelsReduced}
            isNewAccountActive={isNewAccountActive}
          />);
      default:
        return '';
    }
  }

  const handleSetSelectedCompanyId = ({companyId, active}) => {
    if(active === true){
      handleOpenConfirmToActivate();
      setSelectedCompanyId({companyId, active});
    } else {
      handleOpenConfirmToDeactivate();
      setSelectedCompanyId({companyId, active});
    }
  };

  const viewCompanyDetails = companyId => {
    push(`${url}/${companyId}`);
  };

  const handleCreateCompany = companyData => {
    var response = createNewAccount(companyData);
    response.then(function(value) {
      if (!value) {
        handleNext();
      }
    });
  }

  const handleActivateCompany = ({companyId, active}) => {
    if(active === true){
      const id = companyId;
      handleCloseConfirmToActivate();
      updateAccountValues({id, active});
      const newAccounts = accounts.map(account => {
        if(account.id === companyId){
          return ({...account, active: active});
        }
  
        return account;
      });
      setAccounts(newAccounts);
    } else {
      const id = companyId;
      handleCloseConfirmToDeactivate();
      updateAccountValues({id, active});
      const newAccounts = accounts.map(account => {
        if(account.id === companyId){
          return ({...account, active: active});
        }
  
        return account;
      });
      setAccounts(newAccounts);
    }
  };

  const handlerOpenDialogDeleteCompany = (companyId) => {
    setCompanyIdForDelete(companyId);
    handleOpenConfirmDeleteCompany();
  }

  const handlerDeleteCompany = (idCompany) => {
    deleteAccountFromDatabase(idCompany);
    handleCloseConfirmDeleteCompany();
  }

  const handleCreateUser = userData => {
    let sendActivationMail = false;
    if (userData && userData.active && userData.email) {
      sendActivationMail = true;
      userData.active = false;
    }
    const response = createNewUser({...userData, account_id: newAccount.id});
    response.then(function(value) {
      if (!value) {
        if (sendActivationMail) {
          ActivateUser({
            email: userData.email
          });
        }
        closeCreateCompany();
        const newAccounts = accounts.map(account => {
          if(account.id === newAccount.id){
            return ({...account, users_count: 1});
          }
    
          return account;
        });
        setAccounts(newAccounts);
      }
    });
  }

  const companiesTableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "none",
      }
    },
    {
      name: "name",
      label: "COMPANY NAME",
    },
    {
      name: "users_count",
      options: {
        display: "false"
      }
    },
    {
      name: "active",
      label: "STATUS",
    },
    {
      name: "onboarding_allowed",
      label: "ONBOARDING ALLOWED"
    },
    {
      name: "cluster_network_allowed",
      label: "CLUSTER NETWORK"
    },
    {
      name: "country",
      label: "COUNTRY",
    },
    {
      name: "email",
      label: "MAIN EMAIL",
    },
    {
      name: "functions",
      label: " ",
    },
  ];

  const renderContent = accounts ? (
    <MUIDataTable
      title={""}
      data={accounts.sort(sortArrayOfObjects('name'))}
      className={classes.tableBody}
      columns={companiesTableColumns}
      options={{
        responsive: "vertical",
        selectableRows: "none",
        download: false,
        filter: false,
        pagination: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        tableBodyHeight: '65vh', 
        customRowRender: (colData) => {
          const [ id, name, users_count, active, onboarding_allowed, cluster_network_allowed, country, email] = colData;                
          const text = `${url}/${id}`;
          const objCompany = {
            'id': id,
            'name': name,
            'users_count': users_count,
            'status': active,
            'active': active,
            'onboarding_allowed': onboarding_allowed,
            'cluster_network_allowed': cluster_network_allowed,
            'country': country,
            'email': email,
          };
          return (
            <AccountCard
              key={id} 
              to={text} 
              onViewCompanyDetails={viewCompanyDetails} 
              onSetSelectedCompanyId={handleSetSelectedCompanyId}
              onDeleteCompany={handlerOpenDialogDeleteCompany}
              {...objCompany}/>              
          );},
        }
      }
    />
  ) : (
    <EmptyPage height={65} hasButton={true} handleClick={openCreateCompany} title="company"/>
  );

  return (
    <>
      <Grid>
        <PageTitleSection title="Companies">
          <Button
            style={{ width: 180, height: 30, alignSelf: 'flex-end' }}
            onClick={openCreateCompany}>
            Create New Company
          </Button>
        </PageTitleSection>
        {!pending ? renderContent : <Spinner />}
        <ConfirmDialog
          title="Activate Company"
          open={openConfirmToActivate}
          onClose={handleCloseConfirmToActivate}
          options={selectedCompanyId}
          confirmBtnText="Confirm"
          onConfirm={handleActivateCompany}>
          You are about to Activate Company. Please confirm.
        </ConfirmDialog>
        <ConfirmDialog
          title="Deactivate Company"
          open={openConfirmToDeactivate}
          onClose={handleCloseConfirmToDeactivate}
          options={selectedCompanyId}
          confirmBtnText="Confirm"
          onConfirm={handleActivateCompany}>
          You are about to Deactivate Company. Please confirm.
        </ConfirmDialog>
        <ConfirmDialog
          title="Delete Company"
          open={openConfirmDeleteCompany}
          onClose={handleCloseConfirmDeleteCompany}
          options={companyIdForDelete}
          confirmBtnText="Confirm"
          onConfirm={handlerDeleteCompany}>
          You are about to delete company. Be careful, will be removed all users this company.
          All projects created by this company will be reassign to WASP Admin. Please confirm.
        </ConfirmDialog>
        <ToastContainer />
      </Grid>
      <DrawerDialog
        openPage={toggleCreateCompany}
        closePage={closeCreateCompany}
        title="Create Company"
        onSubmit={() => {getFormSubmit(activeStep)}}>
          <Grid>
            <CustomStepper stepTitles={steps} activeStep={activeStep}></CustomStepper>
            {getStepContent(activeStep)}
          </Grid>
      </DrawerDialog>
    </>
  );
};

export default AccountsPage;
