import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
// Components
import ComplianceProcedureCheckbox from './Forms/ComplianceProcedureCheckbox';


const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 50,
    margin: 0,
    width: '100%',
    border: '1px solid var(--black-60)',
    borderLeft: '5px solid var(--black-60)',
    borderRadius: 4,
    paddingLeft: 12,

    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      color: 'var(--black-100)',
      lineHeight: 1,
    },
  },
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      }
    }
  },
  noColumn: {
    flexDirection: 'unset',
    justifyContent: 'flex-end'
  }
});

const cpp_subprocedures_names = ["code_of_conduct", "anti_bribery_corruption", "anti_money_laundering", "sanctions_policy",
  "third_party_risk_management", "conflicts_of_interests", "environmental_social_and_governance", "other_compliance_policies"];
const tpsp_sub_procedures = ["conduct_due_diligence"];

const complianceProceduresQuestions = [
  {
    index: "1",
    label_checkbox: "Are any of your company’s directors, shareholders or UBOs politically exposed persons (PEP)?",
    path_checkbox: "politically_exposed_persons",
    label_text: 'If YES, please list the names and political or government roles of PEPs.',
    path_text: "politically_exposed_persons_text",
    description: "(PEP is an individual with a high-profile political role, or who has been entrusted " +
      "with a prominent public function (e.g., Heads of State or of government, senior " +
      "government, judicial or military officials, senior executives of state-owned entities," +
      " political party officials, etc.)"
  },
  {
    index: "2",
    label_checkbox: "Does your company have a Compliance Officer or a relevant function/position?",
    path_checkbox: "exist_compliance_officer",
    label_text: 'If YES, please specify a name of the chief responsible person.',
    path_text: "exist_compliance_officer_text"
  },
  {
    index: "3",
    label_checkbox: "Does your company have any documented compliance policies and procedures?",
    path_checkbox: "compliance_policies_procedures",
    description_text: "If YES, please list the policies below:"
  },
  {
    label_checkbox: "Code of Conduct",
    path_checkbox: "code_of_conduct",
    is_subppath: true
  },
  {
    label_checkbox: "Anti-Bribery and Corruption",
    path_checkbox: "anti_bribery_corruption",
    is_subppath: true
  },
  {
    label_checkbox: "Anti-money Laundering and Combatting Terrorism Financing",
    path_checkbox: "anti_money_laundering",
    is_subppath: true
  },
  {
    label_checkbox: "Sanctions Policy",
    path_checkbox: "sanctions_policy",
    is_subppath: true
  },
  {
    label_checkbox: "Third Party Risk Management",
    path_checkbox: "third_party_risk_management",
    is_subppath: true
  },
  {
    label_checkbox: "Conflict of Interest",
    path_checkbox: "conflicts_of_interests",
    is_subppath: true
  },
  {
    label_checkbox: "Environmental, Social and Governance (ESG)",
    path_checkbox: "environmental_social_and_governance",
    is_subppath: true
  },
  {
    label_checkbox: "Other compliance policies",
    path_checkbox: "other_compliance_policies",
    label_text: "If you have any other compliance policies, please list them.",
    path_text: "other_compliance_policies_text",
    is_subppath: true
  },
  {
    index: "4",
    label_checkbox: "Will any third parties or subcontractors be used for the purpose of our business relations?",
    path_checkbox: "third_parties_subcontractors_purpose",
    label_text: 'If YES, please confirm that your company will conduct due diligence on the involved third parties.',
  },
  {
    label_checkbox: "If YES, please confirm that your company will conduct due diligence on the involved third parties.",
    path_checkbox: "conduct_due_diligence",
    is_subppath: true
  },
  {
    index: "5",
    label_checkbox: "Is your company under bankruptcy, liquidation or dissolution proceedings?",
    path_checkbox: "bankruptcy_liquidation_proceedings",
  },
  {
    index: "6",
    label_checkbox: "Is or was your company or its affiliated entities (i.e., subsidiary, parent entity, joint venture) under any direct or indirect sanctions (UN, US, EU, UK, other) or import/export control restrictions?",
    path_checkbox: "company_sanctions_restrictions",
    label_text: 'If YES, please specify details and actions taken by your company to mitigate the compliance risks.',
    path_text: "company_sanctions_restrictions_text"
  },
  {
    index: "7",
    label_checkbox: "Does your company or its affiliated entities (i.e., subsidiary, parent entity, joint venture) have any current or former business activity with any country that is subject to any sanctions or international operational restrictions?",
    path_checkbox: "country_sanctions_restrictions",
    label_text: 'If YES, please specify details and actions taken by your company to mitigate the compliance risks.',
    path_text: "country_sanctions_restrictions_text"
  },
  {
    index: "8",
    label_checkbox: "Is or was any shareholder, UBO, management, employee of your company under any sanctions or international operational restrictions?",
    path_checkbox: "individual_international_operation_restrictions",
    label_text: 'If YES, please specify details and actions taken by your company to mitigate the compliance risks.',
    path_text: "individual_international_operation_restrictions_text"
  },
  {
    index: "9",
    label_checkbox: "Was your company management or any UBO a subject to any accusations of corruption or other illegal activity?",
    path_checkbox: "individual_sanctions_restrictions",
    label_text: 'If YES, please specify names and details.',
    path_text: "individual_sanctions_restrictions_text"
  }
]

const compliance_policies_procedures = 'compliance_policies_procedures';
const third_parties_subcontractors_purpose = "third_parties_subcontractors_purpose";
const other_compliance_policies_text = 'other_compliance_policies_text';

const ComplianceProceduresStep = ({
  compliance_procedures,
  onSetFieldValue
}) => {
  const classes = useStyles();

  const handleChangeCheckbox = (checkbox, value) => {
    onSetFieldValue(`compliance_procedures.[${checkbox}]`, value)
    if (value === false) {
      if (checkbox === compliance_policies_procedures) {
        cpp_subprocedures_names.forEach(name => {
          onSetFieldValue(`compliance_procedures.[${name}]`, value)
        });
        onSetFieldValue(`compliance_procedures.[${other_compliance_policies_text}]`, '');

      } else if (checkbox === third_parties_subcontractors_purpose) {
        tpsp_sub_procedures.forEach(name => {
          onSetFieldValue(`compliance_procedures.[${name}]`, value)
        });
      }
      else {
        onSetFieldValue(`compliance_procedures.[${checkbox}_text]`, '');
      }
    }
  };

  const getCheckboxDisabledValue = (name) => {
    if (
      !compliance_procedures.compliance_policies_procedures &&
      cpp_subprocedures_names.includes(name)
    ) return true;
    else if (
      !compliance_procedures.third_parties_subcontractors_purpose &&
      tpsp_sub_procedures.includes(name)
    ) return true;

    return false;
  };

  return (
    <Grid
      container
      className={clsx(classes.mbNone, "pt-2")}
      direction="column"
      justify="space-between"
      alignItems="flex-end">
      <FormControl
        component="fieldset"
        className={classes.fullWidth}>
        <FormGroup
          className={classes.noColumn}>
          {
            complianceProceduresQuestions.map((labels_paths) => {
              return (
                <ComplianceProcedureCheckbox
                  key={labels_paths.path_checkbox}
                  is_subpath={labels_paths?.is_subppath}
                  index={labels_paths?.index}
                  labels_paths={labels_paths}
                  description={labels_paths?.description}
                  description_text={labels_paths?.description_text}
                  disabled={getCheckboxDisabledValue(labels_paths.path_checkbox)}
                  checkbox_value={compliance_procedures[labels_paths.path_checkbox]}
                  text_value={compliance_procedures[labels_paths.path_text]}
                  handleChangeCheckbox={handleChangeCheckbox}
                />
              );
            })
          }
        </FormGroup>
      </FormControl>
    </Grid>
  )
}

export default ComplianceProceduresStep;
