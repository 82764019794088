// Core
import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchProjectConfig } from 'api/projects';
import { fetchLevels } from 'api/info';
// Context
import { UserContext } from 'context/UserContext';
// Constants
import { CS_SERVICE } from 'utils';

const initialEntity = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  country: '',
  name_in_local_language: '',
  website: '',
  position: '',
  registered_address: '',
  tax_number: '',
  trading_name: '',
  operational_address: '',
  business_license_number: '',
  is_key: false,
  type: 'Entity'
};
const initialIndividual = {
  project_id: '',
  subject_id: '',
  name: '',
  country_code: '',
  country: '',
  name_in_local_language: '',
  website: '',
  position: '',
  alias: '',
  shares: '',
  residential_address: '',
  business_address: '',
  passport_number: '',
  national_id: '',
  is_key: false,
  type: 'Individual'
};

const KeySubjectStep = ({
  typeKeySubject,
  selectedConfig,
  startedKeySubject,
  serviceId,
  levelId,
  onSetFieldValue,
  showWarningLevel,
  showWarningSubject,
  isNewProject
}) => {
  const { loggedInUser } = useContext(UserContext);
  const avialableLevels = loggedInUser && (loggedInUser.cs_levels.map(item => item.id)).sort();
  
  const { execute: getLevels, value: levels, pending: pendingLevels } = useAsync(
    fetchLevels, 
    false
  );
  const { execute: getProjectConfig, value: projectConfig, status: getConfigStatus, pending: pendingConfig } = useAsync(
    fetchProjectConfig,
    false,
  );

  useEffect(() => {
    getLevels();
  }, [getLevels]);

  useEffect(() => {
    if (serviceId && parseInt(serviceId) === CS_SERVICE) {
      getProjectConfig(serviceId);
    }
  }, [getProjectConfig, serviceId]);

  const setConfigParam = useCallback((levelValue) => {
    const currentProjectConfig = projectConfig && Object.values(projectConfig).filter(
      configuration => parseInt(levelValue) === parseInt(configuration.config_id)
    );
    if (currentProjectConfig !== null && currentProjectConfig[0]) {
      onSetFieldValue('selectedConfig', currentProjectConfig[0]);
      onSetFieldValue('levelId', currentProjectConfig[0].config_id);
      if (startedKeySubject) {
        onSetFieldValue('typeKeySubject', startedKeySubject);
      }
    }
  }, [onSetFieldValue, projectConfig, startedKeySubject]);

  useEffect(() => {
    if (getConfigStatus === 200 && !pendingConfig && avialableLevels && Object.keys(selectedConfig).length === 0) {
      setConfigParam(isNewProject ? avialableLevels[0] : levelId);
    }
  }, [getConfigStatus, pendingConfig, avialableLevels, levelId, selectedConfig, isNewProject, setConfigParam]);

  const subjects = [{'id': false, 'name': 'Entity'}, {'id': true, 'name': 'Individual'}];

  const handleSetFieldSubject = value => {
    onSetFieldValue('typeKeySubject', value === 'true');
    onSetFieldValue('typeRelatedSubject', value === 'false');
    onSetFieldValue('entities', [initialEntity]);
    onSetFieldValue('individuals', [initialIndividual]);
  };

  const handleSetCurrentProjectConfig = (levelValue) => {
    setConfigParam(parseInt(levelValue));
  }

  return (
    <>
      <Grid container className="pt-1 pb-2">
        <Typography variant="h3">Type</Typography>
      </Grid>
        {showWarningSubject ? (
            <Typography variant="body1" className="warning-msg pt-1 pl-2">
              <i className="fas fa-exclamation-triangle"></i>&nbsp;
              You are switching to different project type. Information about project will be lost
            </Typography>
          ) : ''}
      <Grid container className="pt-2 pb-2">
        <CustomRadioGroup
          images={{
            false: 'fa-city',
            true: 'fa-user-tie',
          }}
          color="simple"
          variant="small"
          onChange={handleSetFieldSubject}
          name="typeKeySubject"
          defaultValue={String(typeKeySubject)}
          fields={subjects} />
      </Grid>
      <Divider className="minus-m-25" />
      <Grid container alignItems="center" className="pt-4 pb-3">
        <Typography variant="h3">Level</Typography>
        {showWarningLevel ? (
          <Typography variant="body1" className="warning-msg pt-1 pl-2">
            <i className="fas fa-exclamation-triangle"></i>&nbsp;
            You are switching to different project type. Information about related subject will be lost
          </Typography>
        ) : ''}
      </Grid>
      <Grid container className="pb-2">
        {/* {projectConfig && levels  */}
        {!pendingLevels && levelId
          ? (<CustomRadioGroup
            color="simple"
            variant="wide"
            onChange={handleSetCurrentProjectConfig}
            name="projectLevel"
            defaultValue={String(levelId)}
            avialableLevels={avialableLevels}
            fields={levels} />) 
          : (<Spinner />)
        }
      </Grid>
    </>
  );
};

KeySubjectStep.defaultProps = {
  selectedConfig: {},
  startedKeySubject: false,
  onSetFieldValue: () => null,
  showWarningLevel: false,
  showWarningSubject: false,
  isNewProject: true,
};

KeySubjectStep.propTypes = {
  selectedConfig: PropTypes.shape({
    config_id: PropTypes.number,
    calculated_due_date: PropTypes.string,
    days_to_complete: PropTypes.number,
    level: PropTypes.shape({}),
    sources: PropTypes.shape({}),
  }),
  startedKeySubject: PropTypes.bool,
  serviceId: PropTypes.number.isRequired,
  levelId: PropTypes.number.isRequired,
  onSetFieldValue: PropTypes.func,
  showWarningLevel: PropTypes.bool,
  showWarningSubject: PropTypes.bool,
  isNewProject: PropTypes.bool
};

export default KeySubjectStep;
