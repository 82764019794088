const transformObjWithKeysToArr = obj => {
  return Object.keys(obj)
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, [])
    .filter(el => el !== null);
};

const reduceObjOnGivenKeys = (obj, ...arrOfKeys) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!arrOfKeys.includes(key)) {
      return acc;
    }

    if (arrOfKeys.includes(key) && obj[key] && typeof obj[key] === 'object') {
      acc[key] = obj[key].name;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

const checkIfAllValsAreFalsy = (array) => {
  let new_arr = array.slice();
  new_arr.map((item, index) => {
    let isEmptyObject = Object.values(item).every(val => {
      if (val !== 'Entity' && val !== 'Individual') {
        return !val;
      } else {
        return true;
      }
    });
    if (isEmptyObject) {
      array.splice(index, 1);
    }
    return item;
  });
  return array;
};

// Method for 'entity' or 'individual' object
const checkIfAllValuesOfObjectAreFalsy = (object) => {
  const isEmpty = Object.values(object).every(val => {
    if (val === 'Entity' || val === 'Individual') {
      return true;
    } else {
      return !val;
    }
  });
  return isEmpty;
}

const sortArrayOfObjects = (sortBy, order='asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(sortBy) || !b.hasOwnProperty(sortBy)) {
      return 0;
    }

    const varA = (typeof a[sortBy] === 'string')
      ? a[sortBy].toUpperCase() : a[sortBy];
    const varB = (typeof b[sortBy] === 'string')
      ? b[sortBy].toUpperCase() : b[sortBy];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
};

const getLocationQueryVariable = (location, variable) => {
  var query = location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  return '';
}

const fileExists = (url) => {
    let http = new XMLHttpRequest();
    let isText = false;
    http.open('GET', url, false);
    http.send();
    if (http.status === 200 && http.readyState === 4) {
      isText = http.responseText.search("<!doctype html>") === -1;  
    }
    return http.status !== 404 && isText;
}

const transformProjectId = (idNum) => {
  for (let i of idNum) {
    if (Number.isNaN(parseInt(i))) {
      return idNum;
    }
  }
  return (parseInt(idNum)).toString();
}

export {
  checkIfAllValsAreFalsy,
  fileExists,
  getLocationQueryVariable,
  reduceObjOnGivenKeys,
  sortArrayOfObjects,
  transformObjWithKeysToArr,
  transformProjectId,
  checkIfAllValuesOfObjectAreFalsy
};
