// Core
import React from 'react';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
} from '@material-ui/core';

const useStyles = makeStyles({
  placeholder: {
    color: 'var(--primary-regular)',
  },
  selectInactive: {
    background: 'var(--black-60)',
  },
  selectActive: {
    background: 'var(--primary-regular)',
  },
  customSelect: {
    position: 'relative',
    height: 32,
    width: 100,
    color: 'var(--white)',
    fontFamily: 'inherit',
    lineHeight: '24px !important',
    letterSpacing: 'normal',
    borderRadius: 4,
    '&:focus': {
      borderRadius: 4,
    },
    '& .MuiSelect-select':{
      width: '100%',
      fontSize: 14,
      fontWeight: 500,
      paddingLeft: 10
    },
    "& .MuiSelect-icon":{
      color: 'var(--white)',
      fontSize: '20px',
      cursor: "pointer",
      right: '8px',
      top: 'unset'
    },
  },
  disabledSelect: {
    backgroundColor: 'var(--black-20)'
  }
});

const CustomTableDataSelect = ({
  id,
  name,
  value,
  displayEmpty,
  children,
  handleChange,
  disabled
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth={true}>
      <Select
        disabled={disabled}
        displayEmpty={displayEmpty}
        id={id}
        disableUnderline={true}
        name={name}
        value={value}
        onChange={handleChange}
        className={clsx(classes.customSelect, {
          [classes.selectInactive]: !value,
          [classes.selectActive]: value,
          [classes.disabledSelect]: disabled
        })}>
        {children}
      </Select>
    </FormControl>
  );
};

export default CustomTableDataSelect;
