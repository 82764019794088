const examples = [
  {
    name: 'Basic level',
    path: "/data/complienceScreening/WASP_SAMPLE_BASIC.pdf"
  },
  {
    name: 'Moderate level',
    path: "/data/complienceScreening/WASP_SAMPLE_MODERATE.pdf"
  },
  {
    name: 'High level',
    path: "/data/complienceScreening/WASP_SAMPLE_HIGH.pdf"
  },  
]

export default examples;