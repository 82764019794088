// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
// Components
import { ViewDetails } from 'components/shared';
import FormInfoSection from 'components/FormInfoSection';
import IconButton from 'components/IconButton';
// Constants
import { ROLE_ADMIN_READ_ONLY } from 'utils';
import SubServicesCheckboxes from '../../SubServicesCheckboxes';

const LegalComplianceDetailsCard = ({
  country,
  countryCode,
  industry,
  sub_services,
  handleBack,
  logginUserRole,
}) => {
  return (
    <Grid>
      <Typography variant="h3" className="pl-0 pb-3 pt-3">
        Key Subject
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="row">
        <ViewDetails
          fullWidth
          label={<FormInfoSection text="Country" />}
          name={country}
          countryCode={countryCode}
        />
      </Grid>
      <Grid container direction="row">
        <ViewDetails
          fullWidth
          label={<FormInfoSection text="Industry" />}
          name={industry}
        />
      </Grid>
      <Divider className="mt-1 mb-2" />
      <Typography variant="h3" className="pl-0 pt-3 pb-3">
        Services
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="column" className="pb-3">
        {sub_services && (
          <SubServicesCheckboxes
            doubleColumned={false}
            sub_services={sub_services}
            disabled
          />
        )}
      </Grid>
      <Divider className="mt-1 mb-2 minus-m-25" />
    </Grid>
  );
};

LegalComplianceDetailsCard.defaultProps = {
  country: '',
  countryCode: '',
  industry: '',
  sub_services: [],
  handleBack: () => null,
};

LegalComplianceDetailsCard.propTypes = {
  country: PropTypes.string,
  countryCode: PropTypes.string,
  industry: PropTypes.string,
  sub_services: PropTypes.array,
  handleBack: PropTypes.func,
};

export default LegalComplianceDetailsCard;
