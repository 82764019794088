// Core
import React from 'react';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone-component';
import { ToastContainer, toast } from 'react-toastify';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// Constants
import { ALLOWED_FILE_TYPES, FILE_SIZE, FILES_SLICE_SIZE } from '../../utils';
// Assets
import IconUpload from 'assets/icon-upload.svg';
import '../FileUpload/fileupload.css';
// Instruments
import { refresh_token } from 'api/auth';


const fileType = (filename) => {
  var re = /(?:\.([^.]+))?$/;
  let fileExt = re.exec(filename)[1];
  switch (fileExt) {
    case "jpg":
    case "png":
      return "fa-file-image";
    case "pdf":
      return "fa-file-pdf";
    case "doc":
    case "docx":
      return "fa-file-word";
    case "rar":
    case "zip":
      return "fa-file-archive";
    default:
      return "fa-file";
  }
}

var ReactDOMServer = require('react-dom/server');
let token = localStorage.getItem('access_token');
var djsConfig = {
  autoProcessQueue: true, // for COSA logic it is required
  autoQueue: true,
  addRemoveLinks: false,
  chunking: true,
  parallelChunkUploads: false, // important to load only in one thread
  forceChunking: true,
  maxFilesize: FILE_SIZE, // megabytes
  dictFileTooBig: "File size upload is limited to {{maxFilesize}}Mb. Please try smaller filesize",
  chunkSize: FILES_SLICE_SIZE, // bytes
  timeout: 300000, // 5 minutes
  maxFiles: 5,
  parallelUploads: 1,
  accept: function(file, done) {
    console.log("accept", file, done)
    if (file.size === 0) {
      done("Empty file will nit be uploaded.")
    } 
    else { done(); }
  },
  acceptedFiles: ALLOWED_FILE_TYPES.join(', '),
  retryChunks: true,
  retryChunksLimit: 3,
  headers:{"Authorization":'Bearer ' + token},
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <Grid container item className="mb-3">
      <div className="dz-typefile-wrapper ml-0"><Icon data-dz-typefile="true" className="fa fa-file" /></div>
      <div className="dz-preview dz-file-preview">
        <div className="dz-details">
          <div className="dz-filename pb-1"><span data-dz-name="true"></span></div>
        </div>
        <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
        <div className="dz-error-mark"><span><Icon className="fa fa-exclamation-triangle" /></span></div>
        <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
      </div>
      <div className="dz-success-mark"><span><Icon className="fa fa-check" /></span></div>
      <a className="dz-remove pt-3 pl-2" href="." data-dz-remove="true"><Icon className="fa fa-times" /></a>
    </Grid>
  ),
  dictDefaultMessage: ReactDOMServer.renderToStaticMarkup(
    <div className="pt-4 pb-4" data-dz-message>
      <img src={IconUpload} className="pb-3" alt="Drop your file here, or browse" /><br />
      <span>Drop your file here, or <u>browse</u></span><br />
      <label>Supports {ALLOWED_FILE_TYPES.join(', ')}</label>
    </div>
  ),
  init() {
    refresh_token().then((resp) => {
      if (resp.status === 200) {
        const token = resp.data.access_token;
        localStorage.setItem('access_token', token);
        djsConfig.headers.Authorization = `Bearer ${token}`;
      }
    }).catch(err => {
      console.log(err);
    });
  }
};

const FileUpload = ({ 
  typeUpload,
  projectId,
  partnerId,
  typePartnerFile,
  onRemovedFile, 
  onUploadFile, 
  onUpdateFiles, 
  isResult 
}) => {

  var numRequest = 1;
  var fileId = 0;
  var uploadingProgress = 0;
  var dropzone;

  if (djsConfig.headers.Authorization === "Bearer null") {
    if (!token) {
      token = localStorage.getItem('access_token');
    }
    if (token) {
      djsConfig.headers.Authorization = `Bearer ${token}`;
    }
  }

  var componentConfig = {
    iconFiletypes: ALLOWED_FILE_TYPES,
    showFiletypeIcon: false,
    postUrl: typeUpload === "project" ? `/api/project_files/${projectId}/` : `/api/partners_files/${partnerId}/${typePartnerFile}/`
  };

  const handleThumbnail = (file) => {
    if (parseInt(file.size) < 1) {
      file.accepted = false;
      file.status = dropzone.ERROR;
      dropzone._errorProcessing([file], "The file is empty. Please try another one.");
    }
  };

  const handleAddedFile = (file) => {
    if (file.upload.total <= 0) {
      return;
    }
    file.previewElement.id = file.upload.uuid;
    let currentFileNode = document.getElementById(file.upload.uuid);
    var ndList = currentFileNode.childNodes;
    var typefileNode;
    for (var i = 0; i < ndList.length; ++i) {
      var childrens = [...ndList[i].children].filter((child) => child.matches('[data-dz-typefile=true]'));
      if (childrens.length > 0)
        typefileNode = childrens[0];
    }
    let file_type = fileType(file.name);
    if (file_type && typefileNode) {
      if (typefileNode.classList.contains('fa-file'))
        typefileNode.classList.remove('fa-file');
      typefileNode.classList.add(file_type);
    }
    if (dropzone && numRequest === 1) {
      const formData = new FormData();
      formData.append('num_request', numRequest);
      formData.append('filename', file.name);
      if (typeUpload === "project") {
        formData.append('is_result', isResult?'Y':'N');
      }
      dropzone.processQueue();
    }
  };

  const handleRemovedFile = (file) => {
    onUploadFile();
    // delete this partly uploaded file
    if (file.file_id) {
      onRemovedFile(file.file_id, true);
      if (file.file_id === fileId) {
        numRequest = 1;
        fileId = 0;
      }
    }
  };

  const handleUploadProgress = (file, progress, bytesSent) => {
    if (numRequest < file.upload.totalChunkCount && progress === 100) {
      progress = uploadingProgress;
      dropzone.emit("uploadprogress", file, progress, bytesSent);
    } else {
      uploadingProgress = progress;
    }
  };

  const handleSendingFile = (file, xhr, formData) => {
    xhr.onreadystatechange = function() {
      if (xhr.status === 200 && xhr.response) {
        let response = JSON.parse(xhr.response);
        if (response.file_id) {
          fileId = response.file_id;
          file.file_id = fileId;
        }
        if (response.file_data && response.finished === true) {
          onUpdateFiles(response.file_data);
        }
      }
    };
    onUploadFile(true);
    const filename = file.upload.filename;
    numRequest = parseInt(formData.get('dzchunkindex')) + 1;
    if (fileId > 0) {
      formData.append('file_id', fileId);
    }
    formData.append('num_request', numRequest);
    formData.append('filename', filename);
    if (typeUpload === "project") {
      formData.append('is_result', isResult?'Y':'N');
    }
  };

  const handleError = (file, error) => {
    let errorObject = {
      message: 'An error occurred, please try to load another file.'
    };
    let fileNode = document.getElementById(file.upload.uuid);
    let errorNode = fileNode && fileNode.getElementsByClassName("dz-error-message");
    if (typeof error === "string" && error.includes("<!DOCTYPE HTML")) {
      error = errorObject.message;
      if (errorNode.length > 0)
        errorNode[0].innerText = error;
    } else if (typeof error === "object") {
      if (error.msg) error = error.msg;
      if (error.message) error = error.message;
      if (typeof error.errors === "object") {
        error = error.errors.message;
      }
      if (errorNode.length > 0)
        errorNode[0].innerText = error;
    }
    if (error) {
      toast.error(error, { autoClose: 5000 });
    } else {
      toast.error(errorObject.message, { autoClose: 5000 });
    }
    numRequest = 1;
    /* delete this partly uploaded file
    if (fileId) onRemovedFile(fileId, true); old version, but 'fileId' is global
    Need delete current file!
    Code below need some more testing */
    if (file.upload.bytesSent > 0) {
      onRemovedFile(fileId, true);
    }
    fileId = 0;
    onUploadFile();
  };

  const handleSuccess = () => {
    //run next object
    numRequest = 1;
    fileId = 0;
    onUploadFile();
    dropzone.processQueue();
  };

  var eventHandlers = {
    init: (passedDropzone) => { dropzone = passedDropzone; },
    thumbnail: handleThumbnail,
    addedfile: handleAddedFile,
    removedfile: handleRemovedFile,
    sending: handleSendingFile,
    uploadprogress: handleUploadProgress,
    error: handleError,
    success: handleSuccess,
    chunksUploaded: function (file, done) {
      // All chunks have been uploaded. Perform any other actions
      return done();
    },
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <DropzoneComponent
        config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    </React.Fragment>
  );
};

FileUpload.defaultProps = {
  onRemovedFile: () => null,
  onUploadFile: () => null,
  onUpdateFiles: () => null,
  isResult: true,
  typeUpload: "project"
};

FileUpload.propTypes = {
  projectId: PropTypes.string,
  partnerId: PropTypes.number,
  onRemovedFile: PropTypes.func,
  onUploadFile: PropTypes.func,
  onUpdateFiles: PropTypes.func,
  isResult: PropTypes.bool,
  typeUpload: PropTypes.string
};

export default FileUpload;
