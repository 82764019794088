// Core 
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; 
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import RisksCheckboxes from 'components/RisksCheckboxes';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
// Components
import { ViewDetails } from 'components/shared';
import FormInfoSection from 'components/FormInfoSection';
import IconButton from 'components/IconButton';
import StakeholdersCheckboxes from 'components/StakeholdersCheckboxes';
// Constants
import { ENHANCED_ANALYSIS, PESTEL_ANALYSIS, ROLE_ADMIN_READ_ONLY } from 'utils';

const AnalysisDetailsCard = ({
  analysisType,
  country,
  countryCode,
  industry,
  risks,
  stakeholders,
  handleBack,
  logginUserRole
}) => {
  const [osintRecords, setOsintRecords] = useState();
  useEffect(() => {
    if (risks) {
      let chosenOsintValues = new Map();
      risks.map(risk => {
        chosenOsintValues.set(risk.id, risk.value);
        return true;
      });
      setOsintRecords(chosenOsintValues);
    };
  }, [risks, setOsintRecords])

  return(
    <Grid>
      <Typography variant="h3" className="pl-0 pb-3 pt-3">
        {
          analysisType === PESTEL_ANALYSIS ?
            "Risk-based PESTEL Analysis" :
            "Enhanced Country Risk Analysis"
        }
        <IconButton
            disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
            inverted="true" 
            className="minus-mr-5 minus-mt-5 float-right"
            onClick={handleBack}
          >
            <Icon className="fa fa-pen" />
          </IconButton>
      </Typography>
      <Grid
        container
        direction="row"
        spacing={2}
        className="pb-4">
          <ViewDetails 
            label={<FormInfoSection text="Country"/>}
            name={country}
            countryCode={countryCode}
          />
          <ViewDetails 
            label={<FormInfoSection text="Industry"/>}
            name={industry}
          />
      </Grid>
      <Divider className="mt-1 mb-2" />
      <Typography variant="h3" className="pl-0 pt-3 pb-3">
        Risks
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true" 
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}
        >
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid
        container
        direction="column"
        className="pb-3">
        {
          risks && ( 
            <RisksCheckboxes 
              analysisType={analysisType}
              doubleColumned={true}
              risks={risks}
              osintValues={osintRecords}
              disabled={true}
            />
          )
        }
        {
          parseInt(analysisType) === ENHANCED_ANALYSIS && stakeholders ?
          <StakeholdersCheckboxes 
            disabled={true}
            stakeholders={stakeholders}/> :
          <></>
        }
      </Grid>
      <Divider className="mt-1 mb-2 minus-m-25" />
    </Grid>
  );
}

AnalysisDetailsCard.defaultProps = {
  analysisType: 1,
  country: "",
  countryCode: "",
  industry: "",
  risks: [],
  stakeholders: [],
  handleBack: () => null
}

AnalysisDetailsCard.propTypes = {
  analysisType: PropTypes.number,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  industry: PropTypes.string,
  risks: PropTypes.array,
  stakeholders: PropTypes.array,
  handleBack: PropTypes.func
}

export default AnalysisDetailsCard;
