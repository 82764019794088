// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import AnalysisDetailsCard from 'components/AnalysisDetailsCard';
import AnalysisTypeCard from 'components/AnalysisTypeCard';
import FormInfoSection from 'components/FormInfoSection';
import ProjectSubjects from 'components/Project/Subjects';
import ProjectSummaryBlock from 'components/Project/ProjectSummaryBlock';
import ResultFiles from 'components/ResultFiles';
import Spinner from 'components/shared/Spinner';
import StickyBlock from 'components/UI/StickyBlock';
import Textarea from 'components/Textarea';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchProjectById } from 'api/projects';
import { getUserFilesList } from 'api/files';
// Constants
import {
  SI_SERVICE,
  ENHANCED_ANALYSIS,
  ROLE_ADMIN_READ_ONLY,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE, PARTNER_SEARCH_SERVICE, TRENDS_INTELIGENCE_SERVICE,
} from 'utils';
import LegalComplianceDetailsCard from './LegalCompilanceStepForms/LegalComplianceDetailsCard';
import TechIntelligenceDetailsCard from './TechIntelligenceStepForms/TechIntelligenceDetailsCard';
import TechIntelligenceTypeCard from './TechIntelligenceStepForms/TechIntelligenceTypeCard';
import PartnerSearchDetailsCard from "./PartnerSearchStepForms/PartnerSearchDetailsCard";
import TrendsIntelligenceDetailsCard from "./TrendsIntelligenceStepForms/TrendsIntelligenceDetailsCard";

const useStyles = makeStyles((theme) => ({
  relativePosition: {
    [theme.breakpoints.up('lg')]: {
      marginTop: '-15%',
    }
  },
}));

const RequestSummaryForm = ({
  values,
  handleBack,
  handleBackTwoSteps,
  onDeleteRelatedSubject,
  logginUserRole,
}) => {
  const classes = useStyles();
  const [osintValue, setOsintValue] = useState(null);

  const {
    execute: fetchProject,
    pending: fetchingProject,
    value: project,
  } = useAsync(fetchProjectById, false);

  const {
    execute: hundleGetUserFilesList,
    setValue: setAllFiles,
    pending: userFilesLoading,
    value: userFiles,
  } = useAsync(getUserFilesList, false);

  useEffect(() => {
    if (values.newProjectId === 0) return;
    fetchProject(values.newProjectId);
  }, [fetchProject, values.newProjectId]);

  useEffect(() => {
    if (project && project.project_id) {
      hundleGetUserFilesList(project.project_id, false, true);
    }
  }, [hundleGetUserFilesList, project]);

  useEffect(() => {
    let foundValue = null;
    if (
      project &&
      project.service_id === SI_SERVICE &&
      project.config_id === ENHANCED_ANALYSIS
    ) {
      foundValue = project.analysis_details.risks[0].value;
      setOsintValue(foundValue);
    }
  }, [project]);

  let projectId;
  let subjects;
  if (project) {
    projectId = project.project_id;
    if (project.service_id !== SI_SERVICE) {
      subjects = project.subjects;
    }
  }

  const handleUpdateUserFiles = (file_obj, toRemove = false) => {
    if (file_obj && file_obj.id) {
      const current_file = userFiles.filter(file => file.id === file_obj.id);
      if (current_file.length === 0 && !toRemove) {
        userFiles.push(file_obj);
        setAllFiles(userFiles);
      } else if (toRemove) {
        setAllFiles(userFiles.filter(file => file.id !== file_obj.id));
      }
    }
  };

  const projectSubjects = (
    <ProjectSubjects
      serviceId={values.serviceId}
      typeKeySubject={values.typeKeySubject}
      subjects={subjects}
      activities={{ handleBack, handleBackTwoSteps }}
      onDeleteRelatedSubject={onDeleteRelatedSubject}
      logginUserRole={logginUserRole}
    />
  );

  const analysisTypeCard = project &&
    project.analysis_details &&
    project.config_id && (
      <>
        <AnalysisTypeCard
          configId={project.config_id}
          handleBackTwoSteps={handleBackTwoSteps}
          logginUserRole={logginUserRole}
        />
        <AnalysisDetailsCard
          analysisType={project.config_id}
          country={project.analysis_details.country_name}
          countryCode={project.analysis_details.country_code}
          industry={project.analysis_details.industry_name}
          risks={project.analysis_details.risks}
          stakeholders={project.analysis_details.stakeholders}
          handleBack={handleBack}
          logginUserRole={logginUserRole}
        />
      </>
    );

  const LegalComplianceCard = project && project.legal_comp_details && (
    <>
      <LegalComplianceDetailsCard
        country={project.legal_comp_details.country_name}
        countryCode={project.legal_comp_details.country_code}
        industry={project.legal_comp_details.industry_name}
        sub_services={project.legal_comp_details.sub_services}
        handleBack={handleBack}
        logginUserRole={logginUserRole}
      />
    </>
  );

  const TechIntelligenceCard = project && project.tech_intelli_details && (
    <>
      <TechIntelligenceTypeCard
        configId={project.config_id}
        handleBackTwoSteps={handleBackTwoSteps}
        logginUserRole={logginUserRole}
      />
      <TechIntelligenceDetailsCard
        country={project.tech_intelli_details.country_name}
        countryCode={project.tech_intelli_details.country_code}
        industry={project.tech_intelli_details.industry_name}
        expectations={project.tech_intelli_details.expectations}
        challenge_problem={project.tech_intelli_details.challenge_problem}
        handleBack={handleBack}
        logginUserRole={logginUserRole}
      />
    </>
  );

  const PartnerSearchCard = project && project.partner_search_details && (
    <>
      <PartnerSearchDetailsCard
        country={project.partner_search_details.country_name}
        countryCode={project.partner_search_details.country_code}
        industry={project.partner_search_details.industry_name}
        partnership_types={project.partner_search_details.partnership_types}
        handleBack={handleBack}
        logginUserRole={logginUserRole}
      />
    </>
  );

  const TrendsIntelligenceCard = project && project.trends_intelligence_details && (
      <>
        <TrendsIntelligenceDetailsCard
          keyMarkets={project.trends_intelligence_details.key_markets}
          keyProducts={project.trends_intelligence_details.key_products}
          keyCompetitors={project.trends_intelligence_details.key_competitors}
          subjectsToImprove={
            project.trends_intelligence_details.subjects_to_improve
          }
          handleBack={handleBack}
          logginUserRole={logginUserRole}
        />
      </>
    );

  return (
    <>
      {projectId && !fetchingProject ? (
        parseInt(project.service_id) === SI_SERVICE && project.config_id ? (
          analysisTypeCard
        ) : parseInt(project.service_id) === LEGAL_COMP_SERVICE ? (
          LegalComplianceCard
        ) : parseInt(project.service_id) === TECH_INTELLI_SERVICE ? (
          TechIntelligenceCard
        ) : parseInt(project.service_id) === PARTNER_SEARCH_SERVICE ? (
          PartnerSearchCard
        ) : parseInt(project.service_id) === TRENDS_INTELIGENCE_SERVICE ? (
          TrendsIntelligenceCard
        ) : (
          projectSubjects
        )
      ) : (
        <Spinner />
      )}
      {projectId && !fetchingProject && !userFilesLoading && (
        <Grid
          container
          direction="column"
          alignItems="stretch"
          className="pb-3">
          <Grid container direction="column" alignItems="stretch">
            <ResultFiles
              serviceId={values.serviceId}
              files={userFiles}
              projectId={projectId}
              editable={logginUserRole !== ROLE_ADMIN_READ_ONLY}
              handleUpdateFiles={handleUpdateUserFiles}
              columns={2}
              isResult={false}
            />
          </Grid>
          <Divider className="mt-1 mb-2 minus-m-25" />
        </Grid>
      )}
      <Grid container direction="column" className="pb-3">
        <Typography variant="h3" className="pb-4 pl-0 pr-0">
          Comments
        </Typography>
        <FormInfoSection
          text="Please leave a message if you have comments regarding project details or due date"
          isCapitalize={false}
        />
        <Textarea
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          id="comment"
          name="comment"
          value={values.comment}
        />
      </Grid>
      {projectId && !fetchingProject && (
        <StickyBlock className={classes.relativePosition}>
          <ProjectSummaryBlock project={project} osintValue={osintValue} />
        </StickyBlock>
      )}
    </>
  );
};

RequestSummaryForm.defaultProps = {
  values: null,
  handleBack: () => null,
  handleBackTwoSteps: () => null,
  onDeleteRelatedSubject: () => null,
};

RequestSummaryForm.propTypes = {
  values: PropTypes.shape({}),
  handleBack: PropTypes.func,
  handleBackTwoSteps: PropTypes.func,
  onDeleteRelatedSubject: PropTypes.func,
};

export default RequestSummaryForm;
