// Core
import React, { createContext, useEffect } from 'react';
// Hooks
import { useAuth } from 'hooks/useAuth';
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchProjectStatuses } from 'api/projects';

export const ProjectStatusesContext = createContext();

const ProjectStatusesProvider = ({ children }) => {
  let { accessToken } = useAuth();
  if (!accessToken) {
    accessToken = localStorage.getItem('access_token');
  }

  const {
    execute: getProjectStatuses,
    pending: isLoadingProjectStatuses,
    value: projectStatuses,
  } = useAsync(fetchProjectStatuses, false);

  useEffect(() => {
    if (accessToken) {
      getProjectStatuses();
    }
  }, [getProjectStatuses, accessToken]);

  return (
    <ProjectStatusesContext.Provider
      value={{ projectStatuses, isLoadingProjectStatuses }}>
      {children}
    </ProjectStatusesContext.Provider>
  );
};

export default ProjectStatusesProvider;
