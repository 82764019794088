// Core
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
// @material-ui
import { Box, Typography } from '@material-ui/core';
// Components
import Button from 'components/CustomButton';

const Status = ({ code, children }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

const NotFoundPage = () => {
  const { push } = useHistory();

  return (
    <Status code={404}>
      <Box paddingTop="25px">
        <Typography gutterBottom variant="h1">
          404 Page not found
        </Typography>
        <Typography gutterBottom variant="h2">
          Sorry. The page seems to be missing, please try again.
        </Typography>
        <Button variant="outlined" onClick={() => push('/home')}>
          Go to Home page
        </Button>
      </Box>
    </Status>
  );
};

export default NotFoundPage;
