const links = [
  {
    name: 'United Nations Security Council Sanctions List',
    url: 'https://scsanctions.un.org/'
  },
  {
    name: 'European Union Consolidated Financial Sanctions List',
    url: 'https://sanctionsmap.eu'
  },
  {
    name: "Interpol's Most Wanted List (Red Notices)",
    url: 'https://interpol.int'
  },
  {
    name: 'World Bank Debarment List',
    url: 'https://worldbank.org'
  },
  {
    name: 'Specially Designated Nationals and Blocked Persons List by the Office of Foreign Assets Control (OFAC) of the U.S. Department of Treasury',
    url: 'https://sanctionssearch.ofac.treas.gov'
  },
  {
    name: 'Foreign Terrorist Organizations List Designated by U.S. Department of State',
    url: 'https://state.gov'
  },
  {
    name: 'List of the Most Wanted Terrorists of the U.S. Federal Bureau of Investigation',
    url: 'https://fbi.gov'
  },
  {
    name: 'U.S. Consolidated Screening List (CSL) of the International Trade Administration',
    url: 'https://trade.gov'
  },
  {
    name: 'U.S. Government Debarment List',
    url: 'https://sam.gov'
  },
  {
    name: 'United Kingdom Consolidated Financial Sanctions List',
    url: 'https://gov.uk'
  },
  {
    name: 'Canadian Consolidated Autonomous Sanctions List',
    url: 'https://international.gc.ca'
  },
  {
    name: 'Swiss State Secretariat for Economic Affairs (SECO) List',
    url: 'https://www.seco.admin.ch/seco/en/home/Aussenwirtschaftspolitik_Wirtschaftliche_Zusammenarbeit/Wirtschaftsbeziehungen/exportkontrollen-und-sanktionen/sanktionen-embargos/sanktionsmassnahmen/suche_sanktionsadressaten.html'
  },
  {
    name: 'Ministry of Economy, Trade and Industry of Japan (METI) List',
    url: 'https://meti.go.jp'
  },
  {
    name: 'Offshore Leaks Database by The International Consortium of Investigative Journalists',
    url: 'https://offshoreleaks.icij.org'
  },
];

export default links;
