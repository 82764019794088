import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import FormikInput from 'components/FormikInput';
import Button from 'components/CustomButton';


const initialValues = { email: '' };

const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    })
    .required('Required'),
});

const ForgotPasswordForm = ({ className, getCodeError, onGetCode }) => {
  const { push } = useHistory();

  const goToLoginPage = () => {
    push('/');
  };
  const onSubmit = (values, setSubmitting) => {
    onGetCode(values);

    setTimeout(() => {
      setSubmitting(false);
    }, 3000);  
  };

  const mailError = getCodeError && getCodeError.split('.');
  const renderForm = values => {
    const form = Object.keys(values).map(key => {
      const type = 'text';
      let error = '';
      if (mailError && mailError[1]) {
        error = mailError[1];
      }
      
      return (
        <FormikInput
          key={key}
          mandatory
          type={type}
          offMultiline={true}
          id={key}
          name={key}
          label={key}
          value={values[key]}
          apiError={error}
        />
      );
    });
  
    return form;
  };

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
        {({ values, handleSubmit, isSubmitting, isValid }) => (
          <form className={className} onSubmit={handleSubmit}>
            {renderForm(values)}
            <Button
              type="submit"
              className="wideButton"
              disabled={!isValid || isSubmitting}>
                Reset
            </Button>
            <Button
              variant='outlined'
              onClick={goToLoginPage}
              className="wideButton">
                Cancel
            </Button>
          </form>
        )}
      </Formik>
  );
}

export default ForgotPasswordForm;
