// Core
import React from 'react';
// @material-ui
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

const ExpansionPanel = withStyles({
  root: {
    marginBottom: 10,
    '&:not(:last-child)': {
      borderBottom: '0px solid',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: 10,
      '& .disabled': {
        cursor: 'default',
      },
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: '5px 0 10px',
    marginBottom: 0,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
    '&.hideSummary': {
      display: 'none',
    },
    '&.disabled': {
      cursor: 'default',
      '& p': {
        cursor: 'default',
        marginRight: 0,
      },
    },
  },
  content: {
    position: 'relative',
    display: 'block',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
    '& p': {
      marginRight: 30,
      position: 'relative',
    },
    '& p span.expandTitleText': {
      backgroundColor: '#ffffff',
      position: 'relative',
      zIndex: 1,
      paddingRight: 10,
    },
    '& p:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: 1,
      borderBottom: '1px solid var(--black-20)',
      bottom: 'calc(50% - 1px)',
      zIndex: 0,
    },
  },
  expanded: {
    margin: 0,
    minHeight: 'auto',
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    position: 'relative',
    padding: '5px 0',
  },
})(MuiExpansionPanelDetails);

const IconButton = withStyles({
  root: {
    position: 'absolute',
    right: '-35px',
    bottom: '-15px',
    '&.icon-row-down': {
      bottom: '-8px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(MuiIconButton);

const CustomExpansionPanel = ({ id="panel1bh", title="", defaultExpanded=true, disabled=false, children }) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const hideSummaryClass = title.length === 0 ? "hideSummary" : "";
  const disabledClass = disabled ? " disabled" : "";

  const handleExpandClick = () => {
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  return (
    <ExpansionPanel expanded={expanded} onChange={handleExpandClick}>
      <ExpansionPanelSummary className={hideSummaryClass + disabledClass} aria-controls={id + "-content"} id={id + "-header"}>
        <Typography variant="body2">
          <span className="expandTitleText">{title}</span>
          {!disabled &&
          <IconButton className={"icon-row-" + (Boolean(expanded) ? "up" : "down")}>
            <Icon color="primary" className={"fa fa-sort-" + (Boolean(expanded) ? "up" : "down")} />
          </IconButton>
          }
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails><>{children}</></ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CustomExpansionPanel;
