// Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import SubjectsToImproveCheckboxes from '../../SubjectsToImproveCheckboxes/SubjectsToImproveCheckboxes';
import { TRENDS_INTELLIGENCE_CONFIG } from '../../../utils';
import FormInfoSection from '../../FormInfoSection';
import Textarea from '../../Textarea';

const useStyles = makeStyles({
  form: {
    paddingRight: 10,
    listStyle: 'none',
    scrollbarWidth: 'thin',
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    },
  },
  servicesLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--black-60)',
  },
});

const TrendsIntelligenceGeneralForm = ({
  values,
  subjects_to_improve,
  config_id,
  dynamicValues,
  subjectToImproveFieldOnChange,
}) => {
  const classes = useStyles();
  return (
    <Grid className={clsx(classes.form, 'pt-2')}>
      <Grid container direction="column" className="pb-3">
        <FormInfoSection text="Key Markets" isCapitalize={false} />
        <Textarea
          id="key_markets"
          name="trendsIntelligence.key_markets"
          value={values && values.key_markets}
          rows={3}
        />
        <FormInfoSection text="Key Products" isCapitalize={false} />
        <Textarea
          id="key_products"
          name="trendsIntelligence.key_products"
          value={values && values.key_products}
          rows={3}
        />
        <FormInfoSection text="Key Competitors" isCapitalize={false} />
        <Textarea
          id="key_competitors"
          name="trendsIntelligence.key_competitors"
          value={values && values.key_competitors}
          rows={3}
        />
      </Grid>
      <Grid item className="pb-3">
        <Typography className={classes.servicesLabel}>
          Subjects to improve
        </Typography>
      </Grid>
      {subjects_to_improve && (
        <SubjectsToImproveCheckboxes
          subjects_to_improve={subjects_to_improve}
          subjectsToImproveRecords={dynamicValues.subjects_to_improve}
          onSubjectsToImproveFieldChange={subjectToImproveFieldOnChange}
        />
      )}
      <Grid className="pb-3" />
    </Grid>
  );
};

TrendsIntelligenceGeneralForm.defaultProps = {
  configId: TRENDS_INTELLIGENCE_CONFIG,
  values: null,
  subjects_to_improve: [],
  dynamicValues: null,
  subjectToImproveFieldOnChange: () => null,
};

TrendsIntelligenceGeneralForm.propTypes = {
  values: PropTypes.shape({
    settings_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key_markets: PropTypes.string,
    key_products: PropTypes.string,
    key_competitors: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  subjects_to_improve: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  config_id: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  dynamicValues: PropTypes.object,
  subjectToImproveFieldOnChange: PropTypes.func,
};

export default TrendsIntelligenceGeneralForm;
