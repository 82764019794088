// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const StyledButton = withStyles({
  root: {
    height: 32,
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: 4,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    margin: '5px',
  },

  label: {
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
})(Button);

const CustomButton = ({
  type,
  variant,
  color,
  className,
  style,
  disabled,
  children,
  onClick,
  component
}) => 
  (<StyledButton
    type={type}
    variant={variant}
    color={color}
    className={className}
    style={style}
    disabled={disabled}
    onClick={onClick}
    component={component}>
    {children}
  </StyledButton>
);


CustomButton.defaultProps = {
  type: 'button',
  variant: 'contained',
  color: 'primary',
  className: '',
  style: {},
  disabled: false,
  onClick: () => null,
};

CustomButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
};

export default CustomButton;
