import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import clsx from'clsx';

const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px",
      color: 'var(--primary-regular)',
      fontWeight: 600
    }
  },
  tableCellName: {
    fontWeight: 500,
    width: '90%',
    textAlign: 'justify',
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  head: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'var(--primary-regular)'
  },
  text: {
    color: 'var(--primary-regular)',
  }
});

const rows = [
  {
    name: "1. Are any of your company’s directors, shareholders or UBOs politically exposed persons (PEP)?",
    path: "politically_exposed_persons"
  },
  {
    name: "2. Does your company have a Compliance Officer or a relevant function/position?",
    path: "exist_compliance_officer"
  },
  {
    name: "3. Does your company have any documented compliance policies and procedures?",
    path: "compliance_policies_procedures"
  },
  {
    name: "- Code of Conduct",
    path: "code_of_conduct"
  },
  {
    name: "- Anti-Bribery and Corruption",
    path: "anti_bribery_corruption"
  },
  {
    name: "- Anti-money Laundering and Combatting Terrorism Financing",
    path: "anti_money_laundering"
  },
  {
    name: "- Sanctions Policy",
    path: "sanctions_policy",
  },
  {
    name: "- Third Party Risk Management",
    path: "third_party_risk_management",
  },
  {
    name: "- Conflict of Interest",
    path: "conflicts_of_interests",
  },
  {
    name: "- Environmental, Social and Governance (ESG)",
    path: "environmental_social_and_governance",
  },
  {
    name: "- Other compliance policies",
    path: "other_compliance_policies"
  },
  {
    name: "4. Will any third parties or subcontractors be used for the purpose of our business relations?",
    path: "third_parties_subcontractors_purpose"
  },
  {
    name: "- If YES, please confirm that your company will conduct due diligence on the involved third parties.",
    path: "conduct_due_diligence"
  },
  {
    name: "5. Is your company under bankruptcy, liquidation or dissolution proceedings?",
    path: "bankruptcy_liquidation_proceedings"
  },
  {
    name: "6. Is or was your company or its affiliated entities (i.e., subsidiary, parent entity, joint venture) under any direct or indirect sanctions (UN, US, EU, UK, other) or import/export control restrictions?",
    path: "company_sanctions_restrictions"
  },
  {
    name: "7. Does your company or its affiliated entities (i.e., subsidiary, parent entity, joint venture) have any current or former business activity with any country that is subject to any sanctions or international operational restrictions?",
    path: "country_sanctions_restrictions"
  },
  {
    name: "8. Is or was any shareholder, UBO, management, employee of your company under any sanctions or international operational restrictions?",
    path: "individual_international_operation_restrictions",
  },
  {
    name: "9. Is or was any shareholder, UBO, management, employee of your company under any sanctions or international operational restrictions?",
    path: "individual_sanctions_restrictions"
  }
];

const ComplianceProceduresPart = ({
  compliance_procedures
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>Compliance procedures</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <React.Fragment key={row.name}>
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tableCellName}>
                  {row.name}
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  {compliance_procedures[row.path] ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              {
                (compliance_procedures[row.path] && compliance_procedures[`${row.path}_text`]) &&
                  <TableRow >
                    <TableCell component="th" scope="row" className={clsx(classes.tableCellName, classes.text)}>
                      {compliance_procedures[`${row.path}_text`]}
                    </TableCell>
                  </TableRow>
              }
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ComplianceProceduresPart;
