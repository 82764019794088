// Core
import clsx from 'clsx';
import React, { useContext } from 'react';
import PropTypes, { shape } from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// Components
import CustomSwitch from 'components/UI/CustomSwitch/CustomSwitch';
import FormikInput from 'components/FormikInput';
import RisksCheckboxes from 'components/RisksCheckboxes';
import StakeholdersCheckboxes from 'components/StakeholdersCheckboxes/';
// Context 
import { CountriesContext } from 'context/CountriesContext';
// Constants
import { PESTEL_ANALYSIS, ENHANCED_ANALYSIS } from 'utils';

const useStyles = makeStyles({
  form: {
    paddingRight: 10,
    listStyle: "none",
    scrollbarWidth: "thin",
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    }
  },
  risksLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: 'var(--black-100)'
  },
  risksDescription: {
    fontSize: 10,
    fontWeight: 500,
    color: 'var(--black-40)'
  },
  industriesSelect: {
    '& .MuiOutlinedInput-root': {
      overflow: 'unset !important',
      height: 30,
    },
    '& .MuiInputBase-input': {
      position: 'absolute',
      maxWidth: '90%',
    },
    marginBottom: '0px !important',
  },
});

const AnalysisDetailsForm = ({
  values,
  industries,
  risks,
  stakeholders,
  analysisType,
  dynamicValues,
  osintToggleChecked,
  riskFieldOnChange,
  stakeholderFieldOnChange,
  pestelAnalysisOsintOnChange,
  onEnhancedToggleChecked,
}) => {
  const classes = useStyles();
  const { countryMenuItems } = useContext(CountriesContext);
  const industryMenuItems = industries &&
    industries.map(industry => {
      const {id, name} = industry;
      return (
        <MenuItem key={id} value={parseInt(id)}>
          {name}
        </MenuItem>
      );
    });
    return (
    <Grid
      className={clsx(classes.form, "pt-2")}>
        <Grid item>
          <FormikInput
            select
            mandatory
            id="country_code"
            name="strategicIntelligence.country_code"
            value={values && values.country_code}
            label="Country">
              {countryMenuItems}
          </FormikInput>
        </Grid>
        <Grid item className="pb-2">
          <FormikInput
            select
            mandatory
            id="industry_id"
            name="strategicIntelligence.industry_id"
            //offMultiline={true}
            value={values && values.industry_id}
            label="industry"
            className={classes.industriesSelect}>
              {industryMenuItems}
          </FormikInput>
        </Grid>
        {analysisType === PESTEL_ANALYSIS ? 
          (<></>) :
          (<Grid item className="pb-4">
            <CustomSwitch 
              id="osintToggleChecked"
              name="osintToggleChecked"
              checked={osintToggleChecked && osintToggleChecked}
              onChange={(event) => onEnhancedToggleChecked(event.target.checked)}
              label="OSINT"/>
          </Grid>)
        }
        <Grid item className="pb-3">
          <Typography className={classes.risksLabel}>Risks</Typography>
          <Typography className={classes.risksDescription}>
            Any risk can be chosen independently in any combination, including OSINT check
          </Typography>
        </Grid>
        {
          risks && ( 
            <RisksCheckboxes 
              analysisType={analysisType}
              risks={risks} 
              risksRecords={dynamicValues.risks} 
              onRiskFieldChange={riskFieldOnChange}
              osintValues={dynamicValues.osintValues}
              onOsintFieldChange={pestelAnalysisOsintOnChange}/>
          ) 
        }
        <Grid className="pb-3"/>
        {
          parseInt(analysisType) === ENHANCED_ANALYSIS ? (
            <>
              <Grid className="pb-2"/>
              <StakeholdersCheckboxes
                stakeholders={stakeholders}
                stakeholderRecords={dynamicValues.stakeholders}
                onStakeholderFieldChange={stakeholderFieldOnChange}/>
            </>
          ) : <></>
        }
    </Grid>
  )
}

AnalysisDetailsForm.defaultProps = {
  values: null,
  industries: [],
  risks: [],
  analysisType: null,
  dynamicValues: null,
  osintToggleChecked: false,
  riskFieldOnChange: () => null,
  stakeholderFieldOnChange: () => null,
  pestelAnalysisOsintOnChange: () => null,
  onEnhancedToggleChecked: () => null,
}

AnalysisDetailsForm.propTypes = {
  values: PropTypes.shape({
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    industry_name: PropTypes.string,
    settings_id:  PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    risks: PropTypes.array,
    stakeholders: PropTypes.array,
  }),
  industries: PropTypes.arrayOf(shape({
    id: PropTypes.number,
    name: PropTypes.string,
    score: PropTypes.string,
  })),
  risks: PropTypes.array,
  stakeholders: PropTypes.array,
  analysisType: PropTypes.number,
  dynamicValues: PropTypes.object,
  osintToggleChecked: PropTypes.bool,
  riskFieldOnChange: PropTypes.func,
  stakeholderFieldOnChange: PropTypes.func,
  pestelAnalysisOsintOnChange: PropTypes.func,
  onEnhancedToggleChecked: PropTypes.func,
}

export default AnalysisDetailsForm;
