// Core
import React from 'react';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  linkWrapper: {
    display: 'flex',
    maxWidth: 600
  },
  linkBody: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 20,
    margin: '3px 0px',

    '&:hover': {
      color: 'var(--secondary-blue)',
      transition: '0.2s',
      textDecoration: 'underline',
      "&.linkText": {
        color: 'var(--secondary-blue)'
      }
    }
  },
  linkDot: {
    paddingTop: 4,
    color: 'var(--brownish-orange)',
    fontSize: 10,
    marginRight: 7
  },
  linkText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '1.2'
  }
});

const WatchlistGuideLink = ({ name, url }) => {
  const styles = useStyles();

  return (
    <Grid className={styles.linkWrapper}>
      <a href={url} className={styles.linkBody} target="_blank" rel="noopener noreferrer">
        <span className={styles.linkDot}>
          <i className="fas fa-circle" />
        </span>
        <span className={styles.linkText}>
          {name}
        </span>
      </a>
    </Grid>
  );
}

export default WatchlistGuideLink;
