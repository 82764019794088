import React, { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
// @material-ui
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Components
import BackgroundTheming from 'components/BackgroundTheming';
import Footer from 'components/Footer';
import ResetPasswordForm from 'components/ResetPasswordForm';
import Wrapper from 'components/Wrapper';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { activateUser } from 'api/auth';

const useStyles = makeStyles(theme => {
  return {
    card: {
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        padding: 40,
      },
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
      padding: 60,
      paddingBottom: '0px !important',
      minWidth: '300px',
      borderRadius: 12,
    },

    generalWrapActivationUser: {
      [theme.breakpoints.down('lg')]: {
        minHeight: '67vh',
      },
      [theme.breakpoints.down('md')]: {
        minHeight: '72vh',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '80vh',
      },
      // height: '60vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },

    // forMac: {
    //   [theme.breakpoints.down('lg')]: {
    //     paddingTop: 100,
    //   },
    // },

    title: {
      margin: '0px -25px 25px',
      padding: '0px 25px',
      fontSize: 36,
  
      '& div': {
        marginBottom: -4,
  
        '& span': {
          paddingBottom: 16,
          fontSize: 36,
          fontWeight: 600,
          wordWrap: 'normal',
          color: 'var(--navy)',
          lineHeight: 'normal',
        },
      },
    },
  
    form: {  
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        height: 48,
        '& .MuiInputBase-input': {
          height: '70%'
        },
      }
    },

    signIn: {
      marginTop: 25,
      fontSize: 15,
      textAlign: 'center',
      color: theme.palette.custom.greyish,
    },

    signInLink: {
      color: theme.palette.custom.brownishOrange,
    },

    footerForActivationUser: {
      display: 'flex',
      width: '100%',
      justifyContent: 'end',
      [theme.breakpoints.down('xl')]: {
        padding: '30px 60px 0px 60px',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '30px 40px 0px 40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 20px 0px 20px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 20px 0px 20px',
      },
      minWidth: '300px',
      backgroundColor: theme.palette.common.white,
      borderRadius: 12,
      paddingBottom: '0px !important',
      paddingTop: '0px !important'
    },
  };
});

const UserActivationPage = () =>{
  const classes = useStyles();
  const { push } = useHistory();

  const { execute: onResetPassword, value: result, error: resetPasswordError } = useAsync(
    activateUser,
    false,
  );

  useEffect(() => {
    if (result) {
      toast.success(result.message, { autoClose: 3000 });
      setTimeout(() => {
        push("/sign_in");
      }, 1500);
    }
  }, [result, push]);

  const match = useRouteMatch({
    path: '/user_activation/:id',
    strict: true,
    sensitive: true,
  });

  return (
    <BackgroundTheming image={match && 'earthGlobe'}>
      <ToastContainer />
      <Wrapper>
        <Grid className={classes.generalWrapActivationUser}>
          <Card className={classes.card}>
            <CardHeader title="User activation" className={classes.title} />
            <CardContent>
              <ResetPasswordForm
                className={classes.form}
                forgotPasswordError={resetPasswordError}
                formResetPassword={onResetPassword}
              />
            </CardContent>
          </Card>
          <Card className={classes.footerForActivationUser}>
            <Footer columned={true}/>
          </Card>
        </Grid>
      </Wrapper>
    </BackgroundTheming>
  );
}

export default UserActivationPage;
