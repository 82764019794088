// Core
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
// Components
import CandidateProfileForm from './CandidateProfileForm';
import MapChart from 'components/MapChart/MapChart';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchProjectConfig } from 'api/projects';
// Constants
import { PES_SERVICE } from 'utils';

const CandidateProfileStep = ({
  typeKeySubject,
  humint,
  individualValues,
  selectedConfig,
  serviceId,
  onSetFieldValue
}) => {

  const { execute: getProjectConfig, value: projectConfig, status: getPESConfigStatus } = useAsync(
    fetchProjectConfig,
    false,
  );

  useEffect(() => {
    if (serviceId && parseInt(serviceId) === PES_SERVICE) {
      getProjectConfig(serviceId);
      onSetFieldValue('typeKeySubject', typeKeySubject);
    }
  },[getProjectConfig, onSetFieldValue, serviceId, typeKeySubject]);

  const setConfigParam = useCallback((humintValue) => {
    const currentProjectConfig = projectConfig && Object.values(projectConfig).filter(
      configuration => humintValue === configuration.humint
    );
    if (currentProjectConfig !== null) {
      onSetFieldValue('selectedConfig', currentProjectConfig[0]);
      onSetFieldValue('humint', humintValue);
    }
  }, [onSetFieldValue, projectConfig]);

  useEffect(() => {
    if (getPESConfigStatus === 200 && Object.keys(selectedConfig).length === 0) {
      setConfigParam(humint);
    }
  }, [setConfigParam, selectedConfig, getPESConfigStatus, humint]);

  const handleSetCurrentProjectConfig = (humintValue) => {
    setConfigParam(humintValue);
  }

  return (
    <Grid
        container
        alignItems="stretch"
        direction="row">
      <Grid item xs={12} md={5} className="pr-4">
        <CandidateProfileForm individual={individualValues} index={0} />
        <Divider className="minus-m-25" />
        <Grid 
          container
          direction='column'>
          <Grid className="pt-3 pb-1">
            <Typography className="pl-0" variant="h3">HUMINT</Typography>
          </Grid>
          <FormControl className="mb-4 flex-centered">
            <FormControlLabel
              value={humint}
              checked={humint}
              control={<Checkbox
                id="humint"
                name="humint"
                color="primary"
                onChange={(event) => {handleSetCurrentProjectConfig(event.target.checked)}}
              />}
              label="Activate"
              className="mr-2" />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} className='leftBorderMap'>
        <Grid container className='generalMapWrapper'>
          <Grid item className='generalMapWrapper'>
            <MapChart width="100%" selectedCountry={individualValues.country_code}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

CandidateProfileStep.defaultProps = {
  typeKeySubject: true,
  humint: false,
  individualValues: {},
  selectedConfig: {},
  onSetFieldValue: () => null,
};

CandidateProfileStep.propTypes = {
  typeKeySubject: PropTypes.bool,
  humint: PropTypes.bool,
  individualValues: PropTypes.shape({}),
  selectedConfig: PropTypes.shape({
    config_id: PropTypes.number,
    days_to_complete: PropTypes.number,
    humint: PropTypes.bool,
    level: PropTypes.number,
    sources: PropTypes.shape({}),
  }),
  serviceId: PropTypes.number.isRequired,
  onSetFieldValue: PropTypes.func,
};

export default CandidateProfileStep;
