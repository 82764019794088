import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    borderRadius: "4px",
    backgroundColor: 'var(--secondary-green)',
    color: 'var(--white)',
    fontWeight: '400',
    margin: '0 10px',

    "& span":{
      padding: '8px',
      fontSize: '14px'
    }
  },
}));

const CompanyTextLabel = ({text}) => {
  const classes = useStyles();
  return (
    <span className={classes.label}>
      <span>
        {text}
      </span>
    </span>
  );
}

export default CompanyTextLabel;
