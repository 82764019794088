import React, { useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
// Hooks
import useDialog from 'hooks/useDialog';


interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  options?: object | undefined;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteLeavingGuard = ({
  when,
  navigate,
  options,
  shouldBlockNavigation,
}: Props) => {

  let Location = useLocation();

  const {
    open: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm,
  } = useDialog();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(Location || null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    // do not save the data and continue navigation
    setModalVisible(false);
    handleCloseConfirm();
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      if (!modalVisible) {
        setModalVisible(true);
      }
      setLastLocation(nextLocation);
      handleOpenConfirm();
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    handleCloseConfirm();
    // do not save the data and continue navigation
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [navigate, confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmDialog
        title="Unsaved data"
        open={openConfirm}
        onClose={closeModal}
        options={options}
        confirmBtnText="Confirm"
        onConfirm={handleConfirmNavigationClick}>
        You are about to leave a page. All unsaved data will be lost. Please confirm.
      </ConfirmDialog>
    </>
  );
};

export default RouteLeavingGuard;
