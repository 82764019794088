// Core
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @material-ui
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Footer from 'components/Footer';
import RequestAccount from 'components/RequestAccount'
import SignInForm from 'components/SignInForm';
import Wrapper from 'components/Wrapper';
// Hooks
import { useAuth } from 'hooks/useAuth';

const useStyles = makeStyles(theme => {
  return {
    card: {
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        padding: 40,
      },
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
      padding: 60,
      minWidth: '300px',
      backgroundColor: theme.palette.common.white,
      borderRadius: 12,
    },

    title: {
      margin: '0px -25px 25px',
      padding: '0px 25px',
      fontSize: 36,

      '& div': {
        marginBottom: -4,

        '& span': {
          paddingBottom: 25,
          fontSize: 36,
          fontWeight: 'bold',
          wordWrap: 'normal',
          color: 'var(--navy)',
          lineHeight: 'normal',
        },
      },
    },

    forgotPassword: {
      position: 'absolute',
      top: 0,
      left: 5
    },

    forgotPasswordLink: {
      fontWeight: 600,
      fontSize: 15,
      color: 'var(--primary-regular)',
      fontFamily: 'Montserrat',
      textDecoration: 'underline'
    },
    
    form: {  
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        height: 48,
        '& .MuiInputBase-input': {
          height: '70%'
        },
      }
    },
    footerForSignin: {
      display: 'flex',
      width: '100%',
      justifyContent: 'end',
      [theme.breakpoints.down('xl')]: {
        padding: '0px 60px 5px 60px',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '0px 40px 5px 40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 20px 5px 20px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 20px 5px 20px',
      },
      minWidth: '300px',
      backgroundColor: theme.palette.common.white,
      borderRadius: 12,
      paddingBottom: '0px!important'
    },
    generalWrap: {
      [theme.breakpoints.down('lg')]: {
        minHeight: '67vh',
      },
      [theme.breakpoints.down('md')]: {
        minHeight: '72vh',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '80vh',
      },
      // height: '70vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    requestAccountButton: {
      [theme.breakpoints.down('xl')]: {
        padding: '0px 60px 5px 60px',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '0px 40px 5px 40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 20px 5px 20px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 20px 5px 20px',
      },
    }
  };
});

const SignInPage = () => {
  const classes = useStyles();
  const { replace } = useHistory();
  const { state } = useLocation();
  const { accessToken, signin, error: authError, pending, isHuman } = useAuth();

  const { from } = state || { from: { pathname: '/home' } };

  useEffect(() => {
    
    if (accessToken || localStorage.getItem('access_token')) {
      replace(from);
    }
  }, [accessToken, replace, from]);

  return (
    <Grid>
      <Wrapper>
        <Grid className={classes.generalWrap}>
          <Card className={classes.card}>
            <CardHeader title="Welcome to WASP" className={classes.title} />
            <CardContent>
              <SignInForm
                authError={authError}
                className={classes.form}
                onSignIn={signin}
                pending={pending}
                isHuman={isHuman}
              />
            </CardContent>
          </Card>
          <Card className={classes.requestAccountButton}>
            <RequestAccount />
          </Card>
          <Card className={classes.footerForSignin}>
            <Footer columned={true}/>
          </Card>
        </Grid>
      </Wrapper>
    </Grid>
  );
};

export default SignInPage;
