// Core
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Assets
import defaultAvatar from 'assets/profile_photo.jpg';

const useStyles = makeStyles({
  card: {
    paddingTop: '10px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'row'
  },

  commentText: {
    color: 'black',
    fontWeight: 600,
    fontSize: 14,
    wordBreak: 'break-word'
  },

  avatarWrapper: {
    width: 40,
    height: 40,
    borderRadius: 4,

    '& img': {
      width: 40,
      height: 40,
      borderRadius: 4,
    },
  },
});

const CommentCard = ({ comment, requester, requestDate, avatar_url }) => {
  const classes = useStyles();

  return (
    <Grid 
      container
      item
      className={classes.card}
      direction="column">
      <Grid
        container 
        item>
        <Grid className={classes.avatarWrapper}>
          <Avatar 
            variant="rounded"
            src={avatar_url !== null ? `${avatar_url}/?${Date.now()}` : defaultAvatar}
          />
        </Grid>
        <Grid className="pl-2">
          <Typography variant="body2">
            {requester}
          </Typography>
          <Typography className="pb-2">
            <label>{moment(requestDate,).format('DD MMM YYYY [at] HH:mm')}</label>
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.commentText}>
        {comment}
      </Typography>
    </Grid>
  );
};

CommentCard.defaultProps = {
  comment: '',
  requester: '',
  requestDate: '',
  avatar_url: ''
};

CommentCard.propTypes = {
  comment: PropTypes.string,
  requester: PropTypes.string,
  requestDate: PropTypes.string,
  avatar_url: PropTypes.string
};

export default CommentCard;
