// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  list: {
    listStyle: 'decimal',
    paddingLeft: 30
  },
  liName: {
    fontWeight: 600,
    fontStyle: 'italic',
  }
});

const TermsConditionsPage = ({hideTitle}) => {
  const classes = useStyles();

  return (
    <Grid>
      {
        !hideTitle && (
          <Typography variant="h2" paragraph={true} color="textPrimary">
            Terms and Conditions
          </Typography>
        )
      }
      <ul className={classes.list}>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Terms of Use</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            Welcome to www.wasp.cosa.solutions. These Terms of Use contain the terms and conditions that govern 
            all use of our WASP Platform and all content, services and/or products available on or through the 
            WASP Platform (“Platform”).
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The WASP Platform is offered to you subject to your acceptance by COSA LLC, company registered in 
            accordance with the law of Ukraine with a registered number 39142411 (the “Contractor”). When 
            accepted by you, these Terms form a legally binding contract between you and the Contractor. If 
            you are entering into these Terms on behalf of an entity, such as your employer or the company 
            you work for, you represent that you have the legal authority to bind that entity.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Platform enables the Client to register and create an account through which an order can 
            be placed (specifying required information for the further research). The Client receives the 
            unique login and password account details. Every order placed by the Client has its turnaround 
            time (TAT), by the end of which the completed report is placed for the Client through the Platform. 
            All information is stored on the Platform and is confidential, which the Client confirms to 
            acknowledge by accepting these Terms of Use.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            PLEASE READ THESE TERMS CAREFULLY. BY REGISTERING FOR, ACCESSING, AND/OR OTHERWISE USING THE 
            WASP PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE 
            TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, DO NOT ACCESS, OR OTHERWISE USE THE PLATFORM.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Contractor may, in its sole discretion, decide to suspend or terminate access to, or use of 
            the WASP Platform to anyone who violates these Terms. The original language of these Terms is English.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Authority to Enter into These Terms with the Contractor</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>2.1</span> The use of the WASP Platform is subject to acceptance of these Terms. 
            To accept these Terms for itself or on behalf of the Client, a person must have the legal capacity to do so. In the 
            case of a legal entity, the entity must be duly incorporated and in good standing.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>2.2</span> The Terms are accepted as soon as the person has received the 
            confirmation of the creation of the Account and necessary credentials from the Contractor in order to log in to the Account.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>2.3</span> Once accepted, these Terms remain effective until terminated as 
            provided for herein.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Modifications</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Contractor reserves the right to modify the Platform or this agreement, at any time, 
            by making notices of such modifications available at the Platform. Modifications will be 
            effective immediately upon posting such notices at the Platform.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Obligations of the Contractor</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>4.1</span> The Contractor will make the WASP Platform, its Content 
            and Client Data available to the Client pursuant to these Terms, provide support for the WASP Platform 
            to the Client, except for planned downtime (of which the Contractor shall give advance electronic notice), 
            and any unavailability caused by circumstances beyond the Contractor’s reasonable control.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>4.2</span> Provide the Services duly and properly in accordance with 
            these Terms, and requirements usual for services of this nature.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Obligations of the Client</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>5.1</span> Timely provide the Contractor through the Platform with 
            known and available information with regard to the area of the Services being rendered.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>5.2</span> Pay for the Services provided duly and properly.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Grant of rights</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            Subject to your compliance with this agreement, the Contractor grants you a non-transferable right 
            during the contractual period to use the Platform in accordance with the terms hereof and the user 
            instructions for the Platform as specified by the Contractor.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            You agree not to use, modify, reproduce, create derivative works from, or in any way exploit or utilize 
            the Platform other than as expressly permitted in this agreement or any other agreement that you may 
            be required to agree to before being given access to the Platform.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Protection of Client Data</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Contractor will maintain administrative, physical, and technical safeguards for protection 
            of the security, confidentiality and integrity of Client Data.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Confidentiality</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Contractor shall keep confidential the information learned while rendering the Services 
            and only disclose it to the Client or the persons identified by the Client.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Logging Into an Account</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Contractor shall provide the Client with a username and password (“Login Credentials”) to be 
            used to log in to its Account. These Login Credentials must not be used by multiple persons. If 
            the Client has designated several Users, each User will be provided with separate Login Credentials. 
            The Client and each User are responsible for keeping confidential all login credentials associated 
            with an Account. The Client must promptly notify the Contractor:
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>9.1</span> of any disclosure, loss or unauthorized use of any Login Credentials;
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>9.2</span> of a User’s departure from the Client’s organization;
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>9.3</span> of a change in a User’s role in the Client’s organization;
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>9.4</span> of any termination of a User’s right for any reason.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Termination of Account</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The Client may terminate these Terms at any time.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Terms of Payment</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>11.1</span> The Client shall pay for the Services provided duly and properly.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>11.2</span> Payment terms is subject for consideration under the conditions 
            of the Consultancy Services Agreement agreed and signed with each particular Client. The payment terms 
            outlined in the Consultancy Services Agreement between the Contractor and the Client prevails over the 
            general Terms and Conditions.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Uploading Client Data to the Platform</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            If the Client uploads Client Data to the Platform, such Client Data and any processing 
            of such Client Data must be in compliance with these Terms and applicable law. By 
            uploading the Client Data to the Platform, the Client authorizes the Contractor to process the Client Data.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Duration</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            The duration of the access to the Platform is subject for consideration under the conditions 
            of the Consultancy Services Agreement agreed and signed with each particular Client. The terms 
            and period of access to the Platform outlined in the Consultancy Services Agreement between 
            the Contractor and the Client prevails over the general Terms and Conditions. 
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Warranty and support services</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>14.1</span> The Contractor agrees to take reasonable measures to ensure 
            that the Platform is available to the Client. The Contractor reserves the right, however, to take certain 
            measures which may affect the availability of the Platform if The Contractor finds it necessary for 
            technical operational, maintenance or security reasons. The Contractor shall, if possible, inform you 
            in advance of such scheduled interruptions in services provided by the Platform. However, you are aware 
            that all or part of the Platform can be shut down with immediate effect if The Contractor finds it 
            necessary for security or other reasons.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>14.2</span> The warranties granted above are the only warranties 
            which The Contractor will grant with respect to the Platform and this agreement, which means that 
            no other warranty or terms, whether express or implied, shall apply except as provided in this agreement.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Liabilities of the parties</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>15.1</span> For failure to perform or inadequate performance 
            of the obligations hereunder, the Parties shall be legally liable to each other in accordance 
            with the applicable law.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>15.2</span> The Parties shall make every effort to settle 
            all disputes arising in relation to performance hereof by means of negotiation. In case of 
            failure in negotiation, any dispute, controversy, or claim arising from or relating to this 
            Agreement shall be settled by the International Commercial Arbitration Court at the Ukrainian 
            Chamber of Commerce and Industry (Kyiv, Ukraine) in accordance with its Rules, by one arbitrator 
            using the Ukrainian language in the arbitral proceeding.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Exemption from liability</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>16.1</span> The Parties shall be exempt from liability for failure to 
            perform or inadequate performance of the obligations hereunder in the event of force majeure including 
            natural disasters, military actions, prohibitive actions of the authorities, etc.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>16.2</span> The Contractor shall be exempt from liability in the event 
            of the Client providing false information.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Term and termination</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>17.1</span> This agreement shall come into force as of the earlier 
            of the date you first access or use the Platform and shall remain in force until terminated by 
            either party under the conditions permitted and outlined in the Consultancy Services Agreement 
            between the Contractor and the Client.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>17.2</span> Both parties shall have a right to terminate this 
            agreement with immediate effect by written notice to the other party.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>17.3</span> Termination of this agreement shall not release 
            you from liability for all fees payable or otherwise due to united influencers in accordance herewith.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>17.4</span> Upon termination of these Terms, the Contractor shall 
            deactivate and permanently delete the Account, within six months of the effective date of termination 
            of these Terms. If the Client has specifically requested for an earlier deletion of the Account, 
            the Contractor shall fulfill such request within 1 month of its receipt of such request.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>17.5</span> The Client must stop using and prevent the further usage of the Platform.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>17.6</span> Pay any amounts owed to the Contractor.
          </Typography>
        </li>
        <li>
          <Typography variant="h4" color="textPrimary">
            <span className={classes.liName}>Other conditions</span>
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>18.1</span> You guarantee that entering into this agreement does not 
            conflict with other commitments or agreements to which you are bound, and that the you always own all 
            the relevant intellectual property rights relating to information that you upload to the platform from time to time.
          </Typography>
          <Typography variant="h4" paragraph={true} color="textPrimary">
            <span style={{ fontWeight: 600 }}>18.2</span> Messages to be sent to a party under this agreement shall 
            be submitted to the email address that the party normally uses in the communication between the parties.
          </Typography>
        </li>
      </ul>
    </Grid>
  )
}

TermsConditionsPage.defaultProps = {
  hideTitle: false,
}

TermsConditionsPage.propTypes = {
  hideTitle: PropTypes.bool,
}

export default TermsConditionsPage;
