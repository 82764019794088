import client from './client';

/**
 * Allows user to be signed in into application
 *
 * @param {String} email user's email
 * @param {String} password user's password
 * @returns {Promise} Promise object represents operation result
 */
async function signIn({ email, password, google_recaptcha_token }) {
  const data = {
    email,
    pwd: password,
    google_recaptcha_token,
  };

  const response = await client.post('/api/users/auth/', data);

  if (response.status === 200) {
    return response;
  }
}

/**
 * Checks if user is a robot
 *
 * @param {String} token google recaptcha token
 */
async function verify({ token }) {
  const data = {
    "g-recaptcha-response": token
  };

  const response = await client.post('/api/users/verify/', data);

  if (response.status === 200) {
    return response;
  }
}

/**
 * Logout user from application
 *
 * @returns {Promise} Promise object represents operation result
 */
async function logout() {

  const response = await client.delete('/api/users/logout/', {
    data: {
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token')
    }
  });

  if (response.status === 200) {
    return response;
  }
}

/**
 * Refreshing both tokens for sign in user
 *
 * @returns {Promise} Promise object represents operation result
 */
async function refresh_token() {

  const response = await client.post('/api/users/refresh_token/');

  if (response.status === 200) {
    return response;
  }
}

/**
 * Fetches user's object that is signed in
 *
 * @returns {Promise} Promise object represents operation result
 */
async function fetchLoggedInUserObj() {

  const response = await client.get('/api/users/self/');

  if (response.status === 200) {
    return response;
  }
}

/**
 * Resets user's password by reset code
 * 
 * @param {String} id user's id
 * @param {Object} values data for resetting password
 * @param {String} values.code user's reset code from mail
 * @param {String} values.password user's new password
 * @returns {Promise} Promise object represents operation result
 */
async function resetPassword({ id, values }){
  const data = {
    id,
    reset_code: values.code,
    pwd: values.password
  };

  const response = await client.put('/api/users/reset_user_password/', data);

  if(response.status === 200){
    return response;
  };
}

/**
 * Sends reset code on email
 * @param {String} email user's email
 * @returns {Promise} Promise object represents operation result
 */
async function getResetCode({email}){
  const data = {
    user_email: email 
  }

  const response = await client.post('/api/reset_pwd_code/generate_reset_code/', data)

  if(response.status === 200){
    return response;
  };
}

/**
 * Activates the user and resets his password 
 * 
 * @param {String} id user's id
 * @param {Object} values data for resetting password
 * @param {String} values.code user's reset code from mail
 * @param {String} values.password user's new password
 * @returns {Promise} Promise object represents operation result
 */
async function activateUser({ id, values }){
  const data = {
    reset_code: values.code,
    pwd: values.password
  };

  const response = await client.put(`/api/users/activate_user/${id}/`, data);

  if(response.status === 200){
    return response;
  };
}

/**
 * Sends activate account link with code for reseting password
 * @param {String} email user's email
 * @returns {Promise} Promise object represents operation result
 */
async function sendActivationLink({email}){

  const data = {
    user_email: email 
  };

  const response = await client.post('/api/users/send_activation_link/', data);

  if(response.status === 200){
    return response;
  }
}

export { activateUser, signIn, logout, refresh_token, fetchLoggedInUserObj, getResetCode, resetPassword, sendActivationLink, verify };
