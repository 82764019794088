// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import { Divider, Grid, MenuItem, Typography, Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
import PermissionsCheckboxes from 'components/PermissionsCheckboxes';
// Constants
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
  ROLE_WASP_ADMIN,
  ROLE_ADMIN,
  ROLE_USER, 
  ROLE_ADMIN_READ_ONLY } from 'utils';

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  role_id: ROLE_USER,
  subscriptions: [],
  csLevels: [],
  active: false
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .required('Required'),
  email: Yup.string()
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    })
    .required('Required'),
  phoneNumber: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable(),
});

const EditForm = ({
  user,
  services,
  levels,
  currentUserRoleAdmin,
  onChangeData,
  onUpdateUser,
}) => {
  const userValues = user && {
    name: user.full_name ? user.full_name : '',
    email: user.email ? user.email : '',
    phoneNumber: user.phone_number ? user.phone_number : '',
    role_id: user.role_id ? user.role_id : ROLE_USER,
    subscriptions: user.subscriptions ? user.subscriptions.map(subscription => subscription.id) : [],
    csLevels: user.csLevels ? user.csLevels.map(level => level.id) : [],
    active: user.active ? user.active : false
  };

  const changeFunc = (values, companyValues) => {
    let formIsChanged = false;
    if (companyValues && values) {
      Object.keys(companyValues).map(key => {
        if (companyValues[key] !== values[key] && typeof(companyValues[key]) !== 'object' && typeof(values[key]) !== 'object') {
          formIsChanged = true;
        }
        else if (typeof(companyValues[key]) === 'object' && typeof(values[key]) === 'object') {
          if (values[key].length !== companyValues[key].length) {
            formIsChanged = true;
          } else {
            for (let i = 0; i < values[key].length; i++) {
              if (!values[key].includes(companyValues[key][i])) {
                formIsChanged = true;
              }
            }
          }
        }
        return formIsChanged;
      });
    }
    return onChangeData(formIsChanged);
  };

  const onSubmit = (values, setSubmitting) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
    const dataToUpdateUser = {
      userId: user && user.id,
      full_name: values.name,
      email: values.email,
      phone_number: values.phoneNumber,
      role_id: values.role_id ? values.role_id : null,
      subscriptions: values.subscriptions,
      cs_levels: values.csLevels,
      active: values.active
    };

    onUpdateUser(dataToUpdateUser);
  };

  const shouldDisabledActiveCheckbox = (userForEdit) => {
    const disabledCheckbox1 = userForEdit && userForEdit.role_id === ROLE_WASP_ADMIN;
    const disabledCheckbox2 = userForEdit && (userForEdit.role_id === ROLE_ADMIN && currentUserRoleAdmin);
    return disabledCheckbox1 || disabledCheckbox2;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={userValues || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({
        values,
        isSubmitting,
        errors,
        handleReset,
        handleSubmit,
        setFieldValue,
        setFieldTouched
      }) => {
        changeFunc(values, userValues);
        return (
        <form onSubmit={handleSubmit} style={{width: '100%'}}>
          <Grid container spacing={3} className="pt-2 pb-2 pl-4 pr-4">
            <Grid item xs={12} md={6}>
              <FormikInput
                mandatory
                id="name"
                name="name"
                label="Full name"
                value={values.name}
                disabled={values.role_id === ROLE_WASP_ADMIN}
              />
              <FormikInput
                mandatory
                id="email"
                name="email"
                label="Email"
                value={values.email}
                disabled={currentUserRoleAdmin}
              />
              <Grid container style={{marginTop: '-15px'}}>
                <FormControl className="flex-centered">
                  <FormControlLabel
                    disabled={shouldDisabledActiveCheckbox(user)}
                    value={true}
                    control={<Checkbox
                      id="active"
                      name="active"
                      color="primary"
                      onChange={(event) => {setFieldValue('active', event.target.checked)}}
                      />}
                    checked={values.active}
                    label="Active"
                    className="mr-2" />
                  <Tooltip 
                    title="Users must be activated for using the service.
                      You can activate them later from the list.
                      If the account is not active, you cannot activate the user." 
                    arrow={true} 
                    interactive={false}
                    style={{color: 'var(--primary-regular)'}}>
                    <Icon className="fa fa-info-circle fa-sm" />
                  </Tooltip>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikInput
                select
                id="role_id"
                name="role_id"
                label="Role"
                hint={(parseInt(values.role_id) !== ROLE_USER) || (values.active === false) ?
                  "You can't change rights to inactive user. Please activate user first to do that action."
                  : ""
                }
                disabled={
                  ![ROLE_USER, ROLE_ADMIN_READ_ONLY].includes(parseInt(values.role_id)) || (values.active === false) ? true : false}
                value={parseInt(values.role_id)}>
                  {(parseInt(values.role_id) === ROLE_WASP_ADMIN) 
                    ? <MenuItem value={ROLE_WASP_ADMIN}>WASP Admin</MenuItem> 
                    : null}
                  <MenuItem value={ROLE_ADMIN}>Company Admin</MenuItem>
                  {([ROLE_USER, ROLE_ADMIN_READ_ONLY].includes(parseInt(values.role_id))) 
                    ? <MenuItem value={ROLE_USER}>Company User</MenuItem>
                    : null}
                  {([ROLE_USER, ROLE_ADMIN_READ_ONLY].includes(parseInt(values.role_id))) 
                    ? <MenuItem value={ROLE_ADMIN_READ_ONLY}>Company admin with read only role</MenuItem> 
                    : null}
              </FormikInput>
              <FormikInput
                id="phoneNumber"
                name="phoneNumber"
                label="Phone number"
                value={values.phoneNumber}
                placeholder='+380951112233'
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ padding: "20px" }}>
              <Typography variant="h3">Permissions</Typography>
          </Grid>
          <Grid container className="pb-4 pl-4 pr-4">
            <PermissionsCheckboxes
              subscriptionsValues={values.subscriptions}
              subscriptionsError={errors.subscriptions}
              levelsValues={values.csLevels}
              levelsError={errors.csLevels}
              onSetFieldValue={setFieldValue}
              onSetFieldTouched={setFieldTouched}
              editing={[ROLE_WASP_ADMIN, ROLE_ADMIN].includes(values.role_id) ? false : true}
              services={services}
              levels={levels}
              rowVariant={true}
            />
          </Grid>
          <Divider />

          <Grid container justify="flex-end" className="pt-4 pb-4 pr-4">
            <Button
              variant="outlined"
              onClick={handleReset}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="mr-0">
              Update
            </Button>
          </Grid>
        </form>
        );
      }}
    </Formik>
  );
};

EditForm.defaultProps = {
  user: null,
  services: null,
  levels: null,
  currentUserRoleAdmin: false,
  onUpdateUser: () => null,
};

EditForm.propTypes = {
  user: PropTypes.shape({}),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  currentUserRoleAdmin: PropTypes.bool,
  onUpdateUser: PropTypes.func,
};

export default EditForm;
