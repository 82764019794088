// Core
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Constants
import GeneralInformationPart from 'components/Partners/GeneralInformationPart';
import EntitiesPart from 'components/Partners/EntitiesPart';
import BankInformationPart from 'components/Partners/BankInformationPart';
import ComplianceProceduresPart from 'components/Partners/ComplianceProceduresPart';
import FinishPart from './FinishPart';
import FilesLinksPart from './FilesLinksPart';
import CommentPart from './CommentPart';

const useStyles = makeStyles({
  wrapper: {
    padding: '1rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    border: '1px solid var(--black-20)',
    borderRadius: '5px'
  },
  textColor: {
    fontWeight: 600,
    lineHeight: '1.2rem',
    color: 'var(--primary-regular)'
  },
  alignRight: {
    alignSelf: 'flex-end'
  },
  justifyText: {
    textAlign: 'justify'
  }
});

const DetailsPartnerCard = ({
  companyName,
  partner
}) => {
  const classes = useStyles();
  
  const checkIsLinksArrayIsEmpty = () => {
    const respondent_fields = ['respondent_date', 'respondent_name', 'respondent_position', 'company_name'];
    return Object.keys(partner.finish_form).some((key) => !respondent_fields.includes(key));
  }
  
  return (
    <Grid
      container
      style={{ maxWidth: '760px', width: '100%' }}
    >
      <Grid item xs={12} className={classes.wrapper} id="general_information_id">
        <GeneralInformationPart
          general_information={partner.general_information}
        />
      </Grid>
      {
        partner.directors.length !== 0
          ? <Grid item xs={12} className={classes.wrapper} id="directors_id">
            <EntitiesPart
              directors={partner.directors}
              type="directors"
            />
          </Grid>
          : <span id="directors_id"></span>
      }
      {
        partner.shareholders.length !== 0
          ? <Grid item xs={12} className={classes.wrapper} id="shareholders_id">
            <EntitiesPart
              shareholders={partner.shareholders}
              type="shareholders"
            />
          </Grid>
          : <span id="shareholders_id"></span>
      }
      {
        partner.ubos.length !== 0
          ? <Grid item xs={12} className={classes.wrapper} id="ubos_id">
            <EntitiesPart
              ubos={partner.ubos}
              type="ubos"
            />
          </Grid>
          : <span id="ubos_id"></span>
      }
      {
        partner.bank_entity
          ? <Grid item xs={12} className={classes.wrapper} id="bank_id">
            <BankInformationPart
              bank_information={partner.bank_entity}
            />
          </Grid>
          : <span id="bank_id"></span>
      }
      {
        partner.compliance_procedures
          ? <Grid item xs={12} className={classes.wrapper} id="compliance_procedures_id">
            <ComplianceProceduresPart
              compliance_procedures={partner.compliance_procedures}
            />
          </Grid>
          : <span id="compliance_procedures_id"></span>
      }
      {
        (partner.partner_files.length !== 0 || checkIsLinksArrayIsEmpty())
          ? <FilesLinksPart
            finish_form={partner.finish_form}
            partner_files={partner.partner_files}
            partnerId={partner.general_information.id}
          />
          : <span id="files_links_id"></span>
      }
      <Grid item xs={12} className={classes.wrapper} id="respondent_id">
        {
          partner.finish_form.respondent_date &&
          <FinishPart
            finish_form={partner.finish_form}
          />
        }
        <CommentPart
          application_review={partner.general_information.application_review || ""}
          risk_assessment={partner.general_information.risk_assessment || ""}
          partner_id={partner.general_information.id}
          account_id={partner.general_information.relation_account}
        />
      </Grid>
      <Grid container id="cosa_politics_id">
        <Grid item xs={12} className={clsx(classes.textColor, "pb-1")}>
          Consent to Personal Data Processing
        </Grid>
        <Grid item xs={12} className={clsx(classes.textColor, classes.justifyText, "pb-1")}>
          I hereby authorize our Сounterparty {companyName} and administrator of this portal COSA LLC to
          process and store the corporate data and documents of our Сompany, including my personal data
          under the GDPR 679/16 – “European regulation on the protection of personal data”. The purpose of
          collecting this data is a third-party due diligence procedure conducted by {companyName}
        </Grid>
        <Grid item xs={12} className={clsx(classes.textColor, classes.justifyText, "pb-1")}>
          The provided information and documents for compliance purposes will be handled on a strictly
          confidential basis and its access will be limited to the internal departments of {companyName} and
          administrator of this portal.
        </Grid>
      </Grid>
    </Grid>
  )
};

export default DetailsPartnerCard;
