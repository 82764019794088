// Core
import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const CookiesReadMorePage = () => {

  return (
    <Grid>
      <Typography variant="h2" paragraph={true} color='textPrimary'>
        This website uses cookies
      </Typography>
      <Typography variant="h4" paragraph={true} color='textPrimary'>
        A cookie is a small text file that the website you are visiting stores on your computer. 
        Cookies are used by a lot of websites to give visitors access to various functions. 
        It is possible to use the information in the cookie to follow the user’s surfing.
        To avoid cookies, you can change the security settings in your web browser. 
        How these are adjusted depends on which web browser you have.
        On this website we use cookies to enable you as a visitor to adapt the appearance of the website.
        The majority are the so called “session cookies”. 
        They will be automatically deleted after the visit to the website. 
        Cookies do not cause any harm to your computer and do not contain viruses.
      </Typography>
      <Typography variant="body2" paragraph={true} color='textSecondary'>
        More information on&nbsp;
        <Link
          className="exampleLink" 
          to="//en.wikipedia.org/wiki/Magic_cookie" 
          target="_blank"
          rel="noopener noreferrer"
        >Cookie</Link>
      </Typography>
      <Typography variant="body2" color='textSecondary'>
        Please see also our&nbsp;
        <Link to="/cookies-read-more/privacy-policy" className="exampleLink">
          privacy policy
        </Link>&nbsp;in this regard!
      </Typography>
    </Grid>
  )
}


export default CookiesReadMorePage;
