// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//Components
import Button from 'components/CustomButton';
import CustomIconButton from 'components/CustomIconButton'; 

const useStyles = makeStyles({
  confirmDialogFullWidth: {
    '& .MuiDialog-paperWidthSm': {
      width: '760px',
      lineHeight: 'normal',
      borderRadius: '4px',
      boxShadow: 'none',
    }
  },
  confirmDialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '450px',
      lineHeight: 'normal',
      borderRadius: '4px',
      boxShadow: 'none',
    },
  },
  dialogTitle: {
    height: '80px',
    padding: '0 20px'
  },
  dialogContent: {
    fontSize: 15,
    fontWeight: 600,
    scrollbarWidth: "thin",
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    }
  },
  testClass: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

const ConfirmDialog = ({
  title,
  open,
  onClose,
  options,
  confirmBtnText,
  children,
  onConfirm,
  disableBackdrop,
  disableEscapeKey,
  simple,
  hideCancelBtn,
  isDisabledDialog,
  messageText,
  fullWidth
}) => {
  const classes = useStyles();

  const showMessage = isDisabledDialog && (
    <Typography variant="body1" className="warning-msg pt-1 pl-2">
      <i className="fas fa-exclamation-triangle"></i>&nbsp;{messageText}
    </Typography>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      className={!fullWidth ? classes.confirmDialog : classes.confirmDialogFullWidth}
      disableBackdropClick={disableBackdrop}
      disableEscapeKeyDown={disableEscapeKey}>
      <Grid 
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.dialogTitle}>
          <Typography variant="h2">
            {title}
          </Typography>
          <CustomIconButton icon="fas fa-times" tooltipText="Close" onIconButtonClick={isDisabledDialog ? null : onClose}/>
      </Grid>
      <Divider/>
      <DialogContent className={classes.dialogContent}>
        {children}
        {showMessage}
      </DialogContent>
      <DialogActions className={simple === true ? '' : classes.testClass}>
        {hideCancelBtn === true ? <></> :
        <Button variant="outlined" onClick={isDisabledDialog ? null : onClose}>
          Cancel
        </Button>}
        {simple === true ? <></> : 
        <Button
          onClick={() => {if (!isDisabledDialog) onConfirm(options)}}>
          {confirmBtnText}
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  title: '',
  options: null,
  children: null,
  onClose: () => null,
  onConfirm: () => null,
  disableBackdrop: false,
  disableEscapeKey: false,
  simple: null,
  hideCancelBtn: false,
  isDisabledDialog: false,
  messageText: "Please, wait a minute",
  fullWidth: false
};

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  options: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(Date), PropTypes.bool, PropTypes.object]),
  confirmBtnText: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  disableBackdrop: PropTypes.bool,
  disableEscapeKey: PropTypes.bool,
  hideCancelBtn: PropTypes.bool,
  simple: PropTypes.bool,
  isDisabledDialog: PropTypes.bool,
  messageText: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default ConfirmDialog;
