// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
// Components
import CustomCheckbox from 'components/RisksCheckboxes/CustomRiskCheckbox';
// import CustomCheckbox from './CustomRiskCheckbox';
// import CustomSwitch from '../UI/CustomSwitch';
import CustomSwitch from 'components/UI/CustomSwitch';
// Constants
import {PESTEL_ANALYSIS, ENHANCED_ANALYSIS} from 'utils';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const RisksCheckboxes = ({
  analysisType,
  doubleColumned,
  risks,
  risksRecords,
  onRiskFieldChange,
  osintValues,
  onOsintFieldChange,
  disabled=false,
}) => {
  const classes = useStyles();

  const renderForm = () => {
    switch(analysisType) {
      case PESTEL_ANALYSIS: 
        return (
          <Grid
            container 
            spacing={2}
            direction="row"
            className={doubleColumned ? "pb-4" : ""}>
              {
                risks.map(risk => {
                  return (
                    <Grid item xs={12} sm={doubleColumned ? 6 : 12 } key={risk.id} container className="pb-2">
                      <Grid 
                        item 
                        xs={8}>
                          <CustomCheckbox
                            id={String(risk.id)}
                            name={String(risk.id)}
                            value={disabled === false ? ((risksRecords && risksRecords.size) ? risksRecords.get(parseInt(risk.id)) : false) : true}
                            disabled={disabled}
                            label={risk.name}
                            onChange={(event) => onRiskFieldChange(event.target.name, event.target.checked)}
                          />
                      </Grid>
                      <Grid 
                        item 
                        xs={4}
                        className={classes.switchArea}>
                          <CustomSwitch 
                            disabled={disabled === false ? ((risksRecords && risksRecords.size && risksRecords.get(parseInt(risk.id))) ? false : true) : true}
                            id={String(risk.id)}
                            name={String(risk.id)}
                            value={(osintValues && osintValues.size) ? osintValues.get(parseInt(risk.id)) : ""}
                            checked={(osintValues && osintValues.size) ? osintValues.get(parseInt(risk.id)) : false}
                            label="OSINT"
                            onChange={(event) => {onOsintFieldChange(event.target.name, event.target.checked);}}
                          />
                      </Grid>
                    </Grid>
                  );
                })
              }
          </Grid>
        );
      case ENHANCED_ANALYSIS: 
        return (
          <Grid 
            container 
            spacing={2}
            direction="row"
            className={doubleColumned ? "pb-4" : ""}>
              {
                risks.map(risk => {
                  return (
                    <Grid 
                      item
                      key={risk.id}
                      xs={12}
                      sm={doubleColumned === true ? 6 : 12}
                      className="pb-0">
                        <CustomCheckbox
                          id={String(risk.id)}
                          name={String(risk.id)}
                          value={disabled === false ? ((risksRecords && risksRecords.size) ? risksRecords.get(parseInt(risk.id)) : false) : true}
                          disabled={disabled}
                          label={risk.name}
                          onChange={(event) => onRiskFieldChange(event.target.name, event.target.checked)}
                        />
                    </Grid>
                  )}
                )
              }
          </Grid>
        );
      default:
        return null;
    }
  }

  return(
    <FormControl 
      component="fieldset" 
      className={classes.formControl}>
        <FormGroup>
          <Grid
            container>
              {renderForm()}
          </Grid>
        </FormGroup>
    </FormControl>
  )
} 

RisksCheckboxes.defaultProps = {
  doubleColumned: false,
  risks: [],
  risksRecords: [],
  onRiskFieldChange: () => null,
  osintValues: [],
  onOsintFieldChange: () => null,
  disabled: false
}

RisksCheckboxes.propTypes = {
  analysisType: PropTypes.number.isRequired,
  doubleColumned: PropTypes.bool,
  risks: PropTypes.array,
  risksRecords: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  onRiskFieldChange: PropTypes.func,
  osintValues: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  onOsintFieldChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default RisksCheckboxes;
