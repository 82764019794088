// Core
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Components
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
// Constants
import {
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE
} from 'utils';

const StyledCheckbox = withStyles({
  root: {
    width: 35,
    height: 35,
    padding: 5,
    color: 'var(--black-20)',
  },
})(props => <Checkbox {...props} />);

const useStyles = makeStyles({
  formControlLevels: {
    padding: 0,
    width: '100%',
    height: 38,
    color: 'var(--black-100)',
    margin: '4px 0',
    border: '1px solid var(--secondary-orange)',
    borderLeftWidth: '8px',
    background: 'var(--white)',
    borderRadius: 4,
    boxShadow: 'none',
    '& label span.MuiCheckbox-root': {
      color: 'var(--black-20)',

      '&.Mui-checked': {
        color: 'var(--primary-regular)',
      },
    },
    '&.greyCheckbox': {
      borderColor: 'var(--black-20)',
      cursor: 'default',
      '& label': {
        cursor: 'default',
        '& span.MuiCheckbox-root.Mui-checked': {
          color: 'var(--black-20)',
        },
      },
      '& span': {
        cursor: 'default',
      },
    },
  },

  formControl: {
    width: '100%',
    height: 38,
    color: 'var(--black-100)',
    margin: '4px 0',
    padding: 0,
    border: '1px solid var(--white)',
    borderLeftWidth: '8px',
    background: 'var(--white)',
    borderRadius: 4,
    boxShadow: 'none',

    '.width100 &': {
      width: '100%'
    },

    '&:first-child': {
      marginLeft: 0,
    },

    '&:last-child': {
      marginRight: 0,
    },

    '& label span.MuiCheckbox-root': {
      color: 'var(--black-20)',

      '&.Mui-checked': {
        color: 'var(--primary-regular)',
      },
    },

    '&.orangeCheckbox': {
      borderColor: 'var(--secondary-orange)',
    },

    '&.greenCheckbox': {
      borderColor: 'var(--secondary-green)',
    },

    '&.turquoiseCheckbox': {
      borderColor: 'var(--secondary-turquoise)',
    },

    '&.darkblueCheckbox': {
      borderColor: 'var(--primary-regular)',
    },

    '&.yellowCheckbox': {
      borderColor: 'var(--dark-yellow)',
    },

    '&.redCheckbox': {
      borderColor: 'var(--dark-red)',
    },

    '&.blueCheckbox': {
      borderColor:  'var(--dark-blue)',
    },

    '&.purpleCheckbox': {
      borderColor: 'var(--dark-purple)',
    },

    '&.greyCheckbox': {
      borderColor: 'var(--black-20)',
      cursor: 'default',
      '& label': {
        cursor: 'default',
        '& span.MuiCheckbox-root.Mui-checked': {
          color: 'var(--black-20)',
        },
      },
      '& span': {
        cursor: 'default',
      },
    },
  },

  leftPad28: {
    padding: '0 0 0 28px',
  },

  iconCheckbox: {
    top: '18%',
    left: '3.5%',
    lineHeight: '1.4rem',
    color: 'var(--primary-regular)',
    width: '35px',
    height: '35px',
    position: 'absolute',
    "& svg":{
      width: '25px',
      height: '25px',
    },
    '&.greyCheckbox': {
      fill: 'var(--black-20)',
      cursor: 'default',
    },
  },

  formControlLabel: {
    margin: 0,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',

    '&:disabled': {
      color: 'var(--black-40)',
    }
  },

  formControlLabelText: {
    flexGrow: 1,
    color: 'var(--black-100)',
    fontWeight: 600,
    padding: '0 10px'
  }
});

const CustomCheckbox = props => {
  const classes = useStyles(props);
  const {
    value, 
    onChange, 
    onBlur,
    id,
    label,
    name,
    disabled,
    isCsLevels,
    setToggleLevels,
    toggleLevels
  } = props;

  const color = (id) => {
    id = parseInt(id);
    switch (id) {
      case CS_SERVICE:
        return 'orangeCheckbox';
      case EDD_SERVICE:
        return 'turquoiseCheckbox';
      case PES_SERVICE:
        return 'darkblueCheckbox';
      case SI_SERVICE:
        return 'greenCheckbox';
      case LEGAL_COMP_SERVICE:
        return 'yellowCheckbox';
      case TECH_INTELLI_SERVICE:
        return 'redCheckbox';
      case PARTNER_SEARCH_SERVICE:
        return 'blueCheckbox';
      case TRENDS_INTELIGENCE_SERVICE:
        return 'purpleCheckbox';
      default:
        return 'greyCheckbox';
    }
  };

  return (
    <FormControl 
      component="fieldset" 
      className={isCsLevels 
                  ? clsx(classes.formControlLevels, color(disabled ? 0 : id))
                  : (!isCsLevels && parseInt(id) === 1)
                  ? clsx(classes.formControl, color(disabled ? 0 : id), classes.leftPad28)
                  : clsx(classes.formControl, color(disabled ? 0 : id))
                }>
      <FormControlLabel
        classes={{
          root: classes.formControlLabel,
          label: classes.formControlLabelText,
        }}
        control={
          <React.Fragment>
            <StyledCheckbox
              id={id}
              name={name}
              value={value}
              checked={value}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
            />
            {parseInt(id) === 1 && !isCsLevels &&
              <StyledCheckbox
                icon={<AddBoxIcon style={{ color: value ? 'var(--primary-regular)' : 'var(--black-20)', width: '20px' }}/>}
                checkedIcon={<IndeterminateCheckBoxIcon style={{ color: 'var(--primary-regular)', width: '20px' }}/>}
                style={{right: '86%'}}
                id={id}
                name={name}
                value={toggleLevels}
                checked={toggleLevels}
                disabled={!value}
                onChange={() => setToggleLevels(prev => !prev)}
                onBlur={onBlur}
              />
            }
          </React.Fragment>
        }
        label={label}
      />
    </FormControl>
  );
};

CustomCheckbox.defaultProps = {
  label: '',
  name: '',
  disabled: false,
  field: null,
  isCsLevels: false,
  setToggleLevels: () => {},
  toggleLevels: false
};

CustomCheckbox.propTypes = {
  field: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isCsLevels: PropTypes.bool,
  setToggleLevels: PropTypes.func,
  toggleLevels: PropTypes.bool
};

export default CustomCheckbox;
