import CookiesReadMorePage from 'pages/CookiesReadMorePage';
import CurrentUserDetailsPage from 'pages/CurrentUserDetailsPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import QuestionnaireFlowPage from 'pages/QuestionnaireFlowPage';
import TermsConditionsPage from 'pages/TermsConditionsPage';


const routes = [
  {
    path: '/home',
    name: 'Compliance Questionnaire',
    component: QuestionnaireFlowPage,
    exact: true,
    level: 0,
  },
  {
    path: '/current-user-details',
    name: 'Current User Details',
    component: CurrentUserDetailsPage,
    exact: true,
    level: 1,
  },
  {
    path: '/cookies-read-more',
    name: 'Read more about cookies',
    component: CookiesReadMorePage,
    exact: true,
    level: 1
  },
  {
    path: '/cookies-read-more/privacy-policy',
    name: 'Privacy policy',
    component: PrivacyPolicyPage,
    exact: true,
    level: 2
  },
  {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    component: TermsConditionsPage,
    exact: true,
    level: 1
  }
];

export default routes;
