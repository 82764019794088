// Core
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Button from 'components/CustomButton';
import MapChart from 'components/MapChart/MapChart';
import Spinner from 'components/shared/Spinner';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import {
  fetchLegalComplianceDicts,
  fetchProjectLegalCompProjectSettings,
} from 'api/projects';
// Constants
import { LEGAL_COMP_CONFIG } from 'utils';
import LegalComplianceGeneralForm from './LegalComplianceGeneralForm';

const LegalComplianceGeneralStep = ({
  config_id,
  newProjectId,
  onUpdateProject,
  values,
  onCreateProject,
  onClickBack,
  onSetFieldValue,
}) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [dynamicValuesForRequest, setDynamicValuesForRequest] = useState();
  const [dynamicValues, setDynamicValues] = useState({
    sub_services: {},
  });

  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeCurrentPage,
    handleClose: handleCloseConfirmToChangeCurrentPage,
  } = useDialog();

  const { execute: getDictionaries, value: dictionaries } = useAsync(
    fetchLegalComplianceDicts,
    false,
  );
  const { execute: getLegalCompProjectSettings, value: settings } = useAsync(
    fetchProjectLegalCompProjectSettings,
    false,
  );

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (config_id) {
      getDictionaries(config_id);
      onSetFieldValue('config_id', config_id);
    }
    // eslint-disable-next-line camelcase
  }, [getDictionaries, onSetFieldValue, config_id]);

  useEffect(() => {
    if (newProjectId) {
      getLegalCompProjectSettings(newProjectId);
    }
  }, [getLegalCompProjectSettings, newProjectId]);

  useEffect(() => {
    if (values && settings && dynamicValuesForRequest) {
      handleChangeData(values, settings, dynamicValuesForRequest);
    }
    // eslint-disable-next-line
  }, [values, dynamicValuesForRequest]);

  useEffect(() => {
    if (dictionaries) {
      const chosenSubServices = new Map();
      if (dictionaries.sub_services) {
        if (
          settings &&
          settings.sub_services &&
          settings.sub_services.length &&
          settings.config_id === config_id
        ) {
          onSetFieldValue('config_id', config_id);
          dictionaries.sub_services.map(sub_service => {
            const foundSubService = settings.sub_services.find(
              foundSubService => foundSubService.id === sub_service.id,
            );
            if (foundSubService) {
              chosenSubServices.set(sub_service.id, true);
            } else {
              chosenSubServices.set(sub_service.id, false);
            }
            return true;
          });
        } else if (
          !newProjectId ||
          (settings && settings.config_id !== config_id)
        ) {
          dictionaries.sub_services.map(sub_service => {
            chosenSubServices.set(sub_service.id, true);
            return true;
          });
        }
        setDynamicValues({ ...dynamicValues, sub_services: chosenSubServices });
        if (
          !dynamicValuesForRequest ||
          !dynamicValuesForRequest.sub_services ||
          !dynamicValuesForRequest.sub_services.size
        ) {
          setDynamicValuesForRequest({
            ...dynamicValuesForRequest,
            sub_services: chosenSubServices,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [dictionaries, settings]);

  const subServiceFieldOnChange = (subServiceId, value) => {
    let changedSubServices = dynamicValuesForRequest.sub_services;

    if (config_id === LEGAL_COMP_CONFIG) {
      changedSubServices = changedSubServices.set(parseInt(subServiceId), value);
      setDynamicValuesForRequest({
        ...dynamicValuesForRequest,
        sub_services: changedSubServices,
      });
    }
  };

  const onClickNextButton = () => {
    const formLegalComp = formLegalCompOptions(
      dynamicValuesForRequest.sub_services,
    );
    const step = 2;

    if (newProjectId) {
      onUpdateProject({ step, formLegalComp });
    } else {
      onCreateProject(formLegalComp);
    }
  };

  const formLegalCompOptions = (sub_services) => {
    const chosenSubServises = new Map();
    sub_services.forEach((value, key) => {
      if (value) {
        chosenSubServises.set(key, value);
      }
    });
    return {
      chosenSubServises,
    };
  };

  const shouldDisableNextButton = values => {
    let noneOfSubServicesChecked = true;
    if (
      dynamicValuesForRequest &&
      dynamicValuesForRequest.sub_services &&
      dynamicValuesForRequest.sub_services.size
    ) {
      for (const value of dynamicValuesForRequest.sub_services.values()) {
        if (value === true) {
          noneOfSubServicesChecked = false;
        }
      }
    }
    return values.country_code === '' || noneOfSubServicesChecked;
  };

  const handleChangeData = (values, oldValues, newValues) => {
    let isDifferent = false;
    isDifferent = values.country_code !== oldValues.country_code;
    if (isDifferent === false) {
      isDifferent = values.industry_id !== oldValues.industry_id;
    }
    if (oldValues.sub_services && newValues.sub_services && newValues.sub_services.size) {
      if (isDifferent === false) {
        let newSubServicesCount = 0;
        newValues.sub_services.forEach(val => {
          if (val === true) {
            newSubServicesCount += 1;
          }
        });
        isDifferent = oldValues.sub_services.length !== newSubServicesCount;
      }
      if (isDifferent === false) {
        newValues.sub_services.forEach((value, key) => {
          if (value && isDifferent === false) {
            const foundObj = oldValues.sub_services.find(sub_service => sub_service.id === key);
            if (!foundObj) {
              isDifferent = true;
            }
          }
        });
      }
    }
    setHasUnsavedData(isDifferent);
  };

  const handleChangeCurrentPage = () => {
    setHasUnsavedData(false);
    handleCloseConfirmToChangeCurrentPage();
    onSetFieldValue('legalCompliance.industry_id', settings.industry_id);
    onSetFieldValue('legalCompliance.country_code',settings.country_code);
    onClickBack();
  };

  return dictionaries && (!newProjectId || (newProjectId && settings)) ? (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12} md={5} className="pr-1">
        {dictionaries && (
          <LegalComplianceGeneralForm
            industries={dictionaries.industries}
            config_id={config_id}
            sub_services={dictionaries.sub_services}
            values={values}
            dynamicValues={dynamicValuesForRequest || dynamicValues}
            subServiceFieldOnChange={subServiceFieldOnChange}
          />
        )}
      </Grid>
      <Grid item xs={12} md={7} className="leftBorderMap">
        <Grid container className={clsx('pl-3', 'generalMapWrapper')}>
          <Grid container>
            <Typography variant="h3" className="pb-0 pl-0">
              Corruption Perception Index
            </Typography>
          </Grid>
          <Grid item className="generalMapWrapper">
            <MapChart selectedCountry={values.country_code} width="100%" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid>
          <Button
            className="mb-0"
            variant="outlined"
            onClick={() => {
              if (hasUnsavedData) {
                handleOpenConfirmToChangeCurrentPage();
              } else {
                onClickBack();
              }
            }}>
            Back
          </Button>
          <Button
            className="mb-0 mr-0"
            disabled={shouldDisableNextButton(values && values)}
            onClick={() => onClickNextButton()}>
            Next
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Unsaved data"
        open={openConfirmToChangeCurrentPage}
        onClose={handleCloseConfirmToChangeCurrentPage}
        options={hasUnsavedData}
        confirmBtnText="Confirm"
        onConfirm={handleChangeCurrentPage}>
        You are about to leave a page. All unsaved data will be lost. Please
        confirm.
      </ConfirmDialog>
      <RouteLeavingGuard
        when={hasUnsavedData}
        navigate={path => {
          if (path !== url) {
            push(path);
          }
        }}
        options={dynamicValuesForRequest}
        shouldBlockNavigation={path => {
          return path.pathname !== url;
        }}
      />
    </Grid>
  ) : (
    <Spinner />
  );
};

LegalComplianceGeneralStep.defaultProps = {
  newProjectId: '',
  values: {},
  onClickBack: () => null,
  onSetFieldValue: () => null,
  onCreateProject: () => null,
  onUpdateProject: () => null,
};

LegalComplianceGeneralStep.propTypes = {
  config_id: PropTypes.number.isRequired,
  newProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdateProject: PropTypes.func,
  values: PropTypes.shape({
    settings_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    industry_name: PropTypes.string,
    sub_services: PropTypes.array,
  }),
  onCreateProject: PropTypes.func,
  onClickBack: PropTypes.func,
  onSetFieldValue: PropTypes.func,
};

export default LegalComplianceGeneralStep;
