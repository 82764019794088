import ProjectsDashboardPage from 'pages/ProjectsDashboardPage';
import CookiesReadMorePage from 'pages/CookiesReadMorePage';
import CurrentUserDetailsPage from 'pages/CurrentUserDetailsPage';
import MyServicesPage from 'pages/MyServicesPage';
import NewProjectPage from 'pages/NewProjectPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import ProjectDetailsPage from 'pages/ProjectDetailsPage';
import TermsConditionsPage from 'pages/TermsConditionsPage';

const routes = [
  {
    path: '/home',
    name: 'My Services',
    component: MyServicesPage,
    exact: true,
    level: 0,
  },
  {
    path: '/projects',
    name: 'Dashboard',
    component: ProjectsDashboardPage,
    exact: true,
    level: 1,
  },
  {
    path: '/projects/new-project',
    name: 'Create New Project',
    component: NewProjectPage,
    exact: true,
    level: 2,
  },
  {
    path: '/projects/:id',
    name: 'Project Details',
    component: ProjectDetailsPage,
    exact: true,
    level: 2,
  },
  {
    path: '/projects/new-project/:id',
    name: 'Create New Project',
    component: NewProjectPage,
    exact: true,
    level: 2,
  },
  {
    path: '/current-user-details',
    name: 'Current User Details',
    component: CurrentUserDetailsPage,
    exact: true,
    level: 1,
  },
  {
    path: '/cookies-read-more',
    name: 'Read more about cookies',
    component: CookiesReadMorePage,
    exact: true,
    level: 1
  },
  {
    path: '/cookies-read-more/privacy-policy',
    name: 'Privacy policy',
    component: PrivacyPolicyPage,
    exact: true,
    level: 2
  },
  {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    component: TermsConditionsPage,
    exact: true,
    level: 1
  },
];

export default routes;
