// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import LinkCard from './LinkCard';
//Helpers
import { sortArrayOfObjects } from 'helpers';

const LinkCards = ({ services, links, projectsCounters }) => {
  services && services.sort(sortArrayOfObjects('id'));
  return (services && projectsCounters && Object.keys(services).map(key => {
    const currentServiceLink = links.find(serviceLink => services[key].id === serviceLink.serviceId);
    const currentCounterProjects = projectsCounters[key];
    return(  
      <Grid key={key} container item xs={12} md={10} lg={6} xl={5}>
        <LinkCard {...services[key]} {...currentServiceLink} counter={currentCounterProjects}/>
      </Grid>);
    }
  ));
} 

LinkCards.defaultProps = {
  services: [],
  links: [],
};

LinkCards.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  })),
  links: PropTypes.arrayOf(PropTypes.shape({
    serviceId: PropTypes.number,
    disabled: PropTypes.bool,
    serviceName: PropTypes.string,
    path: PropTypes.string,
  })),
};

export default LinkCards;
