// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
// @material-ui
import Icon from '@material-ui/core/Icon';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles({
  input: {
    height: 32,
    position: 'relative',
    padding: '0 0 0 40px',
    color: 'black',
    fontFamily: 'inherit',
    width: 150,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid var(--primary-regular)',
    borderRadius: 4,

    '&.MuiInput-underline:before': {
      border: 'none !important',
    },
    '&.MuiInput-underline:after': {
      border: 'none !important',
    },
    '&.Mui-disabled': {
      borderColor: 'var(--black-20)',
    },
  },
  icon: {
    marginTop: 7,
    marginLeft: 14,
    position: 'absolute',
    color: 'var(--primary-regular)',
  },
});

const CustomDatePicker = ({className, onDueDateChange, newDueDate, dueDate}) => {
  const classes = useStyles();
  const [selectedDate] = useState(new Date());
  let transformDueDate = new Date(Date.parse(dueDate));

  if (selectedDate > transformDueDate) {
    transformDueDate = selectedDate;
  }
  if (selectedDate === transformDueDate) {
    transformDueDate = moment(transformDueDate).add(1, 'days');
  }

  return (
    <div>
      <Icon className={clsx("fas fa-calendar-alt ", classes.icon)}/>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          variant="inline"
          disableToolbar={true}
          minDate={moment(selectedDate).add(1, 'days')}
          name="dueDate"
          format="DD MMM YYYY"
          value={newDueDate ? newDueDate : transformDueDate}
          placeholder="DD/MM/YYYY"
          InputProps={{ className: clsx(classes.input, className) }}
          onChange={(date) => {onDueDateChange(date._d);}}/>
      </MuiPickersUtilsProvider>
    </div>
  );
};

CustomDatePicker.defaultProps = {
  className: '',
  onDueDateChange: () => null,
  newDueDate: null,
  dueDate: null
};

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  onDueDateChange: PropTypes.func,
  newDueDate: PropTypes.instanceOf(Date),
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

export default CustomDatePicker;
