// Core
import React, { useContext } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps';
// @material-ui
import Tooltip from '@material-ui/core/Tooltip';
// Context
import { CountriesContext } from 'context/CountriesContext';
// Correct topojson map
import GEOData from './world-110m.json';

const MapChart = ({ selectedCountry, width, height }) => {
  const { countries } = useContext(CountriesContext);
  const marker = countries.find(({ code }) => {
    return code === selectedCountry;
  });
  const markerCoordinates = marker && [marker.lng, marker.lat];
  const countryLevel = marker && marker.score;

  const selectedCountryColor = (level) => {
    switch (level) {
      case 'L':
        return 'var(--secondary-green)';
      case 'M':
        return 'var(--secondary-orange)';
      case 'H':
        return 'var(--secondary-red)';
      default:
        return 'var(--secondary-blue)';
    }
  };

  return (
    <>
      <ComposableMap
        width={1000}
        height={800}
        style={{ width: width ? width : 500, height: width ? height : 358, margin: width ? 'unset' : '-30px' }}
        data-tip=""
        projectionConfig={{ rotate: [0, 0, 0], scale: 180 }}
      >
        <Geographies geography={GEOData}>
          {({ geographies }) =>
            geographies.map(geo => {
              const selected = selectedCountry === geo.properties.ISO_A3;
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="var(--black-20)"
                  style={{
                    default: {
                      fill: `${selected ? selectedCountryColor(countryLevel) : 'var(--black-05)'}`,
                      outline: "none"
                    },
                    hover: {
                      fill: `${selected ? selectedCountryColor(countryLevel) : 'var(--black-05)'}`,
                      outline: 'none',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
        {marker && (
          <Marker key={marker.name} coordinates={markerCoordinates}>
            <Tooltip
              classes={{tooltip: 'markerTooltip', popper: 'tooltipZIndex'}}
              title={`CPI: ${marker.cpi_score}/100`}
              arrow={true}
              open={true}
              placement="top"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              style={{tooltip: {fontSize: 14}}}
            >
              <g
                fill="rgba(97, 97, 97, 0.9)"
                stroke="rgba(97, 97, 97, 0.9)"
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-5, -8)"
              >
                <ellipse cx="5" cy="5" rx="8" ry="2" />
              </g>
            </Tooltip>
          </Marker>
        )}
      </ComposableMap>
    </>
  );
};

export default MapChart;
