import React, { useEffect } from 'react'
import { Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Components
import Button from 'components/CustomButton';
import FormInfoSection from 'components/FormInfoSection';
import FormikInput from 'components/FormikInput';
import Textarea from 'components/Textarea';
import ScrollPaperDialog from 'components/UI/ScrollPaperDialog';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import TermsConditionsPage from 'pages/TermsConditionsPage';
import CustomIconButton from 'components/CustomIconButton';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Constants
import { ACCOUNT_REGEX, CORPORATE_EMAIL_REGEX, PHONE_NUMBER_REGEX } from 'utils';
// Instruments
import { requestForAccount } from 'api/accounts';

const useStyles = makeStyles({
  mainDialog: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '4px',
      boxShadow: 'none',
      maxWidth: 410,
    },
  },
  mainButton: {
    margin: 0
  },
  buttonsWrap: {
    justifyContent: 'flex-end'
  },
  dialogTitleContainer: {
    paddingRight: 12,
    '& .MuiTypography-h6': {
      color: 'var(--navy)',
      fontSize: '22px',
      fontWeight: '600'
    }
  },
  controlLabel: {
    '& .MuiFormControlLabel-label': {
      color: 'var(--black-100)',
    }
  },
  iconButton: {
    padding: 24
  },
  scrollStyle: {
    scrollbarWidth: "thin",
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    }
  }
})

const initialValues = {
  companyName: '',
  corporateEmail: '',
  individualName: '',
  position: '',
  phoneNumber: '',
  comment: '',
  agreeTermsConditions: false,
  agreeContactMe: false
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().max(128, 'Must be 128 characters or less')
    .transform((value) => value.trim())
    .matches(ACCOUNT_REGEX, {
      message: 'You should use latin letters',
      excludeEmptyString: true,
    })
    .nullable()
    .required('Required'),
  corporateEmail: Yup.string()
    .matches(CORPORATE_EMAIL_REGEX, {
      message: 'Corporate email required',
      excludeEmptyString: true,
    })
    .required('Required'),
  individualName: Yup.string().max(128, 'Must be 128 characters or less')
    .matches(/^[ a-zA-Z_]+( [a-zA-Z_ ]+)*$/, {
      message: 'You should use latin letters only',
      excludeEmptyString: true,
    })
    .nullable()
    .required('Required'),
  position: Yup.string().max(128, 'Must be 128 characters or less').nullable().required('Required'),
  phoneNumber: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable()
});

const RequestAccount = () => {
  const classes = useStyles();

  const {
    execute: sendRequestForCreateAccount,
    pending: pendingSendRequestForCreateAccount,
    status: statusRequestAccount,
  } = useAsync(requestForAccount, false);

  const {
    open: openRequestAccountDialog,
    handleOpen: handleOpenRequestAccountDialog,
    handleClose: handleCloseRequestAccountDialog,
  } = useDialog();

  const {
    open: openPrivacyPolicy,
    handleOpen: handleOpenPrivacyPolicy,
    handleClose: handleClosePrivacyPolicy,
  } = useDialog();

  const {
    open: openTermsAndConditions,
    handleOpen: handleOpenTermsAndConditions,
    handleClose: handleCloseTermsAndConditions,
  } = useDialog();

  const privacyPolicyOnClick = () => {
    handleOpenPrivacyPolicy();
  }

  const termsAndConditionsOnClick = () => {
    handleOpenTermsAndConditions();
  }

  useEffect(() => {
    if (!pendingSendRequestForCreateAccount && statusRequestAccount === 200) {
      toast.success(
        "Thank you for your request for setting up a WASP account. Our operations manager will contact you shortly.", 
        { autoClose: 10000 }
      );
    }
  }, [pendingSendRequestForCreateAccount, statusRequestAccount]);

  const onSubmit = (values) => {
    sendRequestForCreateAccount(values);
    handleCloseRequestAccountDialog();
  }

  const disableSubmitButton = ({ 
    companyName, 
    corporateEmail, 
    individualName, 
    position, 
    agreeTermsConditions, 
    agreeContactMe }, 
    errors
  ) => {
    const isDisabled = [
      companyName, corporateEmail, individualName, position].includes("") 
      || !agreeTermsConditions 
      || !agreeContactMe
      || Object.keys(errors).length !== 0;
    return isDisabled;
  }

  return (
    <React.Fragment>
      <Button 
        className={classes.mainButton}
        onClick={handleOpenRequestAccountDialog}>
        Request Account
      </Button>
      <Dialog 
        className={classes.mainDialog}
        open={openRequestAccountDialog} 
        onClose={handleCloseRequestAccountDialog}
        scroll="paper"
        aria-labelledby="form-dialog-title">
          <Grid 
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.dialogTitleContainer}>
            <DialogTitle 
              id="form-dialog-title"
            >
              Request Account
            </DialogTitle>
            <CustomIconButton
              icon="fas fa-times" 
              tooltipText="Close" 
              onIconButtonClick={handleCloseRequestAccountDialog}
            />
          </Grid>
          <Divider />
          <DialogContent className={classes.scrollStyle}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
              {({
                values,
                isSubmitting,
                errors,
                touched,
                handleReset,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => (
                <form onSubmit={handleSubmit} style={{width: '100%'}}>
                  <Grid container className="pt-2 pb-4">
                    <FormikInput
                      mandatory
                      id="individualName"
                      name="individualName"
                      label="Full name"
                      value={values.individualName}
                    />
                    <FormikInput
                      mandatory
                      id="position"
                      name="position"
                      label="Position"
                      value={values.position}
                    />
                    <FormikInput
                      mandatory
                      id="companyName"
                      name="companyName"
                      label="Company/Organization name"
                      value={values.companyName}
                    />
                    <FormikInput
                      mandatory
                      id="corporateEmail"
                      name="corporateEmail"
                      label="Corporate email"
                      value={values.corporateEmail}
                    />
                    <FormikInput
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Phone number"
                      value={values.phoneNumber}
                    />
                    <FormInfoSection 
                      text="Comments"
                      isCapitalize={false}
                    />
                    <Textarea
                      disabled={false}
                      id="comment"
                      name="comment"
                      rows={3}
                      value={values.comment}
                    />
                    <Grid
                      container>
                      <Grid item>
                        <FormControl className="flex-centered">
                          <FormControlLabel
                            disabled={false}
                            value={values.agreeTermsConditions}
                            control={<Checkbox
                              id="agreeTermsConditions"
                              name="agreeTermsConditions"
                              color="primary"
                              onChange={(event) => {setFieldValue('agreeTermsConditions', event.target.checked)}}
                            />}
                            label={<div style={{marginTop: '19px'}}>
                              <span>I agree with the </span>
                              <span onClick={termsAndConditionsOnClick} style={{textDecoration: 'underline'}}>
                                Terms & Conditions
                              </span>
                              <span> and the </span>
                              <span onClick={privacyPolicyOnClick} style={{textDecoration: 'underline'}}>
                                Privacy Policy 
                              </span>&nbsp;*
                              </div>}
                            className={classes.controlLabel}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl className="flex-centered">
                          <FormControlLabel
                            disabled={false}
                            value={values.agreeContactMe}
                            control={<Checkbox
                              id="agreeContactMe"
                              name="agreeContactMe"
                              color="primary"
                              onChange={(event) => {setFieldValue('agreeContactMe', event.target.checked)}}
                            />}
                            label="Contact me and provide more details *"
                            className={classes.controlLabel} 
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={clsx(classes.buttonsWrap, "mozPad24Buttons")}>
                    <Button 
                      onClick={handleCloseRequestAccountDialog} 
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="ml-3"
                      disabled={disableSubmitButton(values, errors)}
                      color="primary"
                    >
                      Submit request
                    </Button>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContent>
      </Dialog>
      <ToastContainer />
      <ScrollPaperDialog 
        title="Privacy Policy"
        open={openPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
        children={<PrivacyPolicyPage hideTitle={true}/>}
      />
      <ScrollPaperDialog 
        title="Terms and Conditions"
        open={openTermsAndConditions}
        onClose={handleCloseTermsAndConditions}
        children={<TermsConditionsPage hideTitle={true}/>}
      />
    </React.Fragment>
  )
}

export default RequestAccount;
