// Core
import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

// Components
import BackgroundTheming from 'components/BackgroundTheming';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/shared/Spinner';
// Pages
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import SignInPage from 'pages/SignInPage';
import ServerErrorPage from 'pages/ServerErrorPage';
import UserActivationPage from 'pages/UserActivationPage';
// Context
import CountriesProvider from './context/CountriesContext';
import ProjectStatusesProvider from './context/ProjectStatusesContext';
import ServicesProvider from './context/ServicesContext';
import UserProvider, { UserConsumer } from './context/UserContext';
import { useAuth } from 'hooks/useAuth';
// Routing
// import PrivateRoute from 'routes/PrivateRoute';
// Instruments
import clientAdminNav from 'config/nav-client-admin';
import clientAdminRoutes from 'config/routes-client-admin';
import clientUserNav from 'config/nav-client-user';
import clientUserRoutes from 'config/routes-client-user';
import waspAdminNav from 'config/nav-wasp-admin';
import waspAdminRoutes from 'config/routes-wasp-admin';
import temporaryUserNav from './config/nav-temporary-user';
import temporaryUserRoutes from './config/routes-temporary-user';
// Constants
import { ROLE_WASP_ADMIN, ROLE_ADMIN, ROLE_ADMIN_READ_ONLY, ROLE_USER, ROLE_WASP_USER, ROLE_APPLICANT, ROLE_NOVATOR, ROLE_PARTNER,  } from 'utils';
import { fileExists } from 'helpers';


const App = () => {
  const auth = useAuth();
  const location = useLocation();

  let match = useRouteMatch({
    path: '/reset_password/:id',
    strict: true,
    sensitive: true,
  });

  const selectBackground = (match) => {
    const locationGlobeBG = ['/', '/500', '/sign_in', '/forgot_password' , '/reset_password'];
    return locationGlobeBG.includes(location.pathname) || match ? 'earthGlobe' : '';
  }

  const captchaRoutes = ['/sign_in', '/', '/forgot_password'];

  React.useEffect( () => {
    if (fileExists('./site-key.txt')) {
      fetch('./site-key.txt').then(res => res.text()).then(text => {

        if (captchaRoutes.includes(location.pathname) && text) {
          const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
              var script = document.createElement("script");
              script.type = "text/javascript";
              script.src = url;
              script.id = id;
              script.onload = function () {
                if (callback) callback();
              };
              document.body.appendChild(script);
            }
            if (isScriptExist && callback) callback();
          }
          loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${text}`);
        } else {
          const nodeBadge = document.querySelector('.grecaptcha-badge');
          if(nodeBadge !== null){
            if (nodeBadge) {
              document.body.removeChild(nodeBadge.parentNode);
            }
            const scriptSelectorGstatic = "script[src*='https://www.gstatic.com/recaptcha/']";
            const scriptSelectorGoogle = "script[src*='https://www.google.com/recaptcha/']";
            const scriptGstatic = document.querySelector(scriptSelectorGstatic);
            const scriptGoogle = document.querySelector(scriptSelectorGoogle);
            if (scriptGstatic && scriptGoogle) {
              scriptGstatic.remove();
              scriptGoogle.remove();
            }
          }
        }

      });
    }
  }, [location.pathname, captchaRoutes]);

  const renderCurrentApp = (user, userLoading) => {
    const token = localStorage.getItem('access_token');
    let unsubscriptionedClientNavs = [];
    let unsubscriptionedClientRoutes = [];
    if (user && user.role_id !== ROLE_WASP_ADMIN) {
      if (user.subscriptions && user.subscriptions.length === 0){
        if (user.role_id === ROLE_ADMIN) {
          unsubscriptionedClientNavs = clientAdminNav.filter(nav => nav.path !== '/projects');
          unsubscriptionedClientRoutes = clientAdminRoutes.filter(route => route.path.slice(0,9) !== '/projects');
        } else if ([ROLE_USER, ROLE_ADMIN_READ_ONLY].includes(user.role_id)) {
          unsubscriptionedClientNavs = clientUserNav.filter(nav => nav.path !== '/projects');
          unsubscriptionedClientRoutes = clientUserRoutes.filter(route => route.path.slice(0,9) !== '/projects');
        }
      }
      if (!user.onboarding_allowed && user.role_id === ROLE_ADMIN) {
        if (unsubscriptionedClientNavs.length !== 0) {
          unsubscriptionedClientNavs = unsubscriptionedClientNavs.filter(nav => nav.path !== '/partners')
        } else {
          unsubscriptionedClientNavs = clientAdminNav.filter(nav => nav.path !== '/partners')
        }

        if (unsubscriptionedClientRoutes.length !== 0) {
          unsubscriptionedClientRoutes = unsubscriptionedClientRoutes.filter(route => route.path.slice(0,9) !== '/partners')
        } else {
          unsubscriptionedClientRoutes = clientAdminRoutes.filter(route => route.path.slice(0,9) !== '/partners')
        }
      }
    }
    switch ((auth.accessToken || token) && user && user.role_id) {
      case ROLE_WASP_ADMIN:
      case ROLE_WASP_USER:
      case ROLE_APPLICANT:
      case ROLE_NOVATOR:
        return (
          <DefaultLayout
            loggedInUser={user}
            routes={waspAdminRoutes}
            nav={waspAdminNav}
            dropDownBtnText="WASP Admin"
          />
        );
      case ROLE_ADMIN:
        return (
          <DefaultLayout
            loggedInUser={user}
            routes={
              user.subscriptions.length === 0 || !user.onboarding_allowed 
              ? unsubscriptionedClientRoutes 
              : clientAdminRoutes
            }
            nav={
              user.subscriptions.length === 0 || !user.onboarding_allowed 
              ? unsubscriptionedClientNavs 
              : clientAdminNav
            }
            dropDownBtnText="Admin"
          />
        );
      case ROLE_USER:
        return (
          <DefaultLayout
            loggedInUser={user}
            routes={user.subscriptions.length === 0 ? unsubscriptionedClientRoutes : clientUserRoutes}
            nav={user.subscriptions.length === 0 ? unsubscriptionedClientNavs : clientUserNav}
            dropDownBtnText="My Account"
          />
        );
      case ROLE_ADMIN_READ_ONLY:
        return (
          <DefaultLayout
            loggedInUser={user}
            routes={user.subscriptions.length === 0 ? unsubscriptionedClientRoutes : clientUserRoutes}
            nav={user.subscriptions.length === 0 ? unsubscriptionedClientNavs : clientUserNav}
            dropDownBtnText="My Account"
          />
        );
      case ROLE_PARTNER:
        return (
          <DefaultLayout
            loggedInUser={user}
            routes={temporaryUserRoutes}
            nav={temporaryUserNav}
            dropDownBtnText="Compliance Questionnaire"
          />
        );
      default:
        if (userLoading) return <Spinner />;
        return (
          <Redirect
            to={{
              pathname: '/sign_in',
              state: { from: location },
            }}
          />
          // <Spinner />
        );
    }
  };

  return (
    <UserProvider>
      <ServicesProvider>
        <CountriesProvider>
          <ProjectStatusesProvider>
            <BackgroundTheming image={selectBackground(match)}> 
              <Switch>
                <Route exact path="/">
                  <SignInPage />
                </Route>
                <Route path="/sign_in">
                  <SignInPage />
                </Route>
                <Route path="/500">
                  <ServerErrorPage />
                </Route>
                <Route path="/forgot_password">
                  <ForgotPasswordPage />
                </Route>
                <Route path="/reset_password/:id">
                  <ResetPasswordPage />
                </Route>
                <Route path="/user_activation/:id">
                  <UserActivationPage />
                </Route>
                <UserConsumer>
                  {({ loggedInUser, loadingUserObj }) => {
                    // if (loadingUserObj) return <Spinner />;
                    const persistUser = localStorage.getItem('user');
                    const parsedUser = JSON.parse(persistUser);

                    return renderCurrentApp(loggedInUser || parsedUser, loadingUserObj);
                  }}
                </UserConsumer>
              </Switch>
            </BackgroundTheming>
          </ProjectStatusesProvider>
        </CountriesProvider>
      </ServicesProvider>
    </UserProvider>
  );
};

export default App;
