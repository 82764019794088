import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// Components
import Button from 'components/CustomButton';
import PageTitleSection from 'components/PageTitleSection';

const useStyles = makeStyles({
  tableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  tableCellName: {
    fontWeight: 500,
    width: '50%',
    "&.MuiTableCell-sizeSmall": {
      padding: "2px 0px"
    }
  },
  head: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'var(--primary-regular)'
  },
  table: {
    wordBreak: 'break-all'
  }
});

const rows = [
  {name: "Full legal name", path: "legal_name"},
  {name: "Trading name", path: "trading_name"},
  {name: "Registered number", path: "registered_number"},
  {name: "VAT Number", path: "vat_number"},
  {name: "DUNS Number", path: "duns_number"},
  {name: "Сountry of registration", path: "country"},
  {name: "Date of incorporation", path: "incorporation_date"},
  {name: "Industry of operations", path: "industry"},
  {name: "Registered address", path: "registered_address"},
  {name: "Office address", path: "office_address"},
  {name: "Corporate website", path: "corporate_website"},
  {name: "Contact telephone", path: "contact_telephone"},
  {name: "Contact email", path: "contact_email"},
  {name: "Business relations", path: "relationship"},
  {name: "Duration of cooperation with us", path: "duration"},
];

const GeneralInformationPart = ({
  general_information
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <PageTitleSection title={general_information.legal_name} className="projectTitle">
        <Button 
          disabled 
          className={"ml-3 typeService3"} 
          style={{ height: 'unset', wordBreak: 'break-all', whiteSpace: 'unset' }}>
          {general_information.registered_number}
        </Button>
      </PageTitleSection>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>General Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" className={classes.tableCellName}>
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {general_information[row.path]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default GeneralInformationPart;
