class UserAutoLogout {

  constructor({ timeout, onTimeout }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.eventTracker();
    this.trackExpiredTime();
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("expiredTime", Date.now() + this.timeout * 1000);
    }, 3);
  }

  trackExpiredTime() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem("expiredTime"), 10);
      if (expiredTime < Date.now()) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expiredTime");
        this.onTimeout();
        this.cleanInterval();
      }
    }, 1000);
  }

  eventTracker() {
    window.addEventListener("keydown", this.eventHandler);
    window.addEventListener("mousemove", this.eventHandler);
  }

  cleanInterval() {
    clearInterval(this.interval);
    window.removeEventListener("keydown", this.eventHandler);
    window.removeEventListener("mousemove", this.eventHandler);
  }
}

export default UserAutoLogout;
