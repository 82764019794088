import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @material-ui
import { Card, CardHeader, CardContent, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Button from 'components/CustomButton';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import Wrapper from 'components/Wrapper';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { getResetCode } from 'api/auth';

const useStyles = makeStyles(theme => {
  return{
    getResetCodeSuccessResponse: {
      width: '100%',
      padding: 60,
      [theme.breakpoints.down('lg')]: {
        padding: 40,
      },
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
      minWidth: '300px',

      "& .MuiCardHeader-root": {
        padding: 0,
        paddingBottom: 16,
        "& .MuiCardHeader-content span": {
          fontSize: 36,
          fontWeight: 600
        }
      },

      "& .MuiCardContent-root p": {
        color: 'var(--black-40)',
        paddingBottom: 40
      }
    },

    card: {
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        padding: 40,
      },
      [theme.breakpoints.down('md')]: {
        padding: 20,
      },
      padding: 60,
      minWidth: '300px',
    },

    title: {
      margin: '0px -25px 25px',
      padding: '0px 25px',
      fontSize: 36,

      '& div': {
        marginBottom: -4,

        '& span': {
          paddingBottom: 16,
          fontSize: 36,
          fontWeight: 600,
          wordWrap: 'normal',
          color: 'var(--navy)',
          lineHeight: 'normal',
        },
      },
    },

    form: {  
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        height: 48,
        '& .MuiInputBase-input': {
          height: '70%'
        },
      }
    }
  }
});

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { execute: onGetCode, value: result, error: getCodeError, status: getResetCodeStatus } = useAsync(
    getResetCode,
    false,
    false,
  );

  const goToLoginPage = () => {
    push('/');
  };

  useEffect(() => {
    if (result) {
      toast.success("Success", { autoClose: 8000 });
    }
  }, [result]);

  return (
    <>
      <ToastContainer />
      <Wrapper>
        <Grid>
          {
            getResetCodeStatus && getResetCodeStatus === 200 ? (
              <Card className={classes.getResetCodeSuccessResponse}>
                <CardHeader title="Check your mail"/>
                <CardContent>
                  <Typography variant="body2">
                    Reset Code was successfully sent. Check your email for further instructions.
                  </Typography>
                  <Button
                  className="wideButton"
                    onClick={goToLoginPage}>
                      Got it!
                  </Button>
                </CardContent>  
              </Card>
            ) : 
            (<Card className={classes.card}>
              <CardHeader title="Reset your password" className={classes.title} />
              <CardContent>
                <ForgotPasswordForm
                  getCodeError={getCodeError}
                  className={classes.form}
                  onGetCode={onGetCode}
                />
              </CardContent>
            </Card>)
          }
        </Grid>
      </Wrapper>
    </>
  );
}

export default ForgotPasswordPage;
