// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/styles';
import { Box, IconButton, SvgIcon, Typography } from '@material-ui/core';
// Components
import { DeleteIcon } from 'components/shared/Icons';

const useStyles = makeStyles(theme => ({
  typography: {
    color: theme.palette.custom.mediumGrey,
    lineHeight: 'normal',
  },

  iconBtn: {
    padding: 0,
  },
}));

const Delete = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={81}>
      <Typography variant="h4" classes={{ h4: classes.typography }}>
        Delete
      </Typography>
      <IconButton classes={{ root: classes.iconBtn }} onClick={onClick}>
        <SvgIcon component={DeleteIcon} />
      </IconButton>
    </Box>
  );
};

Delete.defaultProps = {
  onClick: () => null,
};

Delete.propTypes = {
  onClick: PropTypes.func,
};

export default Delete;
