import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormikInput from 'components/FormikInput';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  inputMargin: {
    marginTop: 32
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 50,
    margin: 0,
    width: '100%',
    border: '1px solid var(--primary-regular)',
    borderLeft: '5px solid var(--primary-regular)',
    borderRadius: 4,
    paddingLeft: 12,

    '& .MuiFormControlLabel-label': {
      margin: '10px 0px',
      fontSize: 14,
      fontWeight: 600,
      color: 'var(--black-100)',
      lineHeight: 1,
    },
  },
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      }
    }
  },
  subCheckbox: {
    paddingBottom: '12px',
    width: '100%',
    alignSelf: 'flex-end'
  },
  description: {
    fontStyle: 'italic',
    color: '#646462',
    lineHeight: 1.2,
    textAlign: 'justify'
  },
  indexedLabel: {
    display: 'flex',
    textAlign: 'justify'
  },
  checkboxDisabled: {
    borderColor: 'var(--black-40)',

    '& .MuiFormControlLabel-label': {
      color: 'var(--black-60)',
    }
  }
});

const ComplianceProcedureCheckbox = ({
  index,
  disabled,
  labels_paths,
  checkbox_value,
  is_subpath,
  text_value,
  description,
  description_text,
  handleChangeCheckbox
}) => {
  const classes = useStyles();

  const buildFieldLabel = (label, description, index) => {
    return (
      description ? (
        <div className={classes.indexedLabel}>
          {
            index && (
              <div className="mr-2">
                {index}
              </div>
            )
          }
          <span>
            <p>{label}</p>
            <p className={clsx(classes.description, "mt-2")}>{description}</p>
          </span>
        </div>
      ) : (
        <div className={classes.indexedLabel}>
          {
            index && (
              <div className="mr-2">
                {index}
              </div>
            )
          }
          {label}
        </div>
      )
    );
  }

  return (
    <Grid 
      item 
      xs={is_subpath ? 11 : 12}
      className={classes.subCheckbox}>
        <FormControlLabel 
          className={clsx(classes.formControl, {[classes.checkboxDisabled]: disabled})}
          control={
            <Checkbox
              checked={checkbox_value}
              onChange={(event) => handleChangeCheckbox(labels_paths.path_checkbox, event.target.checked)}
              name={labels_paths.path_checkbox}
              disabled={disabled}
              color="primary"
            />
          }
          labelPlacement="start"
          label={buildFieldLabel(labels_paths.label_checkbox, description, index)}
        />
        {description_text ? (<Typography className="mt-2">{description_text}</Typography>) : ''}
        {checkbox_value && labels_paths?.path_text && 
          <FormikInput
            className={classes.inputMargin}
            uncapitalized={true}
            id={labels_paths.path_text}
            name={`compliance_procedures.${labels_paths.path_text}`}
            label={labels_paths.label_text}
            value={text_value}
          />
        }
    </Grid>
  )
}

export default ComplianceProcedureCheckbox;
