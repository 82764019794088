//Core
import React from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//Components
import CustomButton from 'components/CustomButton';
import CloudWithLoop from 'components/shared/Icons/CloudWithLoop';

const useStyles = makeStyles({
  emptyPage: {
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'var(--black-20)',
  },
  font: {
    fontWeight: 600,
    color: 'black',
    fontSize: 14,
    paddingBottom: 8,
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "column",
    
    "& .MuiSvgIcon-root": {
      width: 230,
      height: 200,
      color: 'var(--black-20)'
    }
  },
}) 

const EmptyPage = ({title, height, hasButton=false, handleClick, disabled=false}) => {
  const classes = useStyles();
  return (
    <Grid 
      container
      item
      justify="center"
      alignItems="center"
      className={classes.emptyPage}
      style={{height: `${height}vh`}}>
        <span className={classes.card}>
          <CloudWithLoop />
            <Typography className={classes.font}>Start by creating a {title}!</Typography>
            <Typography className="pb-3">There are no items available</Typography>
            {
              hasButton === true ? <CustomButton onClick={handleClick} disabled={disabled}>Create a {title}</CustomButton> : <></>
            }
        </span>
    </Grid>
  );
}

export default EmptyPage;