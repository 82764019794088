// Core
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// Components
import FormInfoSection from 'components/FormInfoSection';
import FormikInput from 'components/FormikInput';
import SimpleDatePicker from 'components/SimpleDatePicker';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: '0px !important'
      },
    }
  },
  datePicker: {
    margin: '-12px 0 12px'
  }
});

const GeneralInformationStep = ({
  dicts,
  general_information,
  onSetFieldValue,
  valuesFromFormik,
  politicAgree
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, "pt-3")}
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          id="legalName"
          name="general_information.legal_name"
          label="Full legal name"
          value={general_information.legal_name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          id="tradingName"
          name="general_information.trading_name"
          uncapitalized={true}
          label="Trade Name (If different)"
          value={general_information.trading_name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          id="registeredNumber"
          name="general_information.registered_number"
          label="Registered number"
          value={general_information.registered_number}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          id="vatNumber"
          name="general_information.vat_number"
          label="VAT number"
          value={general_information.vat_number}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          id="dunsNumber"
          name="general_information.duns_number"
          label="DUNS number"
          value={general_information.duns_number}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          select
          uncapitalized={true}
          id="countryCodeRegistration"
          name="general_information.country_code_registration"
          label="Сountry of Registration"
          value={general_information.country_code_registration}>
          {dicts.countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          select
          uncapitalized={true}
          id="industryId"
          name="general_information.industry_id"
          label="Industry of Operations"
          value={general_information.industry_id}>
          {dicts.industryMenu}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          id="registeredAddress"
          name="general_information.registered_address"
          label="Registered address"
          value={general_information.registered_address}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          id="officeAddress"
          name="general_information.office_address"
          uncapitalized={true}
          label="Office Address (If different)"
          value={general_information.office_address}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          id="corporateWebsite"
          name="general_information.corporate_website"
          label="Corporate website"
          value={general_information.corporate_website}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          id="contactTelephone"
          name="general_information.contact_telephone"
          label="Contact telephone"
          value={general_information.contact_telephone}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          id="contactEmail"
          name="general_information.contact_email"
          label="Contact email"
          value={general_information.contact_email}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          mandatory
          select
          id="businessRelation"
          name="general_information.business_relation"
          label="Business Relations"
          value={general_information.business_relation}>
          {dicts.relationshipMenu}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          disabled={!politicAgree}
          select
          uncapitalized={true}
          id="durationCooperation"
          name="general_information.duration_cooperation"
          label="Duration of Cooperation with Us"
          value={general_information.duration_cooperation}>
          {dicts.durationMenu}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className={clsx("pb-4", classes.datePicker)}>
        <FormInfoSection
          text="Date of Incorporation"
          isCapitalize={false}
        />
        <SimpleDatePicker
          disabled={!politicAgree}
          id="incorporationDate"
          name="general_information.incorporation_date"
          dateValue={valuesFromFormik?.incorporation_date || general_information.incorporation_date}
          onChangeHandler={(date) => date ? onSetFieldValue('general_information.incorporation_date', date._d) : null}
        />
      </Grid>
    </Grid>
  )
};

export default GeneralInformationStep;
