// Core
import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
// @material-ui
import Icon from '@material-ui/core/Icon';
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  listItem: {
    marginTop: '16px',
    position: 'relative',

    '& div': {
      '& span.fa': {
        width: '1.8rem',
        textAlign: 'center',
      },
    },

    '&:hover': {
      backgroundColor: 'var(--primary-regular)',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      left: 0,
      width: '4px',
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: '0 4px 4px 0',
    }
  },

  active: {
    backgroundColor: 'var(--primary-regular)',

    '&:before': {
      backgroundColor: 'var(--secondary-orange)',
    }
  },
});

const ListItemLinkWithIcon = props => {
  const classes = useStyles(props);
  const { path, text, icon } = props;

  const renderLink = useMemo(() =>
    forwardRef((linkProps, ref) => {
      const activeLink = clsx(classes.listItem, classes.active);

      return (
        <NavLink
          ref={ref}
          to={path}
          activeClassName={activeLink}
          {...linkProps}
        />
      );
    }),
    [path, classes.listItem, classes.active]
  );

  return (
    <Tooltip title={text} arrow={true}>
      <ListItem key={text} className={classes.listItem} component={renderLink}>
        {icon ? <ListItemIcon><Icon className={"fa fa-" + icon} /></ListItemIcon> : ''}
        <ListItemText secondary={text} />
      </ListItem>
    </Tooltip>
  );
};

ListItemLinkWithIcon.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default ListItemLinkWithIcon;
