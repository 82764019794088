// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import moment from 'moment';
// @material-ui
import { Box, Card, CardActions, CardContent, Icon, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Button from 'components/CustomButton';
import IconButton from 'components/IconButton';
import DatePickerPartnersPage from 'components/DatePickerPartnersPage';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import { sendActivationLink } from 'api/auth';
import { updateUserById } from 'api/users';
import { changeStatusOnDashboard, changeRevisionDate } from 'api/questionnaire';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.custom.greyBorder}`,

    '&:hover': {
      border: `1px solid ${theme.palette.custom.navy}`,
      boxShadow: 0,
    },
  },

  colWidth: {
    [theme.breakpoints.up('sm')]: {
      width: '14.2%',
      display: 'inline-flex',
      overflow: 'hidden',
      minWidth: '125px',
    }
  },

  flag: {
    '&.MuiIcon-root': {
      display: 'contents'
    }
  },

  countryFlag: {
    marginTop: 1,
    transform: 'scale(1.5)'
  },
  datePicker: {
    marginBottom: 4,
    '& .MuiFormControl-root': {
      '&.MuiFormControl-marginNormal': {
        marginTop: -3,
        minWidth: 200,
      }
    }
  },
  status: {
    marginBottom: 4,
  },
  formControl: {
    marginTop: -1,
    width: '150px',
    '& .MuiInput-root': {
      height: 22
    },
  },
  startIcon: {
    width: 'auto'
  }
}));

const riskColor = (risk) => {
  switch (risk) {
    case "Basic":
      return "green";
    case "Moderate":
      return "orange"
    case "High":
      return "red"
    default:
      return "";
  }
}

const PartnerCard = props => {
  const classes = useStyles(props);
  const {
    to,
    id,
    legal_name,
    registered_number,
    country_code_registration,
    country_name,
    respondent_date,
    risk,
    status,
    revision_date,
    relation_account,
    full_name,
    email,
    active,
    relation_user,
    variantTiny
  } = props;
  const [localStatus, setLocalStatus] = useState(status);
  const [localRespondentStatus, setLocalRespondentStatus] = useState(active);
  const [localRevisionDate, setLocalRevisionDate] = useState(revision_date);

  const {
    execute: changeStatus,
    pending: pendingChangeStatus,
    status: statusChangeStatus,
  } = useAsync(changeStatusOnDashboard, false);

  const {
    execute: changeDateOfRevision,
    pending: pendingChangeDateOfRevision,
    status: statusChangeDateOfRevision,
  } = useAsync(changeRevisionDate, false);

  const { 
    execute: ActivateUser,
    pending: pendingActivateRespondent,
    status: statusActivateRespondent
  } = useAsync(sendActivationLink, false);
  const { 
    execute: DeactivateUser,
    pending: pendingDeactivateRespondent,
    status: statusDeactivateRespondent
  } = useAsync(updateUserById, false);

  const {
    open: openConfirmToActivate,
    handleOpen: handleOpenConfirmToActivate,
    handleClose: handleCloseConfirmToActivate,
  } = useDialog();
  const {
    open: openConfirmToDeactivate,
    handleOpen: handleOpenConfirmToDeactivate,
    handleClose: handleCloseConfirmToDeactivate,
  } = useDialog();

  useEffect(() => {
    if (!pendingChangeStatus && statusChangeStatus === 200) {
      toast.success("Partner status successfully changed!", { autoClose: 3000 });
    }
  }, [pendingChangeStatus, statusChangeStatus]);

  useEffect(() => {
    if (!pendingChangeDateOfRevision && statusChangeDateOfRevision === 200) {
      toast.success("Partner application review date successfully changed!", { autoClose: 3000 });
    }
  }, [pendingChangeDateOfRevision, statusChangeDateOfRevision]);

  useEffect(() => {
    if (!pendingActivateRespondent && statusActivateRespondent === 200) {
      setLocalRespondentStatus(true);
      toast.success("Respondent successfully activated!", { autoClose: 3000 });
    }
  }, [pendingActivateRespondent, statusActivateRespondent]);

  useEffect(() => {
    if (!pendingDeactivateRespondent && statusDeactivateRespondent === 200) {
      setLocalRespondentStatus(false);
      toast.success("Respondent successfully deactivated!", { autoClose: 3000 });
    }
  }, [pendingDeactivateRespondent, statusDeactivateRespondent]);

  const activateDeactivateRespondent = () => {
    if (!localRespondentStatus) {
      ActivateUser({ email: email });
      handleCloseConfirmToActivate();
    } else {
      DeactivateUser({ userId: relation_user, active: false });
      handleCloseConfirmToDeactivate();
    }
  };

  const handlerChangeStatus = (e) => {
    changeStatus({ status: e.target.value, account_id: relation_account, partner_id: id });
    setLocalStatus(e.target.value);
  };

  const handlerChangeDateOfRevision = (date, isValid) => {
    setLocalRevisionDate(date);
    if (isValid) {
      changeDateOfRevision({ date: date, account_id: relation_account, partner_id: id });
    }
  };

  return (
    <Card className={classes.card}>
      <CardActions disableSpacing className={clsx("tableCell", { "tinyPaddings": variantTiny })}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Typography variant="h2" style={{ minWidth: '40%' }}>{legal_name}</Typography>
          <Button disabled className="typeService3" style={{ minWidth: '20%' }}>{registered_number}</Button>
          <Box display="flex" className={clsx(classes.colWidth, "ml-4")}>
          <IconButton inverted="true" disabled>
            <Icon className={classes.flag}>
              <div className={clsx(`flag flag-${country_code_registration}`, classes.countryFlag)}></div>
            </Icon>
          </IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Country</label><br />
            <span className="text-truncate">{country_name}</span>
          </Typography>
        </Box>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Link to={to}>
            <IconButton inverted="true"><Icon className="fa fa-caret-right" /></IconButton>
          </Link>
        </Grid>
      </CardActions>
      <CardContent className={clsx("tableCell", { "tinyPaddings": variantTiny })}>   
        <Box display="flex" className={classes.colWidth} style={{ maxWidth: '160px' }}>
          <IconButton inverted="true" disabled><Icon className="fa fa-calendar-check" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Submission Date</label><br />
            <span className="text-truncate">
              {respondent_date ? moment(respondent_date).format('DD MMM YYYY') : "Not fihished yet"}
            </span>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ maxWidth: '150px' }}>
          <IconButton inverted="true" disabled><Icon className="fa fa-exclamation-circle" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Risk Grade</label><br />
            <span className="text-truncate" style={{ color: riskColor(risk) }}>{risk}</span>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ minWidth: '190px' }}>
          <IconButton inverted="true" disabled><Icon className={clsx("fa fa-star", classes.startIcon)} /></IconButton>
          <div className={classes.status}>
            <Typography variant="body2">
              <label>Application Status</label><br />
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                value={localStatus}
                onChange={handlerChangeStatus}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value={"Due Diligence"}>Due Diligence</MenuItem>
                <MenuItem value={"Approved"}>Approved</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ minWidth: '250px' }}>
          <IconButton inverted="true" disabled><Icon className="fa fa-angle-double-up" /></IconButton>
          <div className={classes.datePicker}>
            <Typography variant="body2">
              <label>Application Review Date</label><br />
            </Typography>
            <DatePickerPartnersPage
              name="revision_date"
              dateValue={localRevisionDate}
              onChangeHandler={(date) => date ? handlerChangeDateOfRevision(date._d, date._isValid) : null}
            />
          </div>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ maxWidth: '170px' }}>
          <IconButton inverted="true" disabled><Icon className="fas fa-user" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Respondent Name</label><br />
            <Tooltip title={full_name} placement="bottom-start">
              <span className="text-truncate">{full_name}</span>
            </Tooltip>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ minWidth: '250px' }}>
          <IconButton inverted="true" disabled><Icon className="fas fa-at" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Respondent Email</label><br />
            <Tooltip title={email} placement="bottom-start">
              <span className="text-truncate">{email}</span>
            </Tooltip>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ minWidth: '190px' }}>
          <IconButton inverted="true" disabled>
            <Icon className={localRespondentStatus ? "fa fa-check-circle" : "fa fa-times-circle"}/>
          </IconButton>
          <div className={classes.status}>
            <Typography variant="body2">
              <label>Third Party Status</label><br />
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                value={localRespondentStatus}
                onChange={localRespondentStatus ? handleOpenConfirmToDeactivate : handleOpenConfirmToActivate}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
      </CardContent>
      <ConfirmDialog
          title="Deactivate respondent"
          open={openConfirmToDeactivate}
          onClose={handleCloseConfirmToDeactivate}
          confirmBtnText="Confirm"
          onConfirm={activateDeactivateRespondent}>
          You are about to deactivate respondent. Please confirm.
        </ConfirmDialog>
        <ConfirmDialog
          title="Activate respondent"
          open={openConfirmToActivate}
          onClose={handleCloseConfirmToActivate}
          confirmBtnText="Confirm"
          onConfirm={activateDeactivateRespondent}>
            You are about to activate respondent. Please confirm.
        </ConfirmDialog>
      <ToastContainer />
    </Card>
  );
};

PartnerCard.defaultProps = {
  status: '',
  revision_date: null,
  relation_account: '',
  variantTiny: false,
};

PartnerCard.propTypes = {
  to: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  legal_name: PropTypes.string.isRequired,
  registered_number: PropTypes.string.isRequired,
  country_code_registration: PropTypes.string.isRequired,
  country_name: PropTypes.string.isRequired,
  respondent_date: PropTypes.string,
  risk: PropTypes.string.isRequired,
  status: PropTypes.string,
  revision_date: PropTypes.string,
  relation_account: PropTypes.string,
  variantTiny: PropTypes.bool,
};

export default PartnerCard;
