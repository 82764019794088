// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//Components
import Button from 'components/CustomButton';
import CustomIconButton from 'components/CustomIconButton'; 

const useStyles = makeStyles({
  root: {
    inset: 'initial !important',
    bottom: '0 !important',
    right: '0 !important',
    padding: '24px',
  },
  cookiesDisclaimer: {
    "& .MuiDialog-paper": {
      margin: '0px !important'
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '450px',
      lineHeight: 'normal',
      border: '1px solid',
      borderRadius: '4px',
      boxShadow: 'none',
    },
    '& .MuiBackdrop-root': {
      background: 'transparent',
      pointerEvents: 'none'
    }
  },
  cookiesTitle: {
    padding: '20px',
    fontSize: 18,
    fontWeight: 500,
    flexWrap: 'nowrap'
  },
  cookiesContent: {
    fontSize: 14,
    fontWeight: 500
  },
  cookiesAccept: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  readMoreLink: {
    fontSize: 14,
    fontWeight: 500,
    color: 'var(--primary-regular)',
    fontFamily: 'Montserrat',
    textDecoration: 'underline',
    "&:hover":{
      color: 'var(--primary-hover)',
      textDecoration: 'underline'
    }
  }
});

const CookiesDisclaimer = ({
  open,
  onClose,
  onAccept,
  disableBackdrop
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.root
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="cookies-disclaimer"
      className={classes.cookiesDisclaimer}
      disableBackdropClick={disableBackdrop}
    >
      <Grid 
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.cookiesTitle}>
          <Typography variant="h2">
            This website uses cookies
          </Typography>
          <CustomIconButton icon="fas fa-times" tooltipText="Close" onIconButtonClick={onClose}/>
      </Grid>
      <DialogContent className={classes.cookiesContent}>
        This website uses cookies to improve your experience. By using our website you consent to all cookies in accordance with our Cookie policy.&nbsp;
        <Link to="/cookies-read-more" className={classes.readMoreLink}>
          Read more
        </Link>
      </DialogContent>
      <DialogActions className={classes.cookiesAccept}> 
        <Button
          onClick={onAccept}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CookiesDisclaimer.defaultProps = {
  onClose: () => null,
  onAccept: () => null,
  disableBackdrop: false
};

CookiesDisclaimer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  disableBackdrop: PropTypes.bool
};

export default CookiesDisclaimer;
