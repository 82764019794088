// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
// Constants
import { PASSWORD_REGEX } from 'utils';


const initialValues = { currentPassword: '', newPassword: '', confirmNewPassword: '' };

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .matches(PASSWORD_REGEX, {
      message: 'Invalid old password',
      excludeEmptyString: true,
    })
    .required('Current password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(100, 'Must be 100 characters or less')
    .matches(PASSWORD_REGEX, {
      message: 'Must contain upper and lowercase letters, digits, and at least one symbol like @#$%^&+=',
      excludeEmptyString: true,
    })
    .notOneOf([Yup.ref('currentPassword')], 'New password cannot match the old one')
    .required('New password is required'),
  confirmNewPassword: Yup.string()
    .matches(PASSWORD_REGEX, {
      message: 'Invalid new password',
      excludeEmptyString: true,
    })
    .oneOf([Yup.ref('newPassword')], 'Confirm password does not match')
    .required('Confirm password is required'),
});

const ChangePasswordForm = ({ oldvalues, onUpdatePassword }) => {
  const onSubmit = (values, initialValues, setSubmitting, resetForm) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
    if (oldvalues) {
      const dataToUpdatePassword = {
        userId: oldvalues.id,
        role_id: oldvalues.role_id,
        old_pwd: values.currentPassword,
        pwd: values.newPassword,
      };
      const response = onUpdatePassword(dataToUpdatePassword);
      if (response) {
        response.then( dataValue => {
          // no errors
          if (!dataValue){
            resetForm(initialValues);
          }
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, initialValues, setSubmitting, resetForm)}
      >
      {({handleChange, handleBlur, handleSubmit, values, isSubmitting, handleReset}) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className="pt-2 pl-4 pr-4">
              <Grid item xs={12} sm={6} className="pb-0">
                <FormikInput
                  mandatory
                  id="currentPassword"
                  type="password"
                  name="currentPassword"
                  value={values.currentPassword}
                  label="Current password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormikInput
                  mandatory
                  id="confirmNewPassword"
                  type="password"
                  name="confirmNewPassword"
                  value={values.confirmNewPassword}
                  label="Confirm new password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pb-0">
                <FormikInput
                  mandatory
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  value={values.newPassword}
                  label="New password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container justify="flex-end" className="pb-4 pr-2">
                <Button
                  variant="outlined"
                  style={{ width: 125, marginRight: 8 }}
                  onClick={handleReset}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  style={{ width: 125 }}
                  disabled={isSubmitting}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Formik>
  );
};

ChangePasswordForm.defaultProps = {
  values: null,
  onUpdatePassword: () => null,
};

ChangePasswordForm.propTypes = {
  values: PropTypes.shape({
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmNewPassword: PropTypes.string.isRequired,
  }),
  onUpdatePassword: PropTypes.func,
};

export default ChangePasswordForm;