// Core
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Button from 'components/CustomButton';
import MapChart from 'components/MapChart/MapChart';
import Spinner from 'components/shared/Spinner';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import {
  fetchPartnerSearchDicts,
  fetchProjectPartnerSearchProjectSettings,
} from 'api/projects';
// Constants
import { PARTNER_SEARCH_ANALYSIS_CONFIG } from 'utils';
import PartnerSearchGeneralForm from './PartnerSearchGeneralForm';

const PartnerSearchGeneralStep = ({
  config_id,
  newProjectId,
  onUpdateProject,
  values,
  onCreateProject,
  onClickBack,
  onSetFieldValue,
}) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [dynamicValuesForRequest, setDynamicValuesForRequest] = useState();
  const [dynamicValues, setDynamicValues] = useState({
    partnership_types: {},
  });

  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeCurrentPage,
    handleClose: handleCloseConfirmToChangeCurrentPage,
  } = useDialog();

  const { execute: getDictionaries, value: dictionaries } = useAsync(
    fetchPartnerSearchDicts,
    false,
  );
  const {
    execute: getPartnerSearchProjectSettings,
    value: settings,
  } = useAsync(fetchProjectPartnerSearchProjectSettings, false);

  const handleChangeData = (values, oldValues, newValues) => {
    let isDifferent = false;
    isDifferent = values.country_code !== oldValues.country_code;
    if (isDifferent === false) {
      isDifferent = values.industry_id !== oldValues.industry_id;
    }
    if (oldValues.partnership_types && newValues.partnership_types && newValues.partnership_types.size) {
      if (isDifferent === false) {
        let newPartnershipTypesCount = 0;
        newValues.partnership_types.forEach(val => {
          if (val.checked === true) {
            newPartnershipTypesCount += 1;
          }
        });
        isDifferent = oldValues.partnership_types.length !== newPartnershipTypesCount;
      }
      if (isDifferent === false) {
        newValues.partnership_types.forEach((value, key) => {
          if (value && isDifferent === false) {
            const foundObj = oldValues.partnership_types.find(
              partnership_type => partnership_type.id === key,
            );
            if (!foundObj) {
              isDifferent = true;
            }
          }
        });
      }
    }
    setHasUnsavedData(isDifferent);
  };

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (config_id) {
      getDictionaries(config_id);
      onSetFieldValue('config_id', config_id);
    }
    // eslint-disable-next-line camelcase
  }, [getDictionaries, onSetFieldValue, config_id]);

  useEffect(() => {
    if (newProjectId) {
      getPartnerSearchProjectSettings(newProjectId);
    }
  }, [getPartnerSearchProjectSettings, newProjectId]);

  useEffect(() => {
    if (values && settings && dynamicValuesForRequest) {
      handleChangeData(values, settings, dynamicValuesForRequest);
    }
    // eslint-disable-next-line
  }, [values, dynamicValuesForRequest]);

  useEffect(() => {
    if (dictionaries) {
      const chosenPartnershipTypes = new Map();
      if (dictionaries.partnership_types) {
        if (
          settings &&
          settings.partnership_types &&
          settings.partnership_types.length &&
          settings.config_id === config_id
        ) {
          onSetFieldValue('config_id', config_id);
          dictionaries.partnership_types.map(partnership_type => {
            const chosenPartnershipType = settings.partnership_types.find(
              chosenPartnershipType_ =>
                chosenPartnershipType_.id === partnership_type.id,
            );
            if (chosenPartnershipType) {
              chosenPartnershipTypes.set(
                partnership_type.id,
                chosenPartnershipType.value,
              );
            } else {
              chosenPartnershipTypes.set(partnership_type.id, {
                checked: false,
                message: '',
              });
            }
            return true;
          });
        } else if (
          !newProjectId ||
          (settings && settings.config_id !== config_id)
        ) {
          dictionaries.partnership_types.map(partnershipType => {
            const partnershipTypeWithMessage = {
              checked: true,
              message: partnershipType.message,
            };
            chosenPartnershipTypes.set(
              partnershipType.id,
              partnershipTypeWithMessage,
            );
            return true;
          });
        }
        setDynamicValues({...dynamicValues, partnership_types: chosenPartnershipTypes});
        if (
          !dynamicValuesForRequest ||
          !dynamicValuesForRequest.partnership_types ||
          !dynamicValuesForRequest.partnership_types.size
        ) {
          setDynamicValuesForRequest({
            ...dynamicValuesForRequest,
            partnership_types: chosenPartnershipTypes,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [dictionaries, settings]);

  const partnershipTypeFieldOnChange = (partnershipTypeId, value) => {
    let changedPartnershipTypes = dynamicValuesForRequest.partnership_types;
    if (config_id === PARTNER_SEARCH_ANALYSIS_CONFIG) {
      let changedPartnerShipTypeObject = changedPartnershipTypes.get(parseInt(partnershipTypeId),);
      changedPartnerShipTypeObject.checked = value;
      changedPartnershipTypes = changedPartnershipTypes.set(parseInt(partnershipTypeId), changedPartnerShipTypeObject);
      setDynamicValuesForRequest({
        ...dynamicValuesForRequest,
        partnership_types: changedPartnershipTypes,
      });
    }
  };

  const formPartnerSearchOptions = (partnership_types) => {
    const chosenPartnershipTypes = new Map();
    partnership_types.forEach((value, key) => {
      if (value && value.checked) {
        chosenPartnershipTypes.set(key, value);
      }
    });
    return {
      chosenPartnershipTypes,
    };
  };

  const onClickNextButton = () => {
    const formPartnerSearch = formPartnerSearchOptions(
      dynamicValuesForRequest.partnership_types,
    );
    const step = 2;

    if (newProjectId) {
      onUpdateProject({ step, formPartnerSearch });
    } else {
      onCreateProject(formPartnerSearch);
    }
  };

  const shouldDisableNextButton = values => {
    let noneOfPartnershipTypesChecked = true;
    if (
      dynamicValuesForRequest &&
      dynamicValuesForRequest.partnership_types &&
      dynamicValuesForRequest.partnership_types.size
    ) {
      let i = 0;
      for (const value of dynamicValuesForRequest.partnership_types.values()) {
        i++;
        if (
          Object.prototype.hasOwnProperty.call(
            values,
            `message_partnership_${i}`,
          )
        ) {
          const tmpRec = dynamicValuesForRequest.partnership_types.get(i);
          tmpRec.message = values[`message_partnership_${i}`];
          dynamicValuesForRequest.partnership_types.set(i, tmpRec);
        }
        if (value.checked === true) {
          noneOfPartnershipTypesChecked = false;
        }
      }
    }
    return values.country_code === '' || noneOfPartnershipTypesChecked;
  };


  const handleChangeCurrentPage = () => {
    setHasUnsavedData(false);
    handleCloseConfirmToChangeCurrentPage();
    onSetFieldValue('partnerSearch.industry_id', settings.industry_id);
    onSetFieldValue('partnerSearch.country_code',settings.country_code);
    onClickBack();
  };

  return dictionaries && (!newProjectId || (newProjectId && settings)) ? (
    <Grid container alignItems="stretch" direction="row">
      <Grid item xs={12} md={5} className="pr-1">
        {dictionaries && (
          <PartnerSearchGeneralForm
            industries={dictionaries.industries}
            config_id={config_id}
            partnership_types={dictionaries.partnership_types}
            values={values}
            dynamicValues={dynamicValuesForRequest || dynamicValues}
            partnershipTypeFieldOnChange={partnershipTypeFieldOnChange}
          />
        )}
      </Grid>
      <Grid item xs={12} md={7} className="leftBorderMap">
        <Grid container className={clsx('pl-3', 'generalMapWrapper')}>
          <Grid container>
            <Typography variant="h3" className="pb-0 pl-0">
              Corruption Perception Index
            </Typography>
          </Grid>
          <Grid item className="generalMapWrapper">
            <MapChart selectedCountry={values.country_code} width="100%" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid>
          <Button
            className="mb-0"
            variant="outlined"
            onClick={() => {
              if (hasUnsavedData) {
                handleOpenConfirmToChangeCurrentPage();
              } else {
                onClickBack();
              }
            }}>
            Back
          </Button>
          <Button
            className="mb-0 mr-0"
            disabled={shouldDisableNextButton(values && values)}
            onClick={() => onClickNextButton()}>
            Next
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Unsaved data"
        open={openConfirmToChangeCurrentPage}
        onClose={handleCloseConfirmToChangeCurrentPage}
        options={hasUnsavedData}
        confirmBtnText="Confirm"
        onConfirm={handleChangeCurrentPage}>
        You are about to leave a page. All unsaved data will be lost. Please
        confirm.
      </ConfirmDialog>
      <RouteLeavingGuard
        when={hasUnsavedData}
        navigate={path => {
          if (path !== url) {
            push(path);
          }
        }}
        options={dynamicValuesForRequest}
        shouldBlockNavigation={path => {
          return path.pathname !== url;
        }}
      />
    </Grid>
  ) : (
    <Spinner />
  );
};

PartnerSearchGeneralStep.defaultProps = {
  newProjectId: '',
  values: {},
  onClickBack: () => null,
  onSetFieldValue: () => null,
  onCreateProject: () => null,
  onUpdateProject: () => null,
};

PartnerSearchGeneralStep.propTypes = {
  config_id: PropTypes.number.isRequired,
  newProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdateProject: PropTypes.func,
  values: PropTypes.shape({
    settings_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    industry_name: PropTypes.string,
    partnership_types: PropTypes.array,
  }),
  onCreateProject: PropTypes.func,
  onClickBack: PropTypes.func,
  onSetFieldValue: PropTypes.func,
};

export default PartnerSearchGeneralStep;
