// Core
import React from 'react';
import { PropTypes } from 'prop-types';
// Components
import ProjectCard from './Card';

const ProjectCards = ({ projects, url }) =>
  projects &&
  projects.map(project => (
    <ProjectCard key={project.id} to={`${url}/${project.id}`} {...project} />
  ));

ProjectCards.defaultProps = {
  projects: null,
};

ProjectCards.propTypes = {
  url: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      subject_name: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      priority: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      requester_id: PropTypes.string.isRequired,
      requester_name: PropTypes.string.isRequired,
      due_date: PropTypes.string.isRequired,
      req_date: PropTypes.string.isRequired,
    }),
  ),
};

export default ProjectCards;
