// Core
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
// Components
import CogIcon from 'components/shared/Icons/CogIcon';
import EyeInMonitorIcon from 'components/shared/Icons/EyeInMonitorIcon';
import Grid from '@material-ui/core/Grid';
import IntelligenceIcon from 'components/shared/Icons/IntelligenceIcon';
import TabletIcon from 'components/shared/Icons/TabletIcon';
//Constants
import {
  CS_SERVICE,
  SI_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
} from 'utils';
import CheckmarkInTabletIcon from "../../shared/Icons/CheckmarkInTabletIcon";
import BulbIcon from "../../shared/Icons/BulbIcon";
import LoupIcon from "../../shared/Icons/LoupIcon";
import SaturnBikeIcon from "../../shared/Icons/SaturnBikeIcon";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: 11,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      border: '1px solid var(--black-20)',
      backgroundColor: 'var(--white)',

      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },

      'input:hover ~ &': {
        backgroundColor: 'var(--black-05)',
      },

      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: 'var(--white)',
        cursor: 'default',
      },

      'input:disabled:hover ~ &': {
        backgroundColor: 'var(--white)',
      },
    },

    checkedIcon: {
      border: '3px solid var(--primary-regular)',
      backgroundColor: 'var(--white)',

      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        content: '""',
      },

      'input:hover ~ &': {
        backgroundColor: 'var(--black-05)',
      },

      'input:disabled ~ &': {
        border: '3px solid var(--black-20)',
      },
    },
    
    formControl: {
      width: 159,
      //height: 120,
      color: 'var(--black-100)',
      margin: '0 12px 24px',
      padding: '0 0 0 18px',
      border: '1px solid var(--white)',
      borderLeftWidth: '8px',
      background: 'var(--white)',
      borderRadius: 4,
      boxShadow: 'none',
      "& svg":{
        width: '44px',
        height: '44px',
      },

      '.inline-permissions &': {
        width: '100%',
        height: 38,
        margin: '4px 0',
        padding: '0 0 0 32px',
      },

      '&.radio-style-small': {
        height: 40,
        padding: '0 0 0 32px',
        [theme.breakpoints.down(1305)]: {
          margin: '0 12px 24px !important',
        }
      },

      '&.radio-style-wide': {
        width: 'calc(100%/3 - 16px)',
        minWidth: 160,
        minHeight: 208,
        height: 'auto',
        margin: '0 0 24px 0',
      },

      '&.radio-style-inline': {
        width: 'calc(100%/2 - 16px)',
        minWidth: 159,
        margin: '0 0 24px 0',
        height: 'auto',
        '& .MuiButtonBase-root': {
          position: 'absolute',
          right: 0,
          top: 0,
        }
      },
      '&.radio-style-middle': {
        margin: '0 0 24px 0',
      },
      '&:first-child': {
        marginLeft: 0,
      },

      '&:last-child': {
        marginRight: 0,
      },

      '&.orangeCheckbox': {
        borderColor: 'var(--secondary-orange)',
      },

      '&.greenCheckbox': {
        borderColor: 'var(--secondary-green)',
      },

      '&.turquoiseCheckbox': {
        borderColor: 'var(--secondary-turquoise)',
      },

      '&.darkblueCheckbox': {
        borderColor: 'var(--primary-regular)',
      },

      '&.greyCheckbox': {
        borderColor: 'var(--black-20)',
      },

      '&.yellowCheckbox': {
        borderColor: 'var(--dark-yellow)',
      },

      '&.redCheckbox': {
        borderColor: 'var(--dark-red)',
      },

      '&.blueCheckbox': {
        borderColor: 'var(--dark-blue)',
      },

      '&.purpleCheckbox': {
        borderColor: 'var(--dark-purple)',
      },
    },

    iconCheckbox: {
      top: '18%',
      left: '18px',
      color: 'var(--primary-regular)',
      width: '35px',
      height: '35px',
      position: 'absolute',
      fontSize: '1.5rem',

      '&.darkblueCheckbox': {
        color: 'var(--primary-regular)',
      },

      '&.greyCheckbox': {
        color: 'var(--black-20)',
      },

      '.inline-permissions &': {
        left: '3.5%',
        fontSize: '1rem',
        lineHeight: '1.4rem',
      },

      '.radio-style-small &': {
        left: '7%',
        fontSize: '1rem',
        lineHeight: '1.4rem',
      },

      '.radio-style-inline &': {
        left: '3.5%',
        fontSize: '1rem',
        lineHeight: '1.4rem',
      },
    },

    formControlLabel: {
      margin: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',

      '&:disabled': {
        color: 'var(--black-40)',
      },

      '.inline-permissions &': {
        flexDirection: 'row-reverse',
        alignItems: 'center',
      },

      '.radio-style-small &': {
        flexDirection: 'row-reverse',
        alignItems: 'center',
      },

      '.radio-style-middle &': {
        height: '100%'
      },

      '.radio-style-wide &': {
        flexDirection: 'row-reverse',
        alignItems: 'center',
      },

      '.radio-style-inline &': {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1.2rem'
      },
    },

    labelChecked: {
      flexGrow: 1,
      color: 'var(--black-100)',
      fontWeight: 600,
      padding: '10px 10px 0 0',

      '.radio-style-small &': {
        padding: '0 10px',
      },

      '.radio-style-wide &': {
        padding: '0',
      },

      '.radio-style-middle &': {
        width: '100%',
        padding: '10px 10px 10px 0',
        display: 'flex',
        alignItems: 'center',
      },

      '.radio-style-inline &': {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        lineHeight: '2.4rem'
      },
    },

    radioColorCheckedSimple: {
      borderColor: 'var(--secondary-blue)',

      '&.greyCheckbox': {
        borderColor: 'var(--secondary-blue)',
      },

      '& .greyCheckbox': {
        color: 'var(--secondary-blue)',
      },
    },

    formControlLabelText: {
      flexGrow: 1,
      color: 'var(--black-100)',
      fontWeight: 600,
      padding: '10px 10px 0 0',
      '.inline-permissions &': {
        padding: '0 10px',
      },

      '.radio-style-small &': {
        padding: '0 10px',
      },

      '.radio-style-wide &': {
        padding: '0',
      },
      '.radio-style-middle &': {
        width: '100%',
        padding: '10px 10px 10px 0',
        display: 'flex',
        alignItems: 'center'
      },
      '.radio-style-inline &': {
        padding: 0,
        lineHeight: '2.4rem'
      },
    },

    bookMark: {
      color: 'var(--secondary-orange)',
      fontSize: '2.4rem',
      marginTop: '-6px',
    },

    wideLabel: {
      display: 'flex',
      alignItems: 'center',
    },

    wideRadioDescription: {
      fontSize: 12,
      lineHeight: 1.5,
      color: 'var(--black-60)',
      margin: '0 25px 0 0',

      '& p': {
        padding: '5px 0',
        fontWeight: 'bold',
      },

      '& .fa': {
        color: 'var(--primary-regular)',
      },

      '& .fas': {
        color: 'var(--primary-regular)',
      },

      '& ul': {
        padding: '5px 0',
        listStyleType: 'none',
        lineHeight: 1.33,
        fontWeight: 500,
      },

      '& ul li': {
        padding: '0 0 10px',
        marginLeft: '20px',

        '&::before': {
          position: 'absolute',
          fontFamily: `'Font Awesome 5 Free'`,
          content: `''`,//hack!
          fontWeight: 900,
          fontSize: '0.7rem',
          color: 'var(--primary-regular)',
          padding: '0 10px 0 0',
          marginLeft: '-20px',
        },
      }
    },
  }
});

const StyledRadio = props => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const setColor = (id) => {
  id = parseInt(id);
  switch (id) {
    case CS_SERVICE:
      return 'orangeCheckbox';
    case EDD_SERVICE:
      return 'turquoiseCheckbox';
    case PES_SERVICE:
      return 'darkblueCheckbox';
    case SI_SERVICE:
      return 'greenCheckbox';
    case LEGAL_COMP_SERVICE:
      return 'yellowCheckbox';
    case TECH_INTELLI_SERVICE:
      return 'redCheckbox';
    case PARTNER_SEARCH_SERVICE:
      return 'blueCheckbox';
    case TRENDS_INTELIGENCE_SERVICE:
      return 'purpleCheckbox';
    default:
      return 'greyCheckbox';
  }
};

const imageId = (id, variant) => {
  id = parseInt(id);
  switch (id) {
    case 1:
      return variant === 'inline' ? 'fas fa-binoculars' : 'fa-window-restore';
    case 2:
      return variant === 'inline' ? 'fas fa-glasses' : 'fa-user-tie';
    case 3:
      return 'fa-building';
    case 4:
      return 'fa-tags';
    default:
      return 'fa-tags';
  }
}

const iconId = (id, checked) => {
  id = parseInt(id);
  switch (id) {
    case CS_SERVICE:
      return <EyeInMonitorIcon color={checked ? 'var(--secondary-orange)' : 'var(--black-20)'}/>;
    case EDD_SERVICE:
      return <CogIcon color={checked ? 'var(--secondary-turquoise)' : 'var(--black-20)'}/>;
    case PES_SERVICE:
      return <TabletIcon color={checked ? 'var(--primary-regular)' : 'var(--black-20)'}/>;
    case SI_SERVICE:
      return <IntelligenceIcon color={checked ? 'var(--secondary-green)' : 'var(--black-20)'}/>;
    case LEGAL_COMP_SERVICE:
      return <CheckmarkInTabletIcon color={checked ? 'var(--dark-yellow)' : 'var(--black-20)'}/>;
    case TECH_INTELLI_SERVICE:
      return <BulbIcon color={checked ? 'var(--dark-red)' : 'var(--black-20)'}/>;
    case PARTNER_SEARCH_SERVICE:
      return <LoupIcon color={checked ? 'var(--dark-blue)' : 'var(--black-20)'}/>;
    case TRENDS_INTELIGENCE_SERVICE:
      return <SaturnBikeIcon color={checked ? 'var(--dark-purple)' : 'var(--black-20)'}/>;
    default:
      return 'fa-tags';
  }
};

const CustomRadio = (props) => {
  const classes = useStyles(props);
  const { setImage, color, variant, id, value, label, checked, disabled, description } = props;
  return (
    <FormControl component="fieldset" className={clsx(
          classes.formControl,
          setColor(!checked || disabled || color ? 0 : id),
          (color === 'simple' && checked) ? classes.radioColorCheckedSimple : '',
          'radio-style-' + variant
      )}>
      <FormControlLabel
        label={variant === 'wide' && checked ? 
          (<span className={classes.wideLabel}><Icon className={clsx("fas fa-bookmark", classes.bookMark)}/>{label}</span>) : 
          (label ? label : '')}
        control={
          variant && variant === 'middle' ? (
            <Grid container justify="space-between" alignItems="flex-start">
              {
                (variant !== 'wide') && 
                  <>
                    <Grid className="pt-3">{iconId(id, checked)}</Grid>
                    <StyledRadio key={id} checked={checked} value={value} disabled={disabled} />
                  </>
              }
            </Grid>
          ) : (
          <React.Fragment>
            {(variant !== 'wide') &&
              (<Icon className={clsx("fa",
                      setImage ? setImage : imageId(id, variant),
                      classes.iconCheckbox,
                      setColor(!checked || disabled || color ? 0 : id))}
              />)}
            <StyledRadio key={id} checked={checked} value={value} disabled={disabled} />
          </React.Fragment>)
        }
  
        classes={{
          root: classes.formControlLabel,
          label: checked ? classes.labelChecked : classes.formControlLabelText,
        }}
      />
      {(variant === 'wide') && (
        <div className={classes.wideRadioDescription} dangerouslySetInnerHTML={{__html: description}} />
      )}
    </FormControl>
  );
};

CustomRadio.defaultProps = {
  value: '',
  label: '',
  checked: false,
  disabled: false,
  description: '',
  variant: 'middle',
  color: '',
};

CustomRadio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]), 
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default CustomRadio;
