// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CustomExpansionPanel from 'components/UI/CustomExpansionPanel';
import FormInfoSection from 'components/FormInfoSection';
import IconButton from 'components/IconButton';
import { ViewDetails } from 'components/shared';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Api
import { deleteRelatedSubject } from 'api/projects';
// Constants
import { ROLE_ADMIN_READ_ONLY } from 'utils';

const Subjects = ({ 
  typeKeySubject, 
  subjects, 
  activities,
  children, 
  onDeleteRelatedSubject, 
  serviceId, 
  logginUserRole }) => {
  const [indexForDelete, setIndexForDelete] = useState(null);
  const { execute: relatedSubjectDelete, value: subjectDeleteResponse } = useAsync(
    deleteRelatedSubject, 
    false
  );
  const {
    open: openConfirmDeleteRelatedSubject,
    handleOpen: handleOpenConfirmDeleteProject,
    handleClose: handleCloseConfirmDeleteRelatedSubject,
  } = useDialog();

  const { handleBack, handleBackTwoSteps } = activities;

  const handleConfirmDialogDeleteRelatedSubject = ({ subject, index }) => {
    relatedSubjectDelete({ project_id: subject.project_id, subject_type: subject.type, subject_id: subject.id });
    onDeleteRelatedSubject(subject.type, index, typeKeySubject);
    handleCloseConfirmDeleteRelatedSubject();
  };
  const handlerForDeleteRelatedSubject = (delIndex) => {
    setIndexForDelete(delIndex);
    handleOpenConfirmDeleteProject();
  };
  useEffect(() => {
    if (subjectDeleteResponse) {
      subjects.splice(indexForDelete, 1);
      toast.success("Related subject deleted", { autoClose: 5000 });
    }
  }, [subjectDeleteResponse, subjects, indexForDelete]);

  const renderSubjects = subjects.map((subject, index) => {
    const text = subject.is_key ? 'Key Subject' : 'Related Subject Info';
    const titleSubject = !subject.is_key ? subject.type : '';
    const countryCode = subject.country_code;
    const subjectFormData = subject;
    const fields = ['name', 'country', 'registered_address', 'operational_address', 
                    'website', 'name_in_local_language', 'trading_name', 'business_license_number',
                    'tax_number', 'position', 'passport_number', 'residential_address', 'shares', 
                    'alias', 'national_id', 'business_address']
    const reducedSubject = Object.keys(subjectFormData).reduce((acc, key) => {
      acc = [
        ...acc,
        {
          value: fields.includes(key) ? subjectFormData[key] : null,
          label: key === 'name' && subject.type === 'Entity' ? 'Company name' : key.split('_').join(' '),
          key: subject.type + '_' + key,
        },
      ];
      return acc;
    }, []);
    const notEmptySubjectData = reducedSubject.filter(element => element.value !== null);

    return (
      <Grid container key={index}>
      <Grid item xs={12}>
        <Typography variant="h3" className={"pb-4 pl-0 pr-0" + (text === 'Related Subject Info' ? ' pt-4' : '')}>{text}
          {Object.keys(activities).length > 0 &&
            <IconButton
              disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
              inverted="true" 
              className="mr-0 minus-mt-5 float-right"
              onClick={parseInt(serviceId) === 1 ? (subject.is_key ? handleBackTwoSteps : handleBack) : handleBack}
            >
              <Icon className="fa fa-pen" />
            </IconButton>}
          {Object.keys(activities).length > 0 && !subject.is_key &&
            <IconButton 
              disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
              inverted="true" 
              className="mr-0 minus-mt-5 float-right" 
              onClick={() => handlerForDeleteRelatedSubject(index)}
            >
              <Icon className="fa fa-trash-alt" />
            </IconButton>}
        </Typography>
        <CustomExpansionPanel title={titleSubject} id={subject.type}>
          <Grid container direction="row" className="mb-2" spacing={2}>
            {notEmptySubjectData.map((el) => {
              return (
                <ViewDetails
                  key={el.key}
                  label={<FormInfoSection text={el.label} />}
                  name={String(el.value)}
                  countryCode={countryCode}
                />
              );
            })}
          </Grid>
          {children &&
            <Grid container direction="column" alignItems="stretch" spacing={4}>
              <Grid item xs={12} className="mt-3 mb-3">
                <Divider className="mt-1 mb-1" />
                {children}
              </Grid>
            </Grid>
          }
        </CustomExpansionPanel>
        <Divider className="mt-1 mb-2 minus-m-25" />
      </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Grid container className="pb-3" direction="column" justify="flex-start" alignItems="stretch">
        {renderSubjects}
      </Grid>
      {subjects[indexForDelete] &&
        <ConfirmDialog
          title="Delete related subject"
          confirmBtnText="Confirm"
          options={{subject: subjects[indexForDelete], index: indexForDelete}}
          open={openConfirmDeleteRelatedSubject}
          onClose={handleCloseConfirmDeleteRelatedSubject}
          onConfirm={handleConfirmDialogDeleteRelatedSubject}
        >
          You are about to delete related subject. Please confirm.
        </ConfirmDialog>
      }
      <ToastContainer />
    </>
    );
};

Subjects.defaultProps = {
  subjects: [],
  activities: {},
};

Subjects.propTypes = {
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  activities: PropTypes.shape({
    handleBack: PropTypes.func,
    handleBackTwoSteps: PropTypes.func,
  }),
};

export default Subjects;
