// Core
import React from 'react';
import { useHistory } from 'react-router-dom';
// @material-ui
import { Box, Typography } from '@material-ui/core';
// Components
import Button from 'components/CustomButton';

const ServerErrorPage = () => {
  const { push } = useHistory();

  return (
    <Box padding="25px">
      <Typography gutterBottom variant="h1" color="error">
        500 Internal Server Error
      </Typography>
      <Typography gutterBottom variant="h2" color="error">
        The server encountered an internal error and was unable to complete your
        request.
      </Typography>
      <Typography gutterBottom variant="h2" color="error">
        Please try again later, or contact the Support and inform them of the
        time the error occurred, and anything you might have done that may have
        caused the error.
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => push('/home')}>
        Go to Home page
      </Button>
    </Box>
  );
};

export default ServerErrorPage;
