// Core
import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
// Components
import CSAdviceStep from 'components/OrderFlow/CSAdviceStep';
import CSKeySubjectStep from 'components/OrderFlow/CSKeySubjectStep';
import CSRelatedSubjectStep from 'components/OrderFlow/CSRelatedSubjectStep';
import {
  ACCOUNT_REGEX,
  WEBSITE_REGEX,
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
  PARTNER_SEARCH_SERVICE,
  ROLE_ADMIN_READ_ONLY,
  LEGAL_COMP_CONFIG, PARTNER_SEARCH_ANALYSIS_CONFIG, TRENDS_INTELLIGENCE_CONFIG,
} from 'utils';
import CandidateProfileStep from './PESStepForms/CandidateProfileStep';
import GeneralSubjectStep from './EDDStepForms/GeneralSubjectStep';
import KeySubjectStep from './OrderStepForms/KeySubjectStep';
import ProjectTypeStep from './OrderStepForms/ProjectTypeStep';
import RequestSummaryForm from './RequestSummaryForm';
import AnalysisStep from './SIStepForms/AnalysisStep';
import AnalysisDetailsStep from './SIStepForms/AnalysisDetailsStep';
import TechIntelliAnalysisStep from './TechIntelligenceStepForms/AnalysisStep';
import TechIntelliAnalysisDetailsStep from './TechIntelligenceStepForms/AnalysisDetailsStep';
import LegalComplianceGeneralStep from './LegalCompilanceStepForms/LegalComplianceGeneralStep';
import PartnerSearchGeneralStep from "./PartnerSearchStepForms/PartnerSearchGeneralStep";
import TrendsIntelligenceGeneralStep from "./TrendsIntelligenceStepForms/TrendsIntelligenceGeneralStep";
// Constants

const validationSchema = Yup.object().shape({
  entities: Yup.array(
    Yup.object().shape({
      name: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .transform(value => value.trim())
        .matches(ACCOUNT_REGEX, {
          message: 'You should use latin letters',
          excludeEmptyString: true,
        })
        .nullable(),
      country_code: Yup.string().nullable(),
      name_in_local_language: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      registered_address: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      tax_number: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      website: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .matches(WEBSITE_REGEX, {
          message: 'Invalid website pattern',
          excludeEmptyString: true,
        })
        .nullable(),
      trading_name: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .matches(/^[ a-zA-Z_]+( [a-zA-Z_ ]+)*$/, {
          message: 'You should use latin letters only',
          excludeEmptyString: true,
        })
        .nullable(),
      operational_address: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      business_license_number: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      position: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
    }),
  ),
  individuals: Yup.array(
    Yup.object().shape({
      name: Yup.string()
        .max(128, 'Must be 128 characters or less')
        // .matches(/^[a-zA-Z_]+( [a-zA-Z_]+)*$/, {
        .matches(/^[ a-zA-Z_]+( [a-zA-Z_ ]+)*$/, {
          message: 'You should use latin letters only',
          excludeEmptyString: true,
        })
        .nullable(),
      country_code: Yup.string().nullable(),
      name_in_local_language: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      passport_number: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      residential_address: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      shares: Yup.string()
        .matches(/^[0-9]$|^[1-9][0-9]$|^(100)$/, {
          message: 'You should enter a number from 0 till 100 inclusively',
          excludeEmptyString: true,
        })
        .nullable(),
      website: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .matches(WEBSITE_REGEX, {
          message: 'Invalid website pattern',
          excludeEmptyString: true,
        })
        .nullable(),
      alias: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .matches(/^[a-zA-Z]+$/, {
          message: 'You should use latin letters only',
          excludeEmptyString: true,
        })
        .nullable(),
      national_id: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .matches(/^\d+$/, {
          message: 'You should use digits only',
          excludeEmptyString: true,
        })
        .nullable(),
      business_address: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
      position: Yup.string()
        .max(128, 'Must be 128 characters or less')
        .nullable(),
    }),
  ),
});

const StepForm = ({
                    activeStep,
                    onClickBack,
                    onClickNextSkipOneStep,
                    onClickBackTwoSteps,
                    onCreateProject,
                    onUpdateProject,
                    values,
                    project,
                    setValues,
                    showCSAdviceStep,
                    onSetShowCSAdviceStep,
                    onClickNext,
                    onDeleteRelatedSubject,
                    setRenderForUpdateNextButton,
                    handleGoToSomeStep,
                    logginUserRole,
                  }) => {
  const initialValues = {...values};
  /* Dynamically set 'is_key' to true for the key subject, and 'false' to secondary subject
  All next subjects also must have 'is_key' = false, by default 'false' */
  if (initialValues.entities[0] && !initialValues.typeKeySubject) {
    initialValues.entities[0].is_key = true;
    if (initialValues.individuals[0]) {
      initialValues.individuals[0].is_key = false;
    }
  } else if (initialValues.individuals[0] && initialValues.typeKeySubject) {
    initialValues.individuals[0].is_key = true;
    if (initialValues.entities[0]) {
      initialValues.entities[0].is_key = false;
    }
  }

  const renderFormEdd = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <GeneralSubjectStep
            typeKeySubject={values.typeKeySubject}
            typeRelatedSubject={values.typeRelatedSubject}
            humint={values.humint}
            entityValues={values.entities[0]}
            individualValues={values.individuals[0]}
            serviceId={parseInt(values.serviceId)}
            selectedConfig={values.selectedConfig}
            onSetFieldValue={setFieldValue}
            isNewProject={values.newProjectId === 0}
          />
        );
      case 3:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            handleBackTwoSteps={onClickBackTwoSteps}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormCs = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return showCSAdviceStep === true ? (
          <CSAdviceStep
            typeKeySubject={values.typeKeySubject}
            onSetShowCSAdviceStep={onSetShowCSAdviceStep}
            onSetFieldValue={setFieldValue}
            values={values.adviceFlow}
            levelId={values.levelId}
            onClickNext={onClickNextSkipOneStep}
          />
        ) : (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
            onSetShowCSAdviceStep={onSetShowCSAdviceStep}
            projectId={values.newProjectId}
          />
        );
      case 2:
        const currentLevel = parseInt(values.levelId);
        const showWarningLevel =
          project &&
          parseInt(project.levelId) !== currentLevel &&
          currentLevel === 1;
        const showWarningSubject =
          project && project.typeKeySubject !== values.typeKeySubject;
        return (
          <KeySubjectStep
            typeKeySubject={values.typeKeySubject}
            serviceId={parseInt(values.serviceId)}
            levelId={values.levelId}
            selectedConfig={values.selectedConfig}
            startedKeySubject={project && project.typeKeySubject}
            onSetFieldValue={setFieldValue}
            showWarningLevel={showWarningLevel}
            showWarningSubject={showWarningSubject}
            isNewProject={values.newProjectId === 0}
          />
        );
      case 3:
        /* typeKeySubject === false => EntityFormStep
        typeKeySubject === true => IndividualFormStep */
        return (
          <CSKeySubjectStep
            typeKeySubject={values.typeKeySubject}
            typeRelatedSubject={values.typeRelatedSubject}
            entityValues={values.entities[0]}
            individualValues={values.individuals[0]}
          />
        );
      case 4:
        return (
          <CSRelatedSubjectStep
            typeKeySubject={values.typeKeySubject}
            typeRelatedSubject={values.typeRelatedSubject}
            entities={values.entities}
            individuals={values.individuals}
            onSetFieldValue={setFieldValue}
            isNewProject={values.newProjectId === 0}
            setRenderForUpdateNextButton={setRenderForUpdateNextButton}
          />
        );
      case 5:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            handleBackTwoSteps={onClickBackTwoSteps}
            onDeleteRelatedSubject={onDeleteRelatedSubject}
            logginUserRole={logginUserRole}
          />
        );

      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormPes = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <CandidateProfileStep
            typeKeySubject
            humint={values.humint}
            individualValues={values.individuals[0]}
            selectedConfig={values.selectedConfig}
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 3:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormSi = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <AnalysisStep
            selectedConfig={values.selectedConfig}
            serviceId={parseInt(values.serviceId)}
            analysisTypeId={parseInt(values.analysisTypeId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 3:
        return (
          <AnalysisDetailsStep
            config_id={parseInt(values.selectedConfig.config_id)}
            newProjectId={values.newProjectId}
            values={values.strategicIntelligence}
            onCreateProject={onCreateProject}
            onUpdateProject={onUpdateProject}
            onClickBack={onClickBack}
            onSetFieldValue={setFieldValue}
          />
        );
      case 4:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            handleBackTwoSteps={onClickBackTwoSteps}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormLegalComp = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <LegalComplianceGeneralStep
            config_id={LEGAL_COMP_CONFIG}
            newProjectId={values.newProjectId}
            values={values.legalCompliance}
            onCreateProject={onCreateProject}
            onUpdateProject={onUpdateProject}
            onClickBack={onClickBack}
            onSetFieldValue={setFieldValue}
          />
        );
      case 3:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormTechIntelli = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <TechIntelliAnalysisStep
            selectedConfig={values.selectedConfig}
            serviceId={parseInt(values.serviceId)}
            analysisTypeId={parseInt(values.analysisTypeId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 3:
        return (
          <TechIntelliAnalysisDetailsStep
            config_id={parseInt(values.selectedConfig.config_id)}
            newProjectId={values.newProjectId}
            values={values.techIntelligence}
            onCreateProject={onCreateProject}
            onUpdateProject={onUpdateProject}
            onClickBack={onClickBack}
            onSetFieldValue={setFieldValue}
          />
        );
      case 4:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            handleBackTwoSteps={onClickBackTwoSteps}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormPartnerSearch = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <PartnerSearchGeneralStep
            config_id={PARTNER_SEARCH_ANALYSIS_CONFIG}
            newProjectId={values.newProjectId}
            values={values.partnerSearch}
            onCreateProject={onCreateProject}
            onUpdateProject={onUpdateProject}
            onClickBack={onClickBack}
            onSetFieldValue={setFieldValue}
          />
        );
      case 3:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            handleBackTwoSteps={onClickBackTwoSteps}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderFormTrendsIntelli = (values, setFieldValue) => {
    switch (activeStep) {
      case 1:
        return (
          <ProjectTypeStep
            serviceId={parseInt(values.serviceId)}
            onSetFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <TrendsIntelligenceGeneralStep
            config_id={TRENDS_INTELLIGENCE_CONFIG}
            newProjectId={values.newProjectId}
            values={values.trendsIntelligence}
            onCreateProject={onCreateProject}
            onUpdateProject={onUpdateProject}
            onClickBack={onClickBack}
            onSetFieldValue={setFieldValue}
          />
        );
      case 3:
        return (
          <RequestSummaryForm
            values={values}
            handleBack={onClickBack}
            handleBackTwoSteps={onClickBackTwoSteps}
            logginUserRole={logginUserRole}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  const renderForm = (serviceId, values, setFieldValue) => {
    switch (parseInt(serviceId)) {
      case CS_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(5);
        }
        return renderFormCs(values, setFieldValue);
      case EDD_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(3);
        }
        return renderFormEdd(values, setFieldValue);
      case PES_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(3);
        }
        return renderFormPes(values, setFieldValue);
      case SI_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(4);
        }
        return renderFormSi(values, setFieldValue);
      case LEGAL_COMP_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(3);
        }
        return renderFormLegalComp(values, setFieldValue);
      case TECH_INTELLI_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(4);
        }
        return renderFormTechIntelli(values, setFieldValue);
      case PARTNER_SEARCH_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(3);
        }
        return renderFormPartnerSearch(values, setFieldValue);
      case TRENDS_INTELIGENCE_SERVICE:
        if (
          values.newProjectId !== 0 &&
          logginUserRole === ROLE_ADMIN_READ_ONLY
        ) {
          handleGoToSomeStep(3);
        }
        return renderFormTrendsIntelli(values, setFieldValue);
      default:
        return null;
    }
  };
  let projectValues = null;
  if (project && project.newProjectId && initialValues.newProjectId === 0) {
    initialValues.newProjectId = project.newProjectId;
    if (parseInt(activeStep) === 1) {
      initialValues.serviceId = project.serviceId;
      if (project.serviceId !== SI_SERVICE && project.serviceId !== LEGAL_COMP_SERVICE && project.serviceId !== PARTNER_SEARCH_SERVICE && project.serviceId !== TRENDS_INTELIGENCE_SERVICE) {
        initialValues.levelId = project.levelId;
        initialValues.humint = !![5, 7].includes(project.configId);
        initialValues.typeKeySubject = project.typeKeySubject;
        initialValues.typeRelatedSubject = project.typeRelatedSubject;
        switch (parseInt(project.serviceId)) {
          case CS_SERVICE:
            initialValues.serviceTitle = 'Compliance Screening';
            break;
          case EDD_SERVICE:
            initialValues.serviceTitle = 'Enhanced Due Diligence';
            break;
          case PES_SERVICE:
            initialValues.serviceTitle = 'Pre-employment Screening';
            break;
          case TECH_INTELLI_SERVICE:
            initialValues.analysisTypeId = project.analysisTypeId;
            initialValues.serviceTitle = 'Tech Intelligence';
            initialValues.techIntelligence = project.techIntelligence;
            break;
          default:
            initialValues.serviceTitle = 'Compliance Screening';
        }
      } else if (project.serviceId === SI_SERVICE) {
        initialValues.analysisTypeId = project.analysisTypeId;
        initialValues.strategicIntelligence = project.strategicIntelligence;
        // change const serviceTitle on dynamicly loaded from somewhere
        initialValues.serviceTitle = 'Starategic Intelligence';
      } else if (project.serviceId === PARTNER_SEARCH_SERVICE) {
        initialValues.serviceTitle = 'Partner Search';
        initialValues.partnerSearch = project.partnerSearch;
      } else if (project.serviceId === TRENDS_INTELIGENCE_SERVICE) {
        initialValues.serviceTitle = 'Trends Intelligence';
        initialValues.trendsIntelligence = project.trendsIntelligence;
      } else {
        initialValues.serviceTitle = 'Legal and Compliance';
        initialValues.legalCompliance = project.legalCompliance;
      }
    }
  }
  if (project) {
    const currentServiceId = project.serviceId;
    // if ((currentServiceId === CS_SERVICE && [3, 4].includes(activeStep))
    if (
      (currentServiceId === CS_SERVICE && [3, 4, 5].includes(activeStep)) ||
      ([EDD_SERVICE, PES_SERVICE].includes(currentServiceId) &&
        activeStep === 2)
    ) {
      projectValues = initialValues;
      projectValues.newProjectId = project.newProjectId;
      if (project.typeKeySubject === initialValues.typeKeySubject) {
        if (project.entities) {
          projectValues.entities = project.entities;
        }
        if (project.individuals) {
          projectValues.individuals = project.individuals;
        }
        if (initialValues.levelId === 1) {
          projectValues.entities = !project.typeKeySubject
            ? project.entities
            : [];
          projectValues.individuals = project.typeKeySubject
            ? project.individuals
            : [];
        }
      }
    }
  }

  const renderCurrentForm = (values, setFieldValue) => {
    setValues(values);
    return <form>{renderForm(values.serviceId, values, setFieldValue)}</form>;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={projectValues || initialValues}
      validationSchema={validationSchema}>
      {({values, setFieldValue}) => renderCurrentForm(values, setFieldValue)}
    </Formik>
  );
};

StepForm.defaultProps = {
  values: {},
  project: {},
  setValues: () => null,
  showCSAdviceStep: false,
  onClickBack: () => null,
  onClickNextSkipOneStep: () => null,
  onClickBackTwoSteps: () => null,
  onSetShowCSAdviceStep: () => null,
  onUpdateProject: () => null,
  onClickNext: () => null,
  onDeleteRelatedSubject: () => null,
  onCreateProject: () => null,
  setRenderForUpdateNextButton: () => null,
  handleGoToSomeStep: () => null,
  logginUserRole: 4,
};

StepForm.propTypes = {
  activeStep: PropTypes.number.isRequired,
  values: PropTypes.shape({}),
  project: PropTypes.shape({}),
  setValues: PropTypes.func,
  showCSAdviceStep: PropTypes.bool,
  onClickBack: PropTypes.func,
  onClickNextSkipOneStep: PropTypes.func,
  onClickBackTwoSteps: PropTypes.func,
  onSetShowCSAdviceStep: PropTypes.func,
  onUpdateProject: PropTypes.func,
  onClickNext: PropTypes.func,
  onDeleteRelatedSubject: PropTypes.func,
  onCreateProject: PropTypes.func,
  setRenderForUpdateNextButton: PropTypes.func,
  handleGoToSomeStep: PropTypes.func,
  logginUserRole: PropTypes.number,
};

export default StepForm;
