// Core
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import moment from 'moment';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardActions, CardContent, Icon, Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import IconButton from 'components/IconButton';
// Helpers
import { transformProjectId } from 'helpers';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.custom.greyBorder}`,

    '&:hover': {
      border: `1px solid ${theme.palette.custom.navy}`,
      boxShadow: 0,
    },
    '& .MuiCardActions-root': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      }  
    },
    '& .MuiCardActions-root .MuiButton-root': {
      [theme.breakpoints.down('xs')]: {
        padding: '6px'
      }
    }
  },

  colWidth: {
    [theme.breakpoints.up('sm')]: {
      width: '14.2%',
      display: 'inline-flex',
      overflow: 'hidden',
      minWidth: '125px',
    },
  },

  flag: {
    '&.MuiIcon-root': {
      display: 'contents'
    }
  },

  countryFlag: {
    marginTop: 1,
    transform: 'scale(1.5)'
  }
}));

const ProjectCard = props => {
  const classes = useStyles(props);
  const {
    to,
    id,
    service_id,
    service_name,
    status,
    subject_name,
    account_name,
    level,
    country,
    due_date,
    req_date,
    account_id,
    country_code,
    variantTiny
  } = props;
  const service_classes = "typeService" + (service_id ? service_id : "1");
  const status_classes = "status-btn status-" + (status ? status.replace(" ", "-").toLowerCase() : "");
  return (
    <Card className={classes.card}>
      <CardActions disableSpacing className={clsx("tableCell", {"tinyPaddings": variantTiny})}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Typography variant="h2" style={{minWidth: '20%'}}>{account_id + transformProjectId(id)}</Typography>
          <Button disabled className={service_classes}>{service_name}</Button>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Link to={to}>
            <IconButton inverted="true"><Icon className="fa fa-caret-right" /></IconButton>
          </Link>
        </Grid>
      </CardActions>
      <CardContent className={clsx("tableCell", {"tinyPaddings": variantTiny})}>
        <Box display="flex" className={classes.colWidth}>
          <IconButton inverted="true" disabled><Icon className="fa fa-file-alt" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Key Subject</label><br />
            <span className="text-truncate">{subject_name}</span>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth}>
          <IconButton inverted="true" disabled><Icon className="fa fa-building" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Account Name</label><br />
            <Tooltip title={account_name} placement="bottom-start">
              <span className="text-truncate">{account_name}</span>
            </Tooltip>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth} style={{ minWidth: '130px' }}>
          <IconButton inverted="true" disabled><Icon className="fa fa-star" style={{ width: '1.1em' }} /></IconButton>
          <Typography variant="body2">
            <label>Project Status</label><br />
            <Button disabled className={status_classes}>{status}</Button>
          </Typography>
        </Box>  
        <Box display="flex" className={classes.colWidth}>
          <IconButton inverted="true" disabled><Icon className="fa fa-angle-double-up" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Level</label><br />
            <span className="text-truncate">{level}</span>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth}>
          {/* <IconButton inverted="true" disabled><Icon className="fa fa-globe-americas" /></IconButton> */}
          {parseInt(country_code) !== -1 ? (
            <IconButton inverted="true" disabled>
              <Icon className={classes.flag}>
                <div className={clsx(`flag flag-${country_code}`, classes.countryFlag)}></div>  
              </Icon>
            </IconButton>
          ) : (
            <IconButton inverted="true" disabled><Icon className="fa fa-globe" /></IconButton>
          )}
          <Typography variant="body2" className="text-truncate">
            <label>Country</label><br />
            <span className="text-truncate">{country}</span>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth}>
          <IconButton inverted="true" disabled><Icon className="fas fa-stopwatch" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Launch Date</label><br />
            <span className="text-truncate">{moment(req_date).format('DD MMM YYYY, HH:mm')}</span>
          </Typography>
        </Box>
        <Box display="flex" className={classes.colWidth}>
          <IconButton inverted="true" disabled><Icon className="fa fa-calendar-check" /></IconButton>
          <Typography variant="body2" className="text-truncate">
            <label>Due Date</label><br />
            <span className="text-truncate">
              { moment(due_date).format('DD MMM YYYY') }
            </span>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ProjectCard.defaultProps = {
  notification: '',
  account_id: '',
  variantTiny: false,
};

ProjectCard.propTypes = {
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  subject_name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  account_name: PropTypes.string.isRequired,
  due_date: PropTypes.string.isRequired,
  req_date: PropTypes.string.isRequired,
  notification: PropTypes.string,
  account_id: PropTypes.string,
  variantTiny: PropTypes.bool,
};

export default ProjectCard;
