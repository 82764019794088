// Core
import React from 'react';
import propTypes from 'prop-types';
// material-ui
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import newEarthGlobe from 'assets/new_earth_globe.png';
import signInHoneyCombs from 'assets/sign_in_honey_combs.svg';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#183f73',//--primary-regular
    },
    secondary: {
      main: '#cb7a2a',//--secondary-orange
    },
    custom: {
      whiteFour: '#f5f5f5',
      brownishOrange: '#cb7a2a',//--secondary-orange
      greyish: '#061427',//--primary-pressed
      mediumGrey: '#646462',
      darkSlateBlue25: '#2c4173',
      navy: '#183f73',
      blackish: '#061427',//--primary-pressed
      greyBorder: ' #cccfd2',//--black-20
    },
    background: {
      images: {
        earthGlobe: `url(${newEarthGlobe})`,
        honeyCombs: `url(${signInHoneyCombs})`
      },
      colors: {
        honeycombs: '#ffffff',
      }
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial"',
    fontFamilyCondensed: 'Montserrat',
    h1: {
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: 'normal',
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bold',
      lineHeight: 1.14,
    },
    h3: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1.44,
    },
    h4: {
      fontSize: 15,
      fontWeight: 'normal',
      lineHeight: 1.6,
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 'normal',
    },
    body2: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 'normal',
    },
  },
  overrides: {
    MuiPopover: {
      paper: {
        boxShadow: 'none',
        border: '1px solid var(--black-20)'
      }
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        height: 16,
        "& p": {
          fontSize: 14,
          fontWeight: 600,
          color: 'var(--black)'
        }
      }
    },
    MuiPickersDay: {
      daySelected: {
        borderRadius: 4,
      },
      day: {
        "&:hover": {
          borderRadius: 4,
        }
      }
    },
    MuiInputBase: {
      multiline: {
        padding: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        minHeight: '1em',
        lineHeight: '1em',
      },
    },
    MuiTextField: {
      root: {
        '&.searchInput': {
          '& .MuiFormHelperText-root': {
            display: 'none',
          },
          '& .MuiInputAdornment-root': {
            position: 'relative',
            marginLeft: '10px',
            border: 0,
            '& .MuiIcon-root': {
              color: 'var(--black-40)',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'var(--black-40)',
        textTransform: 'capitalize',
      },
    },
    MuiStep: {
      root: {
        padding: 0
      },
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: 0
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '100%',
      },
      paperScrollPaper: {
        borderRadius: 0,
      },
      root: {
        '&.drawer-page': {
          left: 'auto!important',
          '& .MuiDialog-paperScrollPaper': {
            margin: 0,
            height: '100%',
            maxHeight: '100%',
            width: '408px',
          },
          '& .drawer-page-title': {
            padding: '26px 24px 27px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            '& .fa': {
              color: 'var(--black-100)',
            },
          },
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: 24,
        '& .fa': {
          color: 'var(--primary-regular)',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0 0 24px 0',
        justifyContent: 'center',
      },
    },
    MuiBackdrop: {
      root: {
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.34)',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white',
        color: 'var(--black-100)',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiToolbar:{
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      regular: {
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: '64px'
      },
    },
    MuiBreadcrumbs: {
      root: {
        padding: '16px 0',
        color: 'var(--black-40)',
        '& a': {
          color: 'var(--black-40)',
        },
        '& p': {
          color: 'var(--black-40)',
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 0,
        borderRadius: 4,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'var(--primary-hover)',
          boxShadow: 'none',
        },
      },
      label: {
        width: 'auto',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: 'var(--primary-hover)',
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: `1px solid var(--black-20)`,
        '&:hover': {
          border: `1px solid var(--secondary-blue)`,
          backgroundColor: 'var(--secondary-blue)',
          color: 'var(--white)',
        },
      },
      contained: {
        '&.typeService': {
          '&1': {
            backgroundColor: 'var(--secondary-orange)',
            color: 'var(--white)',
          },
          '&2': {
            backgroundColor: 'var(--secondary-turquoise)',
            color: 'var(--white)',
          },
          '&3': {
            backgroundColor: 'var(--secondary-blue)',
            color: 'var(--white)',
          },
          '&4': {
            backgroundColor: 'var(--secondary-green)',
            color: 'var(--white)',
          },
          '&5': {
            backgroundColor: 'var(--dark-yellow)',
            color: 'var(--white)',
          },
          '&6': {
            backgroundColor: 'var(--dark-red)',
            color: 'var(--white)',
          },
          '&7': {
            backgroundColor: 'var(--dark-blue)',
            color: 'var(--white)',
          },
          '&8': {
            backgroundColor: 'var(--dark-purple)',
            color: 'var(--white)',
          },
        },
        '&.status-btn': {
          height: '18px',
          margin: '-4px 0 0',
          padding: '6px 10px',
          '&.status-draft': {
            backgroundColor: 'var(--black-60)',
            color: 'var(--white)',
          },
          '&.status-new': {
            backgroundColor: 'var(--secondary-green)',
            color: 'var(--white)',
          },
          '&.status-accepted': {
            backgroundColor: 'var(--secondary-turquoise)',
            color: 'var(--white)',
          },
          '&.status-in-progress': {
            backgroundColor: 'var(--secondary-turquoise)',
            color: 'var(--white)',
          },
          '&.status-completed': {
            backgroundColor: 'var(--secondary-blue)',
            color: 'var(--white)',
          },
          '&.status-on-hold': {
            backgroundColor: 'var(--secondary-orange)',
            color: 'var(--white)',
          },
        }
      }
    },
    MuiIcon: {
      root: {
        fontSize: 16,
        color: 'var(--white)',
      },
      colorSecondary: {
        color: 'var(--black-100)'
      }
    },
    MuiSelect: {
      root: {
        fontFamily: 'Montserrat'
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: 'Montserrat',
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'var(--black-100)',
      },
      h2: {
        fontFamily: 'Montserrat',
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: 'var(--black-100)',
      },
      h3: {
        fontFamily: 'Montserrat',
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.44,
        letterSpacing: 'normal',
        margin: 0,
        padding: '5px 5px 0',
        color: 'var(--black-100)',
      },
      h4: {
        fontFamily: 'Montserrat',
        fontSize: 15,
        fontWeight: 'normal',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: 'var(--black-100)',
      },
      body1: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 14,
        color: 'var(--greyish)',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '& label': {
          fontSize: 11,
        },
        '&.warning-msg': {
          color: '#ed3b16',
          fontSize: 12,
        },
      },
      body2: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 14,
        color: 'var(--black-100)',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '& label': {
          fontWeight: 'normal',
          fontSize: 10,
          lineHeight: '18px',
          color: 'var(--black-60)',
        },
        '& .small-msg': {
          fontWeight: 900,
          fontSize: 10,
          margin: '0 3px',
          color: 'var(--black-100)',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      root: {
        width: 'auto',
      },
      button: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 19,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: 'var(--greyish)',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
      secondary: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        color: 'var(--white)',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 0,
      },
    },
    MuiCardActions: {
      root: {
        '&.tableCell': {
          padding: '22px 8px 8px 13px',
          '&.tinyPaddings': {
            padding: '8px 8px 3px 13px',
          },
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        '&.tableCell': {
          padding: '0 13px 0 8px',
          '&:last-child': {
            paddingBottom: 22,
          },
          '&.tinyPaddings': {
            '&:last-child': {
              paddingBottom: 8,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: 0,
      },
      positionStart: {
        height: '100%',
        maxHeight: '100%',
        marginRight: 0,
      },
      positionEnd: {
        height: '100%',
        maxHeight: '100%',
        marginLeft: '8px',
        padding: '0 8px',
        borderLeft: '1px solid var(--black-20)',
      },
    },
    MuiTab: {
      root: {
        '@media(min-width:600px)': {
          minWidth: 0,
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        height: 4,
      },
    },
    MuiCheckbox: {
      root: {
        color: 'var(--black-20)',
      },
      colorSecondary: {
        '&$disabled': {
          color: 'var(--greyish)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: 'var(--greyish)',
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        display: 'flex',
        padding: '20px 0',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      actions: {
        textAlign: 'right',
        display: 'flex',
        flex: 'none',
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
       filterPaper: {
        height: '100%',
        margin: 0,
        maxHeight: '100%',
        right: 0,
        left: 'initial !important',
        minWidth: 300,
        maxWidth: 420,
        top: '0px!important',
        border: '0 none',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderRadius: 0,
      }/*,
      filterCloseIcon: {
        marginTop: 10,
        marginRight: 8,
      }, */
    },
    MUIDataTableFilterList: {
      root: {
        margin: 0,
        display: 'inline-flex',
      },
    },
    MUIDataTableFilter: {
      root: {
        padding: '24px 0 24px',
        fontFamily: 'inherit',
        backgroundColor: 'var(--white)',
        '& .MuiGridList-root': {
          padding: '10px 24px',
          '& .MuiGridListTile-root': {
            padding: '15px 17px 0!important',
          },
        },
      },
      checkboxListTitle: {
        marginLeft: 0,
      },
      startSelectAdornment: {
        fontWeight: 'bold',
      },
      containerFilterFields: {
        maxHeight: 'initial'
      }
    },
  },
});

const Theming = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theming.propTypes = {
  children: propTypes.node.isRequired,
};

export default Theming;
