// Core
import React from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// components
import WatchlistGuideLink from './WatchlistGuideLink';
// consts
import links from './consts';

const useStyles = makeStyles({
  header: {
    fontSize: 22,
    color: 'var(--secondary-orange)',
    fontWeight: 700,
    marginBottom: 10
  },
  linksListWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
});

const WatchlistGuideLinksList = () => {
  const styles = useStyles();

  return (
    <Grid className={styles.linksListWrapper}>
      <p className={styles.header}>INTERNATIONAL SANCTIONS AND WATCHLISTS</p>
      {
        links?.map((link, idx) => <WatchlistGuideLink key={idx} name={link.name} url={link.url} />)
      }
    </Grid>
  );
}

export default WatchlistGuideLinksList;
