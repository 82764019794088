// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
// Components
import { ViewDetails } from 'components/shared';
import FormInfoSection from 'components/FormInfoSection';
import IconButton from 'components/IconButton';
// Constants
import { ROLE_ADMIN_READ_ONLY } from 'utils';
import PartnershipTypesCheckboxes from 'components/PartnershipTypesCheckboxes/PartnershipTypesCheckboxes';

const PartnerSearchDetailsCard = ({
  country,
  countryCode,
  industry,
  partnership_types,
  handleBack,
  logginUserRole,
}) => {
  const [partnerShipTypesRecord, setPartnerShipTypesRecord] = useState();
  useEffect(() => {
    if (partnership_types) {
      const collectedRecords = new Map();
      partnership_types.map(partnership_type => {
        if (partnership_type.id && partnership_type.value) {
          collectedRecords.set(partnership_type.id, partnership_type.value);
        }
        return true;
      });
      setPartnerShipTypesRecord(collectedRecords);
    }
  }, [setPartnerShipTypesRecord, partnership_types]);

  return (
    <Grid>
      <Typography variant="h3" className="pl-0 pb-3 pt-3">
        General Subject
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="row">
        <ViewDetails
          fullWidth
          label={<FormInfoSection text="Country" />}
          name={country}
          countryCode={countryCode}
        />
      </Grid>
      <Grid container direction="row">
        <ViewDetails
          fullWidth
          label={<FormInfoSection text="Industry" />}
          name={industry}
        />
      </Grid>
      <Divider className="mt-1 mb-2" />
      <Typography variant="h3" className="pl-0 pt-3 pb-3">
        Type of partnership
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="column" className="pb-3">
        {partnership_types && (
          <PartnershipTypesCheckboxes
            doubleColumned={false}
            partnership_types={partnership_types}
            partnershipTypesRecords={partnerShipTypesRecord}
            disabled
          />
        )}
      </Grid>
      <Divider className="mt-1 mb-2 minus-m-25" />
    </Grid>
  );
};

PartnerSearchDetailsCard.defaultProps = {
  country: '',
  countryCode: '',
  industry: '',
  partnership_types: [],
  handleBack: () => null,
};

PartnerSearchDetailsCard.propTypes = {
  country: PropTypes.string,
  countryCode: PropTypes.string,
  industry: PropTypes.string,
  partnership_types: PropTypes.array,
  handleBack: PropTypes.func,
  logginUserRole: PropTypes.number,
};

export default PartnerSearchDetailsCard;
