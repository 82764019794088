import client from './client';

/**
 * Get all partners files for the questionnaire
 *
 * @param {string} partnerId the partner id
 * @returns {Promise} Promise object represents operation result
 */
 const getPartnerFilesList = async (partnerId) => {

  try {
    const response = await client.get(`/api/partners_files/${partnerId}/`);

    if (response.status === 200 || response.status === 204) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

/**
 * Download file from the server
 *
 * @param {String} partnerId project id
 * @param {String} fileId file id
 * @returns {Promise} Promise object represents operation result
 */
 const downloadPartnerFile = async (partnerId, fileId) => {

  try {
    const response = await client.get(`/api/partners_files/${partnerId}/${fileId}/`, {responseType: 'blob'});

    if (response) {
      function getFileNameFromContentDisposition(contentDisposition) {
        if (!contentDisposition) return null;
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        return match ? match[1] : null;
      }
      function getFileLocatonFromContentLocation(contentLocation) {
        if (!contentLocation) return null;
        const match = contentLocation.match(/.blob.core.windows.net?/);
        return match ? match["input"] : null;
      }
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const actualFileName = getFileNameFromContentDisposition(response.headers['content-disposition']);
      const azureLocation = getFileLocatonFromContentLocation(response.headers['content-location']);

      const link = document.createElement('a');
      link.download = actualFileName;
      link.href = azureLocation ? azureLocation : downloadUrl;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    return error;
  }
};

/**
 * Delete a current file from the server
 * 
 * @param {String} partnerId project id
 * @param {String} fileId file id
 * @returns {Promise} Promise object represents operation result
 */
 const deletePartnerFile = async (partnerId, fileId) => {

  try {
    const response = await client.delete(`/api/partners_files/${partnerId}/${fileId}/`);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return error;
  }
};


export {
  getPartnerFilesList,
  downloadPartnerFile,
  deletePartnerFile
};