import axios from 'axios';

const client = axios.create();

const getAccessToken = () => localStorage.getItem('access_token');
const getRefreshToken = () => localStorage.getItem('refresh_token');

client.interceptors.request.use(config => {
  const access_token = getAccessToken();
  const refresh_token = getRefreshToken();
  
  if (config.url.includes('refresh') && refresh_token) {
    config.headers = {'Authorization': `Bearer ${refresh_token}`};
  } else if (access_token) {
    config.headers = {'Authorization': `Bearer ${access_token}`};
  }
  return config;
});

export default client;
