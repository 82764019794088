// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import AnalysisDetailsCard from 'components/AnalysisDetailsCard';
import Button from 'components/CustomButton';
import { CommentCardsList } from 'components/Project';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import DetailsCard from 'components/DetailsCard';
import LeaveCommentForm from 'components/LeaveCommentForm';
import PageTitleSection from 'components/PageTitleSection';
import ProjectSubjects from 'components/Project/Subjects';
import ProjectSummaryBlock from 'components/Project/ProjectSummaryBlock';
import ResultFiles from 'components/ResultFiles';
import StickyBlock from 'components/UI/StickyBlock';
import Spinner from 'components/shared/Spinner';
// Helpers
import { transformProjectId } from 'helpers';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Context
import { ProjectStatusesContext } from 'context/ProjectStatusesContext';
import { ServicesContext } from 'context/ServicesContext';
import { UserContext } from 'context/UserContext';
// Instruments
import { createComment } from 'api/comments';
import { getUserFilesList } from 'api/files';
import { fetchProjectById, updateProject } from 'api/projects';
// Constants
import {PARTNER_SEARCH_SERVICE, ROLE_ADMIN_READ_ONLY, ROLE_WASP_ADMIN, TRENDS_INTELIGENCE_SERVICE} from 'utils';
import {
  SI_SERVICE,
  ENHANCED_ANALYSIS,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
} from 'utils';
import { Formik } from 'formik';
import LegalComplianceDetailsCard from 'components/OrderFlow/LegalCompilanceStepForms/LegalComplianceDetailsCard';
import TechIntelligenceDetailsCard from 'components/OrderFlow/TechIntelligenceStepForms/TechIntelligenceDetailsCard';
import TechIntelligenceTypeCard from 'components/OrderFlow/TechIntelligenceStepForms/TechIntelligenceTypeCard';
import PartnerSearchDetailsCard from 'components/OrderFlow/PartnerSearchStepForms/PartnerSearchDetailsCard';
import TrendsIntelligenceDetailsCard from 'components/OrderFlow/TrendsIntelligenceStepForms/TrendsIntelligenceDetailsCard';

const useStyles = makeStyles({
  wrapper: {
    padding: '1.5rem',
    position: 'relative',
  },
});

const ProjectDetailsPage = props => {
  const classes = useStyles();
  const { id } = useParams();
  const { push } = useHistory();
  const { projectStatuses } = useContext(ProjectStatusesContext);
  const { services } = useContext(ServicesContext);
  const { loggedInUser } = useContext(UserContext);
  const [openCommentInput, setOpenCommentInput] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [onConfirm, setOnConfirm] = useState(true);
  const [newDueDate, handleNewDueDateChange] = useState(null);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [osintValue, setOsintValue] = useState(null);

  const {
    open: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm,
  } = useDialog();

  const {
    open: openUpdateDueDateConfirm,
    handleOpen: handleOpenDueDateUpdateConfirm,
    handleClose: handleCloseDueDateUpdateConfirm,
  } = useDialog();

  const {
    execute: fetchProject,
    pending: fetchingProject,
    setValue: setProject,
    status: fetchProjectStatus, 
    value: project,
  } = useAsync(fetchProjectById, false);

  const { execute: updateProjectStatus, value: updatedProject } = useAsync(
    updateProject,
    false,
  );
  const {
    execute: sendComment,
    value: newComment,
    pending: sendingComment,
    status: createCommentStatus,
  } = useAsync(createComment, false);
  const {
    execute: hundleGetUserFilesList,
    setValue: setAllFiles,
    pending: userFilesLoading,
    value: userFiles,
  } = useAsync(getUserFilesList, false);

  useEffect(() => {
    if (updatedProject !== null) {
      toast.success('The project was successfully updated', {
        autoClose: 5000,
      });
    }
  }, [updatedProject]);

  useEffect(() => {
    if (newComment && createCommentStatus === 201) {
      toast.success('Comment has been sent', { autoClose: 2000 });
      const newComments = project.comments;
      newComments.push({ ...newComment });
      setProject({
        ...project,
        comments: newComments,
      });
      setOpenCommentInput(false);
    }
    // eslint-disable-next-line
  }, [newComment, createCommentStatus, setProject]);

  useEffect(() => {
    fetchProject(id);
  }, [fetchProject, id]);

  useEffect(() => {
    let foundValue = null;
    if (
      project &&
      project.service_id === SI_SERVICE &&
      project.config_id === ENHANCED_ANALYSIS
    ) {
      foundValue = project.analysis_details.risks[0].value;
      setOsintValue(foundValue);
    } else if (fetchProjectStatus && fetchProjectStatus !== 200) {
      push('/projects')
    }
  }, [project, fetchProjectStatus, push]);

  useEffect(() => {
    if (project && project.project_id) {
      hundleGetUserFilesList(project.project_id);
    }
  }, [hundleGetUserFilesList, project]);

  let projectId;
  let transformId;
  let accountId;
  let keySubject;
  let serviceId;
  let subjects;
  let comments;
  let resultFiles = [];
  let projectFiles = [];
  let currentService;
  if (project) {
    projectId = project.project_id;
    transformId = transformProjectId(projectId);
    accountId = project.account_id;
    keySubject = project.key_subject;
    serviceId = project.service_id;
    subjects = project.subjects;
    comments = project.comments;
    if (userFiles && !userFilesLoading) {
      resultFiles = userFiles.filter(file => file.is_result === true);
      projectFiles = userFiles.filter(file => !file.is_result);
    }
    currentService =
      services && services.find(service => serviceId === service.id);
  }

  const handleDatePickerOpen = () => {
    setDatePickerOpened(previousDatePickerOpened => !previousDatePickerOpened);
  };

  const handleOpenCommentInput = () => {
    setOpenCommentInput(prevOpenCommentInput => !prevOpenCommentInput);
  };

  const handleSetSelectedDueDate = dueDate => {
    handleNewDueDateChange(dueDate);
  };

  const handleUnsetSelectedDueDate = () => {
    handleNewDueDateChange(null);
  };

  const handleConfirmUpdateDueDate = () => {
    if (
      moment(newDueDate).format('ddd, DD MMM YYYY [00:00:00 GMT]') ===
      project.due_date
    ) {
      handleDatePickerOpen();
    } else if (newDueDate === null) {
      handleDatePickerOpen();
    } else {
      handleOpenDueDateUpdateConfirm();
    }
  };

  const handleSetSelectedStatus = status => {
    handleOpenConfirm();
    setSelectedStatus(status);
  };

  const handleUpdateProjectStatus = ({ status }) => {
    setOnConfirm(false);
    const response = updateProjectStatus({
      project_id: project.project_id,
      config_id: project.config_id,
      status,
      service_id: project.service_id,
    });
    response.then(function(value) {
      if (!value) {
        setProject({
          ...project,
          status: projectStatuses.find(
            projectStatus => projectStatus.id === status,
          ),
        });
        setOnConfirm(true);
      }
    });

    handleCloseConfirm();
  };

  const handleCreateNewComment = comment => {
    sendComment({ id, comment });
  };

  const handleUpdateProjectDueDate = () => {
    setOnConfirm(false);
    const response = updateProjectStatus({
      project_id: project.project_id,
      config_id: project.config_id,
      service_id: project.service_id,
      due_date: moment(newDueDate).format('DD/MM/YY'),
    });
    response.then(function(value) {
      if (!value) {
        setProject({
          ...project,
          due_date: newDueDate,
        });
        setOnConfirm(true);
        handleNewDueDateChange(null);
        handleDatePickerOpen();
      }
    });

    handleCloseDueDateUpdateConfirm();
  };

  const handleUpdateFiles = (file_obj, toRemove = false) => {
    if (file_obj && file_obj.id) {
      const current_file = userFiles.filter(file => file.id === file_obj.id);
      if (current_file.length === 0 && !toRemove) {
        userFiles.push(file_obj);
        setAllFiles(userFiles);
        resultFiles.push(file_obj);
      } else if (toRemove) {
        setAllFiles(userFiles.filter(file => file.id !== file_obj.id));
        resultFiles = userFiles.filter(file => file.id !== file_obj.id);
      }
    }
  };

  const handleUpdateUserFiles = (file_obj, toRemove = false) => {
    if (file_obj && file_obj.id) {
      const current_file = userFiles.filter(file => file.id === file_obj.id);
      if (current_file.length === 0 && !toRemove) {
        userFiles.push(file_obj);
        setAllFiles(userFiles);
        projectFiles.push(file_obj);
      } else if (toRemove) {
        setAllFiles(userFiles.filter(file => file.id !== file_obj.id));
        projectFiles = userFiles.filter(file => file.id !== file_obj.id);
      }
    }
  };

  const projectSubjects = (
    <ProjectSubjects keySubject={keySubject} subjects={subjects} />
  );

  const analysisTypeCard = project && project.analysis_details && (
    <AnalysisDetailsCard
      analysisType={project.config_id}
      country={project.analysis_details.country_name}
      countryCode={project.analysis_details.country_code}
      industry={project.analysis_details.industry_name}
      risks={project.analysis_details.risks}
      stakeholders={project.analysis_details.stakeholders}
    />
  );

  const LegalComplianceCard = project && project.legal_comp_details && (
    <LegalComplianceDetailsCard
      country={project.legal_comp_details.country_name}
      countryCode={project.legal_comp_details.country_code}
      industry={project.legal_comp_details.industry_name}
      sub_services={project.legal_comp_details.sub_services}
    />
  );

  const techIntelliInitialValues =
    project && project.tech_intelli_details
      ? { techIntelligence: project.tech_intelli_details }
      : { techIntelligence: { expectations: '', challenge_problem: '' } };
  const TechIntelligenceCard = project && project.tech_intelli_details && (
    <>
      <TechIntelligenceTypeCard configId={project.config_id} />
      <Grid container direction="column">
        <Formik initialValues={techIntelliInitialValues}>
        {({ values }) => {
          return (
            <form>
              <TechIntelligenceDetailsCard
                country={project.tech_intelli_details.country_name}
                countryCode={project.tech_intelli_details.country_code}
                industry={project.tech_intelli_details.industry_name}
                expectations={project.tech_intelli_details.expectations}
                challenge_problem={
                  project.tech_intelli_details.challenge_problem
                }
              />
            </form>
          );
        }}
      </Formik>
      </Grid>
    </>
  );

  const PartnerSearchCard = project && project.partner_search_details && (
    <Formik
      enableReinitialize
      initialValues={{ partnerSearch: project.partner_search_details }}>
      <form>
        <PartnerSearchDetailsCard
          country={project.partner_search_details.country_name}
          countryCode={project.partner_search_details.country_code}
          industry={project.partner_search_details.industry_name}
          partnership_types={project.partner_search_details.partnership_types}
        />
      </form>
    </Formik>
  );

  const TrendsIntelligenceCard = project && project.trends_intelligence_details && (
      <Formik
        enableReinitialize
        initialValues={{
          trendsIntelligence: project.trends_intelligence_details,
        }}>
        <form>
          <TrendsIntelligenceDetailsCard
            keyMarkets={project.trends_intelligence_details.key_markets}
            keyProducts={project.trends_intelligence_details.key_products}
            keyCompetitors={project.trends_intelligence_details.key_competitors}
            subjectsToImprove={
              project.trends_intelligence_details.subjects_to_improve
            }
          />
        </form>
      </Formik>
    );

  return (
    <>
      {!fetchingProject ? (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          style={{ maxWidth: '760px' }}>
          <PageTitleSection
            title={String(accountId + transformId)}
            className="projectTitle">
            {project && serviceId && !fetchingProject && (
              <Button
                disabled
                className={`ml-3 typeService${serviceId || '1'}`}>
                {currentService && currentService.name}
              </Button>
            )}
          </PageTitleSection>
          <DetailsCard title="" bordered showTitle={false}>
            <Grid container className={classes.wrapper}>
              {project && projectId && !fetchingProject && (
                <>
                  {parseInt(project.service_id) === SI_SERVICE
                    ? analysisTypeCard
                    : parseInt(project.service_id) === LEGAL_COMP_SERVICE
                    ? LegalComplianceCard
                    : parseInt(project.service_id) === TECH_INTELLI_SERVICE
                    ? TechIntelligenceCard
                    : parseInt(project.service_id) === PARTNER_SEARCH_SERVICE
                    ? PartnerSearchCard
                    : parseInt(project.service_id) === TRENDS_INTELIGENCE_SERVICE
                    ? TrendsIntelligenceCard
                    : projectSubjects}
                  {userFiles && !userFilesLoading && (
                    <Grid
                      container
                      className="pb-3"
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch">
                      <Grid container direction="column">
                        <ResultFiles
                          serviceId={serviceId}
                          columns={2}
                          editable={
                            loggedInUser &&
                            loggedInUser.role_id !== ROLE_ADMIN_READ_ONLY
                          }
                          files={projectFiles}
                          handleUpdateFiles={handleUpdateUserFiles}
                          isResult={false}
                          projectId={projectId}
                        />
                      </Grid>
                      <Divider className="mt-1 mb-2 minus-m-25" />
                    </Grid>
                  )}
                </>
              )}
              <Grid container direction="column">
                <Grid
                  container
                  item
                  direction="row"
                  justify="space-between"
                  alignContent="center">
                  <Typography variant="h3" className="pb-1 pt-0 pl-0 pr-0">
                    Comments
                  </Typography>
                  <Button
                    variant="outlined"
                    className="mt-0"
                    onClick={handleOpenCommentInput}
                    disabled={
                      loggedInUser &&
                      loggedInUser.role_id === ROLE_ADMIN_READ_ONLY
                    }>
                    Leave Comment
                  </Button>
                </Grid>
                {projectId && !fetchingProject && comments && (
                  <CommentCardsList commentsList={comments} />
                )}
                {openCommentInput ? (
                  <LeaveCommentForm
                    sendingComment={sendingComment}
                    onSendComment={handleCreateNewComment}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              {projectId && !fetchingProject && (
                <StickyBlock>
                  <>
                    <ProjectSummaryBlock
                      project={project}
                      onConfirm={onConfirm}
                      newDueDate={newDueDate}
                      datePickerOpened={datePickerOpened}
                      osintValue={osintValue}
                      handleDatePickerOpen={handleDatePickerOpen}
                      handleSetDueDate={handleSetSelectedDueDate}
                      handleSetSelectedStatus={handleSetSelectedStatus}
                      handleUnsetSelectedDueDate={handleUnsetSelectedDueDate}
                      handleConfirmUpdateDueDate={handleConfirmUpdateDueDate}
                    />
                    <Grid container item className="mb-3">
                      <ResultFiles
                        editable={
                          loggedInUser &&
                          loggedInUser.role_id === ROLE_WASP_ADMIN
                        }
                        files={resultFiles}
                        handleUpdateFiles={handleUpdateFiles}
                        projectId={projectId}
                      />
                    </Grid>
                  </>
                </StickyBlock>
              )}
            </Grid>
          </DetailsCard>
        </Grid>
      ) : (
        <Spinner />
      )}
      <ConfirmDialog
        title="Change project status"
        open={openConfirm}
        onClose={handleCloseConfirm}
        options={selectedStatus}
        confirmBtnText="Change"
        onConfirm={handleUpdateProjectStatus}>
        Are you sure to change project status?
      </ConfirmDialog>
      <ConfirmDialog
        title="Change project due date"
        open={openUpdateDueDateConfirm}
        onClose={handleCloseDueDateUpdateConfirm}
        options={newDueDate}
        confirmBtnText="Change"
        onConfirm={handleUpdateProjectDueDate}>
        Are you sure to change due date?
      </ConfirmDialog>
      <ToastContainer />
    </>
  );
};

export default ProjectDetailsPage;
