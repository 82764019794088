// Core
import React, { Fragment } from 'react';
// @material-ui
import Button from 'components/CustomButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// Components
import DirectorForm from './Forms/DirectorForm';

const emptyDirectorObject = {
  name: '',
  director_position: '',
  date: null,
  citizenship: '',
  second_citizenship: '',
  registered_number: ''
};

const DirectorStep = ({
  directors,
  onSetFieldValue,
  countryMenuItems
}) => {
  const deleteOneDirector = (index) => {
    let updatedDirectors = directors.map(s => {return s});
    updatedDirectors.splice(index, 1);
    onSetFieldValue('directors', updatedDirectors);
  };

  const addOneDirector = () => {
    let updatedDirectors = directors.map(s => {return s});
    updatedDirectors.push(emptyDirectorObject);
    onSetFieldValue('directors', updatedDirectors);
  };

  return (
    <Grid
      container
      alignItems="stretch"
      direction="row">
      <Grid item>
        {directors?.map((item, index) => {
          return (
            <Fragment key={index}>
            {index !== 0 && <Divider className="minus-m-25" />}
            <DirectorForm 
              index={index}
              director={item}
              onSetFieldValue={onSetFieldValue}
              countryMenuItems={countryMenuItems}
              deleteOneDirector={deleteOneDirector}
            />
            </Fragment>
          )
        })}
      </Grid>
      <Grid 
        item
        xs={12}
        style={{display: "flex", justifyContent: "flex-end"}}>
        <Button 
          className="mr-0 mt-0"
          onClick={addOneDirector}
          variant="outlined">
          Add Director
        </Button>
      </Grid>
    </Grid>
  )
};

export default DirectorStep;
