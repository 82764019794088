import cloneDeep from 'lodash.clonedeep';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export const defaultFilterStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: '24px 24px 36px 24px',
    fontFamily: 'inherit',
    maxHeight: '100%',
  },
  header: {
    flex: '0 0 auto',
    marginBottom: '16px',
    padding: '0px 24px 29px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  title: {
    display: 'inline-block',
    marginLeft: 0,
    color: 'var(--black-100)',
    fontSize: '21px',
    fontWeight: 600,
  },
  noMargin: {
    marginLeft: '0px',
  },
  reset: {
    alignSelf: 'left',
  },
  resetLink: {
    marginLeft: '16px',
    fontSize: '12px',
    cursor: 'pointer',
  },
  filtersSelected: {
    alignSelf: 'right',
  },
  /* checkbox */
  checkboxListTitle: {
    marginLeft: '7px',
    marginBottom: '8px',
    fontSize: '14px',
    color: theme.palette.text.secondary,
    textAlign: 'left',
    fontWeight: 500,
  },
  checkboxFormGroup: {
    marginTop: '8px',
  },
  checkboxFormControl: {
    margin: '3px 0px',
    flexDirection: 'row-reverse',
    display: 'flex',
  },
  checkboxFormControlLabel: {
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '8px',
    color: theme.palette.text.primary,
    flexGrow: 1,
  },
  checkboxIcon: {
    width: '32px',
    height: '32px',
  },
  checkboxFilterRoot: {
    fontSize: '14px',
  },
  multiListItem: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  multiListItemText: {
    '& .MuiListItemText-primary': {
      fontSize: '14px',
    }
  },
  checked: {},
  gridListTile: {
    marginTop: '16px',
    width: '100%',
  },
  gridListTileHalf: {
    marginTop: '16px',
    width: '50%',
  },
  beautyCheckboxItem: {
    width: '100%',
    height: '40px',
    padding: '0 0 0 5px',
    color: 'var(--black-100)',
    border: '1px solid var(--black-20)',
    margin: '0 0 12px 0',
    background: 'var(--white)',
    boxShadow: 'none',
    borderRadius: 4,
    borderLeftWidth: 8,
  },
  beautyCheckboxItemChecked: {
    '&.beautyCheckboxItem_1': {
      borderColor: 'var(--secondary-orange)',
    },
    '&.beautyCheckboxItem_2': {
      borderColor: 'var(--secondary-turquoise)',
    },
    '&.beautyCheckboxItem_3': {
      borderColor: 'var(--secondary-green)',
    },
    '&.beautyCheckboxItem_4': {
      borderColor: 'var(--primary-regular)',
    },
    '&.beautyCheckboxItem_5': {
      borderColor: 'var(--dark-yellow)',
    },
    '&.beautyCheckboxItem_6': {
      borderColor: 'var(--dark-red)',
    },
    '&.beautyCheckboxItem_7': {
      borderColor: 'var(--dark-blue)',
    },
    '&.beautyCheckboxItem_8': {
      borderColor: 'var(--dark-purple)',
    },
  },
  beautyCheckboxItemUnchecked: {},
  selectedItemChip: {
    color: 'var(--white)',
    height: '22px',
    margin: '1px 4px 1px 1px',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '4px',
    '& .MuiChip-deleteIcon': {
      width: '1em',
      height: '1em',
      color: 'var(--white)',
      fill: 'var(--white)',
      fontSize: 10,
    },
  },
  inputFormControlLabel: {
    fontSize: 14,
    transform: 'none',
    position: 'relative',
  },
  startSelectAdornment: {
    position: 'absolute',
    color: 'var(--black-40)',
    padding: '4px 10px',
    fontSize: 12,
    fontWeight: 'normal',
  },
  containerFilterFields: {
    padding: '0 24px 24px',
    maxHeight: '75vh',
    overflow: 'auto'
  }
});

const BootstrapInput = withStyles({
  input: {
    border: '1px solid var(--black-20)',
    padding: '3px 3px',
    minHeight: '24px',
    fontSize: '14px',
    color: 'var(--black-100)',
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: '4px',
    whiteSpace: 'normal',
    '&:focus': {
      borderRadius: '4px',
    },
  },
  root: {
    marginTop: 10,
    marginBottom: 10,
    'label + &': {
      marginTop: 10,
    },
    '&:before': {
      content: '""',
      borderBottom: '0 none',
      display: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '0 none',
    },
    '&:after': {
      content: '""',
      borderBottom: '0 none',
      display: 'none',
    },
  },
})(Input);

const TextInput = withStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
    '& label': {
      fontSize: 14,
      textTransform: 'capitalize',
      color: 'var(--black-40)',
      transform: 'none',
      top: '-20px',
      pointerEvents: 'auto',
      '& span.fa': {
        display: 'inline',
      },
    },
    '& .MuiInputBase-input': {
      padding: '4px 10px',
      color: 'var(--black-100)',
      minHeight: '24px',
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      overflow: 'hidden',
      padding: 0,
      fontFamily: 'inherit',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      '& fieldset': {
        borderColor: 'var(--black-20)',
      },
      '&:focus': {
        borderColor: 'var(--input-active-border)',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: 'var(--input-active-border)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--input-active-border)',
        border: '1px solid',
        borderWidth: 1,
      },
      '&.Mui-disabled': {
        background: 'var(--input-disabled-border)',
        '& fieldset': {
          borderColor: 'var(--black-20)',
        },
        '&:hover fieldset': {
          borderColor: 'var(--black-20)',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: '2px 12px 3px',
    },
  },
})(TextField);

class CustomTableFilter extends React.Component {
  static propTypes = {
    /** Data used to populate filter dropdown/checkbox */
    filterData: PropTypes.array.isRequired,
    /** Data selected to be filtered against dropdown/checkbox */
    filterList: PropTypes.array.isRequired,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger filter update */
    onFilterUpdate: PropTypes.func,
    /** Callback to trigger filter reset */
    onFilterReset: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      filterList: cloneDeep(props.filterList),
    };
  }

  filterUpdate = (index, value, column, type, customUpdate) => {
    let newFilterList = this.state.filterList.slice(0);

    this.props.updateFilterByType(newFilterList, index, value, type, customUpdate);
    this.setState({
      filterList: newFilterList,
    });
  };

  handleCheckboxChange = (index, value, column) => {
    this.filterUpdate(index, value, column, 'checkbox');

    if (!this.props.options.confirmFilters) {
      this.props.onFilterUpdate(index, value, column, 'checkbox');
    }
  };

  handleDropdownChange = (event, index, column) => {
    const labelFilterAll = this.props.options.textLabels.filter.all;
    const value = event.target.value === labelFilterAll ? [] : [event.target.value];
    this.filterUpdate(index, value, column, 'dropdown');

    if (!this.props.options.confirmFilters) {
      this.props.onFilterUpdate(index, value, column, 'dropdown');
    }
  };

  handleMultiselectChange = (index, value, column) => {
    this.filterUpdate(index, value, column, 'multiselect');

    if (!this.props.options.confirmFilters) {
      this.props.onFilterUpdate(index, value, column, 'multiselect');
    }
  };

  handleTextFieldChange = (event, index, column) => {
    this.filterUpdate(index, event.target.value, column, 'textField');

    if (!this.props.options.confirmFilters) {
      this.props.onFilterUpdate(index, event.target.value, column, 'textField');
    }
  };

  handleCustomChange = (value, index, column) => {
    this.filterUpdate(index, value, column.name, column.filterType);

    if (!this.props.options.confirmFilters) {
      this.props.onFilterUpdate(index, value, column.name, column.filterType);
    }
  };

  renderCheckbox(column, index, components = {}) {
    const CheckboxComponent = components.Checkbox || Checkbox;

    const { classes, filterData } = this.props;
    const { filterList } = this.state;
    const width = (column.filterOptions && !!column.filterOptions.fullWidth) ? 12 : 6;
    const additionalClass = column.filterOptions && column.filterOptions.additionalClass;
    const renderItem =
      column.filterOptions && column.filterOptions.renderValue ? column.filterOptions.renderValue : v => v;

    return (
      <Grid item key={index} xs={width} className="w-100">
        <FormGroup>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.checkboxListTitle}>
              {column.label}
            </Typography>
          </Grid>
          <Grid container>
            {filterData[index].map((filterValue, filterIndex) => (
              <Grid
                item
                key={filterIndex}
                className={clsx(classes.beautyCheckboxItem, additionalClass, "beautyCheckboxItem_"+filterValue,
                    (filterList[index].indexOf(filterValue) >= 0) ? classes.beautyCheckboxItemChecked : classes.beautyCheckboxItemUnchecked)}>
                <FormControlLabel
                  key={filterIndex}
                  classes={{
                    root: classes.checkboxFormControl,
                    label: classes.checkboxFormControlLabel,
                  }}
                  control={
                    <CheckboxComponent
                      data-description="table-filter"
                      color="primary"
                      className={classes.checkboxIcon}
                      onChange={this.handleCheckboxChange.bind(null, index, filterValue, column.name)}
                      checked={filterList[index].indexOf(filterValue) >= 0}
                      classes={{
                        root: classes.checkboxFilterRoot,
                        checked: classes.checked,
                      }}
                      value={filterValue !== null ? filterValue.toString() : ''}
                    />
                  }
                  label={renderItem(filterValue)}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </Grid>
    );
  }

  renderSelect(column, index) {
    const { classes, filterData, options } = this.props;
    const { filterList } = this.state;
    const textLabels = options.textLabels.filter;
    const renderItem =
      column.filterOptions && column.filterOptions.renderValue
        ? column.filterOptions.renderValue
        : v => (v !== null ? v.toString() : '');
    const width = (column.filterOptions && !!column.filterOptions.fullWidth) ? 12 : 6;

    return (
      <Grid
        item
        key={index}
        xs={width}
        classes={{ 'grid-xs-12': classes.gridListTile, 'grid-xs-6': classes.gridListTileHalf }}>
        <FormControl key={index} fullWidth>
          <InputLabel htmlFor={column.name} className={classes.inputFormControlLabel}>{column.label}</InputLabel>
          <Select
            fullWidth
            value={filterList[index].length ? filterList[index].toString() : textLabels.all}
            name={column.name}
            onChange={event => this.handleDropdownChange(event, index, column.name)}
            input={<BootstrapInput name={column.name} id={column.name} />}
            className={classes.checkboxFilterRoot}
          >
            <MenuItem value={textLabels.all} key={0}>
              {textLabels.all}
            </MenuItem>
            {filterData[index].map((filterValue, filterIndex) => (
              <MenuItem value={filterValue} key={filterIndex + 1}>
                {renderItem(filterValue)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  renderTextField(column, index) {
    const { classes } = this.props;
    const { filterList } = this.state;
    if (column.filterOptions && column.filterOptions.renderValue) {
      console.warn('Custom renderValue not supported for textField filters');
    }
    const width = (column.filterOptions && !!column.filterOptions.fullWidth) ? 12 : 6;

    return (
      <Grid
        item
        key={index}
        xs={width}
        classes={{ 'grid-xs-12': classes.gridListTile, 'grid-xs-6': classes.gridListTileHalf }}>
        <FormControl key={index} fullWidth>
          <InputLabel htmlFor={column.name} className={classes.inputFormControlLabel}>{column.label}</InputLabel>
          <TextInput
            fullWidth
            variant="outlined"
            label={''}
            value={filterList[index].toString() || ''}
            onChange={event => this.handleTextFieldChange(event, index, column.name)}
            InputLabelProps={{
              style: { pointerEvents: "auto" },
              disableAnimation: true,
              shrink: false,
            }}
          />
        </FormControl>
      </Grid>
    );
  }

  renderMultiselect(column, index, components = {}) {
    const CheckboxComponent = components.Checkbox || Checkbox;

    const { classes, filterData } = this.props;
    const { filterList } = this.state;
    const width = (column.filterOptions && !!column.filterOptions.fullWidth) ? 12 : 6;
    const renderItem = column.filterOptions && column.filterOptions.renderValue ?
      column.filterOptions.renderValue : v => v;
    const renderChipedSelectedValue = (column.filterOptions && column.filterOptions.renderChipedSelectedValue) ?
      column.filterOptions.renderChipedSelectedValue : false;
    const noneValueText = column.filterOptions && column.filterOptions.noneValueText ?
      column.filterOptions.noneValueText : '';

    const fieldIndex = index;
    const renderValue = selected => selected.map(renderItem).join(', ');
    const renderChipedValue = (selected) => (
      selected.length === 0
        ? (<span className={classes.startSelectAdornment}>{noneValueText}</span>)
        : selected.map((renderItem, index) => (
        <Chip
          key={index}
          label={renderItem}
          color="primary"
          className={classes.selectedItemChip}
          deleteIcon={<Icon className="fa fa-times" />}
          onDelete={() => {
            this.handleCheckboxChange(fieldIndex, selected[index], column.name);
          }}
          clickable={true}
          onMouseDown={event => {
            event.stopPropagation();
          }}
        />
      ))
    );

    return (
      <Grid
        item
        key={index}
        xs={width}
        classes={{ 'grid-xs-12': classes.gridListTile, 'grid-xs-6': classes.gridListTileHalf }}>
        <FormControl key={index} fullWidth>
          <InputLabel htmlFor={column.name} className={classes.inputFormControlLabel}>{column.label}</InputLabel>
          <Select
            multiple
            fullWidth
            displayEmpty
            variant="outlined"
            value={filterList[index] || []}
            renderValue={renderChipedSelectedValue ? renderChipedValue : renderValue}
            name={column.name}
            onChange={event => this.handleMultiselectChange(index, event.target.value, column.name)}
            input={<BootstrapInput name={column.name} id={column.name} />}
            className={classes.checkboxFilterRoot}
          >
            {filterData[index].map((filterValue, filterIndex) => (
              <MenuItem value={filterValue} key={filterIndex + 1} className={classes.multiListItem}>
                <CheckboxComponent
                  data-description="table-filter"
                  color="primary"
                  checked={filterList[index].indexOf(filterValue) >= 0}
                  value={filterValue !== null ? filterValue.toString() : ''}
                  className={classes.checkboxIcon}
                  classes={{
                    root: classes.checkboxFilterRoot,
                    checked: classes.checked,
                  }}
                />
                <ListItemText className={classes.multiListItemText} primary={renderItem(filterValue)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  renderCustomField(column, index) {
    const { classes, filterData, options } = this.props;
    const { filterList } = this.state;
    const width = (column.filterOptions && !!column.filterOptions.fullWidth) ? 12 : 6;
    const display =
      (column.filterOptions && column.filterOptions.display) ||
      (options.filterOptions && options.filterOptions.display);

    if (!display) {
      console.error('Property "display" is required when using custom filter type.');
      return;
    }
    if (column.filterListOptions && column.filterListOptions.renderValue) {
      console.warning('"renderValue" is ignored for custom filter fields');
    }
    const showLabel = column.filterOptions && column.filterOptions.showLabel &&
      (<InputLabel htmlFor={column.name} className={classes.inputFormControlLabel}>{column.label}</InputLabel>);

    return (
      <Grid
        item
        key={index}
        xs={width}
        classes={{ 'grid-xs-12': classes.gridListTile, 'grid-xs-6': classes.gridListTileHalf }}>
        <FormControl key={index} fullWidth>
          {showLabel}
          {display(filterList, this.handleCustomChange, index, column, filterData)}
        </FormControl>
      </Grid>
    );
  }

  applyFilters = () => {
    this.state.filterList.forEach((filter, index) => {
      this.props.onFilterUpdate(index, filter, this.props.columns[index], 'custom');
    });

    this.props.handleClose(); // close filter dialog popover

    if (this.props.options.onFilterConfirm) {
      this.props.options.onFilterConfirm(this.state.filterList);
    }

    return this.state.filterList;
  };

  resetFilters = () => {
    this.setState({
      filterList: this.props.columns.map(() => []),
    });
    //if (this.props.options.confirmFilters !== true) {
      this.props.onFilterReset();
    //}
  };

  render() {
    const { classes, columns, options, customFooter, filterList, components = {} } = this.props;
    const textLabels = options.textLabels.filter;

    return (
      <Card aria-controls="customized-filter" className="customized-filter">
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.reset}>
            <Typography
              variant="body2"
              className={clsx({
                [classes.title]: true,
              })}>
              {textLabels.title}
            </Typography>
            {this.props.options.showStandardResetButton && <Button
              color="primary"
              className={classes.resetLink}
              tabIndex={0}
              aria-label={textLabels.reset}
              data-testid={'filterReset-button'}
              onClick={this.resetFilters}>
              {textLabels.reset}
            </Button>}
          </div>
          <div className={classes.filtersSelected} />
        </div>
        <Grid 
          container 
          direction="row" 
          justify="flex-start" 
          alignItems="center" 
          className={classes.containerFilterFields}
        >
          {columns.map((column, index) => {
            if (column.filter) {
              const filterType = column.filterType || options.filterType;
              return filterType === 'checkbox'
                ? this.renderCheckbox(column, index, components)
                : filterType === 'multiselect'
                ? this.renderMultiselect(column, index, components)
                : filterType === 'textField'
                ? this.renderTextField(column, index)
                : filterType === 'custom'
                ? this.renderCustomField(column, index)
                : this.renderSelect(column, index);
            }
            return false;
          })}
        </Grid>
        {customFooter ? customFooter(filterList, this.applyFilters, this.resetFilters, this.props.handleClose) : ''}
      </div>
      </Card>
    );
  }
}

export default withStyles(defaultFilterStyles, { name: 'MUIDataTableFilter' })(CustomTableFilter);
