// Core
import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listItem: {
    marginRight: 15,
    marginBottom: -4,
    padding: '23px 0px',
    borderBottom: '4px solid transparent',

    '&:hover': {
      borderBottom: `4px solid ${theme.palette.secondary.main}`,
    },

    '&:last-child': {
      marginRight: 0,
    },
  },

  active: {
    borderBottom: `4px solid ${theme.palette.secondary.main}`,

    '& div': {
      '& span': {
        color: ({ match }) =>
          (match && theme.palette.secondary.main) || theme.palette.primary.main,
      },
    },
  },
}));

const ListItemLink = props => {
  const classes = useStyles(props);
  const { path, text } = props;

  const renderLink = useMemo(() =>
    forwardRef((linkProps, ref) => {
      const activeLink = clsx(classes.listItem, classes.active);

      return (
        <NavLink
          ref={ref}
          to={path}
          activeClassName={activeLink}
          {...linkProps}
        />
      );
    }), [classes.listItem, classes.active, path]
  );

  return (
    <ListItem className={classes.listItem} component={renderLink}>
      <ListItemText primary={text} />
    </ListItem>
  );
};

ListItemLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ListItemLink;
