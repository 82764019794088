// Core
import React, { createContext, useEffect } from 'react';
// @material-ui
import MenuItem from '@material-ui/core/MenuItem';
// Hooks
import { useAuth } from 'hooks/useAuth';
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchCountries } from 'api/info';

export const CountriesContext = createContext({});

const CountriesProvider = ({ children }) => {
  let { accessToken } = useAuth();
  if (!accessToken) {
    accessToken = localStorage.getItem('access_token');
  }

  const { execute: getCountries, value: countries } = useAsync(
    fetchCountries,
    false,
  );

  useEffect(() => {
    if (accessToken) {
      getCountries();
    }
  }, [getCountries, accessToken]);
  
  const countryMenuItems =
    countries &&
    countries.map(country => {
      const code = country.code;
      const name = country.name;

      return (
        <MenuItem key={code} name={name} value={code}>
          <div className={`flag flag-${code} mr-2`}></div>
          {name}
        </MenuItem>
      );
    });

  return (
    <CountriesContext.Provider value={{countryMenuItems, countries}}>
      {children}
    </CountriesContext.Provider>
  );
};

export default CountriesProvider;
