// Core
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
import PermissionsCheckboxes from 'components/PermissionsCheckboxes';
// Context
import { CountriesContext } from 'context/CountriesContext';
// Constants
import { ACCOUNT_REGEX, EMAIL_REGEX, PHONE_NUMBER_REGEX, WEBSITE_REGEX } from 'utils';


const initialValues = {
  name: '',
  country_code: '',
  email: '',
  phone_number: '',
  website: '',
  admin: '',
  subscriptions: [],
  csLevels: []
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .transform((value) => value.trim())
    .matches(ACCOUNT_REGEX, {
      message: 'You should use latin letters',
      excludeEmptyString: true,
    })
    .required('Required'),
  email: Yup.string()
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    })
    .nullable(),
  phone_number: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable(),
  website: Yup.string()
    .matches(WEBSITE_REGEX, {
      message: 'Invalid website pattern',
      excludeEmptyString: true,
    })
    .nullable()
    .max(2000, 'Must be 2000 characters or less'),
});

const EditAccountForm = ({
  accountValues,
  levels,
  services,
  onChangeData,
  onUpdateAccount,
  editingPermissions,
}) => {
  const { countryMenuItems } = useContext(CountriesContext);

  const accountInitialValues = accountValues
    ? {
        id: accountValues.id,
        name: accountValues.name,
        country_code: accountValues.country_code ? accountValues.country_code : '',
        email: accountValues.email ? accountValues.email : '',
        phone_number: accountValues.phone_number ? accountValues.phone_number : '',
        website: accountValues.website ? accountValues.website : '',
        active: accountValues.active ? accountValues.active : false,
        onboarding_allowed: accountValues.onboarding_allowed ? accountValues.onboarding_allowed : false,
        cluster_network_allowed: accountValues.cluster_network_allowed ? accountValues.cluster_network_allowed : false,
        admin: _.get(accountValues, 'admin.id', '') && accountValues.admin.id,
      }
    : initialValues;

  const changeFunc = (values, companyValues) => {
    let formIsChanged = false;
    if (companyValues && values) {
      Object.keys(companyValues).map(key => {
        if (companyValues[key] !== values[key] && typeof(companyValues[key]) !== 'object' && typeof(values[key]) !== 'object') {
          formIsChanged = true;
        }
        else if (typeof(companyValues[key]) === 'object' && typeof(values[key]) === 'object') {
          if (values[key].length !== companyValues[key].length) {
            formIsChanged = true;
          } else {
            for (let i = 0; i < values[key].length; i++) {
              if (!values[key].includes(companyValues[key][i])) {
                formIsChanged = true;
              }
            }
          }
        }
      return formIsChanged;
      });
    }
    return onChangeData(formIsChanged);
  };

  if (accountValues) {
    if (accountValues.subscriptions) {
      accountInitialValues['subscriptions'] = accountValues.subscriptions.map(subscription => subscription.id);
    }
    if (accountValues.csLevels) {
      accountInitialValues['csLevels'] = accountValues.csLevels.map(level => level.id);
    }
  }

  const userMenuItems = accountValues &&
    accountValues.users.map(user => {
      const id = user.id;
      const name = user.full_name;
      return (
        <MenuItem key={id} name={name} value={id}>
          {name}
        </MenuItem>
      );
    });

  const onSubmit = (values, setSubmitting) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);

    const dataToUpdateAccount = {
      id: values.id,
      name: values.name.trim(),
      email: values.email,
      country_code: values.country_code,
      phone_number: values.phone_number,
      active: values.active,
      website: values.website,
      admin: values.admin,
      subscriptions: values.subscriptions,
      cs_levels: values.csLevels,
      onboarding_allowed: values.onboarding_allowed,
      cluster_network_allowed: values.cluster_network_allowed,
    };
    onUpdateAccount(dataToUpdateAccount);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={accountInitialValues || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched,
      }) => {
        changeFunc(values, accountInitialValues);
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className="pt-2 pb-2 pl-4 pr-4">
              <Grid item sm={6}>
                <FormikInput
                  mandatory
                  id="name"
                  name="name"
                  label="company name"
                  value={values.name}
                />
                <FormikInput
                  id="email"
                  name="email"
                  label="main email"
                  value={values.email}
                />
                <FormikInput
                  id="phone_number"
                  name="phone_number"
                  label="phone number"
                  value={values.phone_number}
                  placeholder="+380951112233"
                />
                {
                  editingPermissions ? (
                    <Grid container direction="column" style={{marginTop: '-15px'}}>
                      <FormControl className="flex-centered">
                        <FormControlLabel
                          disabled={false}
                          value={true}
                          control={<Checkbox
                            id="active"
                            name="active"
                            color="primary"
                            onChange={(event) => {setFieldValue('active', event.target.checked)}}
                            />}
                          checked={values.active}
                          label="Active"
                          className="mr-2" />
                      </FormControl>
                      <FormControl className="flex-centered">
                        <FormControlLabel
                          disabled={false}
                          value={true}
                          control={<Checkbox
                            id="onboarding_allowed"
                            name="onboarding_allowed"
                            color="primary"
                            onChange={(event) => {setFieldValue('onboarding_allowed', event.target.checked)}}
                            />}
                          checked={values.onboarding_allowed}
                          label="Onboarding Allowed"
                          className="mr-2" />
                      </FormControl>
                      <FormControl className="flex-centered">
                        <FormControlLabel
                          disabled={false}
                          value={true}
                          control={<Checkbox
                            id="cluster_network_allowed"
                            name="cluster_network_allowed"
                            color="primary"
                            onChange={(event) => {setFieldValue('cluster_network_allowed', event.target.checked)}}
                            />}
                          checked={values.cluster_network_allowed}
                          label="Cluster network access"
                          className="mr-2" />
                      </FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )
                }
              </Grid>
              <Grid item sm={6}>
              {countryMenuItems && countryMenuItems.length ?
                <FormikInput
                  select
                  id="country_code"
                  name="country_code"
                  value={values ? values.country_code : ''}
                  label="country">
                  {countryMenuItems}
                </FormikInput> : null
              }
                <FormikInput
                  id="website"
                  name="website"
                  label="website"
                  value={values.website}
                />
                {userMenuItems && userMenuItems.length ? <FormikInput
                  select
                  id="admin"
                  name="admin"
                  value={values && values.admin ? values.admin : ''}
                  label="admin">
                  {userMenuItems}
                </FormikInput> : null}
              </Grid>
            </Grid>
            <Divider />
            
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ padding: "20px" }}>
                <Typography variant="h3">Permissions</Typography>
            </Grid>
            <Grid container className="pb-4 pl-4 pr-4">
              <PermissionsCheckboxes
                subscriptionsValues={values.subscriptions}
                subscriptionsError={errors.subscriptions}
                levelsValues={values.csLevels}
                levelsError={errors.csLevels}
                onSetFieldValue={setFieldValue}
                onSetFieldTouched={setFieldTouched}
                editing={editingPermissions}
                services={services}
                levels={levels}
                rowVariant={true}
              />
            </Grid>
            <Divider />

            <Grid container justify="flex-end" className="pt-4 pb-4 pr-4">
              <Button
                variant="outlined"
                onClick={handleReset}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="mr-0">
                Update
              </Button>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

EditAccountForm.defaultProps = {
  accountValues: null,
  levels: null,
  services: null,
  onUpdateAccount: () => null,
  editingPermissions: true,
};

EditAccountForm.propTypes = {
  accountValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    country: PropTypes.shape({}),
    status: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    phone_number: PropTypes.string,
    website: PropTypes.string,
    admin: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
    csLevels: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateAccount: PropTypes.func,
  editingPermissions: PropTypes.bool,
};

export default EditAccountForm;
