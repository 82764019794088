// Core
import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
// @material-ui
import {makeStyles} from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Grid
} from '@material-ui/core';
// Components
import Button from 'components/CustomButton';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Spinner from 'components/shared/Spinner';
import StepForm from './StepForm';
// Helpers
import {checkIfAllValuesOfObjectAreFalsy} from 'helpers';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Constants
import {
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
  ROLE_ADMIN_READ_ONLY
} from 'utils';
import {UserContext} from 'context/UserContext';
// Instrument
import {deleteProject} from 'api/projects';

const useStyles = makeStyles({
  stepCard: {
    width: '100%',
    overflow: 'visible',
    position: 'relative',
  },

  stepContent: {
    padding: '0 25px',
    minHeight: '25vh',
  },

  btnActions: {
    padding: '0px 25px 25px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});

const StepCard = props => {
  const classes = useStyles(props);
  const {push} = useHistory();
  const {loggedInUser} = useContext(UserContext);
  const {
    values,
    projectData,
    activeStep,
    steps,
    onClickNext,
    onClickBack,
    onClickNextSkipOneStep,
    onClickBackTwoSteps,
    onSetUpdatedValues,
    onCreateProject,
    onUpdateProject,
    onDeactivateOrderFlow,
    showCSAdviceStep,
    showSpinner,
    onSetShowCSAdviceStep,
    onDeleteRelatedSubject,
    pendingCreateProject,
    pendingUpdateProject,
    handleGoToSomeStep
  } = props;
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  // eslint-disable-next-line
  const [renderForUpdateNextButton, setRenderForUpdateNextButton] = useState(false);
  const stepsCount = steps.length;
  const issetProjectId = values.newProjectId !== 0;

  const {
    open: openConfirmDeleteProject,
    handleOpen: handleOpenConfirmDeleteProject,
    handleClose: handleCloseConfirmDeleteProject,
  } = useDialog();

  const {execute: projectDelete, value: deleteProjectResponse} = useAsync(
    deleteProject,
    false
  );

  const helperFuncNextButtonDisable = (subjectsArray) => {
    const disableNextButtonArray = subjectsArray.map(item => {
      if (item.name === "" || item.country_code === "") {
        return true;
      } else {
        return false;
      }
    });
    return disableNextButtonArray.includes(true);
  }

  const shouldDisabledNextButton = (activeStep, values) => {
    let serviceId = parseInt(values.serviceId);
    if (serviceId === CS_SERVICE && activeStep !== 3 && activeStep !== 4) {
      return false;
    }
    if ([EDD_SERVICE, PES_SERVICE].includes(serviceId) && activeStep !== 2) {
      return false;
    }

    let isEmptyZeroEntity = values.entities[0] ? (values.entities[0].name === "" || values.entities[0].country_code === "") : true;
    let isEmptyZeroIndividual = values.individuals[0] ? (values.individuals[0].name === "" || values.individuals[0].country_code === "") : true;

    switch (serviceId) {
      case CS_SERVICE:
        if (activeStep === 3) {
          return (!values.typeKeySubject) ? isEmptyZeroEntity : isEmptyZeroIndividual;
        } else if (activeStep === 4) {
          if (!values.typeKeySubject) {
            if (!values.typeRelatedSubject) {
              return helperFuncNextButtonDisable(values.entities)
            } else {
              return helperFuncNextButtonDisable(values.individuals)
            }
          } else if (values.typeKeySubject) {
            if (!values.typeRelatedSubject) {
              return helperFuncNextButtonDisable(values.entities)
            } else {
              return helperFuncNextButtonDisable(values.individuals)
            }
          }
        }
        return false;
      case EDD_SERVICE:
        return (!values.typeKeySubject) ? isEmptyZeroEntity : isEmptyZeroIndividual;
      case PES_SERVICE:
        return (values.typeKeySubject) && isEmptyZeroIndividual;
      default:
        return false;
    }
  };

  const hideSkipButton = (keySub, relatedSub) => {
    if (values.individuals[0] && !keySub && relatedSub) {
      return checkIfAllValuesOfObjectAreFalsy(values.individuals[0]);
    } else if (values.entities[1] && !keySub && !relatedSub) {
      return checkIfAllValuesOfObjectAreFalsy(values.entities[1]);
    } else if (values.entities[0] && keySub && !relatedSub) {
      return checkIfAllValuesOfObjectAreFalsy(values.entities[0]);
    } else if (values.individuals[1] && keySub && relatedSub) {
      return checkIfAllValuesOfObjectAreFalsy(values.individuals[1]);
    } else {
      return true;
    }
  };

  const CsNextButtonOnClick = () => {
    if (activeStep >= stepsCount) {
      onUpdateProject({step: activeStep});
    } else {
      /* let levelId = parseInt(values.levelId);
      if ((activeStep === 3 && levelId === 1) || (activeStep === 4 && levelId !== 1)) { 
      This change for validation on step 3. Need some more testing  */
      if ([3, 4].includes(activeStep)) {
        if (!issetProjectId) {
          onCreateProject();
        } else {
          onUpdateProject({step: activeStep});
        }
      } else {
        onClickNext();
      }
    }
  };

  const EddNextButtonOnClick = () => {
    if ((activeStep >= stepsCount) || (activeStep === 2 && issetProjectId)) {
      onUpdateProject({ step: activeStep });
    } else if (activeStep === 2 && !issetProjectId) {
      onCreateProject();
    } else {
      onClickNext();
    }
  };

  const PesNextButtonClick = () => {
    if ((activeStep >= stepsCount) || (activeStep === 2 && issetProjectId)) {
      onUpdateProject({ step: activeStep });
    } else if (activeStep === 2 && !issetProjectId) {
      onCreateProject();
    } else {
      onClickNext();
    }
  };

  const SiNextButtonClick = () => {
    if (activeStep > stepsCount || activeStep === 4) {
      onUpdateProject({ step: activeStep });
    } else {
      onClickNext();
    }
  }

  const LegalCompNextButtonOnClick = () => {
    if (activeStep > stepsCount || activeStep === 3) {
      onUpdateProject({ step: activeStep });
    } else {
      onClickNext();
    }
  };

  const TechIntelliNextButtonOnClick = () => {
    if (activeStep > stepsCount || activeStep === 4) {
      onUpdateProject({ step: activeStep });
    } else {
      onClickNext();
    }
  };

  const PartnerSearchNextButtonClick = () => {
    if (activeStep > stepsCount || activeStep === 3) {
      onUpdateProject({ step: activeStep });
    } else {
      onClickNext();
    }
  };

  const TrendInteliNextButtonClick = () => {
    if (activeStep > stepsCount || activeStep === 3) {
      onUpdateProject({ step: activeStep });
    } else {
      onClickNext();
    }
  };

  const nextButtonClickEvent = (typeId = 0) => {
    // eslint-disable-next-line radix
    switch (parseInt(typeId)) {
      case CS_SERVICE:
        return CsNextButtonOnClick();
      case EDD_SERVICE:
        return EddNextButtonOnClick();
      case PES_SERVICE:
        return PesNextButtonClick();
      case SI_SERVICE:
        return SiNextButtonClick();
      case LEGAL_COMP_SERVICE:
        return LegalCompNextButtonOnClick();
      case TECH_INTELLI_SERVICE:
        return TechIntelliNextButtonOnClick();
      case PARTNER_SEARCH_SERVICE:
        return PartnerSearchNextButtonClick();
      case TRENDS_INTELIGENCE_SERVICE:
        return TrendInteliNextButtonClick();
      default:
        return false;
    }
  };

  const handleConfirmDialogDeleteProject = (projectId) => {
    projectDelete(projectId);
    handleCloseConfirmDeleteProject();
    toast.success('Project successful deleted', { autoClose: 2000 });
  };

  useEffect(() => {
    if (deleteProjectResponse) {
      setTimeout(() => {
        push('/projects');
      }, 2000);
    }
  }, [deleteProjectResponse, push]);

  useEffect(() => {
    if (pendingCreateProject || pendingUpdateProject) {
      setNextStepDisabled(true);
    } else {
      setNextStepDisabled(false);
    }
  }, [pendingCreateProject, pendingUpdateProject]);

  return (
    <>
      <Card className={classes.stepCard}>
        <CardContent className={showCSAdviceStep === false ? classes.stepContent : ""}>
          {showSpinner ? (
            <Spinner/>
          ) : (
            <StepForm
              activeStep={activeStep}
              onClickBack={onClickBack}
              onClickNextSkipOneStep={onClickNextSkipOneStep}
              onClickBackTwoSteps={onClickBackTwoSteps}
              onClickNext={onClickNext}
              onCreateProject={onCreateProject}
              onUpdateProject={onUpdateProject}
              values={values}
              project={projectData}
              setValues={onSetUpdatedValues}
              onSetShowCSAdviceStep={onSetShowCSAdviceStep}
              showCSAdviceStep={showCSAdviceStep}
              onDeleteRelatedSubject={onDeleteRelatedSubject}
              setRenderForUpdateNextButton={setRenderForUpdateNextButton}
              handleGoToSomeStep={handleGoToSomeStep}
              logginUserRole={loggedInUser && loggedInUser.role_id}
            />
          )}
        </CardContent>
        {
          showCSAdviceStep === true || (activeStep === 3 && parseInt(values.serviceId) === SI_SERVICE)
          || (activeStep === 3 && parseInt(values.serviceId) === TECH_INTELLI_SERVICE)
          || (activeStep === 2 && parseInt(values.serviceId) === PARTNER_SEARCH_SERVICE)
          || (activeStep === 2 && parseInt(values.serviceId) === TRENDS_INTELIGENCE_SERVICE)
          || (activeStep === 2 && parseInt(values.serviceId) === LEGAL_COMP_SERVICE) ? (<></>) : (
            <CardActions className={classes.btnActions}>
              {!showSpinner && (
                <>
                  <Grid>
                    {(activeStep === stepsCount) &&
                    <Button
                      disabled={loggedInUser && loggedInUser.role_id === ROLE_ADMIN_READ_ONLY}
                      className="mb-0 ml-0"
                      variant="outlined"
                      onClick={handleOpenConfirmDeleteProject}>
                      Delete project
                    </Button>}
                  </Grid>
                  <Grid>
                    <Button
                      disabled={loggedInUser && loggedInUser.role_id === ROLE_ADMIN_READ_ONLY}
                      className="mb-0"
                      variant="outlined"
                      onClick={
                        activeStep === 1 ? onDeactivateOrderFlow : (
                          parseInt(values.levelId) === 1 && activeStep === 5 ? onClickBackTwoSteps : onClickBack
                        )
                      }>
                      Back
                    </Button>
                    {
                      (activeStep === 4 && (
                        parseInt(values.serviceId) === 1
                          ? hideSkipButton(values.typeKeySubject, values.typeRelatedSubject)
                          : false))
                      && <Button
                        className="mb-0"
                        variant="outlined"
                        onClick={
                          parseInt(values.levelId) !== 1 ? (
                            !issetProjectId ? onCreateProject : (() => onUpdateProject(activeStep))
                          ) : onClickNext
                        }>
                        Skip
                      </Button>
                    }
                    <Button
                      disabled={
                        shouldDisabledNextButton(activeStep, values)
                        || nextStepDisabled
                        || (loggedInUser && loggedInUser.role_id === ROLE_ADMIN_READ_ONLY)}
                      className="mb-0 mr-0"
                      onClick={() => nextButtonClickEvent(values.serviceId)}>
                      {activeStep >= stepsCount ? "Finish" : "Next"}
                    </Button>
                  </Grid>
                </>
              )}
            </CardActions>)
        }
      </Card>
      <ConfirmDialog
        title="Delete project"
        confirmBtnText="Confirm"
        options={values.newProjectId}
        open={openConfirmDeleteProject}
        onClose={handleCloseConfirmDeleteProject}
        onConfirm={handleConfirmDialogDeleteProject}
      >
        You are about to delete project. Please confirm.
      </ConfirmDialog>
      <ToastContainer/>
    </>
  );
};

StepCard.defaultProps = {
  values: {},
  projectData: null,
  pendingCreateProject: false,
  pendingUpdateProject: false,
  onClickNext: () => null,
  onClickBack: () => null,
  onClickNextSkipOneStep: () => null,
  onClickBackTwoSteps: () => null,
  onSetUpdatedValues: () => null,
  onCreateProject: () => null,
  onUpdateProject: () => null,
  onDeactivateOrderFlow: () => null,
  showCSAdviceStep: false,
  showSpinner: false,
  onSetShowCSAdviceStep: () => null,
  onDeleteRelatedSubject: () => null,
  handleGoToSomeStep: () => null
};

StepCard.propTypes = {
  activeStep: PropTypes.number.isRequired,
  values: PropTypes.shape({}),
  projectData: PropTypes.shape({}),
  pendingCreateProject: PropTypes.bool,
  pendingUpdateProject: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  onClickNext: PropTypes.func,
  onClickBack: PropTypes.func,
  onClickNextSkipOneStep: PropTypes.func,
  onClickNextTwoSteps: PropTypes.func,
  onClickBackTwoSteps: PropTypes.func,
  onSetUpdatedValues: PropTypes.func,
  onCreateProject: PropTypes.func,
  onUpdateProject: PropTypes.func,
  onDeactivateOrderFlow: PropTypes.func,
  showCSAdviceStep: PropTypes.bool,
  showSpinner: PropTypes.bool,
  onSetShowCSAdviceStep: PropTypes.func,
  onDeleteRelatedSubject: PropTypes.func,
  handleGoToSomeStep: PropTypes.func
};

export default StepCard;
