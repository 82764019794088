// Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
// Components
import CustomRadio from 'components/UI/CustomRadio';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '&.inline': {
      width: '100%',
      justifyContent: 'space-between',
    },
    '&.middle': {
      justifyContent: 'space-between',
      width: '100%'
    },
    '&.wide': {
      justifyContent: 'space-between',
      width: '100%'
    }
  },
});

const CustomRadioGroup = ({
  images,
  color,
  variant,
  name,
  fields,
  defaultValue,
  onChange,
  avialableLevels
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  if (avialableLevels.length !== 0 && fields) {
    fields.map(item => {
      if (avialableLevels.includes(item.id)) {
        item.disabled = false;
      } else {
        item.disabled = true;
      }
      return item;
    });
  }

  return (
    <RadioGroup
      name={name}
      value={value}
      defaultValue={defaultValue ? defaultValue : ''}
      onChange={handleChange}
      className={clsx(
        classes.root, {
          [`${variant}`]: variant.length > 0
        }
      )}
      role="group"
      >
      {fields && fields.map(elementRadio => {
        return <CustomRadio
          key={String(elementRadio.id)}
          id={String(elementRadio.id)}
          value={String(elementRadio.id)}
          label={elementRadio.name}
          checked={String(defaultValue || value) === String(elementRadio.id)}
          description={elementRadio.description}
          variant={variant}
          color={color}
          setImage={images && images[elementRadio.id]}
          disabled={elementRadio.disabled}
        />
      })}
    </RadioGroup>
  );
};

CustomRadioGroup.defaultProps = {
  images: {},
  color: '',
  variant: 'middle',
  fields: [],
  defaultValue: '',
  onChange: () => null,
  avialableLevels: []
};

CustomRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  images: PropTypes.shape({}),
  color: PropTypes.string,
  variant: PropTypes.string,
  fields: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]), 
  onChange: PropTypes.func,
  avialableLevels: PropTypes.array
};

export default CustomRadioGroup;
