// Core
import React from 'react';
import { Formik } from 'formik';
// @material-ui
import { MenuItem, TableCell, TableRow, Typography } from '@material-ui/core';
// Components
import CustomIconButton from 'components/CustomIconButton';
import CustomTableDataSelect from 'components/CustomTableDataSelect';
import CompanyTextLabel from '../CompanyTextLabel/CompanyTextLabel';


const AccountCard = ({
  id, 
  name, 
  country, 
  email, 
  active, 
  users_count,
  onboarding_allowed,
  cluster_network_allowed,
  onSetSelectedCompanyId, 
  onViewCompanyDetails,
  onDeleteCompany
}) => {
  const onActiveFieldChange = (event, Id) => {
    const active = event.target.value;
    const companyId = Id;
    onSetSelectedCompanyId({active, companyId});
  };

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">
          {name}
          {users_count === 0 ? (
              <CompanyTextLabel text="New"/>
            ): <></>
          }
        </Typography>
      </TableCell>
      <TableCell>
        <Formik>
          {() => {
            return (
              <form>
                <CustomTableDataSelect
                  id="activate"
                  name="activate"
                  value={active}
                  handleChange={(event) => {onActiveFieldChange(event, id);}}
                  placeholder="Activation">
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </CustomTableDataSelect>
              </form>
            );
          }}
        </Formik>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{onboarding_allowed ? "Allowed" : "Restricted"}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{cluster_network_allowed ? "Allowed" : "Restricted"}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{country}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{email}</Typography>
      </TableCell>
      <TableCell>          
        <CustomIconButton icon="fas fa-pen" tooltipText="Edit" onIconButtonClick={()=>{onViewCompanyDetails(id)}} />
        <CustomIconButton icon="fas fa-trash" tooltipText="Delete" onIconButtonClick={() => onDeleteCompany(id)} />
      </TableCell>
    </TableRow>
  );
};

export default AccountCard;
