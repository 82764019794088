// Core
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// Components
import { QuestionMarkIcon } from 'components/shared/Icons';

const useStyles = makeStyles({
  icon: {
    margin: '0 6px',
  },

  textLabel: {
    fontSize: 14,
    fontWeight: 'normal',
    color: 'var(--black-40)',
  },

  isCapitalize: {
    textTransform: 'capitalize',
  },
});

const FormInfoSection = ({ questionMark, text, isCapitalize=true }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" marginBottom="6px">
      {questionMark && <QuestionMarkIcon className={classes.icon} />}
      <Typography classes={{ root: clsx(classes.textLabel, isCapitalize ? classes.isCapitalize : '') }}>{text}</Typography>
    </Box>
  );
};

FormInfoSection.propTypes = {
  text: PropTypes.string.isRequired,
  isCapitalize: PropTypes.bool,
};

export default FormInfoSection;
