// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @material-ui
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
//import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  link :{
    "&:hover": {
      textDecoration: "underline"
    },
  }
})

const BreadcrumbsCustom = ({
  addToBreadcrumbs,
  currentPath
}) => {
  const classes = useStyles();
  const pathParts = currentPath.substr(1).split('/');

  const removeItemDoubled = (arrToAdd) => {
    var index = 0;
    while (index < arrToAdd.length) {
      var path = arrToAdd[index].path;
      if ((currentPath === path && path.search(':id') === -1)
        || (path.search(':id') !== -1 && pathParts[1] && currentPath === path)) {
      } else {
        arrToAdd.splice(index, 1);
      }
      ++index;
    }
    return arrToAdd;
  }

  const filterBreadcrumbsFunction = (arrayOfRouts) => {
    let result = {};
    for (let level = 1; level <= pathParts.length; level++) {
      var arrToAdd = arrayOfRouts.filter((element) => {
        var path = element.path;
        if (path !== '/home' && level === element.level && path.search(pathParts[0]) !== -1) {
          return element;
        }
        return false;
      });
      if (arrToAdd.length === 1)
        result[level] = arrToAdd[0];
      if (arrToAdd.length > 1) {
        arrToAdd = removeItemDoubled(arrToAdd);
        result[level] = arrToAdd[0];
      }
    }
    return result;
  };
  var filteredBreadcrumbs = filterBreadcrumbsFunction(addToBreadcrumbs);

  const renderBreadcrumbs = (filteredBreadcrumbs) => {
    const result = Object.values(filteredBreadcrumbs).map(itemBreadcrumb => {
      let path = itemBreadcrumb.path;
      let name = itemBreadcrumb.name;
      if (currentPath === path)
        return (<Typography key={path}>{name}</Typography>);
      else if (pathParts.length > (path.substr(1).split('/')).length && (path.substr(1).split('/')).length < 3)
        return (<Link className={classes.link} key={path} to={path.replace(':id', pathParts[1])}>{name}</Link>);
      else if(pathParts.length > 1 && pathParts[1].length < 1)
        return null;
      else
        return (<Typography key={path}>{name}</Typography>);
    });
    return result;
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {(currentPath !== '/home') && (<Link className={classes.link} to="/home">Home</Link>)}
      {renderBreadcrumbs(filteredBreadcrumbs)}
    </Breadcrumbs>
  );

};

BreadcrumbsCustom.defaultProps = {
  addToBreadcrumbs: [],
  currentPath: '/home'
};

BreadcrumbsCustom.propTypes = {
  addToBreadcrumbs: PropTypes.array,
  currentPath: PropTypes.string,
};

export default BreadcrumbsCustom;
