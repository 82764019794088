// Core
import React from 'react';
// @material-ui
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
// Components
import Wrapper from 'components/Wrapper';

const PerformancePage = () => (
  <Container maxWidth="md">
    <Wrapper>
      <Typography variant="h2">This page is under construction</Typography>
    </Wrapper>
  </Container>
);

export default PerformancePage;
