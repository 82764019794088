import client from './client';

/**
 * Creates new account
 *
 * @param {Object} values contains data for creating account
 * @param {String} values.name company name
 * @param {String} values.email company email
 * @param {String} values.country company's country code
 * @returns {Promise} Promise object represents operation result
 */
async function createAccount(values) {
  const data = {
    name: values.name.trim(),
    email: values.email,
    country_code: values.country,
    phone_number: values.phone_number,
    website: values.website,
    subscriptions: values.subscriptions,
    cs_levels: values.cs_levels,
    active: values.active,
    onboarding_allowed: values.onboarding_allowed,
    cluster_network_allowed: values.cluster_network_allowed,
  };

  const response = await client.post('/api/accounts/', data);
  if (response.status === 201) {
    return response;
  }
}

/**
 * Fetches accounts from api
 *
 * @returns {Promise} Promise object represents operation result
 */
async function fetchAccounts() {

  const response = await client.get('/api/accounts/');

  if (response.status === 200) {
    return response;
  }
}

/**
 * Fetches account object by id
 *
 * @param {String} id account id
 * @returns {Promise} Promise object represents operation result
 */
async function fetchAccountById(id) {
  const response = await client.get(`/api/accounts/${id}/`);

  if (response.status === 200) {
    const account = {
      data: {
        name: response.data.name,
        country_code: response.data.country_code,
        country: response.data.country,
        email: response.data.email,
        phone_number: response.data.phone_number,
        website: response.data.website,
        admin: response.data.admin,
        id: response.data.id,
        subscriptions: response.data.subscriptions,
        csLevels: response.data.cs_levels,
        users: response.data.users,
        active: response.data.active,
        onboarding_allowed: response.data.onboarding_allowed,
        cluster_network_allowed: response.data.cluster_network_allowed,
      },
    };
    return account;
  }
}

/**
 * Updates account by id
 *
 * @param {String} id account id
 * @param values data that should be updated
 * @returns {Promise} Promise object represents operation result
 */
async function updateAccount({ id, ...values }) {
  const data = { ...values };

  const response = await client.put(`/api/accounts/${id}/`, data);

  if (response.status === 200) {
    const updatedAccount = {
      data: {
        name: response.data.name,
        country_code: response.data.country_code,
        country: response.data.country,
        email: response.data.email,
        phone_number: response.data.phone_number,
        website: response.data.website,
        admin: response.data.admin,
        id: response.data.id,
        subscriptions: response.data.subscriptions,
        csLevels: response.data.cs_levels,
        users: response.data.users,
        active: response.data.active,
        onboarding_allowed: response.data.onboarding_allowed,
        cluster_network_allowed: response.data.cluster_network_allowed,
      },
    };

    return updatedAccount;
  }
}

/**
 * Delete account
 *
 * @returns {Promise} Promise object represents operation result
 */
 async function deleteAccount(id) {

  const response = await client.delete(`/api/accounts/${id}/`);

  if (response.status === 200) {
    return response;
  }
}

/**
 * Request from unregistered company for creating account
 *
 * @returns {Promise} Promise object represents operation result
 */
 async function requestForAccount(values) {
  const data = values;

  const response = await client.post(`/api/accounts/request_for_account/`, data);

  if (response.status === 200) {
    return response;
  }
}

export {
  createAccount,
  fetchAccounts,
  fetchAccountById,
  updateAccount,
  deleteAccount,
  requestForAccount
};
