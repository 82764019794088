//Core
import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
//@material-ui
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  block : {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ExampleLinks = ({links}) => {
  const classes = useStyles();

  return (
    <div className={classes.block}>
      <Typography className="pl-0" variant="h3">Download examples:</Typography>
      <div className={clsx(classes.block, "description-service")}>
        {
          links && links.length !== 0 ?
          (<ul>
              {
                links.map(link => {
                  return <li><Link to={link.path} className="exampleLink pb-4" target="_blank" replace>{link.name}</Link></li>
                })    
              }
            </ul>)
          : (
            <div>
            <Typography variant="body1">
              Doesn't contain any examples yet 
            </Typography>
          </div>)
        }
      </div>
    </div>
  );
}

export default ExampleLinks;
