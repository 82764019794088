// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => {
  return {
    rootingClass: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // maxWidth: props.maxWidth,
      // height: '100%',
      [theme.breakpoints.down('lg')]: {
        minHeight: 550,
      },
      [theme.breakpoints.down('md')]: {
        minHeight: 425,
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 400,
      },
      // minHeight: 500,
    }
  };
});

const Wrapper = props => {
  const { children } = props;
  const classes = useStyles(props);

  return (
    <Container className={classes.rootingClass} disableGutters>
      {children}
    </Container>
  );
};

Wrapper.defaultProps = {
  maxWidth: 1440,
};

Wrapper.propTypes = {
  maxWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default Wrapper;
