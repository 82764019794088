// Core
import React, { Fragment } from 'react';
// @material-ui
import Button from 'components/CustomButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// Components
import ShareholdersForm from './Forms/ShareholdersForm';

const emptyShareholderObject = {
  citizenship: "",
  citizenship_name: "",
  date: null,
  director_position: "",
  entity_legal_or_individual: "",
  entity_type: "",
  id: "",
  name: "",
  partner_id: "",
  registered_number: "",
  second_citizenship: "",
  shares: ""
};

const ShareholdersStep = ({
  shareholders,
  onSetFieldValue,
  countryMenuItems,
}) => {
  const deleteOneShareholder = (index) => {
    let updatedShareholders = shareholders.map(s => {return s});
    updatedShareholders.splice(index, 1);
    onSetFieldValue('shareholders', updatedShareholders);
  };

  const addOneShareholder = () => {
    let updatedShareholders = shareholders.map(s => {return s});
    updatedShareholders.push(emptyShareholderObject);
    onSetFieldValue('shareholders', updatedShareholders);
  };

  return (
    <Grid
      container
      alignItems="stretch"
      direction="column">
      <Grid item>
        {shareholders?.map((item, index) => {
          return (
            <Fragment key={index}>
              {index !== 0 && <Divider className="minus-m-25" />}
              <ShareholdersForm
                index={index}
                shareholder={item}
                onSetFieldValue={onSetFieldValue}
                countryMenuItems={countryMenuItems}
                deleteOneShareholder={deleteOneShareholder}
              />
            </Fragment>
          )
        })}
      </Grid>
      <Grid 
        item
        xs={12}
        style={{display: "flex", justifyContent: "flex-end"}}>
        <Button 
          className="mr-0 mt-0"
          onClick={addOneShareholder}
          variant="outlined">
          Add Shareholder
        </Button>
      </Grid>
    </Grid>
  )
};

export default ShareholdersStep;
