// Core
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Components
import IconButton from 'components/IconButton';
// Constants
import {
  TECH_SEARCH_ANALYSIS_CONFIG,
  ROLE_ADMIN_READ_ONLY,
} from 'utils';

const useStyles = makeStyles({
  card: {
    height: '40px !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid var(--primary-regular)',
    borderRadius: 4,
    borderLeft: '8px solid var(--primary-regular)',
    fontSize: 14,
    marginBottom: 24,
  },
  icon: {
    '&.MuiIcon-root': {
      fontSize: '12px',
      width: 'unset',
      height: 'unset',
      color: 'var(--primary-regular)',
      margin: '0px 13px 0px 13px',
    },
  },
  cardContent: {
    color: 'var(--black-100)',
    fontSize: 14,
    fontWeight: 600,
  },
});

const TechIntelligenceTypeCard = ({
  configId,
  handleBackTwoSteps,
  logginUserRole,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Typography variant="h3" className="pl-0 pb-3">
        Analysis Type
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBackTwoSteps}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid className={classes.card}>
            <Icon
              className={clsx(
                classes.icon,
                configId === TECH_SEARCH_ANALYSIS_CONFIG
                  ? 'fas fa-binoculars'
                  : 'fas fa-glasses',
              )}
            />
            <Typography className={clsx(classes.cardContent)}>
              {configId === TECH_SEARCH_ANALYSIS_CONFIG
                ? 'Tech Search'
                : 'IntellTech Lab\n'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Divider className="mt-1 mb-2 minus-m-25" />
    </Grid>
  );
};

TechIntelligenceTypeCard.defaultProps = {
  configId: TECH_SEARCH_ANALYSIS_CONFIG,
  handleBackTwoSteps: () => null,
};

TechIntelligenceTypeCard.propTypes = {
  configId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  handleBackTwoSteps: PropTypes.func,
};

export default TechIntelligenceTypeCard;
