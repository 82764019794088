// Core
import React, { createContext, useEffect } from 'react';
// Hooks
import { useAuth } from 'hooks/useAuth';
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchLoggedInUserObj } from 'api/auth';

export const UserContext = createContext({});
export const UserConsumer = UserContext.Consumer;

const UserProvider = ({ children }) => {
  let { accessToken } = useAuth();
  if (!accessToken) {
    accessToken = localStorage.getItem('access_token');
  }

  const {
    execute: getLoggedInUser,
    pending: loadingUserObj,
    error,
    value: loggedInUser,
  } = useAsync(fetchLoggedInUserObj, false);

  useEffect(() => {
    if (accessToken) {
      getLoggedInUser();
    }
  }, [getLoggedInUser, accessToken]);

  useEffect(() => {
    if (loggedInUser) {
      localStorage.setItem('user', JSON.stringify(loggedInUser));
    }
  }, [loggedInUser]);

  const updateUserData = () => {
    let token = localStorage.getItem('access_token');
    if (token) {
      getLoggedInUser();
      if (loggedInUser) {
        localStorage.setItem('user', JSON.stringify(loggedInUser));
      }
    }
  };

  return (
    <UserContext.Provider value={{ loggedInUser, loadingUserObj, error, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
