// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// material-ui
import Grid from '@material-ui/core/Grid';
// Hooks
import useDialog from 'hooks/useDialog';
// Components
import ScrollPaperDialog from 'components/UI/ScrollPaperDialog';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import TermsConditionsPage from 'pages/TermsConditionsPage';

const Footer = ({ columned }) => {

  const {
    open: openPrivacyPolicy,
    handleOpen: handleOpenPrivacyPolicy,
    handleClose: handleClosePrivacyPolicy,
  } = useDialog();

  const {
    open: openTermsAndConditions,
    handleOpen: handleOpenTermsAndConditions,
    handleClose: handleCloseTermsAndConditions,
  } = useDialog();

  const privacyPolicyOnClick = () => {
    handleOpenPrivacyPolicy();
  }

  const termsAndConditionsOnClick = () => {
    handleOpenTermsAndConditions();
  }
  const thisYear = new Date().getFullYear();
  return (
    <Grid 
      container
      direction={columned ? "column-reverse" : "row"}
      justify={"space-between"}
      className={columned ? "pb-2 pt-0 pr-0 pl-0" : "pb-2 pt-4 pr-0 pl-0"}>
        <span className="fotterTextWithoutMargin">
          This platform is powered by COSA ™. <br/>
          © {thisYear} WASP. All rights reserved.
        </span>
        {
          columned 
            ? (<span className="fotterTextWithMargin">
                <span className="mr-2" onClick={privacyPolicyOnClick}>
                  Privacy Policy
                </span>|
                <span className="ml-2" onClick={termsAndConditionsOnClick}>
                  Terms and Conditions
                </span>
              </span>)
            : ( 
            <span className="fotterTextWithMargin">
              <Link to="/cookies-read-more/privacy-policy" className="mr-2">
                Privacy Policy
              </Link>|
              <Link to="/terms-conditions" className="ml-2">
                Terms and Conditions
              </Link>
            </span>)
        }
        <ScrollPaperDialog 
          title="Privacy Policy"
          open={openPrivacyPolicy}
          onClose={handleClosePrivacyPolicy}
          children={<PrivacyPolicyPage hideTitle={true}/>}
        />
        <ScrollPaperDialog 
          title="Terms and Conditions"
          open={openTermsAndConditions}
          onClose={handleCloseTermsAndConditions}
          children={<TermsConditionsPage hideTitle={true}/>}
        />
    </Grid>
  );
}

Footer.defaultProps = {
  columned: false,
};

Footer.propTypes = {
  columned: PropTypes.bool,
};

export default Footer;
