const FILE_SIZE = 100;
const // Mb
  AVATAR_FILE_SIZE = 3;
const // Mb
  AVATAR_FILE_TYPES_VALIDATION = ['image/jpeg', 'image/png'];
const ALLOWED_FILE_TYPES = [
  '.jpg',
  '.png',
  '.pdf',
  '.doc',
  '.docx',
  '.rar',
  '.zip',
];
const ALLOWED_FILE_TYPES_VALIDATION = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.rar',
  'application/zip',
  'application/x-zip-compressed',
];
const ACCOUNT_REGEX = new RegExp(
  /^[a-zA-Z0-9.,&+_'-]+( [a-zA-Z0-9.,&+_'-]+)*$/,
);
const CODE_REGEX = new RegExp(/\d{4}/);
const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/);
const CORPORATE_EMAIL_REGEX = new RegExp(
  /^[A-Za-z0-9.+_-]+@(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|mail|rambler|yandex|aol|msn)[A-Za-z0-9._-]+\.[a-zA-Z]+$/,
);
const FILES_SLICE_SIZE = 500000;
const // bytes
  PASSWORD_REGEX = new RegExp(
    /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&+=])[\w\d@#$%^&+=]{8,}$/,
  );
const PHONE_NUMBER_REGEX = new RegExp(/^\+?\d{1,15}?$/);
const WEBSITE_REGEX = new RegExp(/(?:[-]?[\w])+[.]{1,2}[\w]+/);
const ROLE_WASP_ADMIN = 1;
const ROLE_ADMIN = 2;
const ROLE_WASP_USER = 3;
const ROLE_USER = 4;
const ROLE_ADMIN_READ_ONLY = 5;
const ROLE_PARTNER = 6;
const ROLE_APPLICANT = 8;
const ROLE_NOVATOR = 9
const PROJECT_STATUS_DRAFT = 1;
const PROJECT_STATUS_NEW = 2;
const CS_SERVICE = 1;
const EDD_SERVICE = 2;
const PES_SERVICE = 3;
const SI_SERVICE = 4;
const LEGAL_COMP_SERVICE = 5; // Legal and Compliance
const TECH_INTELLI_SERVICE = 6; // Tech Intelligence
const PARTNER_SEARCH_SERVICE = 7; // Partner Search
const TRENDS_INTELIGENCE_SERVICE = 8; // Trends Intelligence
const BASIC_LEVEL = 1;
const MODERATE_LEVEL = 2;
const HIGH_LEVEL = 3;
const ADMIN_EMAIL = 'waspadmin@cosa.solutions';
const PESTEL_ANALYSIS = 8;
const ENHANCED_ANALYSIS = 9;
const LEGAL_COMP_CONFIG = 10;
const TECH_SEARCH_ANALYSIS_CONFIG = 11;
const INTELL_TECH_LAB_ANALYSIS_CONFIG = 12;
const PARTNER_SEARCH_ANALYSIS_CONFIG = 13;
const TRENDS_INTELLIGENCE_CONFIG = 14;
const PESTEL_ANALYSIS_TYPE = 1;
const PROJECT_STATUSES = [
  'Draft',
  'New',
  'In Progress',
  'On Hold',
  'Completed',
];
const PROJECT_LEVELS = ['Basic', 'Moderate', 'High'];

export {
  FILE_SIZE,
  AVATAR_FILE_SIZE,
  AVATAR_FILE_TYPES_VALIDATION,
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_TYPES_VALIDATION,
  ACCOUNT_REGEX,
  CODE_REGEX,
  EMAIL_REGEX,
  FILES_SLICE_SIZE,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
  WEBSITE_REGEX,
  CORPORATE_EMAIL_REGEX,
};
export {
  ROLE_WASP_ADMIN,
  ROLE_ADMIN,
  ROLE_WASP_USER,
  ROLE_APPLICANT,
  ROLE_NOVATOR,
  ROLE_USER,
  ROLE_ADMIN_READ_ONLY,
  ROLE_PARTNER
};
export { PROJECT_STATUS_DRAFT, PROJECT_STATUS_NEW };
export {
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
};
export { BASIC_LEVEL, MODERATE_LEVEL, HIGH_LEVEL };
export { ADMIN_EMAIL };
export {
  PESTEL_ANALYSIS,
  ENHANCED_ANALYSIS,
  LEGAL_COMP_CONFIG,
  TECH_SEARCH_ANALYSIS_CONFIG,
  INTELL_TECH_LAB_ANALYSIS_CONFIG,
  PARTNER_SEARCH_ANALYSIS_CONFIG,
  TRENDS_INTELLIGENCE_CONFIG,
};
export { PESTEL_ANALYSIS_TYPE };
export { PROJECT_STATUSES, PROJECT_LEVELS };
