import {
  CS_SERVICE,
  EDD_SERVICE,
  PES_SERVICE,
  SI_SERVICE,
  LEGAL_COMP_SERVICE,
  TECH_INTELLI_SERVICE,
  PARTNER_SEARCH_SERVICE,
  TRENDS_INTELIGENCE_SERVICE,
} from 'utils';

const waspServicesLinks = [
  {
    serviceId: CS_SERVICE,
    disabled: false,
    serviceName: 'Compliance Screening',
    path: '/cc-dashboard',
  },
  {
    serviceId: EDD_SERVICE,
    disabled: false,
    serviceName: 'Enhanced Due Diligence',
    path: '/cc-dashboard',
  },
  {
    serviceId: PES_SERVICE,
    disabled: false,
    serviceName: 'Pre-employment Screening',
    path: '/cc-dashboard',
  },
  {
    serviceId: SI_SERVICE,
    disabled: false,
    serviceName: 'Strategic Intelligance',
    path: '/cc-dashboard',
  },
  {
    serviceId: LEGAL_COMP_SERVICE,
    disabled: false,
    serviceName: 'Legal and Compliance',
    path: '/cc-dashboard',
  },
  {
    serviceId: TECH_INTELLI_SERVICE,
    disabled: false,
    serviceName: 'Tech Intelligence',
    path: '/cc-dashboard',
  },
  {
    serviceId: PARTNER_SEARCH_SERVICE,
    disabled: false,
    serviceName: 'Partner Search',
    path: '/cc-dashboard',
  },
  {
    serviceId: TRENDS_INTELIGENCE_SERVICE,
    disabled: false,
    serviceName: 'Trends Intelligence',
    path: '/cc-dashboard',
  },
];

export default waspServicesLinks;
