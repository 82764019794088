// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
// Components
import IconButton from 'components/IconButton';
// Constants
import { ROLE_ADMIN_READ_ONLY } from 'utils';
import SubjectsToImproveCheckboxes from "../../SubjectsToImproveCheckboxes";
import FormInfoSection from "../../FormInfoSection";
import Textarea from "../../Textarea";

const TrendsIntelligenceDetailsCard = ({
  keyMarkets,
  keyProducts,
  keyCompetitors,
  subjectsToImprove,
  handleBack,
  logginUserRole,
}) => {
  return (
    <Grid>
      <Typography variant="h3" className="pl-0 pb-3 pt-3">
        General Subject
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="row" className="pb-3">
        <FormInfoSection text="Key Markets" isCapitalize={false} />
        <Textarea
          id="key_markets"
          name="trendsIntelligence.key_markets"
          value={keyMarkets}
          rows={3}
          disabled
        />
        <FormInfoSection text="Key Products" isCapitalize={false} />
        <Textarea
          id="key_products"
          name="trendsIntelligence.key_products"
          value={keyProducts}
          rows={3}
          disabled
        />
        <FormInfoSection text="Key Competitors" isCapitalize={false} />
        <Textarea
          id="key_competitors"
          name="trendsIntelligence.key_competitors"
          value={keyCompetitors}
          rows={3}
          disabled
        />
      </Grid>
      <Typography variant="h3" className="pl-0 pt-3 pb-3">
        Subjects to improve
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="column" className="pb-3">
        {subjectsToImprove && (
          <SubjectsToImproveCheckboxes
            doubleColumned={false}
            subjects_to_improve={subjectsToImprove}
            disabled
          />
        )}
      </Grid>
      <Divider className="mt-1 mb-2 minus-m-25" />
    </Grid>
  );
};

TrendsIntelligenceDetailsCard.defaultProps = {
  keyMarkets: '',
  keyProducts: '',
  keyCompetitors: '',
  subjectsToImprove: [],
  handleBack: () => null,
};

TrendsIntelligenceDetailsCard.propTypes = {
  keyMarkets: PropTypes.string,
  keyProducts: PropTypes.string,
  keyCompetitors: PropTypes.string,
  subjectsToImprove: PropTypes.array,
  handleBack: PropTypes.func,
};

export default TrendsIntelligenceDetailsCard;
