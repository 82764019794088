import client from './client';

/**
 * Creates comment
 *
 * @param {Object} comment object contains data for creating comment
 * @param {String} comment.text comment content
 * @returns {Promise} Promise object represents operation result
 */
async function createComment({ id, comment }){
  const data = {...comment}

  const response = await client.post(`/api/comments/${id}/`, data);

  if (response.status === 201) {
    return response;
  }
};

export { createComment };
