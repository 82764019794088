// Core
import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @material-ui
import Grid from '@material-ui/core/Grid';
// Components
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import CreateUserForm from 'components/Account/Users/User/CreateForm';
import DetailsCard from 'components/DetailsCard';
import DrawerDialog from 'components/UI/DrawerDialog/DrawerDialog';
import EditAccountForm from 'components/Account/Overview/Form';
import EmptyPage from 'components/EmptyPage';
import PageTitleSection from 'components/PageTitleSection';
import Spinner from 'components/shared/Spinner';
import Switch from 'components/CustomSwitch';
import UsersList from 'components/Account/Users/List/List';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
// Context
import { ServicesContext } from 'context/ServicesContext';
// Hooks
import useAsync from 'hooks/useAsync';
import { useAuth } from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
// Instruments
import { fetchAccountById, updateAccount } from 'api/accounts';
import { createUser, deleteUserById, updateUserById } from 'api/users';
import { sendActivationLink } from 'api/auth';
import { fetchLevels } from 'api/info';
import { sortArrayOfObjects } from 'helpers';
// Constants
import { ROLE_ADMIN, ROLE_USER } from 'utils';

const initialValues = {
  recentPage: false,
};

const AccountDetailsPage = (loggedInUser) => {
  const { signout } = useAuth();
  const { push } = useHistory();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  let { id } = useParams();
  const isCurrentUserCompanyAdmin = loggedInUser && parseInt(loggedInUser.role_id) === ROLE_ADMIN;
  if (isCurrentUserCompanyAdmin) {
    id = loggedInUser.account_id;
  }
  const { url } = useRouteMatch();
  const { services } = useContext(ServicesContext);

  const [recentPage, setRecentPage] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectdeUserEmail, setSelectdeUserEmail] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [changedAccountData, setChangedAccountData] = useState(null);
  const [startedPage, setStartedPage] = useState(null);

  const {
    open: openConfirmToDelete,
    handleOpen: handleOpenConfirmToDelete,
    handleClose: handleCloseConfirmToDelete,
  } = useDialog();

  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeCurrentPage,
    handleClose: handleCloseConfirmToChangeCurrentPage,
  } = useDialog();

  const {
    open: openConfirmToActivate,
    handleOpen: handleOpenConfirmToActivate,
    handleClose: handleCloseConfirmToActivate,
  } = useDialog();

  const {
    open: openConfirmToDeactivate,
    handleOpen: handleOpenConfirmToDeactivate,
    handleClose: handleCloseConfirmToDeactivate,
  } = useDialog();

  const {
    open: openConfirmToTransferRights,
    handleOpen: handleOpenConfirmToTransferRights,
    handleClose: handleCloseConfirmToTransferRights,
  } = useDialog();

  const {
    open: openActivationConfirmError,
    handleOpen: handleOpenActivationConfirmError,
    handleClose: handleCloseActivationConfirmError,
  } = useDialog();

  const {
    open: openConfirmToChangeAccount,
    handleOpen: handleOpenConfirmToChangeAccount,
    handleClose: handleCloseConfirmToChangeAccount,
  } = useDialog();

  const {
    open: openConfirmToChangeAccountError,
    handleOpen: handleOpenConfirmToChangeAccountError,
    handleClose: handleCloseConfirmToChangeAccountError,
  } = useDialog();

  const handleDataChanged = dataIsChanged => {
    setHasUnsavedData(dataIsChanged);
  };

  const { value: levels } = useAsync(
    fetchLevels,
    true
  );
  const {
    execute: fetchAccount,
    setValue: setAccount,
    pending: fetchingAccount,
    value: account,
  } = useAsync(fetchAccountById, false);

  const { 
    execute: updateAccountValues, 
    value: updatedAccount 
  } = useAsync(
    updateAccount,
    false,
  );

  const { execute: deleteUser } = useAsync(
    deleteUserById,
    false,
  );

  const { 
    execute: ActivateUser, 
    value: callBack 
  } = useAsync(
    sendActivationLink,
    false,
  );

  const { 
    execute: DeactivateUser, 
    status: deactivateUserStatus 
  } = useAsync(
    updateUserById,
    false,
  );

  const { 
    execute: createNewUser, 
    value: newUser, 
    status: addUserStatus,
    error: createNewUserError
  } = useAsync(
    createUser,
    false,
    true,
    true
  );

  useEffect(() => {
    if(deactivateUserStatus === 200){
      toast.success("User was successfully deactivated", { autoClose: 4000 });
    }
  }, [deactivateUserStatus]);

  useEffect(() => {
    if(callBack){
      toast.success(callBack.message, { autoClose: 5000 });
    }
  }, [callBack]);

  useEffect(() => {
    if(updatedAccount !== null){
      toast.success("The company data was successfully updated", { autoClose: 5000 });
    }
  }, [updatedAccount]);

  useEffect(() => {
    fetchAccount(id);
  }, [fetchAccount, id]);

  useEffect(() => {
      if (account && account.users && newUser && addUserStatus === 201) {      
        account.users.push(newUser);
        account.users.sort(sortArrayOfObjects('full_name'));
    }
  }, [account, addUserStatus, newUser]);

  const accountValues = updatedAccount || account;

  const viewUserDetails = userId => {
    push(`${url}/${userId}`);
  };
  const viewAddUser = () => {
    setOpenCreateUser(true);
  };
  const closeAddUser = () => {
    setOpenCreateUser(false);
  };
  
  const servicesReduced = accountValues && accountValues.subscriptions && services && services.filter(service => {
    const foundSubId = accountValues.subscriptions.find(subscription => subscription.id === service.id);
    if (!foundSubId) return false;
    return service;
  });

  const levelsReduced = accountValues && accountValues.csLevels && levels && levels.filter(globalLevel => {
    const foundLevelId = accountValues.csLevels.find(level => level.id === globalLevel.id);
    if (!foundLevelId) return false;
    return globalLevel;
  });

  const handleChangeResetPage = val => {
    setRecentPage(val);
  };

  const handleSetSelectedUserId = id => {
    handleOpenConfirmToDelete();
    setSelectedUserId(id);
  };

  const handleSetSelectedUserEmail = ({email, value, userId, role_id}) => {
    if (value === false && role_id === ROLE_ADMIN) {
      handleOpenActivationConfirmError();
    } else if (value === false) {
      handleOpenConfirmToDeactivate();
      setSelectdeUserEmail({email, value, userId, role_id});
    } else {
      handleOpenConfirmToActivate();
      setSelectdeUserEmail({email, value, userId, role_id});
    }
  };

  const handleSetSelectedAdmin = admin => {
    handleOpenConfirmToTransferRights();
    setSelectedAdmin(admin);
  };

  const handleCreateUser = userData => {
    let sendActivationMail = false;
    if (userData && userData.active && userData.email) {
      sendActivationMail = true;
      userData.active = false;
    }
    var response = createNewUser(userData);
    response.then(function(value) {
      if (!value) {
        if (sendActivationMail) {
          ActivateUser({
            email: userData.email
          });
        }
        closeAddUser();
      }
    });
  };

  const handleDeleteUser = id => {
    deleteUser(id);
    setAccount({
      ...account,
      users: account.users.filter(user => user.id !== id),
    });
    handleCloseConfirmToDelete();
  };

  const handleActivateUser = ({email, value, userId}) => {
    if(value){
      ActivateUser({ email });
      handleCloseConfirmToActivate();
    } else {
      DeactivateUser({userId, active: false});
      setAccount({
        ...account,
        users: account.users.filter(user => {
          if (user.id === userId && user.role_id !== ROLE_ADMIN) {
            user["active"] = false;
          };

          return user;
        })
      });
      handleCloseConfirmToDeactivate();
    }
  };

  const handleUpdateAccountAdmin = (admin) => {
    const response = updateAccountValues({id, admin});
    const newAdmin = {
      id: admin,
      name: ''
    };
    const newUsers = account.users.map(user => {
      if (user.id === admin) {
        newAdmin.name = user.name;

        return({...user, role_id: ROLE_ADMIN})
      } 
      else if (user.id === account.admin.id) {
        return ({...user, role_id: ROLE_USER})
      } 
      else {
        return user;
      }
    });
    response.then(function(value) {
      if (!value) {
        setAccount({
          ...account,
          admin: newAdmin,
          users: newUsers,
        });
      }
    })
    handleCloseConfirmToTransferRights();
    if (isCurrentUserCompanyAdmin && loggedInUser.id !== admin) {
      signout();
    }
  };

  const handleTryToSubmitAccountData = accountNewValues => {
    if (accountNewValues && accountNewValues.admin && accountNewValues.admin !== accountValues.admin?.id) {
      const users = accountValues.users;
      const newAdminStatus = users.find(user => user.id === accountNewValues.admin).active;
      if (newAdminStatus === true) {
        handleOpenConfirmToChangeAccount();
        setChangedAccountData(accountNewValues);
      } else {
        handleOpenConfirmToChangeAccountError();
      }
    } else {
      handleUpdateAccountValues(accountNewValues);
    }
  };

  const handleUpdateAccountValues = accountNewValues => {
    const response = updateAccountValues(accountNewValues);
    response.then(function(value){
      if(!value){
        setAccount({
          ...account,
          ...accountNewValues,
        });
        handleCloseConfirmToChangeAccount();
      }
    })
    if (isCurrentUserCompanyAdmin && loggedInUser.id !== accountNewValues.admin) {
      signout();
    }
  };

  const handleChangeCurrentPage = () => {
    setStartedPage({recentPage: true});
    setHasUnsavedData(false);
    setRecentPage(false);
    handleCloseConfirmToChangeCurrentPage();
  }

  const accountDetails = (
    !fetchingAccount && account ? (
      <DetailsCard title="General Info" bordered={true} justifyPosition="flex-start">
        <EditAccountForm
          accountValues={accountValues || account}
          levels={levels}
          services={services}
          onChangeData={handleDataChanged}
          onUpdateAccount={handleTryToSubmitAccountData}
          editingPermissions={!isCurrentUserCompanyAdmin}
        />
      </DetailsCard>
    ) : (
      <Spinner />
    )
  );

  const usersTable = (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      >
      <DetailsCard title="User List" hasPadding={false} bordered={false} hasButton={true} addUserToggle={viewAddUser}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center">
          {accountValues && accountValues.users.length !== 0
            ? (<UsersList
                accountId={id}
                users={accountValues.users}
                onViewUserDetails={viewUserDetails}
                onSetSelectedUserId={handleSetSelectedUserId}
                onSetSelectedUserEmail={handleSetSelectedUserEmail}
                onSetSelectedAdmin={handleSetSelectedAdmin}
                isCurrentUserCompanyAdmin={isCurrentUserCompanyAdmin}
              />)
            : (<EmptyPage height={65} hasButton={true} handleClick={viewAddUser} title="user"/>)
          } 
        </Grid>
      </DetailsCard>
    </Grid>
  );

  const styled = recentPage ? {maxWidth: '760px'} : {};

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch" style={styled}>
        <PageTitleSection title={(account && account.name) || ''}>
          <Formik 
            enableReinitialize
            initialValues={startedPage || initialValues}>
            {({ values, setFieldValue }) => {
              return (
                <form>
                  <Field
                    id="recentPage"
                    component={Switch}
                    name="recentPage"
                    checked={values.recentPage}
                    value={values.recentPage}
                    opt1="General"
                    opt2="User List"
                    onChange={(event, checked) => {
                      if (hasUnsavedData) {
                        handleOpenConfirmToChangeCurrentPage();
                      } else {
                        setFieldValue('recentPage', checked ? true : false);
                        handleChangeResetPage(values.recentPage);
                        setStartedPage(null);
                      }
                    }}
                  />
                </form>
              );
            }}
          </Formik>
        </PageTitleSection>
        {recentPage ? (accountDetails) : (usersTable)}
        <DrawerDialog
          openPage={openCreateUser}
          closePage={closeAddUser}
          title="Create User"
          onSubmit={() => {document.getElementsByClassName("create-new-user")[0].click()}}>
            <CreateUserForm
              accountId={account && account.id}
              accountIsActive={account && account.active}
              services={servicesReduced}
              levels={levelsReduced}
              onCreateUser={handleCreateUser}
              isOutherSubmit={true}
              responseErrors={createNewUserError || {}}
              firstUser={account && account.users && (account.users.length === 0)} />
        </DrawerDialog>
        <ConfirmDialog
          title="Delete user"
          open={openConfirmToDelete}
          onClose={handleCloseConfirmToDelete}
          options={selectedUserId}
          confirmBtnText="Confirm"
          onConfirm={handleDeleteUser}>
          You are about to Delete user. Please confirm.
        </ConfirmDialog>
        <ConfirmDialog
          title="Activate user"
          open={openConfirmToActivate}
          onClose={handleCloseConfirmToActivate}
          options={selectdeUserEmail}
          confirmBtnText="Confirm"
          onConfirm={handleActivateUser}>
            You are about to Activate user. Please confirm.
        </ConfirmDialog>
        <ConfirmDialog
          title="Transfer Admin rights"
          open={openConfirmToTransferRights}
          onClose={handleCloseConfirmToTransferRights}
          options={selectedAdmin}
          confirmBtnText="Confirm"
          onConfirm={handleUpdateAccountAdmin}>
          User role was changed to Admin, so user right and permissions will be transferred from a current Admin to this user.
          You can be logged out. Please confirm that action.
        </ConfirmDialog>
        <ConfirmDialog
          title="Deactivate admin"
          open={openActivationConfirmError}
          onClose={handleCloseActivationConfirmError}
          options={selectdeUserEmail}
          confirmBtnText="Confirm"
          simple={true}> 
          You are trying to deactivate user with Admin rights. Please transfer Admin function to another user before completing of that action.
        </ConfirmDialog>
        <ConfirmDialog
          title="Transfer Admin rights"
          open={openConfirmToChangeAccountError}
          onClose={handleCloseConfirmToChangeAccountError}
          confirmBtnText="Confirm"
          simple={true}> 
          Inactive User. Please activate user first to complete that action.
        </ConfirmDialog>
        <ConfirmDialog
          title="Deactivate user"
          open={openConfirmToDeactivate}
          onClose={handleCloseConfirmToDeactivate}
          options={selectdeUserEmail}
          confirmBtnText="Confirm"
          onConfirm={handleActivateUser}>
          You are about to Deactivate user. Please confirm.
        </ConfirmDialog>
        <ConfirmDialog
          title="Transfer Admin rights"
          open={openConfirmToChangeAccount}
          onClose={handleCloseConfirmToChangeAccount}
          options={changedAccountData}
          confirmBtnText="Confirm"
          onConfirm={handleUpdateAccountValues}>
          User role was changed to Admin, so user right and permissions will be transferred from a current Admin to this user.
          You can be logged out. Please confirm that action.
        </ConfirmDialog>
        <ConfirmDialog
          title="Unsaved data"
          open={openConfirmToChangeCurrentPage}
          onClose={handleCloseConfirmToChangeCurrentPage}
          options={recentPage}
          confirmBtnText="Confirm"
          onConfirm={handleChangeCurrentPage}>
          You are about to leave a page. All unsaved data will be lost. Please confirm.
        </ConfirmDialog>
        <RouteLeavingGuard
          when={hasUnsavedData}
          navigate={path => {
            if(path !== url){
              push(path);
            }
          }}          
          options={changedAccountData}
          shouldBlockNavigation={(path) => { return path.pathname === url || path.pathname === '/sign_in' ? false : true; }}
        />
        <ToastContainer />
    </Grid>
  );
};

export default AccountDetailsPage;
