// Core
import React from 'react';
import MUIDataTable from "mui-datatables";
import { Formik } from 'formik';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { TableCell, TableRow, Grid, Icon, Typography } from '@material-ui/core';
// Components
import CustomTableDataSelect from 'components/CustomTableDataSelect';
import CustomIconButton from 'components/CustomIconButton';
// Constants
import {
  ROLE_WASP_ADMIN,
  ROLE_ADMIN, 
  ROLE_WASP_USER,
  ROLE_USER,
  ROLE_ADMIN_READ_ONLY,
  ROLE_PARTNER,
  ROLE_APPLICANT,
  ROLE_NOVATOR} from 'utils';

const useStyles = makeStyles(theme => ({
  icon: {
    display: "inline-flex",
    alignItems: "center",
    marginRight: 15,
    "& span::before": {
      color: "var(--black-20)",
      fontSize:"1rem",
    }
  },
  muiTable: {
    "& .MuiPaper-root":{
      width: "100%",
    }
  },
  tableBody: {
    "& .MuiTable-root":{
      "& .MuiTableHead-root": {
        "& .MuiTableRow-head": {
          border: "none !important",
          marginBottom: "8px !important",
          "&:hover": {
            border: "none",
          },

          "& div":{
            fontFamily: "Montserrat",
            fontSize: 10,
            color: "var(--black-60)",
            lineHeight: "normal",
            letterSpacing: "normal",
          },

          "& .MuiTableCell-head": {
            padding: "16px 16px 16px 12px",
            borderBottom: "none !important",
          },
        },

        "& .MuiTableRow-root": {
          border: "1px solid var(--black-20)",
          borderRadius: 4,
          marginBottom: 10,
          display: "grid",
          gridTemplateColumns: "20% 15% 20% 30% 15%",
          
          "& td:last-child":{
            justifyContent: "flex-end",          
            visibility: "hidden",          
          },
          
          "&:hover": {
            border: "1px solid  var(--primary-regular)",
            "& td:last-child":{
              visibility: "visible"          
            },
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          border: "1px solid var(--black-20)",
          borderRadius: 4,
          marginBottom: 10,
          display: "grid",
          gridTemplateColumns: "20% 15% 20% 30% 15%",
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "100%"
          },
          "& td:last-child":{
            justifyContent: "flex-end",          
            visibility: "hidden",          
          },
          
          "&:hover": {
            border: "1px solid  var(--primary-regular)",
            "& td:last-child":{
              visibility: "visible"          
            },
          },

          "& .MuiTableCell-root": {
            padding: "5px 10px 5px 10px",
            borderBottom: 'none',
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            wordBreak: "break-word",
          },
        },
      },
    },
  },
}));

const userRole = (roleId) => {
  switch(roleId){
    case ROLE_WASP_ADMIN:
      return "WASP admin"      
    case ROLE_ADMIN:
      return "Company Admin"
    case ROLE_WASP_USER:
    case ROLE_APPLICANT:
    case ROLE_NOVATOR:
      return "WASP user"
    case ROLE_USER:
      return "Company User"
    case ROLE_ADMIN_READ_ONLY:
      return "Admin read only"
    case ROLE_PARTNER:
      return "Third-party partner"
    default:
      return "Company User"
  };
};

const UsersList = ({ 
  users, 
  onSetSelectedUserId, 
  onViewUserDetails, 
  onSetSelectedUserEmail, 
  onSetSelectedAdmin,
  isCurrentUserCompanyAdmin
 }) => {
  const classes = useStyles();

  const usersWithoutPartners = users.filter(user => user.role_id !== 6);

  const onActiveFieldChange = (event, email, id, role_id) => {
    const value = event.target.value;
    const userId = id;
    onSetSelectedUserEmail({email, value, userId, role_id});
  }; 

  const usersTableColumns = [
    {
      name: "id",
      label: " "
    },
    {
      name: "full_name",
      label: "USER NAME",
    },
    {
      name: "active",
      label: "ACTIVE",
    },
    {
      name: "role_id",
      label: "ROLE",
    },
    {
      name: "email",
      label: "EMAIL",
    },
    {
      name: "functions",
      label: " ",
    },
  ];

  const renderContent = (
    <MUIDataTable
      title={""}
      data={usersWithoutPartners}
      className={classes.tableBody}
      columns={usersTableColumns}
      options={{
        responsive: "vertical",
        selectableRows: "none",
        download: false,
        filter: false,
        pagination: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        tableBodyHeight: "73vh", 
        customRowRender: (colData) => {
          const [ id, full_name, active, role_id, email ] = colData; 
          return (
            <TableRow key={id}>
              <TableCell>
                <Typography variant="body2">{full_name}</Typography>
              </TableCell>
              <TableCell>
                <Formik>
                  {() => {
                    return (
                      <form>
                        <CustomTableDataSelect
                          disabled={(role_id === ROLE_WASP_ADMIN) || (role_id === ROLE_ADMIN && isCurrentUserCompanyAdmin)}
                          displayEmpty={true}
                          id="active"
                          name="active"
                          value={active}
                          handleChange={(event) => {onActiveFieldChange(event, email, id, role_id)}}
                          placeholder="Activation">
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Inactive</MenuItem>
                        </CustomTableDataSelect>
                      </form>
                    );
                  }}
                </Formik>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {role_id !== 1 && role_id !== 2 ? (
                    <span className={classes.icon}>
                      <Icon 
                        fontSize="small"
                        className="fa fa-user" />
                    </span>) : (
                      <span className={classes.icon}> 
                      <Icon 
                        fontSize="small" 
                        className="fa fa-user-shield" />
                      </span>
                    )
                  }
                  {userRole(role_id)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{email}</Typography>
              </TableCell>
              <TableCell>
                {role_id !== 1 && role_id !== 2 && active === true ? (
                  <CustomIconButton 
                    icon="fa fa-user-shield" 
                    tooltipText="Admin"
                    onIconButtonClick={() => {onSetSelectedAdmin(id)}} />) : (
                    <></>
                  ) 
                }               
                <CustomIconButton icon="fas fa-pen" tooltipText="Edit" onIconButtonClick={() => {onViewUserDetails(id)}} />
                {role_id !== 1 && role_id !== 2 ? (
                  <CustomIconButton 
                  icon="fas fa-trash" 
                  tooltipText="Delete" 
                  onIconButtonClick={() => {onSetSelectedUserId(id)}} />) : (
                    <></>
                  )
                }
              </TableCell>
            </TableRow>
          );
        },
      }}
    />
  )

    return( 
      <Grid 
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.muiTable}>
          {renderContent}
      </Grid>
    );
};

export default UsersList;
