// Core
import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
// @material-ui
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputBase } from '@material-ui/core';

const Textarea = withStyles(theme => ({
  input: {
    position: 'relative',
    padding: '6px 12px',
    marginBottom: 13,
    color: 'var(--black-100)',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    borderRadius: 4,
    border: `1px solid var(--black-20)`,
    '&:focus': {
      borderColor: 'var(--input-active-border)',
    },
  },
}))(InputBase);

const CustomTextarea = ({ id, name, rows, classes, disabled }) => {
  const [field] = useField(name);
  return (
    <FormControl fullWidth>
      <Textarea
        disabled={disabled}
        id={id}
        multiline
        type="textarea"
        placeholder="Enter text here"
        rows={rows}
        classes={classes}
        {...field}
      />
    </FormControl>
  );
};

CustomTextarea.defaultProps = {
  rows: 5,
  classes: {},
  disabled: false
};

CustomTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default CustomTextarea;
