// Core
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from 'components/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// Components
import FormInfoSection from 'components/FormInfoSection';
import FormikInput from 'components/FormikInput';
import SimpleDatePicker from 'components/SimpleDatePicker';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: '0px !important'
      },
    }
  },
  datePicker: {
    margin: '-14px 0px 0px'
  }
});

const UbosForm = ({
  index,
  ubo,
  onSetFieldValue,
  countryMenuItems,
  deleteOneUbo,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, index !== 0 ? "pt-0 pb-3" : "pt-3 pb-3")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      {index !== 0 &&
        (<Grid item xs={12} className="pb-0 pt-0">
          <IconButton
            inverted="true"
            className="mr-0 float-right"
            onClick={() => deleteOneUbo(index)}
          >
            <Icon className="fa fa-trash-alt" />
          </IconButton>
        </Grid>)}
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="individualName"
          name={`ubos[${index}].name`}
          label="Full name"
          value={ubo.name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="shares"
          name={`ubos[${index}].shares`}
          label="Shares"
          value={ubo.shares}
          customAdornment={"percent"}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="passportNumber"
          name={`ubos[${index}].registered_number`}
          label="Passport number"
          value={ubo.registered_number}
        />
      </Grid>
      <Grid item xs={6} className={clsx(classes.datePicker)}>
        <FormInfoSection
          text="Date of Birth"
          isCapitalize={false}
        />
        <SimpleDatePicker
          id={`dateOfBirth[${index}]`}
          name={`ubos[${index}].date`}
          dateValue={ubo.date}
          onChangeHandler={date => date ? onSetFieldValue(`ubos[${index}].date`, date._d) : null}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          select
          id={`citizenship[${index}]`}
          name={`ubos[${index}].citizenship`}
          label="Citizenship"
          value={ubo.citizenship}>
          {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          select
          id={`secondCitizenship[${index}]`}
          name={`ubos[${index}].second_citizenship`}
          label="Second citizenship"
          value={ubo.second_citizenship}>
          {countryMenuItems}
        </FormikInput>
      </Grid>
    </Grid>
  )
};

export default UbosForm;
