// Core
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import Grid from '@material-ui/core/Grid';
import IconButton from 'components/IconButton';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
// Components
import FormInfoSection from 'components/FormInfoSection';
import FormikInput from 'components/FormikInput';
import SimpleDatePicker from 'components/SimpleDatePicker';
import Spinner from 'components/shared/Spinner';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: '0px !important'
      },
    }
  },
  datePicker: {
    margin: '-13px 0 4px'
  },
  shareholderTitle: {
    fontWeight: 600,
    color: 'var(--primary-regular)',
    marginBottom: 15
  }
});

const ShareholdersForm = ({
  index,
  shareholder,
  onSetFieldValue,
  countryMenuItems,
  deleteOneShareholder,
}) => {
  const classes = useStyles();
  const itemIndex = index + 1;

  return shareholder ? (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, index !== 0 ? "pt-0 pb-3" : "pt-3 pb-3")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      {index !== 0 &&
        (<Grid item xs={12} className="pb-0 pt-0">
          <IconButton
            inverted="true"
            className="mr-0 float-right"
            onClick={() => deleteOneShareholder(index)}
          >
            <Icon className="fa fa-trash-alt" />
          </IconButton>
        </Grid>)}
      <Grid item xs={12} className={classes.shareholderTitle}>Shareholder {itemIndex}</Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          select
          id="legalOrIndividual"
          name={`shareholders[${index}].entity_legal_or_individual`}
          label="Legal Entity or Individual"
          uncapitalized={true}
          value={shareholder.entity_legal_or_individual}>
          <MenuItem key="legal" name="legal" value="Legal entity">
            Legal entity
          </MenuItem>
          <MenuItem key="individual" name="individual" value="Individual">
            Individual
          </MenuItem>
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="fullName"
          name={`shareholders[${index}].name`}
          label="Full name"
          value={shareholder.name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          id="shares"
          name={`shareholders[${index}].shares`}
          label="Shares"
          value={shareholder.shares}
          customAdornment={"percent"}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          uncapitalized={true}
          id="registeredOrPassportNumber"
          name={`shareholders[${index}].registered_number`}
          label={"Registered or Passport Number"}
          value={shareholder.registered_number}
        />
      </Grid>
      <Grid item xs={6} className={classes.datePicker}>
        <FormInfoSection
          text="Date of Incorporation or Date of Birth"
          isCapitalize={false}
        />
        <SimpleDatePicker
          id="dateOfIncorporationOrBirth"
          name={`shareholders[${index}].date`}
          dateValue={shareholder?.date || shareholder.date}
          onChangeHandler={date => date ? onSetFieldValue(`shareholders[${index}].date`, date._d) : null}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          uncapitalized={true}
          select
          id={`citizenship[${index}]`}
          name={`shareholders[${index}].citizenship`}
          label={"Country of Registration or Citizenship"}
          value={shareholder.citizenship}>
          {countryMenuItems}
        </FormikInput>
      </Grid>
      {
        shareholder.entity_legal_or_individual === "Individual" &&
        <Grid item xs={6} className="pb-0">
          <FormikInput
            select
            id={`secondCitizenship[${index}]`}
            name={`shareholders[${index}].second_citizenship`}
            label="Second citizenship"
            value={shareholder.second_citizenship}>
            {countryMenuItems}
          </FormikInput>
        </Grid>
      }
    </Grid>
  ) : <Spinner />
};

export default ShareholdersForm;
