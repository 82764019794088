// Core
import React, { useEffect, useState, useContext } from 'react';
// import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, Grid } from '@material-ui/core';
// Components
import BankStep from './BankStep';
import Button from 'components/CustomButton';
import ComplianceProceduresStep from './ComplianceProceduresStep';
import DirectorStep from './DirectorStep';
import GeneralInformationStep from './GeneralInformationStep';
import ShareholdersStep from './ShareholdersStep';
import Spinner from 'components/shared/Spinner';
import UbossStep from './UbosStep';
// Context
import { CountriesContext } from 'context/CountriesContext';
// Constants
import { ACCOUNT_REGEX, WEBSITE_REGEX, EMAIL_REGEX, PHONE_NUMBER_REGEX } from 'utils';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdviceFlowDataDictionaries } from 'api/projects';
import { saveQuestionnaire } from 'api/questionnaire';
import { addObjectToStore } from 'redux/actions/actions';
import DownloadFilesStep from './DownloadFilesStep';


const validationSchema = Yup.object().shape({
  general_information: Yup.object().shape({
    legal_name: Yup.string().max(128, 'Must be 128 characters or less')
      .transform((value) => value.trim())
      .matches(ACCOUNT_REGEX, {
        message: 'You should use latin letters only',
        excludeEmptyString: true,
      }).required("Required field"),
    trading_name: Yup.string().max(128, 'Must be 128 characters or less')
      .transform((value) => value.trim())
      .matches(ACCOUNT_REGEX, {
        message: 'You should use latin letters only',
        excludeEmptyString: true,
      }).nullable(),
    registered_number: Yup.string()
      .transform((value) => value.trim())
      .max(128, 'Must be 128 characters or less')
      .required("Required field"),
    vat_number: Yup.string()
      .max(128, 'Must be 128 characters or less')
      .nullable(),
    duns_number: Yup.string()
      .max(128, 'Must be 128 characters or less')
      .nullable(),
    country_code_registration: Yup.string().required("Required field").nullable(),
    industry_id: Yup.string().required("Required field"),
    registered_address: Yup.string().max(128, 'Must be 128 characters or less').transform((value) => value.trim()).required("Required field"),
    office_address: Yup.string().max(128, 'Must be 128 characters or less').nullable(),
    corporate_website: Yup.string().max(128, 'Must be 128 characters or less')
      .matches(WEBSITE_REGEX, {
        message: 'Invalid website pattern',
        excludeEmptyString: true,
      }).nullable(),
    contact_telephone: Yup.string()
      .max(128, 'Must be 128 characters or less')
      .transform((value) => value.trim())
      .matches(PHONE_NUMBER_REGEX, {
        message: 'Invalid phone number pattern',
        excludeEmptyString: true,
      })
      .required("Required field"),
    contact_email: Yup.string().max(128, 'Must be 128 characters or less')
      .matches(EMAIL_REGEX, {
        message: 'Invalid email pattern',
        excludeEmptyString: true,
      })
      .transform((value) => value.trim())
      .required("Required field"),
    business_relation: Yup.string().required("Required field"),
    incorporation_date: Yup.date().nullable()
  }),
  directors: Yup.array(
    Yup.object().shape({
      name: Yup.string().transform((value) => value.trim()).max(128, 'Must be 128 characters or less')
        .transform((value) => value.trim())
        .matches(/^[ a-zA-Z_]+( [a-zA-Z_ ]+)*$/, {
          message: 'You should use latin letters only',
          excludeEmptyString: true,
        }).required("Required field"),
      director_position: Yup.string().max(128, 'Must be 128 characters or less').transform((value) => value.trim()).required("Required field"),
      citizenship: Yup.string().required("Required field"),
      registered_number: Yup.string().transform((value) => value.trim()).max(128, 'Must be 128 characters or less').required("Required field"),
      date: Yup.date().nullable()
    })
  ),
  shareholders: Yup.array(
    Yup.object().shape({
      entity_legal_or_individual: Yup.string().required("Required field"),
      name: Yup.string().max(128, 'Must be 128 characters or less').transform((value) => value.trim()).required("Required field")
        .transform((value) => value.trim())
        .matches(/^[ a-zA-Z_]+( [a-zA-Z_ ]+)*$/, {
          message: 'You should use latin letters only',
          excludeEmptyString: true,
        }).required("Required field"),
      shares: Yup.string().matches(/^[0-9]$|^[1-9][0-9]$|^(100)$/, {
        message: 'You should enter a number from 0 till 100 inclusively',
        excludeEmptyString: true,
      }).nullable(),
      registered_number: Yup.string().max(128, 'Must be 128 characters or less').transform((value) => value.trim()).required("Required field"),
      citizenship: Yup.string().required("Required field"),
      date: Yup.date().nullable()
    })
  ),
  ubos: Yup.array(
    Yup.object().shape({
      name: Yup.string().max(128, 'Must be 128 characters or less').required("Required field")
        .transform((value) => value.trim())
        .matches(/^[ a-zA-Z_]+( [a-zA-Z_ ]+)*$/, {
          message: 'You should use latin letters only',
          excludeEmptyString: true,
        }).required("Required field"),
      shares: Yup.number()
        .integer()
        .min(1, "Must hold more than 1%")
        .max(100, "Must hold less than 100%")
        .required("Required field"),
      registered_number: Yup.string().max(128, 'Must be 128 characters or less').required("Required field"),
      citizenship: Yup.string().required("Required field"),
      date: Yup.date().nullable()
    })
  ),
  bank_entity: Yup.object().shape({
    beneficiary_name: Yup.string().transform((value) => value.trim()).max(128, 'Must be 128 characters or less').required("Required field"),
    beneficiary_iban: Yup.string().transform((value) => value.trim()).max(128, 'Must be 128 characters or less').required("Required field"),
    beneficiary_address: Yup.string().transform((value) => value.trim()).max(128, 'Must be 128 characters or less').required("Required field"),
    beneficiary_swift: Yup.string().transform((value) => value.trim()).max(128, 'Must be 128 characters or less').required("Required field"),
    correspondent_name: Yup.string().max(128, 'Must be 128 characters or less'),
    correspondent_iban: Yup.string().max(128, 'Must be 128 characters or less'),
    correspondent_address: Yup.string().max(128, 'Must be 128 characters or less'),
    correspondent_swift: Yup.string().max(128, 'Must be 128 characters or less')
  }),
  compliance_procedures: Yup.object().shape({
    politically_exposed_persons_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    exist_compliance_officer_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    compliance_policies_procedures_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    anti_money_laundering_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    other_compliance_policies_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    bankruptcy_liquidation_proceedings_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    company_sanctions_restrictions_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    country_sanctions_restrictions_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    individual_sanctions_restrictions_text: Yup.string().max(256, 'Must be 256 characters or less').nullable(),
    individual_international_operation_restrictions_text: Yup.string().max(256, 'Must be 256 characters or less').nullable()
  }),
  finish_form: Yup.object().shape({
    respondent_name: Yup.string().transform((value) => value.trim()).max(256, 'Must be 256 characters or less').required("Required field").nullable(),
    respondent_position: Yup.string().transform((value) => value.trim()).max(256, 'Must be 256 characters or less').required("Required field").nullable(),
    registry_extract: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    certificate_incorporation: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    ownership_chart: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    vat_certificate: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    prof_license_certificate: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    latest_audited_report: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    bank_reference_letter: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    document_directors: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    document_shareholders: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    document_ubos: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    introduction_presentation: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    code_conduct_compliance_policies: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    invoice_sample: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
    other: Yup.string().max(1024, 'Must be 1024 characters or less').nullable(),
  })
});

const useStyles = makeStyles({
  stepCard: {
    width: '100%',
    overflow: 'visible',
    position: 'relative',
  },
  stepContent: {
    padding: '0 25px',
  },
  btnActions: {
    padding: '0px 25px 25px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
});

const stepsNames = {
  1: 'general_information',
  2: 'directors',
  3: 'shareholders',
  4: 'ubos',
  5: 'bank_entity',
  6: 'compliance_procedures',
  7: 'finish_form'
}

const QuestionnaireStepper = ({
  activeStep,
  stepsCount,
  handleNextStep,
  handleBackStep,
  finishQuestionnaire,
  userId,
  pendingGetPartner,
  politicAgree,
  setPoliticAgree
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { countryMenuItems } = useContext(CountriesContext);
  const generalState = useSelector(state => state.questionnaire);

  const [formikValues, setFormikValues] = useState(null);
  const [isContentDownload, setIsContentDownload] = useState(false);
  const [isNeedToast, setIsNeedToast] = useState(false);
  const [dicts, setDicts] = useState({
    durationMenu: [],
    industryMenu: [],
    relationshipMenu: [],
    countryMenuItems: []
  });

  const { value: dictionaries } = useAsync(fetchAdviceFlowDataDictionaries);
  const { execute: saveStore, pending: pendingSaveStore, status: statusSaveStore } = useAsync(saveQuestionnaire, false);

  useEffect(() => {
    if (generalState.general_information.id) {
      setPoliticAgree(true);
    }
  }, [generalState.general_information.id, setPoliticAgree]);

  useEffect(() => {
    if (!pendingSaveStore && statusSaveStore === 200 && isNeedToast) {
      toast.success(
        "Information successful saved", { autoClose: 2000 }
      );
    }
    // eslint-disable-next-line
  }, [pendingSaveStore, statusSaveStore]);

  useEffect(() => {
    setFormikValues(JSON.parse(JSON.stringify(generalState)));
  }, [generalState])

  useEffect(() => {
    if (dictionaries) {
      const durationMenuItems = dictionaries.durations.map(duration => {
        const { id, name } = duration;
        return (
          <MenuItem key={id} name={name} value={id}>
            {name}
          </MenuItem>
        );
      });
      const industryMenuItems = dictionaries.industries.map(industry => {
        const { id, name } = industry;
        return (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        );
      });
      const relationshipMenuItems = dictionaries.relationships.map(relationship => {
        const { id, name } = relationship;
        return (
          <MenuItem key={id} name={name} value={id}>
            {name}
          </MenuItem>
        );
      });
      setDicts(prev => ({
        ...prev,
        durationMenu: durationMenuItems,
        industryMenu: industryMenuItems,
        relationshipMenu: relationshipMenuItems,
        countryMenuItems: countryMenuItems
      }));
      setIsContentDownload(true);
    }
  }, [dictionaries, countryMenuItems]);

  const validatePartOfForm = async (values, validateForm, setTouched) => {
    const errors = await validateForm(values);
    if (errors[stepsNames[activeStep]]) {
      setTouched(errors);
      return false;
    } else {
      return true;
    }
  };

  const handleSaveInformation = async (values, validateForm, setTouched) => {
    const isValid = await validatePartOfForm(values, validateForm, setTouched);
    if (isValid) {
      setIsNeedToast(true);
      dispatch(addObjectToStore(values[stepsNames[activeStep]], stepsNames[activeStep]));
      saveStore({ info: values[stepsNames[activeStep]], stepName: stepsNames[activeStep] });
    }
  };

  const handleNextButton = async (values, validateForm, setTouched) => {
    const isValid = await validatePartOfForm(values, validateForm, setTouched);
    if (isValid) {
      setIsNeedToast(false);
      dispatch(addObjectToStore(values[stepsNames[activeStep]], stepsNames[activeStep]));
      saveStore({ info: values[stepsNames[activeStep]], stepName: stepsNames[activeStep] });
      if (activeStep < stepsCount) {
        handleNextStep();
      } else {
        finishQuestionnaire();
      }
    }
  }

  const renderForm = (setFieldValue, values, validateForm) => {
    switch (activeStep) {
      case 1:
        return (
          <GeneralInformationStep
            general_information={generalState.general_information}
            dicts={dicts}
            onSetFieldValue={setFieldValue}
            valuesFromFormik={values.general_information}
            politicAgree={politicAgree}
          />
        );
      case 2:
        return (
          <DirectorStep
            directors={values.directors}
            onSetFieldValue={setFieldValue}
            countryMenuItems={countryMenuItems}
          />
        );
      case 3:
        return (
          <ShareholdersStep
            shareholders={values.shareholders}
            onSetFieldValue={setFieldValue}
            countryMenuItems={countryMenuItems}
          />
        );
      case 4:
        return (
          <UbossStep
            ubos={values.ubos}
            onSetFieldValue={setFieldValue}
            countryMenuItems={countryMenuItems}
          />
        );
      case 5:
        return (
          <BankStep
            bank_entity={generalState.bank_entity}
          />
        );
      case 6:
        return (
          <ComplianceProceduresStep
            compliance_procedures={values.compliance_procedures}
            onSetFieldValue={setFieldValue}
          />
        );
      case 7:
        return (
          <DownloadFilesStep
            linksObject={values.finish_form}
            onSetFieldValue={setFieldValue}
            userId={userId}
          />
        );
      default:
        return 'Something went wrong. There is no any content';
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.stepCard}>
        <Divider className="mb-3" />
        {
          isContentDownload && !pendingGetPartner
            ? (<Formik
              enableReinitialize
              initialValues={formikValues}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                validateForm,
                setFieldValue,
                setTouched,
              }) => {
                return (
                  <Form>
                    <CardContent className={classes.stepContent}>
                      {
                        renderForm(setFieldValue, values, validateForm)
                      }
                    </CardContent>
                    <CardActions className={classes.btnActions}>
                      <Grid>
                        {
                          politicAgree && (
                            <Button
                              disabled={!!errors[stepsNames[activeStep]]}
                              className="mb-0"
                              variant="outlined"
                              onClick={() => handleSaveInformation(values, validateForm, setTouched)}>
                              Save information
                            </Button>)
                        }
                        {
                          activeStep !== 1 && (
                            <Button
                              disabled={false}
                              className="mb-0"
                              variant="outlined"
                              onClick={handleBackStep}>
                              Back
                            </Button>
                          )
                        }
                        {
                          politicAgree &&
                          <Button
                            disabled={!!errors[stepsNames[activeStep]]}
                            className="mb-0 mr-0"
                            onClick={() => handleNextButton(values, validateForm, setTouched)}>
                            {activeStep >= stepsCount ? "Finish" : "Next"}
                          </Button>
                        }
                      </Grid>
                    </CardActions>
                  </Form>
                )
              }}
            </Formik>)
            : (<CardContent className={classes.stepContent}>
              <Spinner />
            </CardContent>)
        }
      </Card>
      <ToastContainer />
    </React.Fragment>
  )
};


export default QuestionnaireStepper;