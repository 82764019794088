// Core
import React from 'react';
import { useParams } from 'react-router-dom';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// Components
import Button from 'components/CustomButton';
import DetailsCard from 'components/DetailsCard';
import PageTitleSection from 'components/PageTitleSection';
import PartnerGuideLinksPart from 'components/Partners/PartnerGuideLinksPart';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { getPartnerLinkGuide } from 'api/questionnaire';
import { useEffect } from 'react';
import Spinner from 'components/shared/Spinner';


const useStyles = makeStyles({
  wrapper: {
    padding: '1.5rem',
    position: 'relative',
  },
});

const PartnerLinksGuidePage = () => {
  const { id } = useParams();
  const classes = useStyles();

  const {
    execute: getPartnerLinks,
    value: partnersLinks
  } = useAsync(getPartnerLinkGuide, false);

  useEffect(() => {
    getPartnerLinks(id);
  }, [id, getPartnerLinks]);

  return partnersLinks
    ? (<Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
      style={{ maxWidth: '760px' }}>
      <PageTitleSection title={partnersLinks.general_information[0].name} className="projectTitle">
        <Button disabled className={"ml-3 typeService3"}>
          {partnersLinks.general_information[0].citizenship_name}
        </Button>
        <Button disabled className={"typeService3"}>
          {partnersLinks.general_information[0].registered_number}
        </Button>
      </PageTitleSection>
      <DetailsCard title="" bordered={true} showTitle={false}>
        <Grid
          container
          className={classes.wrapper}>
          <Grid container className="pb-3" direction="column" justify="flex-start" alignItems="stretch">
            <PartnerGuideLinksPart
              infoForTable={partnersLinks.general_information}
            />
            <Divider className="mt-3 minus-m-25" />
          </Grid>
          <Grid container className="pb-3" direction="column" justify="flex-start" alignItems="stretch">
            <PartnerGuideLinksPart
              infoForTable={partnersLinks.directors}
            />
            <Divider className="mt-3 minus-m-25" />
          </Grid>
          <Grid container className="pb-3" direction="column" justify="flex-start" alignItems="stretch">
            <PartnerGuideLinksPart
              infoForTable={partnersLinks.shareholders}
            />
            <Divider className="mt-3 minus-m-25" />
          </Grid>
          <Grid container className="pb-3" direction="column" justify="flex-start" alignItems="stretch">
            <PartnerGuideLinksPart
              infoForTable={partnersLinks.ubos}
            />
          </Grid>
        </Grid>
      </DetailsCard>
    </Grid>)
    : (<Spinner />)
}

export default PartnerLinksGuidePage;
