// Core
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// @material-ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Assets
import defaultAvatar from 'assets/profile_photo.jpg';
// Hooks
import { useAuth } from 'hooks/useAuth';
// Constants
import { ROLE_WASP_ADMIN, ROLE_ADMIN, ROLE_WASP_USER, ROLE_APPLICANT, ROLE_NOVATOR, ROLE_USER, ROLE_ADMIN_READ_ONLY } from 'utils';

const useStyles = makeStyles({
  userDataRoot: {
    display: 'flex',
    alignItems: 'center',
    height: 44,
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
    border: '1px solid var(--white)',

    '&:hover': {
      border: '1px solid var(--input-active-border)',
    }
  },

  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '140px',
  },

  avatarWrapper: {
    width: 40,
    height: 40,
    margin: '0 8px',
    borderRadius: 4,

    '& img': {
      width: 40,
      height: 40,
      // borderRadius: 4,
    },
  },

  cardContent: {
    flex: '1 0 auto',

    '&:last-child': {
      paddingBottom: 0,
    }
  },
});

const StyledMenu = withStyles({
  paper: {
    '& ul': {
      width: '200px',
      padding: '8px',
      border: '1px solid var(--input-regular-border)',
      borderRadius: 4,
    },
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    padding: '6px 20px',
    marginBottom: '4px',
    borderRadius: 4,
    '&:focus': {
      backgroundColor: 'var(--white)',
    },
    '&:hover': {
      backgroundColor: 'var(--input-active-border)',
      color: 'var(--white)',
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--input-active-border)',
      color: 'var(--white)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'var(--input-active-border)',
      color: 'var(--white)',
    },
  },
})(MenuItem);

const DropdownMenu = props => {
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const { btnText, variant } = props;
  const { signout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const userData = JSON.parse(localStorage.getItem('user'));

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
    setSelectedPath(location.pathname);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateMenu = (hrefParam='/') => {
    history.push(hrefParam);
    setAnchorEl(null);
  };

  const showCurrentUserRole = (roleId) => {
    switch (roleId) {
      case ROLE_WASP_ADMIN:
        return "WASP Admin";
      case ROLE_ADMIN:
        return "Company Admin";
      case ROLE_WASP_USER:
      case ROLE_APPLICANT:
      case ROLE_NOVATOR:
        return "WASP User";
      case ROLE_USER:
        return "Company User";
      case ROLE_ADMIN_READ_ONLY:
        return "Admin Read Only";
      default:
        return "User";
    }
  };

  return (
    <>
      <Card
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant={variant}
        className={classes.userDataRoot}
        onClick={handleClick}>
          {userData ? (
            <React.Fragment>
              <CardMedia className={classes.avatarWrapper}>
                <Avatar 
                  variant="rounded" 
                  className={classes.avatar} 
                  src={userData.avatar_url !== null ? `${userData.avatar_url}/?${Date.now()}` : defaultAvatar}/>
              </CardMedia>
              <div className={classes.userDetails}>
              <CardContent className={classes.cardContent}>
                <Typography variant="body2">{userData.full_name ? userData.full_name : ''}</Typography>
                <Typography>
                  <label>{showCurrentUserRole(userData.role_id)}</label>
                </Typography>
              </CardContent>
              </div>
            </React.Fragment>
          ) : btnText}
        <IconButton>
          <Icon color="primary" className={"fa fa-sort-" + (Boolean(anchorEl) ? "up" : "down")} />
        </IconButton>
      </Card>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <StyledMenuItem
          selected={selectedPath === '/current-user-details'}
          onClick={() => {navigateMenu('/current-user-details')}}>Account Details</StyledMenuItem>
        {/*<StyledMenuItem>Settings</StyledMenuItem>
        <StyledMenuItem>Contact WASP Team</StyledMenuItem>*/}
        <StyledMenuItem onClick={() => signout()}>Logout</StyledMenuItem>
      </StyledMenu>
    </>
  );
};

DropdownMenu.defaultProps = {
  variant: 'outlined',
  style: {},
};

DropdownMenu.propTypes = {
  btnText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  style: PropTypes.shape({}),
};

export default DropdownMenu;
