// Core
import React, { useContext } from 'react';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
// Components
import LinkCards from 'components/LinkCards/LinkCards';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
// Context
import { ServicesContext } from 'context/ServicesContext';
import { UserContext } from 'context/UserContext';
//Instruments
import clientServicesLinks from 'config/services-links-client';
import waspServicesLinks from 'config/services-links-wasp';
import { fetchProjectsCounters } from 'api/projects';
//Constants
import { ROLE_WASP_ADMIN } from 'utils';

const MyServicesPage = () => {
  const { services, isLoadingServices } = useContext(ServicesContext);
  const { loggedInUser } = useContext(UserContext);
  const { value: projectsCounters } = useAsync(
    fetchProjectsCounters,
    true,
  );
  const links = loggedInUser && loggedInUser.role_id === ROLE_WASP_ADMIN ? waspServicesLinks 
    : clientServicesLinks.map((serviceLink) => {
      if (loggedInUser && loggedInUser.subscriptions) {
        const foundSubId = loggedInUser.subscriptions.find(
          subscription => subscription.id === serviceLink.serviceId
        );
        if (foundSubId) {
          serviceLink.disabled = false;
        } else {
          serviceLink.disabled = true;
        }  
        return serviceLink;
      } else {
        serviceLink.disabled = true;
        return serviceLink;
      }
    });
  const title = loggedInUser && loggedInUser.role_id === ROLE_WASP_ADMIN ? 'Solutions' : 'My Solutions';
  return !isLoadingServices ? (
    <Grid
      container>
        <Typography variant="h1" className="pb-3">{title}</Typography>
        <Grid
          container
          spacing={3}
          className="mb-0">
          <LinkCards services={services} links={links} projectsCounters={projectsCounters}/>
        </Grid>
    </Grid>
  ) : (
    <Spinner />
  );
};

export default MyServicesPage;
