// Core
import clsx from 'clsx';
import React, {useState, useEffect} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Components
import AnalysisDetailsForm from 'components/AnalysisDetailsForm';
import ConfirmDialog from 'components/UI/ConfirmDialog/ConfirmDialog';
import Button from 'components/CustomButton';
import MapChart from 'components/MapChart/MapChart';
import Spinner from 'components/shared/Spinner';
import RouteLeavingGuard from 'components/RouteLeavingGuard';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Instruments
import { fetchAnalysisDetails, fetchProjectSiProjectSettings } from 'api/projects';
// Constants
import {PESTEL_ANALYSIS, ENHANCED_ANALYSIS} from 'utils';

const AnalysisDetailsStep = ({
  config_id,
  newProjectId,
  onUpdateProject,
  values, 
  onCreateProject, 
  onClickBack,
  onSetFieldValue,
}) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [osintToggleChecked, setOsintToggleChecked] = useState(true);
  const [dynamicValuesForRequest, setDynamicValuesForRequest] = useState();
  const [dynamicValues, setDynamicValues] = useState({
    risks: {},
    stakeholders: {},
    osintValues: {},
  });

  const {
    open: openConfirmToChangeCurrentPage,
    handleOpen: handleOpenConfirmToChangeCurrentPage,
    handleClose: handleCloseConfirmToChangeCurrentPage,
  } = useDialog();

  const { execute: getDictionaries, value: dictionaries } = useAsync(fetchAnalysisDetails, false);
  const { execute: getSiProjectSettings, value: settings } = useAsync(fetchProjectSiProjectSettings, false);

  useEffect(() => {
    if (config_id) {
      getDictionaries(config_id);
    }
  }, [getDictionaries, config_id]);

  useEffect(() => {
    if(newProjectId) {
      getSiProjectSettings(newProjectId);
    }
  }, [getSiProjectSettings, newProjectId]);

  useEffect(() => {
    if (values && settings && dynamicValuesForRequest) {
      handleChangeData(values, settings, dynamicValuesForRequest);
    }
  // eslint-disable-next-line
  }, [values, dynamicValuesForRequest]);

  useEffect(() => {
    if (dictionaries) {
      let chosenRisks = new Map();
      let chosenOsintValues = new Map();
      let chosenStakeholders = new Map();
      if (dictionaries.risks) { 
        if (settings && settings.risks && settings.risks.length && settings.config_id === config_id) {
          if (Object.keys(settings).includes("osint_value")) {
            setOsintToggleChecked(settings.osint_value);
          }
          dictionaries.risks.map(risk => {
            const foundRisk = settings.risks.find(foundRisk => foundRisk.id === risk.id);
            if (foundRisk) {
              chosenRisks.set(risk.id, true);
              chosenOsintValues.set(risk.id, foundRisk.value);  
            } else {
              chosenRisks.set(risk.id, false);
              chosenOsintValues.set(risk.id, false);
            }
            return true;
          });
          } else if (!newProjectId || (settings && settings.config_id !== config_id)){
            dictionaries.risks.map(risk => {
              chosenRisks.set(risk.id, true);
              chosenOsintValues.set(risk.id, true);
              return true;
            }
          );
        }
        setDynamicValues({...dynamicValues, risks: chosenRisks, osintValues: chosenOsintValues});
        if (!dynamicValuesForRequest || !dynamicValuesForRequest.risks || !dynamicValuesForRequest.risks.size) {
          setDynamicValuesForRequest({...dynamicValuesForRequest, risks: chosenRisks, stakeholders: chosenStakeholders, osintValues: chosenOsintValues});
        }
      }
      if (dictionaries.stakeholders && config_id === ENHANCED_ANALYSIS) {
        if (settings && settings.stakeholders && settings.stakeholders.length) {
          dictionaries.stakeholders.map(stakeholder => {
            const foundStakeholder = settings.stakeholders.find(foundStakeholder => foundStakeholder.id === stakeholder.id);
            if (foundStakeholder) {
              chosenStakeholders.set(stakeholder.id, true);
            } else {
              chosenStakeholders.set(stakeholder.id, false);
            }
            return true;
          });
        } else {
          dictionaries.stakeholders.map(stakeholder => {
            chosenStakeholders.set(stakeholder.id, false)
            return true;
          });
        }
        setDynamicValues((prevDynamicValues) => {return {...prevDynamicValues, stakeholders: chosenStakeholders}});
        if (dynamicValuesForRequest && (!dynamicValuesForRequest.stakeholders || !dynamicValuesForRequest.stakeholders.size)) {
          setDynamicValuesForRequest({...dynamicValuesForRequest, stakeholders: chosenStakeholders});
        }
      }
    }
  // eslint-disable-next-line
  }, [dictionaries, settings]);

  const riskFieldOnChange = (riskId, value) => {
    let changedRisks = dynamicValuesForRequest.risks;

    if (config_id === PESTEL_ANALYSIS && value === false) {
      changedRisks = changedRisks.set(parseInt(riskId), value);
      setDynamicValuesForRequest({...dynamicValuesForRequest, risks: changedRisks});
      pestelAnalysisOsintOnChange(parseInt(riskId), value);
    } else if (config_id === PESTEL_ANALYSIS && value === true){
      changedRisks = changedRisks.set(parseInt(riskId), value);
      setDynamicValuesForRequest({...dynamicValuesForRequest, risks: changedRisks});
    } else if (config_id === ENHANCED_ANALYSIS) {
      changedRisks = changedRisks.set(parseInt(riskId), value);
      setDynamicValuesForRequest({...dynamicValuesForRequest, risks: changedRisks});
    }
  }

  const stakeholderFieldOnChange = (stakeholderId, value) => {
    let changedStakeholders = (dynamicValuesForRequest.stakeholders).set(parseInt(stakeholderId), value);
    setDynamicValuesForRequest({...dynamicValuesForRequest, stakeholders: changedStakeholders});
  }

  const pestelAnalysisOsintOnChange = (osintId, value) => {
    let changedOsints = (dynamicValuesForRequest.osintValues).set(parseInt(osintId), value);
    setDynamicValuesForRequest({...dynamicValuesForRequest, osintValues: changedOsints});
  }

  const onEnhancedToggleChecked = (checkedValue) => {
    let changedOsints = dynamicValuesForRequest.osintValues;
    for (let key of changedOsints.keys()) {
      changedOsints.set(key, checkedValue);      
    }
    setDynamicValuesForRequest({...dynamicValuesForRequest, osintValues: changedOsints});
    setOsintToggleChecked(checkedValue);
  }

  const onClickNextButton = () => {
    const analysisDetailsValues = formAnalysisDetailsRequestOptions(
      dynamicValuesForRequest.risks, 
      dynamicValuesForRequest.stakeholders
    );
    const step = 3;
    
    if (newProjectId) {
      onUpdateProject({step, analysisDetailsValues});
    } else {
      onCreateProject(analysisDetailsValues);
    }
  }

  const formAnalysisDetailsRequestOptions = (risks, stakeholders) => {
    let chosenRisks = new Map();
    let chosenStakeholders = [];

    risks.forEach((value, key) => {
      if (value && dynamicValuesForRequest.osintValues) {
        chosenRisks.set(key, dynamicValuesForRequest.osintValues.get(key));
      }
    });
    stakeholders.forEach((value, key) => {
      if (value) {
        chosenStakeholders.push(key);
      }
    });

    return {
      chosenRisks,
      chosenStakeholders,
    }
  }

  const shouldDisableNextButton = (values) => {
    let noneOfRisksChecked = true;
    if(dynamicValuesForRequest && dynamicValuesForRequest.risks && dynamicValuesForRequest.risks.size){
      for (let value of dynamicValuesForRequest.risks.values()) {
        if (value === true) {
          noneOfRisksChecked = false;
        };
      };
    }
    return values.country_code === '' || noneOfRisksChecked;
  }

  const handleChangeData = (values, oldValues, newValues) => {
    let isDifferent = false;
    isDifferent = (values.country_code !== oldValues.country_code);
    if (isDifferent === false) {
      isDifferent = (values.industry_id !== oldValues.industry_id);
    }
    if (isDifferent === false ? oldValues.osint_value === true : oldValues.osint_value === false) {
      isDifferent = osintToggleChecked !== oldValues.osint_value;
    }
    if(oldValues.risks && newValues.risks && newValues.risks.size) {
      if (isDifferent === false) {
        let newRisksCount = 0;
        newValues.risks.forEach(val => {
          if (val === true) {
            newRisksCount += 1;
          } 
        });
        isDifferent = (oldValues.risks.length !== newRisksCount);
      }
      if (isDifferent === false) {
        newValues.risks.forEach((value, key) => {
          if (value && isDifferent === false) {
            const foundObj = oldValues.risks.find(risk => risk.id === key);
            if (!foundObj) {
              isDifferent = true;
            } else if (newValues.osintValues.get(key) !== foundObj.value) {
              isDifferent = true;
            }
          }
        });
      }
    }
    if (oldValues.stakeholders && newValues.stakeholders && newValues.stakeholders.size) {
      if (isDifferent === false) {
        let newStkhldrsCount = 0;
        newValues.stakeholders.forEach(val => {
          if (val === true) {
            newStkhldrsCount += 1;
          } 
        });
        isDifferent = (oldValues.stakeholders.length !== newStkhldrsCount);
      }
      if (isDifferent === false) { 
        newValues.stakeholders.forEach((value, key) => {
          if (value && isDifferent === false) {
            const foundObj = oldValues.stakeholders.find(stakeholder => stakeholder.id === key);
            if (!foundObj) {
              isDifferent = true;
            } 
          }
        });
      }
    }
    setHasUnsavedData(isDifferent);
  }

  const handleChangeCurrentPage = () => {
    setHasUnsavedData(false);
    handleCloseConfirmToChangeCurrentPage();
    onSetFieldValue('strategicIntelligence.industry_id', settings.industry_id);
    onSetFieldValue('strategicIntelligence.country_code', settings.country_code);
    onClickBack();
  }

  return (
    dictionaries && (!newProjectId || (newProjectId && settings)) ? (
      <Grid
        container
        alignItems="stretch"
        direction="row">
          <Grid item xs={12} md={5} className="pr-1">
            <Typography variant="h3" className="pb-4 pl-0">{config_id === PESTEL_ANALYSIS ? "Risk-based PESTEL Analysis" : "Enhanced Сountry Risk Analysis"}</Typography>
            {
              dictionaries && (
                <AnalysisDetailsForm 
                  industries={dictionaries.industries} 
                  risks={dictionaries.risks} 
                  values={values}
                  dynamicValues={dynamicValuesForRequest || dynamicValues}
                  analysisType={config_id}
                  stakeholders={dictionaries.stakeholders}
                  osintToggleChecked={osintToggleChecked}
                  riskFieldOnChange={riskFieldOnChange}
                  stakeholderFieldOnChange={stakeholderFieldOnChange}
                  pestelAnalysisOsintOnChange={pestelAnalysisOsintOnChange}
                  onEnhancedToggleChecked={onEnhancedToggleChecked}
                />
              )
            }
          </Grid>
          <Grid item xs={12} md={7} className='leftBorderMap'>
            <Grid container className={clsx("pl-3", 'generalMapWrapper')}>
              <Grid container>
                <Typography variant="h3" className="pb-0 pl-0">Corruption Perception Index</Typography>
              </Grid>
              <Grid item className='generalMapWrapper'>
                <MapChart selectedCountry={values.country_code} width="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Grid 
            container
            justify="flex-end">
              <Grid>
                <Button
                  className="mb-0"
                  variant="outlined"
                  onClick={() => {
                    if (hasUnsavedData) {
                      handleOpenConfirmToChangeCurrentPage()
                    } else {
                      onClickBack()
                    }
                  }}>
                    Back
                </Button>
                <Button
                  className="mb-0 mr-0"
                  disabled={shouldDisableNextButton(values && values)}
                  onClick={() => onClickNextButton()}>
                    Next
                </Button>
              </Grid>
            </Grid>
        <ConfirmDialog
          title="Unsaved data"
          open={openConfirmToChangeCurrentPage}
          onClose={handleCloseConfirmToChangeCurrentPage}
          options={hasUnsavedData}
          confirmBtnText="Confirm"
          onConfirm={handleChangeCurrentPage}>
          You are about to leave a page. All unsaved data will be lost. Please confirm.
        </ConfirmDialog>
        <RouteLeavingGuard
          when={hasUnsavedData}
          navigate={path => {
            if(path !== url){
              push(path);
            }
          }}
          options={dynamicValuesForRequest}
          shouldBlockNavigation={(path) => { return path.pathname === url ? false : true; }}
        />
      </Grid>
    ) : <Spinner />
  )
}

AnalysisDetailsStep.defaultProps = {
  newProjectId: "",
  values: {},
  onClickBack: () => null,
  onSetFieldValue: () => null,
  onCreateProject: () => null,
  onUpdateProject: () => null,
}

AnalysisDetailsStep.propTypes = {
  config_id: PropTypes.number.isRequired,
  newProjectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onUpdateProject: PropTypes.func,
  values: PropTypes.shape({
    settings_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    country_code: PropTypes.string,
    country_name: PropTypes.string,
    industry_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    industry_name: PropTypes.string,
    risks: PropTypes.array,
    stakeholders: PropTypes.array,
  }), 
  onCreateProject: PropTypes.func, 
  onClickBack: PropTypes.func,
  onSetFieldValue: PropTypes.func,
}

export default AnalysisDetailsStep;
