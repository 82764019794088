// Core
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from 'components/IconButton';
import Icon from '@material-ui/core/Icon';
// Components
import FormInfoSection from 'components/FormInfoSection';
import FormikInput from 'components/FormikInput';
import SimpleDatePicker from 'components/SimpleDatePicker';

const useStyles = makeStyles({
  mbNone: {
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        marginBottom: '0px !important',
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: '0px !important'
      },
    }
  },
  datePicker: {
    margin: '-14px 0 12px'
  }
});

const DirectorForm = ({
  index,
  director,
  onSetFieldValue,
  deleteOneDirector,
  countryMenuItems,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.mbNone, index !== 0 ? "pt-0 pb-3" : "pt-3 pb-3")}
      direction="row"
      justify="space-between"
      alignItems="flex-start">
      {index !== 0 &&
        (<Grid item xs={12} className="pb-0 pt-0">
        <IconButton 
          inverted="true" 
          className="mr-0 float-right" 
          onClick={() => deleteOneDirector(index)}
        >
          <Icon className="fa fa-trash-alt" />
        </IconButton>
      </Grid>)}
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="individualName"
          name={`directors[${index}].name`}
          label="Full name"
          value={director.name}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="position"
          name={`directors[${index}].director_position`}
          label="Position"
          value={director.director_position}
        />
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          select
          id={`citizenship[${index}]`}
          name={`directors[${index}].citizenship`}
          label="Citizenship"
          value={director.citizenship}>
            {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          select
          id={`secondCitizenship[${index}]`}
          name={`directors[${index}].second_citizenship`}
          label="Second citizenship"
          value={director.second_citizenship}>
            {countryMenuItems}
        </FormikInput>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <FormikInput
          mandatory
          id="passportNumber"
          name={`directors[${index}].registered_number`}
          label="Passport number"
          value={director.registered_number}
        />
      </Grid>
      <Grid item xs={6} className={clsx(classes.datePicker)}>  
        <FormInfoSection 
          text="Date of Birth"
          isCapitalize={false}
        />
        <SimpleDatePicker 
          id={`dateOfBirth[${index}]`}
          name={`directors[${index}].date`}
          dateValue={director.date}
          onChangeHandler={(date) => date ? onSetFieldValue(`directors[${index}].date`, date._d) : null}
        />                   
      </Grid>     
    </Grid>
  )
};

export default DirectorForm;
