import React from 'react';

const CogIcon = props => {
    const { color } = props;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="80" 
            height="80" 
            viewBox="0 0 80 80">
            <g 
                fill="none" 
                fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path 
                                d="M0 0H80V80H0z" 
                                transform="translate(-873 -340) translate(858 204) translate(15 136)"/>
                            <path 
                                fill={color ? color : "#FFF"} 
                                d="M40 3c.638 0 1.163.486 1.226 1.108l.007.126v2.489c17.383.635 31.408 14.66 32.044 32.043h2.49c.68 0 1.233.553 1.233 1.234 0 
                                .638-.486 1.164-1.108 1.227l-.126.006h-2.489c-.636 17.384-14.661 31.41-32.044 32.045v2.489c0 .68-.553 1.233-1.233 1.233-.639 
                                0-1.164-.486-1.227-1.107l-.007-.126v-2.489C21.383 72.642 7.36 58.617 6.723 41.234h-2.49C3.554 41.233 3 40.68 3 40c0-.638.486-1.164 
                                1.108-1.227l.126-.006h2.489c.636-17.384 14.66-31.408 32.043-32.044v-2.49C38.766 3.554 39.32 3 40 3zM11.66 41.234H9.19c.634 16.023 13.554 
                                28.942 29.577 29.575V68.34c-14.663-.63-26.477-12.444-27.108-27.106zm59.148 0H68.34C67.71 55.896 55.895 67.71 41.233 68.34v2.47c16.023-.634 
                                28.942-13.553 29.575-29.576zM41.233 14.129v2.438c0 .68-.553 1.233-1.233 1.233-.639 0-1.164-.486-1.227-1.107l-.007-.126v-2.438c-13.303.627-24.01 
                                11.335-24.637 24.637h2.438c.68 0 1.233.553 1.233 1.234 0 .638-.486 1.164-1.107 1.227l-.126.006h-2.438c.627 13.303 11.334 24.011 24.637 
                                24.638v-2.437c0-.681.553-1.234 1.234-1.234.638 0 1.163.486 1.226 1.108l.007.126v2.437c13.303-.626 24.011-11.335 24.638-24.638h-2.438c-.68 
                                0-1.233-.552-1.233-1.233 0-.638.485-1.164 1.107-1.227l.126-.006h2.438c-.627-13.304-11.336-24.011-24.638-24.638zm1.233 7.37c.682 0 1.234.553 
                                1.234 1.234v2.084c0 .404.25.76.606.864.922.271 1.794.642 2.826 1.206.33.18.761.107 1.047-.178l1.475-1.475c.482-.483 1.262-.483 1.744 0l3.368 
                                3.367c.483.483.483 1.262 0 1.745l-1.475 1.475c-.285.284-.358.715-.178 1.046.562 1.033.934 1.905 1.206 2.826.104.358.459.607.863.607h2.085c.68 0 
                                1.233.553 1.233 1.233v4.934c0 .68-.553 1.233-1.233 1.233h-2.085c-.404 0-.76.25-.864.606-.271.922-.643 1.794-1.207 2.826-.18.332-.107.763.179 
                                1.048l1.476 1.475c.482.482.482 1.261 0 1.744l-3.369 3.368c-.482.482-1.262.482-1.744 
                                0l-1.475-1.475c-.285-.285-.715-.36-1.047-.18-1.032.564-1.903.934-2.825 1.208-.357.103-.606.458-.606.864v2.083c0 
                                .68-.552 1.233-1.234 1.233h-4.933c-.68 
                                0-1.233-.552-1.233-1.233v-2.083c0-.405-.25-.76-.606-.864-.922-.272-1.794-.643-2.826-1.207-.331-.179-.762-.108-1.047.179l-1.475 
                                1.475c-.483.482-1.262.482-1.745 0l-3.368-3.368c-.482-.483-.482-1.262 
                                0-1.744l1.475-1.475c.285-.285.359-.717.18-1.048-.563-1.032-.935-1.903-1.207-2.825-.104-.357-.459-.607-.863-.607h-2.085c-.68 
                                0-1.233-.552-1.233-1.233v-4.934c0-.68.553-1.233 1.233-1.233h2.085c.404 0 
                                .76-.249.864-.605.271-.923.643-1.794 1.205-2.827.18-.332.107-.763-.178-1.047l-1.475-1.476c-.482-.482-.482-1.26 
                                0-1.744l3.369-3.368c.482-.482 1.261-.482 1.743 0l1.475 1.476c.285.284.716.36 1.048.178 1.032-.562 1.903-.933 
                                2.825-1.206.357-.103.606-.458.606-.864v-2.084c0-.68.552-1.233 1.233-1.233zm-1.232 2.468h-2.468v.85c0 1.49-.976 2.82-2.376 
                                3.23-.757.224-1.458.523-2.34 1.005-1.285.702-2.917.454-3.972-.6l-.603-.603-1.625 1.625.604.602c1.053 1.054 1.3 2.686.599 
                                3.972-.481.884-.782 1.585-1.004 2.34-.41 1.402-1.74 2.379-3.231 2.379h-.851v2.466h.851c1.491 0 2.82.977 3.231 2.377.223.757.523 1.457 
                                1.004 2.34.7 1.286.454 2.92-.6 3.972l-.603.604 1.625 1.623.603-.602c1.053-1.054 2.687-1.302 3.971-.6.883.481 1.583.782 2.341 1.004 
                                1.4.41 2.376 1.74 2.376 3.232v.85h2.468v-.85c0-1.49.976-2.82 2.376-3.23.757-.224 1.458-.524 2.34-1.005 1.286-.7 2.919-.454 3.972.6l.603.603 
                                1.625-1.624-.604-.603c-1.053-1.054-1.3-2.687-.599-3.972.48-.883.782-1.583 1.004-2.34.41-1.402 1.74-2.379 3.231-2.379h.851v-2.466h-.851c-1.491 
                                0-2.82-.977-3.231-2.377-.223-.756-.523-1.456-1.005-2.342-.7-1.284-.454-2.917.6-3.97l.604-.604-1.625-1.623-.603.603c-1.053 1.053-2.688 
                                1.3-3.971.6-.883-.482-1.584-.783-2.341-1.005-1.4-.41-2.376-1.74-2.376-3.232v-.85zM40 31.367c4.76 0 8.633 3.872 8.633 8.633 0 4.761-3.872 
                                8.633-8.633 8.633-4.76 0-8.634-3.872-8.634-8.633 0-4.76 3.873-8.633 8.634-8.633zm0 2.467c-3.4 0-6.167 2.766-6.167 6.166 0 3.4 2.767 6.167 
                                6.167 6.167S46.167 43.4 46.167 40 43.4 33.834 40 33.834zM38.767 9.19C22.744 9.824 9.825 22.743 9.19 38.766h2.468C12.29 24.104 24.104 12.29 
                                38.766 11.66V9.19zm2.465 0v2.469c14.663.63 26.477 12.444 27.108 27.106h2.468C70.174 22.743 57.255 9.824 41.232 9.19z" 
                                transform="translate(-873 -340) translate(858 204) translate(15 136)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CogIcon;
