import { 
  ADD_EMPTY_OBJECT_TO_PART_OF_STORE, 
  ADD_OBJECT_TO_STORE, 
  CHANGE_CHECKBOX, 
  DELETE_OBJECT_FROM_PART_OF_STORE,
  SET_INFO_FROM_DB,
  CHANGE_UBOS,
  CLEAR_STORE
} from "./types"

export const addObjectToStore = (payload, partOfStore) => {
  return {
    type: ADD_OBJECT_TO_STORE,
    payload: payload,
    partOfStore: partOfStore
  }
}

export const addEmptyObjectToSomePartStore = (partOfStore) => {
  return {
    type: ADD_EMPTY_OBJECT_TO_PART_OF_STORE,
    partOfStore: partOfStore
  }
}

export const changeUbos = (payload) => {
  return {
    type: CHANGE_UBOS,
    payload: payload
  }
}

export const deleteObjectFromPartStore = (partOfStore, index) => {
  return {
    type: DELETE_OBJECT_FROM_PART_OF_STORE,
    partOfStore: partOfStore,
    index: index
  }
}

export const changeCheckbox = (payload) => {
  return {
    type: CHANGE_CHECKBOX,
    payload: payload
  }
}

export const setInformationFromDB = (payload) => {
  return {
    type: SET_INFO_FROM_DB,
    payload: payload
  }
}

export const clearStore = () => {
  return {
    type: CLEAR_STORE
  }
}
