// Core
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// @material-ui
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
// Components
import Button from 'components/CustomButton';
import FormikInput from 'components/FormikInput';
import PermissionsCheckboxes from 'components/PermissionsCheckboxes';
// Context
import { CountriesContext } from 'context/CountriesContext';
import { ServicesContext } from 'context/ServicesContext';
// Constants
import { ACCOUNT_REGEX, EMAIL_REGEX, PHONE_NUMBER_REGEX, WEBSITE_REGEX } from 'utils';


const initialValues = {
  name: '',
  email: '',
  country: '',
  phoneNumber: '',
  website: '',
  subscriptions: [],
  csLevels: [],
  active: false,
  onboardingAllowed: false,
  clusterNetworkAllowed: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .transform((value) => value.trim())
    .matches(ACCOUNT_REGEX, {
      message: 'You should use latin letters',
      excludeEmptyString: true,
    })
    .required('Required'),
  email: Yup.string()
    .max(64, 'Must be 64 characters or less')
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    }),
  phoneNumber: Yup.string()
    .max(16, 'Must be 15 digits or less, except the plus character')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Only a plus character and digits are allowed',
      excludeEmptyString: true,
    })
    .nullable(),
  website: Yup.string()
    .max(128, 'Must be 128 characters or less')
    .matches(WEBSITE_REGEX, {
      message: 'Invalid website',
      excludeEmptyString: true,
    }),
});

const CreateCompanyForm = ({
  onCreateCompany,
  isOutherSubmit,
  levels
}) => {
  const { countryMenuItems } = useContext(CountriesContext);
  const { services } = useContext(ServicesContext);

    const onSubmit = (values, setSubmitting) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
      const dataToCreateCompany = {
        name: values.name,
        email: values.email,
        country: values.country,
        phone_number: values.phoneNumber,
        website: values.website,
        subscriptions: values.subscriptions,
        cs_levels: values.csLevels,
        active: values.active ? values.active : false,
        onboarding_allowed: values.onboardingAllowed,
        cluster_network_allowed: values.cluster_network_allowed,
      };

      onCreateCompany(dataToCreateCompany);
    };
  
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
        {({
          values,
          isSubmitting,
          touched,
          errors,
          setFieldTouched,
          handleReset,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <Grid container className="pt-2 pb-4">              
              <FormikInput
                mandatory
                id="name"
                name="name"
                label="Company Name"
                value={values.name}
              />
              <FormikInput
                id="email"
                name="email"
                label="Main Email"
                value={values.email}
              />
              <FormikInput
                select
                id="country"
                name="country"
                value={values['country']}
                label="Country">
                {countryMenuItems}
              </FormikInput>

              <FormikInput
                id="phoneNumber"
                name="phoneNumber"
                label="Phone number"
                value={values.phoneNumber}
                placeholder='+380951112233'
              />
              <FormikInput
                id="website"
                name="website"
                label="Website"
                value={values.website}
                className="mb-0"
              />
              <InputLabel htmlFor="subscriptions">Permissions</InputLabel>
              <Grid container className="pt-1 pb-3">
                <PermissionsCheckboxes
                  subscriptionsValues={values.subscriptions}
                  subscriptionsError={errors.subscriptions}
                  levelsValues={values.csLevels}
                  levelsError={errors.csLevels}
                  onSetFieldValue={setFieldValue}
                  onSetFieldTouched={setFieldTouched}
                  editing={true}
                  services={services}
                  levels={levels}
                  rowVariant={false}
                />
              </Grid>
              <InputLabel htmlFor="active">Status</InputLabel>
              <Grid container>
                <FormControl className="mb-2 flex-centered">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox
                      id="active"
                      name="active"
                      color="primary"
                      onChange={(event) => {setFieldValue('active', event.target.checked)}}
                    />}
                    label="Active"
                    className="mr-2" />
                </FormControl>
              </Grid>
              <InputLabel htmlFor="active">Onboarding Allowed</InputLabel>
              <Grid container>
                <FormControl className="mb-4 flex-centered">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox
                      id="onboardingAllowed"
                      name="onboardingAllowed"
                      color="primary"
                      onChange={(event) => {setFieldValue('onboardingAllowed', event.target.checked)}}
                    />}
                    label="Allowed"
                    className="mr-2" />
                </FormControl>
              </Grid>
              <InputLabel htmlFor="active">Cluster Network Allowed</InputLabel>
              <Grid container>
                <FormControl className="mb-4 flex-centered">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox
                      id="clusterNetworkAllowed"
                      name="clusterNetworkAllowed"
                      color="primary"
                      onChange={(event) => {setFieldValue('clusterNetworkAllowed', event.target.checked)}}
                    />}
                    label="Allowed"
                    className="mr-2" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="flex-end" className={"pt-4 pb-4 pr-4"+(isOutherSubmit ? ' hidden' : '')}>
              <Button
                variant="outlined"
                onClick={handleReset}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="mr-0 create-new-company">
                Create
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    );
  };

CreateCompanyForm.defaultProps = {
  onCreateAccount: () => null,
  onClose: () => null,
};

CreateCompanyForm.propTypes = {
  onCreateAccount: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateCompanyForm;
