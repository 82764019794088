// Core
import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable, { TableFilterList } from "mui-datatables";
import { ToastContainer, toast } from 'react-toastify';
import { Formik } from 'formik';
import clsx from 'clsx';
import moment from 'moment';
import * as Yup from 'yup';
// @material-ui
import Chip from '@material-ui/core/Chip';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import DialogTitle from '@material-ui/core/DialogTitle';
// Components
import Button from 'components/CustomButton';
import CustomIconButton from 'components/CustomIconButton';
import CustomTableFilter from 'components/CustomTableFilter/CustomTableFilter';
import EmptyPage from 'components/EmptyPage';
import FormikInput from 'components/FormikInput';
import IconButton from 'components/IconButton';
import PageTitleSection from 'components/PageTitleSection';
import PartnerCard from 'components/Partners/PartnerCard';
import Spinner from 'components/shared/Spinner';
// Hooks
import useAsync from 'hooks/useAsync';
import useDialog from 'hooks/useDialog';
// Context
import { UserContext } from 'context/UserContext';
// Instruments
import { downloadCsv } from 'api/projects';
import { requestForOnboarding } from 'api/users';
import { getAllPartners } from 'api/questionnaire';
// Constants
import { ROLE_ADMIN, EMAIL_REGEX, ACCOUNT_REGEX, PROJECT_LEVELS } from 'utils';

const useStyles = makeStyles( theme => ({
  rowPaging: {
    '& td': {
      paddingBottom: 10,
      margin: 0,
    },
    '&:nth-of-type(4n) td': {
      paddingBottom: 0,
    },
  },

  customTableToolbarOrdering: {
    order: '-1',
  },

  customFilterFooter: {
    position: 'fixed',
    bottom: 0,
    padding: '12px 24px 24px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '408px',
  },

  typeServiceFiltered: {
    color: 'var(--white)',
    backgroundColor: 'var(--primary-regular)',
    height: 32,
    boxShadow: 'none',
    fontSize: 14,
    lineHeight: 'normal',
    margin: '5px 10px 20px 0',
    fontWeight: 500,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'var(--primary-hover)',
    },
    '&.MuiChip-deletable.MuiChip-outlinedPrimary:focus': {
      backgroundColor: 'var(--primary-hover)',
    },
    '& .MuiChip-deleteIcon': {
      width: '1em',
      height: '1em',
      color: 'var(--white)',
      fill: 'var(--white)',
      fontSize: 10,
      margin: '0 10px 0 1px',
    },
    '&.MuiChip-deletable.MuiButton-outlinedPrimary': {
      color: 'var(--primary-regular)',
      backgroundColor: 'var(--white)',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 278
    }
  },

  inputDatePicker: {
    marginTop: '10px',
    marginBottom: '10px',
    border: '1px solid var(--black-20)',
    padding: '0px 10px',
    minHeight: '24px',
    fontSize: '14px',
    color: 'var(--black-100)',
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: '4px',
    whiteSpace: 'normal',
    '&:focus': {
      borderRadius: '4px',
    },
    '&.MuiInput-underline:before': {
      border: 'none !important',
    },
    '&.MuiInput-underline:after': {
      border: 'none !important',
    },
    '&.Mui-disabled': {
      borderColor: 'var(--black-20)',
    },
    '& .MuiInputBase-input': {
      height: 'auto',
      fontWeight: 600,
    },
  },

  placeholderDatePicker: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
  },

  filterCustomReset: {
    margin: 0,
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
  },

  iconCalendar: {
    marginRight: '7px',
    marginTop: '-2px',
    color: 'var(--primary-regular)',
  },
  filterApplied: {
    color: '#ed7916 !important',
    position: 'absolute',
    fontSize: '8px',
    margin: '0 0 13px 13px'
  },
  mainDialog: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '4px',
      boxShadow: 'none',
      maxWidth: 410,
    },
  },
  mainButton: {
    margin: 0
  },
  buttonsWrap: {
    justifyContent: 'flex-end'
  },
  dialogTitleContainer: {
    paddingRight: 12,
    '& .MuiTypography-h6': {
      color: 'var(--navy)',
      fontSize: '22px',
      fontWeight: '600'
    }
  },
  controlLabel: {
    '& .MuiFormControlLabel-label': {
      color: 'var(--black-100)',
    }
  },
  iconButton: {
    padding: 24
  },
  scrollStyle: {
    scrollbarWidth: "thin",
    scrollbarColor: '#183f73 white',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--primary-regular)',
      borderRadius: 10,
    }
  },

  // Request for onboarding modal Note text styles
  onboardingRequestNote: {
    paddingTop: 20,
    textAlign: 'justify',
    lineHeight: '1.2em',
    fontWeight: 500,
    color: 'var(--dark-slate-blue)'
  },
  noteWord: {
    fontWeight: 600,
    color: 'var(--secondary-orange)'
  }
}));

const initialValues = {
  full_name: '',
  email: ''
};

const validationSchema = Yup.object().shape({
  full_name: Yup.string().max(128, 'Must be 128 characters or less')
    .transform((value) => value.trim())
    .matches(ACCOUNT_REGEX, {
      message: 'You should use latin letters',
      excludeEmptyString: true,
    }).required('Required'),
  email: Yup.string()
    .matches(EMAIL_REGEX, {
      message: 'Corporate email required',
      excludeEmptyString: true,
    }).required('Required')
});

const PartnersPage = () => {
  const classes = useStyles();
  const [dataForTable, setDataForTable] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { loggedInUser } = useContext(UserContext);

  const {
    execute: requestForAllPartners,
    pending: pendingRequestForAllPartners,
    value: allPartners
  } = useAsync(getAllPartners, false);

  const {
    execute: sendRequestForOnboarding,
    pending: pendingSendRequestForOnboarding,
    status: statusRequestForOnboarding,
  } = useAsync(requestForOnboarding, false);

  const {
    open: openRequestAccountDialog,
    handleOpen: handleOpenRequestAccountDialog,
    handleClose: handleCloseRequestAccountDialog,
  } = useDialog();

  const startedFilterParameters = { account_id: loggedInUser?.account_id };
  useEffect(() => {
    if (!pendingRequestForAllPartners && loggedInUser) {
      if (!isFiltered) {
        requestForAllPartners(startedFilterParameters);
        setIsFiltered(true);
      }
      if (!pendingRequestForAllPartners && allPartners) {
        const reverseAllPartners = allPartners.reverse();
        setDataForTable(reverseAllPartners);
      }
    }
    // eslint-disable-next-line
  }, [
    pendingRequestForAllPartners,
    loggedInUser,
    requestForAllPartners,
    startedFilterParameters,
    setIsFiltered,
    allPartners,
    setDataForTable
  ]);

  useEffect(() => {
    if (!pendingSendRequestForOnboarding && statusRequestForOnboarding === 200) {
      handleCloseRequestAccountDialog();
      toast.success("Thank you for your request!", { autoClose: 5000 });
    }
  }, [pendingSendRequestForOnboarding, statusRequestForOnboarding, handleCloseRequestAccountDialog]);

  const letUserToCreateRequest = loggedInUser && loggedInUser.role_id === ROLE_ADMIN;
  const downloadBtnClick = () => downloadCsv({});

  const CustomFilterListComponent = (props) => {
    const filterNotApplied = props.filterList.every(item => item.length === 0);
    setIsFilterApplied(!filterNotApplied);
    let countFilledList = 0;
    Object.keys(props.filterList).map(key => {
      if (projectsTableColumns[key].options.filter) {
        const value = props.filterList[key];
        if (value.length > 0)
          countFilledList += 1;
      }
      return true;
    });
    return (<>
      <TableFilterList {...props} />
      {countFilledList > 0 &&
        <div className={classes.filterCustomReset}>
          <Chip
            color="primary"
            variant="outlined"
            deleteIcon={<Icon className="fa fa-times hidden" />}
            className={clsx("MuiButton-outlinedPrimary", classes.typeServiceFiltered)}
            label="Clear All"
            onClick={() => {
              props.options.onFilterChange("clear_all", props.filterList, 'chip', props.options.onFilterChange);
            }}
            onDelete={() => {
              props.options.onFilterChange("clear_all", props.filterList, 'chip', props.options.onFilterChange);
            }}
          />
        </div>
      }
      <Chip
        className="set-type-btn hidden"
        label="set type"
        onClick={() => {
          props.options.onFilterChange("set_type", props.filterList, 'chip', props.options.onFilterChange);
        }}
      />
    </>);
  };

  const getFormatDate = (date) => {
    date = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    return date;
  }

  const filterData = [
    [], [], [],
    [], [], [],
    [...PROJECT_LEVELS],
    [], []
  ];

  const CustomTableFilterComponent = (props) => {
    let newProps = Object.assign({}, props);
    return (
      <CustomTableFilter {...newProps} filterData={filterData} />);
  };

  const DownloadIcon = (props) => {
    return (
      <IconButton inverted="true" component="span">
        <Icon {...props} className="fas fa-file-download" color="primary" />
      </IconButton>
    );
  };

  const FilterIcon = (props) => {
    return (
      <IconButton inverted="true" component="span">
        <Icon {...props} className="fas fa-filter" color="primary" />
        {isFilterApplied ?
          <Icon {...props} className={clsx("fas fa-circle", classes.filterApplied)} /> : null
        }
      </IconButton>
    );
  };

  let iconCalendar = (<Icon className={clsx("fas fa-calendar-alt ", classes.iconCalendar)} />);

  const createNewRequest = () => {
    handleOpenRequestAccountDialog();
  }

  const CustomTooltipComponent = (props) => {
    return (<Tooltip {...props} arrow={true} />);
  };

  const customToolbarFunction = () => {
    return (
      letUserToCreateRequest &&
      <Button
        className={classes.customTableToolbarOrdering}
        onClick={createNewRequest}
        disabled={false}>
        Request for Onboarding
      </Button>
    )
  };

  const customFilterDialogFooter = (currentFilterList, applyFilters, resetFilters, handleClose) => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.customFilterFooter}>
        <Grid container item xs={3}>
          <Button
            variant="outlined"
            onClick={() => {
              resetFilters();
              requestForAllPartners(startedFilterParameters);
              setIsFiltered(false);
            }}>
            Clear All
          </Button>
        </Grid>
        <Grid container item xs={9} justify="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              if (!!handleClose) handleClose();
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleFilterSubmit(applyFilters);
              //TODO loadItemsPage(0);
            }}>
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  };

  const onSubmit = (values) => {
    sendRequestForOnboarding(values);
    // handleCloseRequestAccountDialog();
  }

  const disableSubmitButton = ({ email, full_name }, errors) => {
    return [email, full_name].includes("") || Object.keys(errors).length !== 0;
  }

  const projectsTableColumns = [
    {
      name: "id",
      label: "id",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "legal_name",
      label: "Counterparty Name",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "registered_number",
      label: "Registered Number",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "country_code_registration",
      label: "Country",
      options: {
        download: false,
        display: false,
        filter: false,
      }
    },
    {
      name: "country_name",
      label: "Country name",
      options: {
        download: false,
        display: false,
        filter: false,
      }
    },
    {
      name: "respondent_date",
      label: "Application Date",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "risk",
      label: "Risk Grade",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          fullWidth: true,
          renderChipedSelectedValue: true,
          noneValueText: "All risks"
        },
        customFilterListOptions: {
          render: v => {
            if (v) {
              return `Risk: ${v}`;
            }
            return false;
          },
        },
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "revision_date",
      label: "Review Date",
      options: {
        download: true,
        display: false,
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              return `Revision Date: ${moment(v[0]).format('DD MMM YYYY')} - ${moment(v[1]).format('DD MMM YYYY')}`;
            } else if (v[0]) {
              return `Revision Date: from ${moment(v[0]).format('DD MMM YYYY')}`;
            } else if (v[1]) {
              return `Revision Date: to ${moment(v[1]).format('DD MMM YYYY')}`;
            }
            return false;
          },
        },
        filterOptions: {
          fullWidth: true,
          showLabel: true,
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            check.setDate(check.getDate() + 1);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            check = new Date(check).setHours(0, 0, 0, 0);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);
            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant="inline"
                  // disablePast={true}
                  disableToolbar={true}
                  emptyLabel=""
                  label=""
                  defaultValue={null}
                  name="launchDateFrom"
                  format="DD MMM YYYY"
                  value={filterList[index][0] ? moment(filterList[index][0]).format('DD MMM YYYY') : null}
                  placeholder="Select date from"
                  InputProps={{
                    className: clsx({
                      [classes.inputDatePicker]: true,
                      [classes.placeholderDatePicker]: !filterList[index][0]
                    }),
                    startAdornment: iconCalendar,
                  }}
                  onChange={date => {
                    filterList[index][0] = date._d;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '49%', marginRight: '1%' }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant="inline"
                  // disablePast={true}
                  disableToolbar={true}
                  emptyLabel=""
                  label=""
                  name="launchDateTo"
                  format="DD MMM YYYY"
                  value={filterList[index][1] ? moment(filterList[index][1]).format('DD MMM YYYY') : null}
                  placeholder="Select date to"
                  InputProps={{
                    className: clsx({
                      [classes.inputDatePicker]: true,
                      [classes.placeholderDatePicker]: !filterList[index][1]
                    }),
                    startAdornment: iconCalendar,
                  }}
                  onChange={date => {
                    filterList[index][1] = date._d;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '49%', marginLeft: '1%' }}
                />
              </MuiPickersUtilsProvider>
            </div>
          ),
        },
      }
    },
    {
      name: "relation_account",
      label: "Relation Account",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "full_name",
      label: "Full name",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "active",
      label: "Respondent status",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
    {
      name: "relation_user",
      label: "Relation user",
      options: {
        download: true,
        display: false,
        filter: false,
      }
    },
  ];

  const tableTextLabels = {
    body: {
      noMatch: "Sorry, no matching records found"
    },
    toolbar: {
      downloadCsv: "Export partners",
      filterTable: "Filter partners",
    },
    filter: {
      all: "All",
      title: "Filters"
    }
  };

  const customRowRenderFunction = (colData, rowIndex) => {
    if (pendingRequestForAllPartners) {
      return (<Spinner key={rowIndex} />);
    }

    if (!allPartners && isFiltered) {
      if (rowIndex === 0) {
        return (
          letUserToCreateRequest
            ? <EmptyPage
              key={rowIndex}
              height={65}
              hasButton={true}
              handleClick={createNewRequest}
              title="request for onboarding"
              disabled={false}
            />
            : <EmptyPage height={65} title="request for onboarding" key={rowIndex} />
        );
      }
      return ('');
    };

    const [id, legal_name, registered_number, country_code_registration,
      country_name, respondent_date, risk, status, revision_date, relation_account,
      full_name, email, active, relation_user] = colData;
    const obProject = {
      'id': id,
      'legal_name': legal_name,
      'registered_number': registered_number,
      'country_code_registration': country_code_registration,
      'country_name': country_name,
      'respondent_date': respondent_date,
      'risk': risk,
      'status': status ? status : '',
      'revision_date': revision_date,
      'relation_account': relation_account,
      'full_name': full_name,
      'email': email,
      'active': active,
      'relation_user': relation_user
    };
    const linkToCard = `/partners/${id}`;
    return (
      <tr key={rowIndex} className={classes.rowPaging}>
        <td colSpan={0}>
          <PartnerCard key={id} to={linkToCard} variantTiny={true} {...obProject} />
        </td>
      </tr>
    );
  };

  const handleFilterChange = (changedColumn, filterList, type, onFilterChange = () => { }) => {
    if (type === 'chip' && changedColumn === 'clear_all') {
      Object.keys(filterList).map(key => {
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter && value.length > 0) {
          const name = projectsTableColumns[key].name;
          filterList[key] = []; // clear all
          onFilterChange(name, filterList, 'chip');
        }
        return true;
      });
    } else if (type === 'chip' && changedColumn === 'set_type') {
      Object.keys(filterList).map(key => {
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter && value.length === 0) {
          // TODO!!!!!!! 'name' never used!
          // eslint-disable-next-line
          const name = projectsTableColumns[key].name;
        }
        return true;
      });
    }

    if (typeof changedColumn === 'string') {
      let parameters = {};
      Object.keys(filterList).map(key => {
        const name = projectsTableColumns[key].name;
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter && value.length > 0) {
          if (name !== "revision_date") {
            parameters[name] = value;
          } else {
            if (value[0]) {
              let dateObject = new Date(value[0]);
              parameters[name + '_from'] = getFormatDate(dateObject);
            }
            if (value[1]) {
              let dateObject = new Date(value[1]);
              parameters[name + '_to'] = getFormatDate(dateObject);
            }
          }
        }
        return true;
      });
      requestForAllPartners({ ...parameters, account_id: loggedInUser.account_id });
      setIsFiltered(true);
    }
  };

  const handleFilterSubmit = (applyFilters) => {
    if (!!applyFilters) {
      let filterList = applyFilters();
      let parameters = {};
      Object.keys(filterList).map(key => {
        const name = projectsTableColumns[key].name;
        const value = filterList[key];
        if (projectsTableColumns[key].options.filter) {
          if (value.length > 0) {
            if (name === "revision_date") {
              if (value[0]) {
                let dateObject = new Date(value[0]);
                parameters[name + '_from'] = getFormatDate(dateObject);
              }
              if (value[1]) {
                let dateObject = new Date(value[1]);
                parameters[name + '_to'] = getFormatDate(dateObject);
              }
            } else {
              parameters[name] = value;
            }
          }
        }
        return true;
      });
      requestForAllPartners({ ...parameters, account_id: loggedInUser.account_id });
      if (!pendingRequestForAllPartners) {
        setIsFiltered(true);
      }
    }
  };

  const handleBackdrop = () => {
    let filter = document.querySelector('.customized-filter');
    let filterParent = filter && filter.parentElement;
    let rolePresentation = filterParent && filterParent.parentElement;
    let filterBackdrop = rolePresentation && rolePresentation.querySelector('div[aria-hidden="true"]');
    if (filterBackdrop) {
      filterBackdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.34)';
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleBackdrop);
    return () => {
      document.removeEventListener('click', handleBackdrop)
    }
  }, [])
  

  const renderContent = dataForTable
    ? (<MUIDataTable
      title={<Typography variant="h1">Third Parties</Typography>}
      data={dataForTable}
      columns={projectsTableColumns}
      className={classes.projectsTableStyle}
      options={{
        responsive: "vertical",
        selectableRows: "none",
        download: false,
        pagination: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        tableBodyHeight: "100%",
        filter: true,
        filterType: "dropdown",
        confirmFilters: true,
        showStandardResetButton: false,
        onDownload: () => {
          downloadBtnClick();
          return false;
        },
        customFilterDialogFooter: customFilterDialogFooter,
        setFilterChipProps: () => {
          return {
            color: "primary",
            variant: "contained",
            deleteIcon: <Icon className="fa fa-times" />,
            className: clsx("MuiButton-containedPrimary", classes.typeServiceFiltered),
          };
        },
        textLabels: tableTextLabels,
        onFilterChange: handleFilterChange,
        serverSide: true,
        customToolbar: customToolbarFunction,
        customRowRender: customRowRenderFunction
      }}
      components={{
        TableFilter: CustomTableFilterComponent,
        TableFilterList: CustomFilterListComponent,
        Tooltip: CustomTooltipComponent,
        icons: {
          DownloadIcon,
          FilterIcon
        }
      }}
    />)
    : (<React.Fragment>
      <PageTitleSection title="Third Parties">
        <Grid
          container
          direction="row"
          alignItems="center">
          {letUserToCreateRequest &&
            <Button
              className="mr-0"
              onClick={createNewRequest}
              disabled={false}>
              Request for Onboarding
            </Button>}
        </Grid>
      </PageTitleSection>
      {pendingRequestForAllPartners ? <Spinner /> : (
        letUserToCreateRequest
          ? <EmptyPage
            height={65}
            hasButton={true}
            handleClick={createNewRequest}
            title="request for onboarding"
            disabled={false}
          />
          : <EmptyPage height={65} title="request for onboarding" />
      )}
    </React.Fragment>);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch">
      <Grid
        container
        direction="column"
        justify="flex-start"
        style={{ minHeight: '60vh', display: "block" }}
      >
        {
          renderContent
        }
      </Grid>
      <Dialog
        className={classes.mainDialog}
        open={openRequestAccountDialog}
        onClose={handleCloseRequestAccountDialog}
        scroll="paper"
        aria-labelledby="form-dialog-title">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.dialogTitleContainer}>
          <DialogTitle
            id="form-dialog-title"
          >
            Request for onboarding
          </DialogTitle>
          <CustomIconButton
            icon="fas fa-times"
            tooltipText="Close"
            onIconButtonClick={handleCloseRequestAccountDialog}
          />
        </Grid>
        <Divider />
        <DialogContent className={classes.scrollStyle}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}>
            {({
              values,
              errors,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Grid container className="pt-2">
                  <FormikInput
                    mandatory
                    id="full_name"
                    name="full_name"
                    label="Full name"
                    value={values.full_name}
                  />
                  <FormikInput
                    mandatory
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                  />
                </Grid>
                <Grid container className={clsx(classes.buttonsWrap, "mozPad24Buttons")}>
                  <Button
                    onClick={handleCloseRequestAccountDialog}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="ml-3"
                    disabled={disableSubmitButton(values, errors)}
                    color="primary"
                  >
                    Submit request
                  </Button>
                </Grid>
                <Grid className={classes.onboardingRequestNote}>
                  <span className={classes.noteWord}>Note:</span> If current email was used for the registration of the active account, the system will not submit this Request. 
                  Please either insert a new email or ask your Third Party to log in using this email and previous password.
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </Grid>
  );
};

export default PartnersPage;
