// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
// Components
import { ViewDetails } from 'components/shared';
import FormInfoSection from 'components/FormInfoSection';
import IconButton from 'components/IconButton';
// Constants
import { ROLE_ADMIN_READ_ONLY } from 'utils';
import Textarea from "../../Textarea";

const TechIntelligenceDetailsCard = ({
  country,
  countryCode,
  industry,
  expectations,
  challenge_problem,
  handleBack,
  logginUserRole,
}) => {
  return (
    <Grid>
      <Typography variant="h3" className="pl-0 pb-3 pt-3">
        General information
        <IconButton
          disabled={logginUserRole === ROLE_ADMIN_READ_ONLY}
          inverted="true"
          className="minus-mr-5 minus-mt-5 float-right"
          onClick={handleBack}>
          <Icon className="fa fa-pen" />
        </IconButton>
      </Typography>
      <Grid container direction="row">
        <ViewDetails
          fullWidth
          label={<FormInfoSection text="Country" />}
          name={country}
          countryCode={countryCode}
        />
      </Grid>
      <Grid container direction="row">
        <ViewDetails
          fullWidth
          label={<FormInfoSection text="Industry" />}
          name={industry}
        />
      </Grid>
      <Grid container direction="column" className="pb-3">
        <FormInfoSection
          text="Describe your challenge/problem"
          isCapitalize={false}
        />
        <Textarea
          disabled
          id="challenge_problem"
          name="techIntelligence.challenge_problem"
          value={challenge_problem}
        />
        <FormInfoSection text="Your expectations" isCapitalize={false} />
        <Textarea
          disabled
          id="expectations"
          name="techIntelligence.expectations"
          value={expectations}
        />
      </Grid>
      <Divider className="mt-1 mb-2 minus-m-25" />
    </Grid>
  );
};

TechIntelligenceDetailsCard.defaultProps = {
  country: '',
  countryCode: '',
  industry: '',
  expectations: '',
  challenge_problem: '',
  handleBack: () => null,
};

TechIntelligenceDetailsCard.propTypes = {
  country: PropTypes.string,
  countryCode: PropTypes.string,
  industry: PropTypes.string,
  expectations: PropTypes.string,
  challenge_problem: PropTypes.string,
  handleBack: PropTypes.func,
};

export default TechIntelligenceDetailsCard;
