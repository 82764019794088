import React from 'react';

const IntelligenceIcon = props => {
    const { color } = props;
    
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="80" 
            height="80" 
            viewBox="0 0 80 80">
            <g 
                fill="none" 
                fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path 
                                d="M0 0H80V80H0z" 
                                transform="translate(-873 -716) translate(858 580) translate(15 136)"/>
                            <path 
                                fill={color ? color : "#FFF"} 
                                d="M49.655 4c.666 0 1.207.54 1.207 1.207l-.001 1.206h22.932c.625 0 1.139.476 1.2 1.084l.007.124v45.862c0 .666-.54 1.207-1.207 
                                1.207l-20.517-.001v1.208c0 .624-.475 1.138-1.084 1.2l-.123.006h-3.121l17.25 17.25c.44.441.47 1.135.088 
                                1.61l-.088.098c-.235.235-.544.353-.853.353-.309 0-.618-.118-.854-.353L45.534 57.103h-4.328v18.104c0 .624-.475 1.139-1.083 
                                1.2l-.123.007c-.666 0-1.207-.541-1.207-1.207l-.001-18.104h-4.327L15.508 76.061c-.202.201-.457.317-.72.346l-.133.007c-.31 
                                0-.618-.118-.853-.353-.472-.473-.472-1.235 0-1.707l17.25-17.251h-3.12c-.625 0-1.14-.475-1.202-1.083l-.006-.123v-1.208H6.206c-.624 
                                0-1.138-.475-1.2-1.082L5 53.483V7.621c0-.667.54-1.207 1.206-1.207l22.931-.001V5.207c0-.625.476-1.139 1.085-1.2L30.345 4zm1.207 
                                48.276H29.138v2.414h21.724v-2.414zM29.138 8.827H7.414v43.449h19.31v-1.207c0-.666.54-1.207 1.207-1.207H52.07l.123.007c.609.061 1.084.576 
                                1.084 1.2v1.207h19.31V8.827H50.862v1.207c0 .667-.541 1.208-1.207 1.208h-19.31c-.667 0-1.207-.541-1.207-1.208V8.827zM63.79 16.07c.952 0 
                                1.727.775 1.727 1.727l-.001 24.824h2.242c.625 0 1.14.476 1.201 1.085l.007.123c0 .666-.541 1.206-1.208 1.206H12.241c-.666 0-1.207-.54-1.207-1.206 
                                0-.666.541-1.207 1.207-1.207l2.241-.001v-7.843c0-.902.696-1.645 1.579-1.721l.149-.006h7.408c.952 0 1.727.774 1.727 1.727l-.001 
                                7.843h2.586V31.072c0-.902.696-1.645 1.579-1.72l.149-.007h7.408c.952 0 1.727.775 1.727 1.727l-.001 11.548h2.414V22.624c0-.902.696-1.645 
                                1.579-1.72l.149-.007h7.408c.952 0 1.727.775 1.727 1.727l-.001 19.996h2.587V17.796c0-.902.696-1.645 1.578-1.72l.149-.007zm-.687 
                                2.414H57.07v24.138h6.034V18.483zM36.38 31.76h-6.034V42.62h6.034V31.759zm-13.448 3.704h-6.035v7.158h6.035v-7.158zM49.655 
                                23.31h-6.034v19.31h6.034V23.31zm-21.897-4.827c.667 0 1.208.54 1.208 1.207 0 .666-.541 1.207-1.208 1.207h-4.827c-.666 0-1.207-.54-1.207-1.207s.54-1.207 
                                1.207-1.207zm-9.655 0c.667 0 1.207.54 1.207 1.207 0 .666-.54 1.207-1.207 1.207H15.69c-.667 0-1.207-.54-1.207-1.207s.54-1.207 
                                1.207-1.207zm2.415-4.828c.665 0 1.206.541 1.206 1.207s-.54 1.207-1.206 1.207H15.69c-.667 0-1.207-.54-1.207-1.207 0-.666.54-1.207 
                                1.207-1.207zm7.24 0c.667 0 1.208.541 1.208 1.207s-.541 1.207-1.208 1.207h-2.413c-.666 0-1.207-.54-1.207-1.207 0-.666.54-1.207 
                                1.207-1.207zm20.69-7.241H31.551v2.413h16.897V6.414z" 
                                transform="translate(-873 -716) translate(858 580) translate(15 136)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
} 

export default IntelligenceIcon;
